<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('headerData.userGroups', { }, { locale: lang }) }}
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('add:UserGroups', userInfo.access).length > 0)"
                    type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary" :style="{'margin-left':'10px'}"
                    :title="t('headerData.createRols', {}, { locale: lang })" data-placement="bottom"
                    @click="changeModalAddEditGroups()">
                    <i class="fas fa-plus-circle style-1icon"></i>
                  </button>
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalUserGroups()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" :style="{ 'max-height': '65vh', 'min-height': '40vh', 'overflow-x':'hidden', 'overflow-y':'auto'}">
                <table id="userGroupsTable" class="table table-striped table-bordered cell-border compact stripe">
                  <thead class="text-center">
                    <tr>
                      <th>{{ t('headerData.name', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.teams', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.users', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.createdDate', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.options', {}, {locale: lang}) }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr v-for="group in groups" :key="group.id">
                      <td>{{ group.name }}</td>
                      <td>{{ group.Team.name }}</td>
                      <td>{{ group.users.length }}</td>
                      <td>{{ moment(group.createdAt).format('MM-DD-YYYY') }}</td>
                      <td>
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:UserGroups', userInfo.access).length > 0)"
                          type="button" :title="t('dashboardData.viewDetails', {}, { locale: lang })" @click="chageModalShowInfo(group)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px'}" class="fas fa-eye"></i>
                        </button>

                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('edit:UserGroups', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.updatedRols', {}, { locale: lang })" @click="changeModalAddEditGroups(group)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px'}" class="fas fa-edit"></i>
                        </button>
                        
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('delete:UserGroups', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.delete', {}, { locale: lang })" @click="changemodalDeleteGroups(group)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px', 'color':'red'}" class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="changeModalUserGroups()">
                {{ t('button.close', { }, { locale: lang }) }}
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <!-- Modal para agregar o editar un grupo de usuarios -->
  <div v-if="modalAddEditNewGroup">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t(formGroup.new ? 'headerData.createRols' : 'headerData.updatedRols', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalAddEditGroups()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">

                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.name', { }, { locale: lang }) }}</label>
                      <input name="name" id="name" placeholder="" type="text" class="form-control form-control-sm"
                        v-model="formGroup.name" required>
                      <span class="helper-text danger-text">{{ formGroup.errorName }}</span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.teams', { }, { locale: lang }) }}</label>
                      <select class="multiselect-dropdown form-control form-control-sm" v-model="formGroup.team" @change="loadUsersByTeam()">
                        <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                        <option v-for="t in teamsFilters" :key="t.id" :value="t.id">{{ t.name }}</option>
                      </select>
                      <span class="helper-text danger-text">{{ formGroup.errorTeam }}</span>
                    </div>
                  </div>

                  <div v-show="formGroup.team != 0" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="form-group row" :style="{'padding':'8px'}">
                      <label for="" class="">{{ t('headerData.users', { }, { locale: lang }) }}</label>
                      <div class="form-check col-sm-12 col-md-4 col-lg-4 col-xl-4" v-for="u in uFilters" :key="u.id">
                        <input type="checkbox" :value="u.user_email" v-model="formGroup.users" :id="u.id" class="form-check-input" />
                        <label class="form-check-label" :for="u.id">{{ u.user_name }}</label>
                      </div> 

                      <span class="helper-text danger-text">{{ formGroup.errorUsers }}</span>
                    </div>
                  </div>

                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="addEditUserGroups()">
                  {{ t(formGroup.new ? 'button.add' : 'button.save', { }, { locale:lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  @click="changeModalAddEditGroups()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para agregar o editar un grupo de usuarios -->  


  <div v-if="modalDeleteGroup">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t('headerData.delete', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changemodalDeleteGroups()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h6>{{ t('headerData.confirmationDelete', { }, { locale: lang }) }}</h6>
                    <b>{{ groupItem.name }}</b>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deteleOneUserGroup()">
                  {{ t('button.delete', {}, {locale: lang}) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  @click="changemodalDeleteGroups()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>


  <div v-if="modalShowGroup">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t('headerData.userGroups', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="chageModalShowInfo()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">

                <div class="row">

                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                    <h4>{{ groupItem.name }}</h4>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                    <div class="row">
                      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        {{ t('headerData.teams', { }, { locale: lang }) }} : {{ groupItem.Team.name }}
                      </div>

                      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        {{ t('headerData.createdDate', { }, { locale: lang }) }} : {{ moment(groupItem.createdAt).format('MM-DD-YYYY') }}
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" :style="{'margin-top':'1.5rem'}">
                    <h5 class="card-title text-center">{{ t('headerData.users', { }, { locale: lang }) }}</h5>
                    <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                      <div class="vertical-timeline-item dot-info vertical-timeline-element" v-for="user in groupItem.users" :key="user.id">
                        <div>
                          <span class="vertical-timeline-element-icon bounce-in"></span>
                          <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">{{ `${user.name} ${user.surname}` }} - {{ user.email }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="chageModalShowInfo()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>


<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { showInfoData, checkError401, checkSuperAdminRol, getTeamsByPermissions } from '@/utility/functions';
import { requiredFields, requiredFieldsNumberSelect, } from '@/utility/validations';
import 'datatables.net-bs5';
import $ from 'jquery';
export default {
  name: 'ModalManageUserGroups',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      showInfoData,
      moment,
      checkSuperAdminRol,
      getTeamsByPermissions
    };
  },
  components: {
  },
  data: function () {
    return {
      groupItem: {},
      modalAddEditNewGroup: false,
      formGroup: {
        new: true,
        name: '',
        errorName: '',
        team: 0,
        errorTeam: '',
        users: [],
        errorUsers: '',
      },
      modalDeleteGroup: false,
      modalAssignPermissions: false,
      uFilters: [],
      teamsFilters: [],
      modalShowGroup: false,
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang', 'modalRols']),
    ...mapState('userGroupsData', ['groups', 'filter', 'errorGroups']),
    ...mapState('teamsData', ['teams', 'errorTeams']),
    ...mapState('usersData', ['usersFilters', 'errorUsers']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['changeModalUserGroups']),
    ...mapActions('userGroupsData', ['getUserGroupsData', 'registerUserGroups', 'updateUserGroupsData', 'deleteUserGroupsData']),
    ...mapActions('teamsData', ['getTeamsData', 'checkTeamsLimitsUsers']),
    ...mapActions('usersData', ['getUsersByTeamsData2']),


    table() {
      this.$nextTick(() => {
        $('#userGroupsTable').DataTable();
      });
    },
  
    /** Funcion para abrir modal para añadir o editar rol */
    changeModalAddEditGroups(item = {}) {

      if (Object.entries(item).length > 0) {

        this.groupItem = item;
        this.formGroup.new = false;
        this.formGroup.name = item.name;
        this.formGroup.errorName = '';
        this.formGroup.team = item.team_id;
        this.formGroup.errorTeam = '';
        item.users.forEach(it => {
          this.formGroup.users.push(it.email);
        }) 
        this.formGroup.errorUsers = '';
        this.loadUsersByTeam();

      } else {

        this.groupItem = item;
        this.formGroup = {
          new: true,
          name: '',
          errorName: '',
          team: 0,
          errorTeam: '',
          users: [],
          errorUsers: '',
        };

        this.uFilters = [];
      }

      this.modalAddEditNewGroup = !this.modalAddEditNewGroup;

      /** Filtrar los team */
      if (checkSuperAdminRol(this.userInfo.access)) {

        this.teamsFilters = this.teams;

      } else if (getTeamsByPermissions('add:UserGroups', this.userInfo.access).length > 0) {
        let teamsData = getTeamsByPermissions('add:UserGroups', this.userInfo.access);
        this.teams.forEach(item => {
          if (teamsData.includes(item.id)) {
            this.teamsFilters.push(item);
          }
        });
      }
       
    },


    /** Funcion para cargar los usuarios del team seleccionado */
    async loadUsersByTeam(){
      if(this.formGroup.team != 0) {
        await this.getUsersByTeamsData2(this.formGroup.team);
        this.uFilters = this.usersFilters;
        
        if(this.formGroup.new) {

          this.formGroup.users = [];

        } else {
          if(this.formGroup.team == this.groupItem.team_id) {
            this.groupItem.users.forEach(it => {
              this.formGroup.users.push(it.email);
            }) 
          } else {
            this.formGroup.users = [];
          }
        }

      } else {
        this.uFilters = [];
      }
    },


    /** Funcion para registrar o editar un grupo de usuarios */
    async addEditUserGroups() {

      this.formGroup.errorName = requiredFields(this.formGroup.name, this.lang);
      this.formGroup.errorTeam = requiredFieldsNumberSelect(this.formGroup.team, this.lang);
      this.formGroup.errorUsers = requiredFieldsNumberSelect(this.formGroup.users.length, this.lang);

      console.log(this.formGroup.users.length)

      if (this.formGroup.errorName == '' && this.formGroup.errorTeam == '' && this.formGroup.errorUsers == '') {

        if (this.formGroup.new) {

          await this.registerUserGroups(this.formGroup);
          if (checkError401(this.errorGroups)) { this.logOut(); }
          if (this.errorGroups == null) {
            this.changeModalAddEditGroups();
          }

        } else {

          await this.updateUserGroupsData({ ...{ id: this.groupItem.id }, ...this.formGroup });
          if (checkError401(this.errorGroups)) { this.logOut(); }
          if (this.errorGroups == null) {
            this.changeModalAddEditGroups();
          }
        }
      }

    },


    /** Funcion para cambiar el estado del modal eliminar grupo */
    changemodalDeleteGroups(item = {}) {

      this.groupItem = item;
      this.modalDeleteGroup = !this.modalDeleteGroup;
    },

    /** Funcion para eliminar un grupo de usuarios */
    async deteleOneUserGroup() {

      if(Object.entries(this.groupItem).length > 0){

        await this.deleteUserGroupsData(this.groupItem.id);
        if (checkError401(this.errorGroups)) { this.logOut(); }
        if (this.errorGroups == null) {
          this.changemodalDeleteGroups();
        }

      }
    },


    /** Funcion para cambiar el estado del modal informacion de grupo */
    chageModalShowInfo(item = {}) {
      this.groupItem = item;
      this.modalShowGroup = !this.modalShowGroup;
    }

  },
  mounted: async function () {

    await this.getUserGroupsData();
    if (checkError401(this.errorGroups)) { this.logOut(); }
    if(this.errorGroups == null){
      this.table();
    }

    await this.getTeamsData();
    this.uFilters = [];
  }
}
</script> 


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>