export default {

  /** Funcion para reiniciar la data a sus estados iniciales */
  resetDataLabelsSuccess(state) {
    state.loading = false;
    state.labels = [];
    state.errorLabels = null;
    state.resultAction = {};
  },


  /** Funciones para Obtener las etiquetas de un usuario  */
  getLabelsDataBegin(state) {
    state.loading = true;
  },

  getLabelsDataSuccess(state, data) {
    state.loading = false;
    state.labels = data;
    state.errorLabels = null;
  },

  getLabelsDataErr(state, err) {
    state.loading = false;
    state.errorLabels = err;
  },
  /** Funciones para Obtener las etiquetas de un usuario */


  /** Funciones para agregar una nueva etiqueta a las etiquetas del usuario */
  addNewLabelBegin(state) {
    state.loading = true;
  },

  addNewLabelSuccess(state, data) {
    state.loading = false;
    if (data.tp == 0) {
      state.labels.unshift(data.data.label);
    } else {
      state.labels.unshift(data.data.label);
      state.resultAction = data.data;
    }
    state.errorLabels = null;
  },

  addNewLabelErr(state, err) {
    state.loading = false;
    state.errorLabels = err;
  },
  /** Funciones para agregar una nueva etiqueta a las etiquetas del usuario */


  /** Funciones para registrar una relacion (tarjeta - etiqueta) */
  addLabelRelationToCardBegin(state) {
    state.loading = true;
  },

  addLabelRelationToCardSuccess(state, data) {
    state.loading = false;
    state.resultAction = data;
    state.errorLabels = null;
  },

  addLabelRelationToCardErr(state, err) {
    state.loading = false;
    state.errorLabels = err;
  },
  /** Funciones para registrar una relacion (tarjeta - etiqueta) */


  /** Funciones para editar una etiqueta */
  updateLabelBegin(state) {
    state.loading = true;
  },

  updateLabelSuccess(state, data) {
    state.loading = false;
    state.labels.forEach((item, index) => {
      if (item.id === data.id) {
        state.labels[index] = data;
      }
    });
    state.resultAction = data;
    state.errorLabels = null;
  },

  updateLabelErr(state, err) {
    state.loading = false;
    state.errorLabels = err;
  },
  /** Funciones para editar una etiqueta */


  /** Funciones para eliminar una etiqueta */
  deleteLabelBegin(state) {
    state.loading = true;
  },

  deleteLabelSuccess(state, data) {
    state.loading = false;
    if (state.labels.length > 0) {
      state.labels = state.labels.filter(item => item.id !== data.label);
    }
    state.resultAction = data;
    state.errorLabels = null;
  },

  deleteLabelErr(state, err) {
    state.loading = false;
    state.errorLabels = err;
  },
  /** Funciones para eliminar una etiqueta */


};