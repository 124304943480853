<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header row">
                <div class="col-md-12 text-center">
                  <h5 class="modal-title" id="exampleModalLongTitle"><b>Terms & Conditions</b></h5>
                </div>
              </div>
              <div ref='contenDisplay' class="modal-body scroll-area-lg" :style="{'overflow':'auto'}" @scroll="showButtonOpt()">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <b>
                      Beta Participation Agreement (BPA) <br>
                      360 Technologies USA LLC <br>
                      June, 2018
                    </b>
                  </div>
                  <div class="col-md-12 text-center" :style="{'margin-top':'10px', 'margin-bottom':'15px'}">
                    <b>Beta Participation Agreement (BPA)</b>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Arrangement and Definitions</b>
                    <p>
                      “Scope of this Agreement <br>
                      The Software-Product accompanying this Agreement as a pre-release copy and all affiliated
                      materials, including documentation and information (collectively the “Product”), is copyrighted.
                      Scope of this agreement is the licensing (not selling) of the “Product” to You, as the ‘user’
                      (either an individual or an entity). 360 Technologies USA LLC (“360 Tech”) reserves all rights not
                      expressly granted. “Party”, “Parties”, or “Us”, refers to both the User and 360 Tech.
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Eligibility and Enrollment</b>
                    <p>
                      “Please read and agree to the following terms and conditions, if you wish to be eligible to
                      participate in the 360 Beta Testing. However, we do not guarantee that you will be selected to
                      participate in the 360 Beta Testing. <br>
                      BY SELECTING THE “ACCEPT” BUTTON, YOU ACKNOWLEDGE THAT: <br>
                      (1) YOU ARE 21 YEARS OF AGE OR OLDER; AND <br>
                      (2) YOU HAVE READ, UNDERSTOOD, AND ACCEPTED THE TERMS AND CONDITIONS OF THIS AGREEMENT.”
                    </p>

                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Copyrights and Ownership</b>
                    <p>
                      “Ownership and Copyright of Software Title to the Software and all copies thereof remain with 360
                      Tech and/or its suppliers. The Software is copyrighted and is protected by United States copyright
                      laws and international treaty provisions. User will not remove copyright notices from the
                      Software. User agrees to prevent any unauthorized copying of the Software. Except as expressly
                      provided herein, 360 Tech does not grant any express or implied right to you under 360 Tech
                      patents, copyrights, trademarks, or trade secret information.”
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>License and Acceptable Use</b>
                    <p>
                      “Limited License. You are entitled to access, download or install, and operate the Product solely
                      for the purposes of performing your obligations under this Agreement. You may not sell, license,
                      or transfer the Product, or reproductions of the Product to other parties in any way. You may
                      download or Install and operate the Product on Android, Apple and Microsoft devices linked to the
                      email address provided on sign-up.”
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Disclaimer of Liability and Warranties (Beta Disclaimer)</b>
                    <p>
                      “Limitation on Liability Provision of any Software under this Agreement is experimental and shall
                      not create any obligation for 360 Tech to continue to develop, productize, support, repair, offer
                      for sale or in any other way continue to provide or develop the Product either to User or to any
                      other party. THE PRODUCT IS PROVIDED “AS IS” WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OF ANY KIND
                      INCLUDING WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE. IN NO EVENT SHALL
                      360 Tech OR ITS SUPPLIERS BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION,
                      DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION) ARISING OUT OF THE USE OF
                      OR INABILITY TO USE THE PRODUCT, EVEN IF 360 Tech HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                      DAMAGES.”
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>TERM AND TERMINATION</b>
                    <p>
                      “Unless otherwise terminated as specified under this Agreement. User’s rights with respect to the
                      Beta Product will terminate upon the earlier of (a) the initial commercial release by Talend of a
                      generally available version of the Beta Product or (b) automatic expiration of the Beta Product
                      based on the system date. Either party may terminate this Agreement at any time for any reason or
                      no reason by providing the other party advance written notice thereof. 360 Tech shall immediately
                      terminate this Agreement and any User rights with respect to the Beta Product without notice in
                      the event of improper disclosure of 360 Tech’s Beta Product as specified (Confidentiality) below.
                      Upon any expiration or termination of this Agreement, the rights and licenses granted to User
                      under this Agreement shall immediately terminate, and User shall immediately cease using, and will
                      return to 360 Tech (or, at 360 Tech’s requests, destroy), the Beta Product, and all other tangible
                      items in User’s possession or control that are proprietary to or contain Confidential
                      Information.”
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Feedback</b>
                    <p>
                      “USER DUTIES <br>
                      User agrees to report any flaws, errors or imperfections discovered in the Product or other
                      materials where User has been granted access to the Beta Test. User understands that prompt and
                      accurate reporting is the purpose of the Beta Tests and undertakes to use best efforts to provide
                      frequent reports on all aspects of the product both positive and negative and acknowledges that
                      any improvements, modifications and changes arising from or in connection with the Users
                      contribution to the Project, remain or become the exclusive property of the Disclosing Party
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Confidentiality and Non-Disclosure</b>
                    <p>
                      “Confidentiality <br>
                      The User will not disclose Product or any comments regarding Product to any third party without
                      the prior written approval of 360 Tech. The User will maintain the confidentiality of Product with
                      at least the same degree of care that you use to protect your own confidential and proprietary
                      information, but not less than a reasonable degree of care under the circumstances. The User will
                      not be liable for the disclosure of any confidential information which is: <br>
                      (a.) in the public domain other than by a breach of this Agreement on User’s part; or <br>
                      (b.) rightfully received from a third party without any obligation of confidentiality; or <br>
                      (c.) rightfully known to User without any limitation on use or disclosure prior to its receipt
                      from 360 Tech; or <br>
                      (d.) generally made available to third parties by 360 Tech without restriction on disclosure.”
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Support</b>
                    <p>
                      “No Support and Maintenance; Future Products. <br>
                      During your participation in the 360 Beta Program 360 Tech is not obligated to provide you with
                      any maintenance, technical or other support for the Pre-Release Product If at 360 Tech’s option,
                      such support is provided, it will be provided in addition to your normal warranty coverage for
                      your computer and/or device. You agree to abide by any support rules and policies that 360 Tech
                      provides to you in order to receive such support. You acknowledge that 360 Tech has no express or
                      implied obligation to announce or make available a commercial version of the Pre-Release Software
                      to anyone in the future. Should a commercial version be made available, it may have features or
                      functionality that are different from those found in the Pre-Release Software licensed hereunder.”
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Privacy Policy</b>
                    <p>
                      “Privacy Policy the 360 Tech’s Privacy Policy (available at <a
                        href="http://www.360techusa.com/privacy" target="_black">http://www.360techusa.com/privacy</a>)
                      (“Privacy Policy”) applies to the 360 Beta Program and the Product. You acknowledge and agree that
                      by participating in the 360 Beta Program or by using the Product, 360 Tech may receive certain
                      information about you. including personally identifiable information, and you hereby consent to
                      360 Tech’s collection, use and disclosure such information in accordance with the Privacy Policy.”
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Fees and Payment</b>
                    <p>
                      “Fees and Costs <br>
                      There are no license fees for User’s use of the Product under this Agreement. <br>
                      User is responsible for all costs and expenses associated with the use of the Product and the
                      performance of all testing and evaluation activities.”
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Modification</b>
                    <p>
                      “Although most changes are likely to be minor, 360 Tech may change its Agreement from time to
                      time, and in 360 Tech’s sole discretion. 360 Tech encourages User to frequently check this page
                      for any changes to its Agreement. If any provision of this Agreement is held by a court of
                      competent jurisdiction to be contrary to law the remaining provisions of this Agreement shall
                      remain in full force and effect.”
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Assignment</b>
                    <p>
                      “No Assignment. <br>
                      This Agreement is personal to User. User shall not assign or otherwise transfer any rights or
                      obligations under this Agreement.”
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Severability</b>
                    <p>
                      “If any provision of this Agreement shall be found by a court to be void, invalid or
                      unenforceable, the same shall be reformed to comply with applicable law or stricken if not so
                      conformable, so as not to affect the validity or enforceability of this Agreement.”
                    </p>
                  </div>
                  <div class="col-md-12 justify-content-center">
                    <b>Governing Law and Jurisdiction / Arbitration</b>
                    <p>
                      “CHOICE OF LAW AND DISPUTES <br>
                      For other than the U.S. Government as a party, this Agreement shall be governed by and construed
                      in accordance with the laws of the State of Florida, as If performed wholly within the state and
                      without giving effect to the principles of conflict of law rules of any jurisdiction or the United
                      Nations Convention on Contracts for the International Sale of Goods, the application of which is
                      expressly excluded. Any legal action or proceeding arising under this Agreement will be brought
                      exclusively in the federal or state courts located in Miami, Florida and the parties hereby
                      consent to personal Jurisdiction and venue therein.”
                    </p>
                  </div>

                  <div class="col-md-12 text-center">
                    8333 NW 53rd St. Doral, FL 33166 <br>
                    +1 305-250-8402 <br>
                    <a href="www.360techusa.com" target="_blank">www.360techusa.com</a>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="decline()">Decline</button>
                <button v-show="showButton" type="button" class="btn btn-info btn-sm" @click="agree()">Agree</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'ModalTerms',
  data: function () {
    return {
      showButton: false,
    }
  },
  computed: {
    ...mapState('auth', ['errorAuth']),
  },
  methods: {
    ...mapActions('auth', ['logOut', 'userAgreeTermsToSystem', 'changeModalTerms']),

    /** Funcion para mostrar los botones del footer al llegar al finald el documento */
    showButtonOpt() {
      var messageDisplay = this.$refs.contenDisplay;
      if (messageDisplay.offsetHeight + messageDisplay.scrollTop >= messageDisplay.scrollHeight) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },

    /** Funcion para declinar los terminos */
    decline(){
      this.changeModalTerms();
      this.logOut();
    },

    /** Funcion para aceptar los terminos  */
    async agree() {
      await this.userAgreeTermsToSystem();
      if (this.errorAuth == null) {
        this.changeModalTerms();
      }
    }
  },
}
</script> 


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95vh;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0;
}

.modal-header,
.modal-footer {
  background: #fff;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.2rem 1rem;
  border-bottom: 0px solid #fff;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 1rem 8px 1rem;
}
</style>