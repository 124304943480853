import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import toastr from 'toastr';


/** Notificacion Exito */
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};


/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};


const state = () => ({
  loading: false,
  modalcategories: false,
  categories: [],
  errorCategories: null,
});


const actions = {

  /** Reset data */
  resetDataCategories({ commit }) {
    try {

      commit('resetDataCategoriesSuccess');

    } catch (err) {

      console.log(err);
    }
  },


  /** Action para cambiar el estado del modal para gestionar las categorias */
  changeModalCategories({ commit }) {
    try {

      commit('categoriesActionBegin');
      commit('changeModalCategoriesSuccess');

    } catch (err) {

      commit('boardsActionErr', err);
    }
  },


  /** Action para procesar Get data Categorias */
  async getCategoriesData({ commit }) {
    try {

      commit('categoriesActionBegin');

      let response = await DataService.get(`/categories`);

      console.log(response.data);

      await commit('getCategoriesDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('categoriesActionErr', err);
    }
  },


  /** Action para agregar una categoria */
  async addCategories({ commit }, data) {
    try {

      commit('categoriesActionBegin');

      let response = await DataService.post('categories', data);

      console.log(response.data.payload);

      await commit('addCategoriesSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('categoriesActionErr', err);
    }
  },


  /** Action para actualizar una categoria */
  async updateCategories({ commit }, data) {
    try {

      await commit('categoriesActionBegin');

      let response = await DataService.put(`/categories/${data.id}`, data);

      console.log(response.data.payload);

      commit('updateCategoriesSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('categoriesActionErr', err);
    }
  },


  /** Action para eliminar una categoria  */
  async deleteCategories({ commit }, data) {
    try {

      await commit('categoriesActionBegin');

      let response = await DataService.delete(`categories/${data.id}/${data.new}`);

      console.log(response.data);

      await commit('deleteCategoriesSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('categoriesActionErr', err);
    }
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};