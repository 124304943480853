<template>
  <div class="justify-content-center">
    <div class="offset-md-2 col-lg-8 col-md-8">
      <br />

      <div class="main-card mb-3 card">
        <div class="card-body">
          <div class="row">
            <!-- Bloque detalles del Plan -->
            <div class="col-md-4 text-center">
            
              <img src="../assets/logo-m.png" alt="logo" :style="{'width':'100%', 'object-fit': 'contain', 'margin-top': '10px'}">
              <h3 class="" :style="{'margin-top':'60px'}">{{ planItem.name }}</h3>
              <h5>Flash Team 360 monthly subscription <span>$ {{ planItem.cost }}</span></h5>
              <br />
              <p>{{ planItem.description }}</p>
              <br />
              <p class="" :style="{'text-align':'left', 'padding-left':'40px'}">
                <i class="icon fas fa-check" ></i>{{ planItem.members_limit }} User<br>
                <i class="icon fas fa-check"></i> Unlimited Cards <br>
                <i class="icon fas fa-check"></i> Up to {{ planItem.boards_limit }} Boards <br>
                <i class="icon fas fa-check"></i> Calendar View <br>
                <i class="icon fas fa-check"></i> Advance Checklist <br>
                <i class="icon fas fa-check"></i> Board & Checklist due day <br>  
                <i class="icon fas fa-check"></i> Unlimited storage (10MB/file) <br>
                <i class="icon fas fa-check"></i> Integrate with Google Drive <br>          
                <i class="icon fas fa-check"></i> Unlimited activity log <br>
                <i class="icon fas fa-check"></i> Unlimited color labels <br>
              </p>

            </div>
            <!-- Bloque detalles del Plan -->

            <!-- Bloque de formularios -->
            <div class="col-md-8">

              <!-- Formulario para los datos basicos y planes con valor 0 -->
              <div v-show="stepNumber == 1" class="step1 col-md-8 offset-md-2">
                <h5>1. Personal Information</h5>
                    
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="name" class="control-label">Name</label>
                      <input type="text" name="name" id="name" 
                      v-model="formStep1.name"
                      :class="formStep1.errName == '' ? 'form-control' : 'form-control is-invalid'" 
                      :aria-invalid="formStep1.errName == '' ? false : true" >
                      <span class="helper-text danger-text">{{ formStep1.errName }}</span>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="email" class="control-label">Email</label>
                      <input type="text" name="email" id="email" 
                      v-model="formStep1.email"
                      @change="checkRegistrationEmail()"
                      :class="formStep1.errEmail == '' ? 'form-control' : 'form-control is-invalid'" 
                      :aria-invalid="formStep1.errEmail == '' ? false : true" >                      
                      <span class="helper-text danger-text">{{ formStep1.errEmail }}</span>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="phone" class="control-label">Phone</label><br>
                      <input type="text" name="phone" id="phone"
                      v-model="formStep1.phone"
                      :class="formStep1.errPhone == '' ? 'form-control' : 'form-control is-invalid'" 
                      :aria-invalid="formStep1.errPhone == '' ? false : true" >
                      <br>  
                      <span class="helper-text danger-text">{{ formStep1.errPhone }}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="company" class="control-label">Team alias</label>
                      <input type="text" name="company" id="company" class="form-control" 
                      v-model="formStep1.team_alias" placeholder="Ex: John Doe or My company"
                      @change="checkTeamsName()">
                      <span class="helper-text danger-text">{{ formStep1.errTeamAlias }}</span>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="address" class="control-label">Address</label>
                      <input type="text" name="address" id="address"
                      v-model="formStep1.address"
                      :class="formStep1.errAddress == '' ? 'form-control' : 'form-control is-invalid'" 
                      :aria-invalid="formStep1.errAddress == '' ? false : true" >  
                      <span class="helper-text danger-text">{{ formStep1.errAddress }}</span>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="state" class="control-label">State</label>
                      <input type="text" name="state" id="state" 
                      v-model="formStep1.state"
                      :class="formStep1.errState == '' ? 'form-control' : 'form-control is-invalid'" 
                      :aria-invalid="formStep1.errState == '' ? false : true" >  
                      <span class="helper-text danger-text">{{ formStep1.errState }}</span>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="city" class="control-label">City</label>
                      <input type="text" name="city" id="city" 
                      v-model="formStep1.city"
                      :class="formStep1.errCity == '' ? 'form-control' : 'form-control is-invalid'" 
                      :aria-invalid="formStep1.errCity == '' ? false : true" >  
                      <span class="helper-text danger-text">{{ formStep1.errCity }}</span>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group ">
                      <label for="zipcode" class="control-label">ZipCode</label>
                      <input type="text" name="zipcode" id="zipcode" 
                      v-model="formStep1.zipCode"
                      :class="formStep1.errZipCode == '' ? 'form-control' : 'form-control is-invalid'" 
                      :aria-invalid="formStep1.errZipCode == '' ? false : true" >  
                      <span class="helper-text danger-text">{{ formStep1.errZipCode }}</span>
                    </div>
                  </div>
                </div>
                
                <!-- accept terms -->
                <div class="form-check">
                  <input v-if="formStep1.terms" name="terms" id="terms" type="checkbox" class="form-check-input" checked @click="changesTerms()">
                  <input v-else name="terms" id="terms" type="checkbox" class="form-check-input" @click="changesTerms()">
                  <label class="form-check-label" for="flexCheckDefault" :style="{'color': formStep1.errTerms ? 'red' : ''}">
                    I accept the <a target="_blank" href="https://360techusa.com/service-agreement/">Terms of Service</a>
                  </label>
                </div>
              </div>
              <!-- Formulario para los datos basicos y planes con valor 0 -->
              
              <!-- Formulario Informacion del pago -->
              <div v-show="stepNumber == 2" class="step2 col-md-8 offset-md-2">

                <h5>2. Payment Information</h5>

                <div class="row">

                  <div class="col-md-6">
                    <div class="form-group">
                      <input id="first_name" type="text" name="first_name" 
                      placeholder="First Name" 
                      class="form-control" 
                      v-model="formStep2.first_name"/>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <input id="last_name" type="text" name="last_name" placeholder="Last Name"
                      class="form-control" 
                      v-model="formStep2.last_name"/>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <input id="address1" name="address1" type="text" placeholder="Address"
                      class="form-control" 
                      v-model="formStep2.address1"/>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <input id="address2" name="address1" type="text" placeholder="Address"
                      class="form-control" 
                      v-model="formStep2.address2"/>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <input id="city" name="city" type="text" placeholder="City"
                      class="form-control" 
                      v-model="formStep2.city"/>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <input id="state" name="State" type="text" placeholder="FL"
                      class="form-control" 
                      v-model="formStep2.state"/>
                    </div>
                  </div>

                </div>
    
                <br>
                <!-- Contenedor para la informacion de la tarjeta de credito -->
                <div id="card-container" :style="{'margin-top':'0'}"></div>
                <div id="payment-status-container"></div> 
                <!-- Contenedor para la informacion de la tarjeta de credito -->                 

              </div>
              <!-- Formulario Informacion del pago -->

              <!-- Mensaje de proceso finalizado Go to Login -->
              <div v-show="stepNumber == 3" class="step3 col-md-8 offset-md-2" style="display: none;">
                <div class="no-results">
                    <div class="swal2-icon swal2-success swal2-animate-success-icon">
                        <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                        <span class="swal2-success-line-tip"></span>
                        <span class="swal2-success-line-long"></span>
                        <div class="swal2-success-ring"></div>
                        <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                        <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                    </div>
                    <div class="results-subtitle mt-4" id="message"> Thanks for trusting us! <br> We have sent an email with your access data </div>
                    <br>
                    <div class="results-title" id="dateCita"></div>
                    <div class="mt-3 mb-3"></div>
                    <div class="text-center">
                      <button type="button"  class="reset-btn-end btn-shadow btn-wide btn btn-success btn-lg" @click="this.$router.push('/');">Ok</button>
                    </div>
                </div>
              </div>
              <!-- Mensaje de proceso finalizado Go to Login -->

              <!-- Botones de los formularios -->
              <div v-if="stepNumber != 3" class="row">
                <div v-if="planItem.cost > 0" class="col-md-6 offset-md-6 text-right">
                  <button type="button" :class="b1 ? 'btn btn-primary btn-lg' : 'btn btn-primary btn-lg disabled' " 
                    @click="checkFormPay()">{{ stepNumber == 1 ? 'Continue' : 'Back' }}</button>
                  <button v-show="stepNumber == 2" type="button" :class="b2 ? 'btn btn-success btn-lg' : 'btn btn-success btn-lg disabled'"
                   :style="{'margin-left':'15px'}"
                   @click="modalConfirmPay=true;">{{ textButton }}</button>
                </div>
                <div v-else class="col-md-12 text-right">
                  <button type="submit" class="btn btn-primary btn-lg" @click="registerPlanFree()">Start Free subscription</button>  
                </div>
              </div>
              <!-- Botones de los formularios -->

            </div>

            <!-- Bloque de formularios -->
          </div>

        </div>
      </div>
    </div>

  <!-- Modal para Confirmar pago de planes con valor > 0 -->
  <div v-if="modalConfirmPay">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="myModalLabel"> Are you sure to subscribe to Flash Team 360?</h5>
                <button type="button" class="close" aria-label="Close" @click="modalConfirmPay=false;">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                - Flash Team 360 monthly subscription $ {{ planItem.cost }} 
                <br>
              </div>
              <div class="modal-footer">
                <input type="button" class="btn btn-default" data-dismiss='modal' value="Close" @click="modalConfirmPay = false;">
                <input type="button" id="confirmBuy" data-loading-text='Processing...'  
                class="btn btn-success"  value="Place Order" @click="processPaymentPlan()">
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para Confirmar pago de planes con valor > 0 -->

  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import { requiredFields, emailFields } from '../utility/validations';
import intlTelInput from 'intl-tel-input';
import $ from 'jquery';
export default {
  name: 'Register Users',
  components: {
  },
  data: function () {
    return {
      appId: 'sq0idp-8Vb-gFkiOlaB0sI9asg-1g',// 'sandbox-sq0idb-lqHc3vg149Bk37aA1Y8BAg'
      locationId: 'LWNMZDMNJ1VQR',
      payments: null,
      iti: null,
      card: null,
      stepNumber: 1,
      formStep1: {
        name: '',
        errName: '',
        email: '',
        errEmail: '',
        phone: '',
        errPhone: '',
        team_alias: '',
        errTeamAlias: '',
        address: '',
        errAddress: '',
        state: '',
        errState: '',
        city: '',
        errCity: '',
        zipCode: '',
        errZipCode: '',
        terms: false,
        errTerms: '',
      },
      formStep2: {
        first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
      },
      modalConfirmPay: false,
      textButton: 'Finish',
      b1: true,
      b2: true,
    }
  },
  computed: {
    ...mapState('auth', ['errorAuth', 'checkEmailRegister', 'checkTeamName']),
    ...mapState('plansData', ['planItem', 'errorPlans']),
  },
  methods: {
    ...mapActions('auth', ['registerUsersFree', 'registerUsersPaymentPlans', 'validateRegistrationEmail', 'validateRegistrationTeam']),
    ...mapActions('plansData', ['getPlansItem',]),

    /** Funcion para inicializar el contenedor de la tarjeta de credito */
    async initializeCard(payments) {
      const card = await payments.card();
      await card.attach('#card-container');
      return card;
    },


    /** Funcion generar el token de la tarjeta ingresada por el usuario */
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        throw new Error(
          `Tokenization errors: ${JSON.stringify(tokenResult.errors)}`
        );
      }
    },


    /** Funcion para mostrar el resultado de las operaciones a las tarjetas de credito */
    displayResults(status) {

      const statusContainer = document.getElementById('payment-status-container');

      if (status === 'SUCCESS') {

        statusContainer.classList.remove('is-failure');
        statusContainer.classList.add('is-success');
      
      } else {

        statusContainer.classList.remove('is-success');
        statusContainer.classList.add('is-failure');
      }

      statusContainer.style.visibility = 'visible';
    },


    /** Funcion para verificar los datos del comprador (Tarjeta) */
    async verifyBuyer(payments, token) {

      const verificationDetails = {
        billingContact: {
          addressLines: [this.formStep2.address1, this.formStep2.address2],
          familyName: this.formStep2.last_name,
          givenName: this.formStep2.first_name,
          country: 'US',
          region: this.formStep2.state,
          city: this.formStep2.city,
        },
        intent: 'STORE',
      };

      const verificationResults = await payments.verifyBuyer(token, verificationDetails);
      return verificationResults.token;
    },


    /** Funcion para procesar el registro y pago de los planes pagos  */
    async handleStoreCardMethodSubmission(paymentMethod) {

      try {

        /* disable the submit button as we await tokenization and make a payment request. */
        const token = await this.tokenize(paymentMethod);
        let verificationToken = await this.verifyBuyer(this.payments, token);
        const storeCardResults = await this.storeCard(token, verificationToken);

        this.displayResults('SUCCESS');
        console.debug('Store Card Success', storeCardResults);
        this.textButton = 'Finish';
        this.stepNumber = 3;

      } catch (e) {
        // cardButton.disabled = false;
        this.displayResults('FAILURE');
        console.error('Store Card Failure', e);
      }
    },


    /** Funcion para procesar los pagos de registro mediante la api */
    async storeCard(token, verificationToken) {

      this.formStep1.phone = `+${this.iti.getSelectedCountryData().dialCode}${this.formStep1.phone}`;

      const bodyParameters = {
        locationid: this.locationId,
        token: token,
        verification_token: verificationToken,
        billing_address1: this.formStep2.address1, 
        billing_address2: this.formStep2.address2,
        billing_city: this.formStep2.city,
        billing_state: this.formStep2.state,
        billing_first_name: this.formStep2.first_name,
        billing_last_name: this.formStep2.last_name,
        name: this.formStep1.name,
        email: this.formStep1.email,
        phone: this.formStep1.phone,
        team_alias: this.formStep1.team_alias,
        address: this.formStep1.address,
        state: this.formStep1.state,
        city: this.formStep1.city,
        zipcode: this.formStep1.zipCode,
        plan: this.planItem.id,
      };

      await this.registerUsersPaymentPlans(bodyParameters);
    },


    /** Funcion para Iniciar el proceso de registro en los planes Planes */
    processPaymentPlan(){

      this.modalConfirmPay = false;
      this.b1 = false;
      this.b2 = false;
      this.textButton = 'Processing...';

      this.handleStoreCardMethodSubmission(this.card);
    },


    /** Funcion para cambiar el estatus del check de terminos y condiciones */
    changesTerms(){
      this.formStep1.terms = !this.formStep1.terms; 
    },


    /** Verificar campos del formulario # 1 */
    checkeFormStep1() {

      let check = false;

      this.formStep1.errName = requiredFields(this.formStep1.name, 'en');
      if(this.formStep1.errEmail == ''){
        this.formStep1.errEmail = requiredFields(this.formStep1.email, 'en');
        if (this.formStep1.errEmail == '') {
          this.formStep1.errEmail = emailFields(this.formStep1.email, 'en');
        }
      }
      this.formStep1.errPhone = requiredFields(this.formStep1.phone, 'en');
      if(this.planItem.cost > 0) {
        this.formStep1.errTeamAlias = requiredFields(this.formStep1.team_alias, 'en');
      }
      this.formStep1.errAddress = requiredFields(this.formStep1.address, 'en');
      this.formStep1.errState = requiredFields(this.formStep1.state, 'en');
      this.formStep1.errCity = requiredFields(this.formStep1.city, 'en');
      this.formStep1.errZipCode = requiredFields(this.formStep1.zipCode, 'en');
      this.formStep1.errTerms = this.formStep1.terms ? false : true;

      if(this.formStep1.errName == '' && this.formStep1.errEmail == '' && this.formStep1.errPhone == '' &&
        this.formStep1.errTeamAlias == '' && this.formStep1.errAddress == '' && this.formStep1.errState == '' && 
        this.formStep1.errCity == '' && this.formStep1.errZipCode == '' && this.formStep1.errTerms == false) {
        check = true;
      }

      return check;
    },


    /** Funcion para verificar que el correo no se encuentre registrado en la BD */
    async checkRegistrationEmail() {

      if(emailFields(this.formStep1.email, 'en') == ''){
        await this.validateRegistrationEmail({email: this.formStep1.email, tp: this.planItem.cost > 0 ? 1 : 0});
        if(this.checkEmailRegister == true){
          this.formStep1.errEmail = 'Email already exists';
        } else {
          this.formStep1.errEmail = '';
        }
      } else {
        this.formStep1.errEmail = '';
      }
    },


    /** Funcion para verificar que el nombre del team no se encuentre registrado en la BD */
    async checkTeamsName() {

      if (this.formStep1.team_alias != '' && this.planItem.cost > 0) {
        await this.validateRegistrationTeam(this.formStep1.team_alias);
        if (this.checkTeamName == true) {
          this.formStep1.errTeamAlias = 'Team alias already exists';
        } else {
          this.formStep1.errTeamAlias = '';
        }
      } else {
        this.formStep1.errTeamAlias = '';
      }
    },


    /** Funcion para registrar un usuario con un plan Gratis */
    async registerPlanFree() {

      if(this.checkeFormStep1()) {

        this.formStep1.phone = `+${this.iti.getSelectedCountryData().dialCode}${this.formStep1.phone}`;

        const bodyParameters = {
          locationid: this.locationId,
          token: '',
          verification_token: '',
          billing_address1: this.formStep2.address1,
          billing_address2: this.formStep2.address2,
          billing_city: this.formStep2.city,
          billing_state: this.formStep2.state,
          billing_first_name: this.formStep2.first_name,
          billing_last_name: this.formStep2.last_name,
          name: this.formStep1.name,
          email: this.formStep1.email,
          phone: this.formStep1.phone,
          team_alias: this.formStep1.team_alias,
          address: this.formStep1.address,
          state: this.formStep1.state,
          city: this.formStep1.city,
          zipcode: this.formStep1.zipCode,
          plan: this.planItem.id,
        };

        
        await this.registerUsersFree(bodyParameters);
        if (this.errorUsers == null) {
          this.stepNumber = 3;
        }
      }
    },


    /** Funcion para verificar el primer formulario (Primera paso) para planes pagos o para regresar a la primera parte */
    checkFormPay(){

      if(this.stepNumber == 2) {
        
        this.stepNumber = 1;
      
      }else{
        if(this.checkeFormStep1()) {
          this.stepNumber = 2;
        }
      }
    },



    /** Regresar a Login */
    loginTo() {
      this.$router.push('/');
    }
  },
  mounted: async function () {

    /** Se obtiene el plan por el codigo */
    await this.getPlansItem(this.$route.params.plan);

    /** Se inicializa el campo phone */
    const input = document.querySelector("#phone");
    this.iti = await intlTelInput(input, {
      geoIpLookup: function (success, failure) {
        $.get("https://ipinfo.io", function () { }, "jsonp").always(function (resp) {
          var countryCode = (resp && resp.country) ? resp.country : "us";
          //success(countryCode);
          console.log(success);
          console.log(failure);
          console.log(countryCode);
        });
      },
      utilsScript: '../utility/utils.js',
      separateDialCode: true
    });

    /** Se inicializa el contenedor de la tarjeta de Square */
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }

    try {
      this.payments = window.Square.payments(this.appId, this.locationId);
    } catch (e) {
      console.error('Initializing', e);
      const statusContainer = document.getElementById(
        'payment-status-container'
      );
      statusContainer.className = 'missing-credentials';
      statusContainer.style.visibility = 'visible';
      return;
    }

    try {
      this.card = await this.initializeCard(this.payments);
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }
    /** Se inicializa el contenedor de la tarjeta de Square */

  }
}
</script>


<style scoped>

  @import "../../public/style/intlTelInput.css";

  .iti__flag {background-image: url("../../node_modules/intl-tel-input/build/img/flags.png");}

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {background-image: url("../../node_modules/intl-tel-input/build/img/flags@2x.png");}
  }
  .icon{
    color: #e5b51b;
    margin-right: 10px;
    }

    #payment-form {
      max-width: 550px;
      min-width: 300px;
      
    }
    
    .buyer-inputs {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      border: none;
      margin: 0;
      padding: 0;
    }
    
    #card-container {
      margin-top: 45px;
      /* this height depends on the size of the container element */
      /* We transition from a single row to double row at 485px */
      /* Settting this min-height minimizes the impact of the card form loading */
      min-height: 90px;
    }
    
    #gift-card-container {
      margin-top: 45px;
      min-height: 90px;
    }
    
    @media screen and (max-width: 500px) {
      #card-container {
        min-height: 140px;
      }
    }
    
    #ach-button {
      margin-top: 20px;
    }
    
    #landing-page-layout {
      width: 80%;
      margin: 150px auto;
      max-width: 1000px;
    }
    
    #its-working {
      color: #737373;
    }
    
    #example-container {
      width: 100%;
      border: 1px solid #b3b3b3;
      padding: 48px;
      margin: 32px 0;
      border-radius: 12px;
    }
    
    #example-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    
    #customer-input {
      margin-bottom: 40px;
    }
    
    #card-input {
      margin-top: 0;
      margin-bottom: 40px;
    }
    
    
    #payment-status-container {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      border-radius: 50px;
      margin: 0 auto;
      width: 225px;
      height: 48px;
      visibility: hidden;
    }
    
    #payment-status-container.missing-credentials {
      width: 350px;
    }
    
    #payment-status-container.is-success:before {
      content: '';
      background-color: #00b23b;
      width: 16px;
      height: 16px;
      margin-right: 16px;
      -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
      mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
    }
    
    #payment-status-container.is-success:after {
      content: 'Payment successful';
      font-size: 14px;
      line-height: 16px;
    }
    
    #payment-status-container.is-failure:before {
      content: '';
      background-color: #cc0023;
      width: 16px;
      height: 16px;
      margin-right: 16px;
      -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
      mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
    }
    
    #payment-status-container.is-failure:after {
      content: 'Payment failed';
      font-size: 14px;
      line-height: 16px;
    }
    
    #payment-status-container.missing-credentials:before {
      content: '';
      background-color: #cc0023;
      width: 16px;
      height: 16px;
      margin-right: 16px;
      -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
      mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
    }
    
    #payment-status-container.missing-credentials:after {
      content: 'applicationId and/or locationId is incorrect';
      font-size: 14px;
      line-height: 16px;
    }
    
    #payment-status-container.is-success.store-card-message:after {
      content: 'Store card successful';
    }
    
    #payment-status-container.is-failure.store-card-message:after {
      content: 'Store card failed';
    }
    
    #afterpay-button {
      height: 40px;
    }

    .iti.iti--allow-dropdown.iti--separate-dial-code {
      width: 100%;
    }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }
    
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }    
    

</style>
