<template>
  <div>
    <Header />
    <div class="app-main">
      <Sidebar />
      {{ closeMenu() }}
      <div class="app-main__outer" @mouseenter="closeMenu()" :style="{'background-color':'white'}">
        <div class="app-main__inner">
          <div class="app-page-title" :style="{ 'background': 'rgba(238, 233, 233, 0.45)' }">
            <!-- Header del Contenido  -->
            <div class="page-title-wrapper row">
              <!-- Detalles del Tablero -->
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="page-title-heading">
                  <div class="page-title-icon">
                    <i class="pe-7s-stopwatch icon-gradient bg-mean-fruit"></i>
                  </div>
                  <div>
                    <h4>{{ t('userSettings.myAccount', {}, { locale: lang }) }}</h4>
                    <div class="page-title-subheading">
                      {{ t('userSettings.infoYsettings', {}, { locale: lang }) }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- Detalles del Tablero -->

            </div>
            <!-- Header del Contenido  -->
          </div>

          <!-- Container Normal -->
          <div class="container" :style="{'max-width':'85vw !important', 'min-height':'90vh !important', 'margin-top':'3vh'}">

            <div class="row">

              <!-- Informacion y foto del usuario -->
              <div class="col-md-3">
                <div class="card card-primary card-outline">
                  <div class="card-body box-profile">
                    <i class="dropdown-icon ion-android-create" :title="t('button.edit', {}, { locale: lang })" 
                      @click="changeModalChangeImg()"
                      :style="{'font-size': '1.1rem' }"> </i>
                    <div class="text-center">
                      <img :title="t('button.edit', {}, { locale: lang })" id="imgProfile"
                        @click="changeModalChangeImg()"
                        class="profile-user-img img-fluid rounded-circle img-profile" :src="imgProfile" alt="User profile picture"
                      >
                    </div>
                    <h3 class="profile-username text-center">{{ userInfo.n21 }}</h3>
                    <p class="text-muted text-center">--</p>

                    <ul class="list-group list-group-unbordered mb-3">
                      <li class="list-group-item text-center" :style="{'margin-bottom':'-14px !important'}">
                        <b>{{ `${t('headerData.teams', {}, { locale: lang })}  :  Roles` }}</b> 
                      </li>
                      <li class="list-group-item" v-for="(r, index) in userInfo.access" :key="index" :style="{ 'margin-bottom': '-14px !important' }">
                        <b>{{ r.team.name }}: </b> {{ r.rol.display_name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Informacion y foto del usuario -->

              <!-- Opciones de configuración de la cuenta del usuario -->
              <div class="col-md-9 card card-primary card-outline card-profile-container">
                <div class="">
                  <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                    
                    <!-- Informacion del usuario -->
                    <li class="nav-item">
                      <a role="tab" class="nav-link active" id="tab-0" data-toggle="tab" href="#tab-content-0">
                        <span>{{ t('userSettings.info', {}, { locale: lang }) }}</span>
                      </a>
                    </li>
                    <!-- Informacion del usuario -->

                    <!-- Actualizar contraseña -->
                    <li class="nav-item">
                      <a role="tab" class="nav-link" id="tab-1" data-toggle="tab" href="#tab-content-1">
                        <span>{{ t('modalChangePassword.changePassword', {}, { locale: lang }) }}</span>
                      </a>
                    </li>
                    <!-- Actualizar contraseña -->

                    <!-- Metodos de pago (Tarjetas) -->
                    <li class="nav-item">
                      <a role="tab" class="nav-link" id="tab-2" data-toggle="tab" href="#tab-content-2">
                        <span>{{ t('userSettings.billing', {}, { locale: lang }) }}</span>
                      </a>
                    </li> 
                    <!-- Metodos de pago (Tarjetas) -->

                    <!-- Historial de pagos -->
                    <li class="nav-item">
                      <a role="tab" class="nav-link" id="tab-3" data-toggle="tab" href="#tab-content-3">
                        <span>{{ t('userSettings.paymentHistory', {}, { locale: lang }) }}</span>
                      </a>
                    </li>
                    <!-- Historial de pagos -->
                    
                    <!-- Plan actual del usuario -->
                    <li class="nav-item">
                      <a role="tab" class="nav-link" id="tab-4" data-toggle="tab" href="#tab-content-4">
                        <span>{{ t('userSettings.myPlan', {}, { locale: lang }) }}</span>
                      </a>
                    </li>
                    <!-- Plan actual del usuario -->


                  </ul>

                  <div class="tab-content">

                    <!-- Informacion del usuario -->
                    <div class="tab-pane tabs-animation fade show active" id="tab-content-0" role="tabpanel">

                      <table v-if="!showEditProfile" class="table">
                        <tbody>
                          <tr>
                            <td scope="col"><b>{{ t('headerData.name', {}, { locale: lang }) }}</b></td>
                            <td>{{ userInfo.n21 }}</td>
                            <td :style="{ 'text-align': 'right' }" :title="t('button.edit', {}, { locale: lang })">
                              <i class="dropdown-icon ion-android-create" :style="{ 'font-size': '1.1rem' }" @click="showEditUserProfile(true)"> </i>
                            </td>
                          </tr>
                          <tr>
                            <td scope="col"><b>{{ t('headerData.email', {}, { locale: lang }) }}</b></td>
                            <td colspan="2">{{ userInfo.e21 }}</td>
                          </tr>
                          <tr>
                            <td scope="col"><b>{{ t('headerData.phone', {}, { locale: lang }) }}</b></td>
                            <td colspan="2">{{ userInfo.p21 }}</td>
                          </tr>
                          <tr>
                            <td scope="col"><b>{{ t('headerData.address', {}, { locale: lang }) }}</b></td>
                            <td colspan="2">{{ userInfo.ad21 }}</td>
                          </tr>   
                          <tr>
                            <td scope="col"><b>{{ t('headerData.state', {}, { locale: lang }) }}</b></td>
                            <td colspan="2">{{ userInfo.st21 }}</td>
                          </tr>                           
                          <tr>
                            <td scope="col"><b>{{ t('headerData.city', {}, { locale: lang }) }}</b></td>
                            <td colspan="2">{{ userInfo.c21 }}</td>
                          </tr> 
                          <tr>
                            <td scope="col"><b>{{ t('headerData.zipCode', {}, { locale: lang }) }}</b></td>
                            <td colspan="2">{{ userInfo.z21 }}</td>
                          </tr>
                        </tbody>
                      </table>

                      <div v-else class="row">

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="position-relative form-group">
                            <label for="" class="">{{ t('headerData.name', {}, { locale: lang }) }}</label>
                            <input name="name" id="name" placeholder="" type="text" class="form-control form-control-sm"  v-model="forUser.name" required>
                            <span class="helper-text danger-text">{{ forUser.errName }}</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="position-relative form-group">
                            <label for="" class="">{{ t('headerData.phone', {}, { locale: lang }) }}</label>
                            <input name="phone" id="phone" placeholder="" type="text" class="form-control form-control-sm"
                                v-model="forUser.phone" required>
                            <span class="helper-text danger-text">{{ forUser.errPhone }}</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="position-relative form-group">
                            <label for="" class="">{{ t('headerData.address', {}, { locale: lang }) }}</label>
                            <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                              v-model="forUser.address" required>
                            <span class="helper-text danger-text">{{ forUser.errAddress }}</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <div class="position-relative form-group">
                            <label for="" class="">{{ t('headerData.state', {}, { locale: lang }) }}</label>
                            <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                              v-model="forUser.state" required>
                            <span class="helper-text danger-text">{{ forUser.errState }}</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <div class="position-relative form-group">
                            <label for="" class="">{{ t('headerData.city', {}, { locale: lang }) }}</label>
                            <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                              v-model="forUser.city" required>
                            <span class="helper-text danger-text">{{ forUser.errCity }}</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <div class="position-relative form-group">
                            <label for="" class="">{{ t('headerData.zipCode', {}, { locale: lang }) }}</label>
                            <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                              v-model="forUser.zip_code" required>
                            <span class="helper-text danger-text">{{ forUser.errZipCode }}</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" :style="{ 'text-align': 'right' }">
                          <button type="button" class="btn btn-primary btn-sm" :style="{'margin-right':'15px'}" @click="updateUserLoginData()">
                            {{ t('button.save', {}, { locale: lang }) }}
                          </button>
                          <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="showEditUserProfile(false)">
                            {{ t('button.cancel', {}, { locale: lang }) }}
                          </button>
                        </div>

                      </div>

                    </div>
                    <!-- Informacion del usuario -->


                    <!-- Actualizar contraseña -->
                    <div class="tab-pane tabs-animation fade" id="tab-content-1" role="tabpanel">

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="password_old" class="control-label">
                            {{ t('modalChangePassword.currentPassword', {}, { locale: lang }) }}
                          </label>
                          <input type="password" name="password_old"
                            :class="formPassword.errPasswordOld == '' ? 'form-control' : 'form-control is-invalid'" 
                            :aria-invalid="formPassword.errPasswordOld == '' ? false : true"                                
                            v-model="formPassword.password_old">
                          <span class="helper-text danger-text">{{ formPassword.errPasswordOld }}</span>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="password_new" class="control-label">
                            {{ t('modalChangePassword.passwordNew', {}, { locale: lang }) }}
                          </label>
                          <input type="password" name="password_new"
                            :class="formPassword.errPasswordNew == '' ? 'form-control' : 'form-control is-invalid'" 
                            :aria-invalid="formPassword.errPasswordNew == '' ? false : true" 
                            v-model="formPassword.password_new">
                          <span class="helper-text danger-text">{{ formPassword.errPasswordNew }}</span>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="password_repeat" class="control-label">
                            {{ t('modalChangePassword.confirmPassword', {}, { locale: lang }) }}
                          </label>
                          <input type="password" name="password_repeat" 
                            :class="formPassword.errPasswordRep == '' ? 'form-control' : 'form-control is-invalid'" 
                            :aria-invalid="formPassword.errPasswordRep == '' ? false : true"                                 
                            v-model="formPassword.password_repeat">
                          <span class="helper-text danger-text">{{ formPassword.errPasswordRep }}</span>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <button type="button" class="btn btn-primary btn-sm" @click="updateUserPassword()">
                          {{ t('button.save', {}, { locale: lang }) }}
                        </button>
                      </div>

                    </div>
                    <!-- Actualizar contraseña -->


                    <!-- Metodos de pago (Tarjetas) -->
                    <div class="tab-pane tabs-animation fade" id="tab-content-2" role="tabpanel">
                      <div class="card-header">
                          <i class="header-icon lnr-license icon-gradient bg-plum-plate"> </i>
                          {{ t('userSettings.paymentMethods', {}, { locale: lang }) }}

                          <div class="btn-actions-pane-right">
                            <button type="button" class="btn-pill btn-wide active btn btn-outline-alternate btn-sm" @click="openModalNewCard()">
                              {{ t('button.add', {}, { locale: lang }) }}
                            </button>
                          </div>

                      </div>
                      <div class="col-12" id="paymentMethodsList">
                        <h4 v-if="billing == undefined || billing.length == 0" 
                        class="text-center text-muted" :style="{'margin-top':'10px'}" >
                          {{ t('userSettings.paymentMethodsMsm', {}, { locale: lang }) }}
                        </h4>

                        <table v-else id="paymentMethodsTable" class="table">

                          <tr v-for="b in billing" :key="b.uuid">
                            <td>
                              <input v-if="b.default" :id="`check_${b.uuid}`" checked="checked" title="default" class="chk_default" name="default" type="radio"/>
                              <input v-else :id="`check_${b.uuid}`" title="default" class="chk_default" name="default" type="radio"/>
                              <label class="far setDefault" title="default" :for="`check_${b.uuid}`"></label> 
                            </td>

                            <td>
                              <img :src="cardsIconBilling(b.type)" :style="{ 'width': '60px' }">
                              * * * *{{ b.last4 }}
                            </td>

                            <td>
                              <b :style="{'padding':'.0rem .0rem'}">{{ b.holdername }}</b><br>
                              <small :style="{'padding':'.0rem .0rem'}">
                                {{ b.address_line1 != null ? b.address_line1 : '' }}
                                <br> 
                                {{ b.address_line2 != null ? b.address_line2 : '' }}
                              </small>
                            </td>

                            <td>  
                              <a data-placement="top" data-toggle="tooltip" title="Delete card ">
                                <button class="btn btn-danger btn-xs"  data-toggle="modal" data-target="#modal-default" 
                                  @click="openModalDeleteCards(b)" data-backdrop="static" data-keyboard="false">
                                  <i class="fas fa-trash"></i>
                                </button>
                              </a>
                            </td>

                          </tr>
                          
                        </table>


                      </div>

                    </div>
                    <!-- Metodos de pago (Tarjetas) -->


                    <!-- Historial de pagos -->
                    <div class="tab-pane tabs-animation fade" id="tab-content-3" role="tabpanel">
                      <div class="card-header">
                          <i class="header-icon lnr-license icon-gradient bg-plum-plate"> </i>
                          {{ t('userSettings.paymentHistory', {}, { locale: lang }) }}
                          <div class="btn-actions-pane-right">

                          </div>

                      </div>
                      <div class="col-12" id="paymentList" :style="{ 'max-height': '90%', 'overflow-x': 'hidden', 'overflow-y': 'auto', }">

                        <table id="paymentTable" class="table"  v-if="paymentHistory.length > 0">

                          <thead>
                            <tr>
                              <th>{{ t('userSettings.date', {}, { locale: lang }) }}</th>
                              <th>{{ t('userSettings.method', {}, { locale: lang }) }}</th>
                              <th>{{ t('userSettings.reason', {}, { locale: lang }) }}</th>
                              <th>{{ t('userSettings.status', {}, { locale: lang }) }}</th>
                              <th>{{ t('userSettings.amount', {}, { locale: lang }) }}</th>
                            </tr>
                          </thead>
                        
                          <tbody>
                            <tr v-for="h in paymentHistory" :key="h.uuid"  :id="`row-card-${h.uuid}`">
                              <td>{{ moment(h.created_at).format("MMM Do YY") }}</td>
                              <td>
                                <img :src="cardsIconBilling(h.card_info.type)" :style="{'width':'60px'}" >
                                * * * * {{ h.card_info.last4 }}
                              </td>
                              <td>{{ h.description }}</td>
                              <td>
                                <b :style="{'margin-right':'5px'}">{{ h.status }}</b>
                                <a href="#" @click.prevent="downloadOnePaymentsInvoice(h.uuid)">  <i class="fas fa-download"></i></a>
                              </td>
                              <td> $<b>{{ h.amount }}</b> </td>
                            </tr>
                          </tbody>
                        </table>






                      </div>
                    </div>
                    <!-- Historial de pagos -->


                    <!-- Plan actual del usuario -->
                    <div class="tab-pane tabs-animation fade scroll-area-lg" 
                      :style="{'overflow-x':'hidden', 'overflow-y':'auto', }" 
                      id="tab-content-4" role="tabpanel"
                    >
                    
                      <div class="row col-equal-height" v-for="(p, index) in plansInfo" :key="index">
                        <!-- Datos basicos del plan -->
                        <div class="col-md-3">
                          <div class="card card-primary card-outline mb-2" :style="{'height':'260px'}">
                            <div class="card-body text-center">
                              <img src="@/assets/box-cartoon.png" :style="{'width':'50px'}">
                              <br>
                              <h3>{{ p.plan.name }}</h3>
                              <small>{{ p.plan.description }}</small>
                              <p v-if="p.showButton">
                                <b>{{ t('userSettings.nextInvoice', {}, { locale: lang }) }}</b>:
                                {{ moment(p.next_payment).format("YYYY-MM-DD") }}
                              </p>
                              <button v-if="p.showButton" 
                                @click="changeTeamPlan(p.team)"
                                type="button" id="btnChangePlan" class="btn btn-primary btn-pill">
                                <i class="fas fa-medal"></i>  {{ t('button.changePlan', {}, { locale: lang }) }}
                              </button>
                            </div>
                          </div>
                        </div>
                        <!-- Datos basicos del plan -->

                        <!-- Detalles del plan -->
                        <div class="col-md-9  ">
                          <div class="card card-primary card-outline p-2 mb-2" :style="{'height':'260px'}">
                            <div class="row mt-3">
                              <div class="col-md-3 col-6 mt-3">
                                <div class="font-icon-wrapper">
                                  <i class="fas fa-users bg-happy-fisher icon-gradient" style="font-size: 5em !important;"></i>
                                  <p>{{ t('headerData.users', {}, { locale: lang }) }}</p>
                                  <h3>
                                    <span class="small">{{ p.limit.users }} </span> / {{ p.limitPlan.users }}
                                  </h3>
                                </div>
                              </div>
                              <div class="col-md-3 col-6 mt-3">
                                <div class="font-icon-wrapper">
                                  <i class="fas fa-mail-bulk bg-deep-blue icon-gradient" style="font-size: 5em !important;"></i>
                                  <p>{{ t('dashboardData.boards', {}, { locale: lang }) }}</p>
                                  <h3>
                                    <span class="small">{{ p.limit.boards }} </span> / {{ p.limitPlan.boards }}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Detalles del plan -->
                      </div>

                    </div>
                    <!-- Plan actual del usuario -->

                  
                  </div>
              
                </div>
                
              </div>
              <!-- Opciones de configuración de la cuenta del usuario -->
            
            </div>

          </div>
          <!-- Container Normal -->

        </div>
      </div>
    </div>


    <!-- Modal para agregar Tarjetas de credito -->
    <div v-if="modalAddNewCard">
      <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      {{ t('userSettings.addPaymentMethod', {}, { locale: lang }) }}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="openModalNewCard()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
        
                    <div class="row">

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="first_name" type="text" name="first_name" 
                          placeholder="First Name" 
                          class="form-control" 
                          v-model="formCreditCards.first_name"/>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="last_name" type="text" name="last_name" placeholder="Last Name"
                          class="form-control" 
                          v-model="formCreditCards.last_name"/>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <input id="address1" name="address1" type="text" placeholder="Address"
                          class="form-control" 
                          v-model="formCreditCards.address1"/>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <input id="address2" name="address1" type="text" placeholder="Address"
                          class="form-control" 
                          v-model="formCreditCards.address2"/>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="city" name="city" type="text" placeholder="City"
                          class="form-control" 
                          v-model="formCreditCards.city"/>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="state" name="State" type="text" placeholder="FL"
                          class="form-control" 
                          v-model="formCreditCards.state"/>
                        </div>
                      </div>

                    </div>
      
                    <br>
                    <!-- Contenedor para la informacion de la tarjeta de credito -->
                    <div id="card-container" :style="{ 'margin-top': '0' }"></div>
                    <div id="payment-status-container"></div> 
                    <!-- Contenedor para la informacion de la tarjeta de credito -->
                  
                    <div class="col-md-12">
                      <button id="card-button" class="btn btn-primary btn-block btn-pill" type="button" @click="handleStoreCardMethodSubmission()">
                        {{ t('button.storeCard', {}, { locale: lang }) }}
                      </button>
                    </div>

                  </div>

                </div>
              </div>
            </div>
        </div>
      </transition>
    </div>
    <!-- Modal para agregar Tarjetas de credito -->


    <!-- Modal para eliminar Tarjetas de credito -->
    <div v-if="modalDeleteCards">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    {{ t('modalsCardsData.deleteCard', { }, { locale: lang }) }}
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    @click="openModalDeleteCards()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body text-center">
                  <h6>{{ t('modalsCardsData.deleteCardMsj', { }, { locale: lang }) }}</h6>
                  <img :src="cardsIconBilling(deleteCard.type)" :style="{ 'width': '60px' }">
                  * * * *{{ deleteCard.last4 }}
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-danger btn-sm" @click="deleteSelectCard()">
                    {{ t('button.delete', { }, { locale: lang }) }}
                  </button>
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                    @click="openModalDeleteCards()">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para eliminar Tarjetas de credito -->


    <!-- Modal para editar la imagen de perfil -->
    <div v-if="modalEditProfilePicture">
      <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      {{ t('modalAddImgUser.changeProfileImage', {}, { locale: lang }) }}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="changeModalChangeImg()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>


                <div v-if="!fUserImg.loadingFile" class="modal-body dropzone"
                  :class="{ 'active-dropzone': fUserImg.dropActive }" @dragenter.prevent="handleDropZoneIn"
                  @dragleave.prevent="handleDropZoneEnd" @dragover.prevent @drop="drop($event)">

                  <label for="file">

                    <img src="../assets/file-upload.svg" :style="{'width':'3.8rem'}">

                    <span :style="{'font-size':'12px',}">
                      <b> {{ t('modalAddImgUser.dragDropImage', {}, { locale: lang }) }} </b>
                    </span>
                    <br>
                    <br>

                    <input class="inputdropzone" type="file" id="file" ref="file" @change="handleFileUpload()">

                  </label>

                  <img v-if="fUserImg.file != ''" width="52" class="rounded-circle" :src="fUserImg.fileSelect" alt="">
                  <span v-if="fUserImg.file != ''">
                    <b> {{ t('modalAddImgUser.image', {}, { locale: lang }) }}: </b>
                    {{ fUserImg.file.name }}
                    <i :style="{'font-size':'18px', 'color':'red',}" class="lnr-trash btn-icon-wrapper"
                      @click="deleteSelectImg()"></i>
                  </span>
                  <br>
                  <span class="helper-text danger-text">
                    {{ fUserImg.fileError }}
                  </span>

                </div>
                <div v-else class="modal-body loader-zone">
                  <div class="loader-wrapper d-flex justify-content-center align-items-center">
                    <div class="loader">
                      <div class="ball-scale-ripple-multiple">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>

                  <div class="modal-footer">
                    <button type="button" class="btn btn-primary btn-sm" @click="changeUserImg()">
                      {{ t('button.add', {}, { locale: lang }) }}
                    </button>
                    <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                      @click="changeModalChangeImg()">
                      {{ t('button.close', {}, { locale: lang }) }}
                    </button>
                  </div>

                </div>
              </div>
            </div>
        </div>
      </transition>
    </div>
    <!-- Modal para editar la imagen de perfil -->    

  </div>
</template>


<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { closeMenu, showInfoData, checkSuperAdminRol, checPermissions, getTeamsByPermissions, checkError401 } from '@/utility/functions';
import { requiredFields, samePasswords, requiredFiles } from '@/utility/validations';
import Header from '@/components/template/Header.vue';
import Sidebar from '@/components/template/Sidebar.vue';

export default {
  name: 'SettingsAccount',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      closeMenu,
      showInfoData,
      checkSuperAdminRol,
      checPermissions,
      getTeamsByPermissions,
      moment
    };
  },
  components: {
    Header,
    Sidebar,
  },
  data() {
    return {
      appId: 'sq0idp-8Vb-gFkiOlaB0sI9asg-1g',// 'sandbox-sq0idb-lqHc3vg149Bk37aA1Y8BAg'
      locationId: 'LWNMZDMNJ1VQR',
      payments: null,
      iti: null,
      card: null,
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      modalEditProfilePicture: false,
      fUserImg: {
        loadingFile: false,
        dropActive: false,
        file: '',
        fileError: '',
        fileSelect: '',
      },
      showEditProfile: false,
      forUser: {
        name: '',
        errName: '',
        address: '',
        errAddress: '',
        state: '',
        errState: '',
        city: '',
        errCity: '',
        zip_code: '',
        errZipCode: '',
        phone: '',
        errPhone: '',
      },
      formPassword: {
        password_old: '',
        errPasswordOld: '',
        password_new: '',
        errPasswordNew: '',
        password_repeat: '',
        errPasswordRep: '',
      },
      cardsIcons:  {
        VISA: require("@/assets/cardsIcon/Visa.svg"),
        MASTERCARD: require("@/assets/cardsIcon/Mastercard.svg"),
        AMERICAN_EXPRESS: require("@/assets/cardsIcon/Amex.svg"),
        DISCOVER: require("@/assets/cardsIcon/Discover.svg"),
        JCB: require("@/assets/cardsIcon/JCB.svg"),
      },
      modalAddNewCard: false,
      formCreditCards: {
        first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
      },
      modalDeleteCards: false,
      deleteCard: {},
    };
  },
  computed: {
    ...mapState('auth', ['userInfo', 'imgProfile',]),
    ...mapState('system', ['lang']),
    ...mapState('usersData', ['plansInfo','errorUsers']),
    ...mapState('paymentsData', ['billing', 'paymentHistory', 'errorPayments',]),    
  },
  methods: {
    ...mapActions('auth', ['updateLoginUsersData', 'userUpdateImg', 'logOut']),
    ...mapActions('system', ['loadSystemBackground']),
    ...mapActions('usersData', ['getUsersPlansinfo', 'updatePasswordData']),
    ...mapActions('paymentsData', [   
      'paymentHistoryData',
      'downloadPaymentsInvoice',
      'getBillingsData',
      'registerNewBilling',
      'deleteBillingsData',
      'assignTeamForPlanChange',
    ]),

  
    /** Funcion para activar el editar el perfil del usuario */
    showEditUserProfile(action) { 

      if(action) {

        this.forUser.name = this.userInfo.n21;
        this.forUser.errName = '';
        this.forUser.address = this.userInfo.ad21;
        this.forUser.errAddress = '';
        this.forUser.state = this.userInfo.st21;
        this.forUser.errState = '';
        this.forUser.city = this.userInfo.c21;
        this.forUser.errCity = '';
        this.forUser.zip_code = this.userInfo.z21;
        this.forUser.errZipCode = '';
        this.forUser.phone = this.userInfo.p21;
        this.forUser.errPhone = '';        
      }

      this.showEditProfile = !this.showEditProfile;
    },


    /** Funcion para actualizar datos basicos del usuario */
    async updateUserLoginData() {

      this.forUser.errName = requiredFields(this.forUser.name, this.lang);
      this.forUser.errPhone = requiredFields(this.forUser.phone, this.lang);
      this.forUser.errAddress = requiredFields(this.forUser.address, this.lang);
      this.forUser.errState = requiredFields(this.forUser.state, this.lang);
      this.forUser.errCity = requiredFields(this.forUser.city, this.lang);
      this.forUser.errZipCode = requiredFields(this.forUser.zip_code, this.lang);

      if (this.forUser.errName == '' && this.forUser.errPhone == '' && this.forUser.errAddress == '' 
        && this.forUser.errState == '' && this.forUser.errCity == '' && this.forUser.errZipCode == '') {
        await this.updateLoginUsersData({ ...this.forUser });
        if (checkError401(this.errorAuth)) { this.logOut(); }
        this.showEditProfile = !this.showEditProfile;
      }
    },


    /** Funcion para actualizar la contraseña del usuario */
    async updateUserPassword() {

      this.formPassword.errPasswordOld = requiredFields(this.formPassword.password_old, this.lang);
      this.formPassword.errPasswordNew = requiredFields(this.formPassword.password_new, this.lang);
      this.formPassword.errPasswordRep = requiredFields(this.formPassword.password_repeat, this.lang);
      if (this.formPassword.password_new != '' && this.formPassword.password_repeat != '') {
        this.formPassword.errPasswordRep = samePasswords(this.formPassword.password_new, this.formPassword.password_repeat, this.lang);
      }

      if (this.formPassword.errPasswordOld == '' && this.formPassword.errPasswordNew == '' && this.formPassword.errPasswordRep == '') {
        await this.updatePasswordData(this.formPassword);
        if (checkError401(this.errorUsers)) { this.logOut(); }
        this.formPassword = {
          password_old: '',
          errPasswordOld: '',
          password_new: '',
          errPasswordNew: '',
          password_repeat: '',
          errPasswordRep: '',
        };
      }
    },


    /** Funcion para el cambio de imagen de perfil */


    /** Funcion para abrir o cerrar modal cambiar imagen de perfil */
    changeModalChangeImg() {
      this.fUserImg = {
        loadingFile: false,
        dropActive: false,
        file: '',
        fileError: '',
        fileSelect: '',
      };
      this.modalEditProfilePicture = !this.modalEditProfilePicture;
    },
    
    
    /** Funcion para mostrar la miniatura de la imagen seleccionada */
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },

    
    /** Iniciar eventos del Drop */
    handleDropZoneIn(event) {
      event.dataTransfer.dropEffect = 'copy';
      event.dataTransfer.effectAllowed = 'copy';
      this.fUserImg.dropActive = true;
    },

    /** Procesar evento Drop */
    drop(event) {
      event.preventDefault();
      this.fUserImg.file = event.dataTransfer.files[0];
      this.getBase64(event.dataTransfer.files[0], (base64Url) => {
        this.fUserImg.fileSelect = base64Url;
      });
      this.fUserImg.fileError = '';
      this.fUserImg.dropActive = true;
    },


    /** Finalizar eventos del Drop  */
    handleDropZoneEnd() {
      if (this.fUserImg.file == '') {
        this.fUserImg.dropActive = false;
      }
    },


    /** Manejar evento seleccionar archivo */
    handleFileUpload() {
      this.fUserImg.file = this.$refs.file.files[0];
      this.getBase64(this.$refs.file.files[0], (base64Url) => {
        this.fUserImg.fileSelect = base64Url;
      });
      this.fUserImg.fileError = '';
      this.fUserImg.dropActive = true;
    },


    /** Funcion para eliminar imagen seleccionada por el usaurio */
    deleteSelectImg() {
      this.fUserImg.loadingFile = '';
      this.fUserImg.dropActive = '';
      this.fUserImg.file = '';
      this.fUserImg.fileError = '';
      this.fUserImg.fileSelect = '';
    },


    /** Funcion para guardar imagen de perfil del usuario en la BD */
    async changeUserImg() {
      this.fUserImg.fileError = requiredFiles(this.fUserImg.file, this.lang);
      if (this.fUserImg.fileError == '') {
        await this.userUpdateImg(this.fUserImg.file);
        if (checkError401(this.errorAuth)) { this.logOut(); }
        this.changeModalChangeImg();
      }
    },

    /** Funcion para el cambio de imagen de perfil  */






    /** Funciones para la opcion de Metodos de Pago */

    /** Funcion para elegir el icono de las tarjetas de credito */
    cardsIconBilling(tp) { 
      /* Visa, Mastercard, AmericanExpress, Discover, JCB, or DinersClub. */

      if (tp == 'VISA') {

        return this.cardsIcons.VISA;
      
      } else if (tp == 'MASTERCARD') {

        return this.cardsIcons.MASTERCARD;

      } else if (tp == 'AMERICAN_EXPRESS') {

        return this.cardsIcons.AMERICAN_EXPRESS;

      } else if (tp == 'DISCOVER') {

        return this.cardsIcons.DISCOVER;

      } else if (tp == 'JCB' || tp == 'DISCOVER_DINERS') {

        return this.cardsIcons.JCB;

      }
    },


    /** Funcion para mostrar el modal para agregar Tarjetas de credito */
    async openModalNewCard() {
      this.modalAddNewCard = !this.modalAddNewCard;
      this.formCreditCards =  {
        first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
      };

      if (this.modalAddNewCard) {

        /** Se inicializa el contenedor de la tarjeta de Square */
        if (!window.Square) {
          throw new Error('Square.js failed to load properly');
        }

        try {
          this.payments = window.Square.payments(this.appId, this.locationId);
        } catch (e) {
          console.error('Initializing', e);
          const statusContainer = document.getElementById('payment-status-container');
          statusContainer.className = 'missing-credentials';
          statusContainer.style.visibility = 'visible';
          return;
        }

        try {
          this.card = await this.initializeCard(this.payments);
        } catch (e) {
          console.error('Initializing Card failed', e);
          return;
        }
        /** Se inicializa el contenedor de la tarjeta de Square */
      }
    },


    /** Funcion para inicializar el contenedor de la tarjeta de credito */
    async initializeCard(payments) {
      const card = await payments.card();
      await card.attach('#card-container');
      return card;
    },


    /** Funcion para procesar el registro de la tarjeta de credito */
    async handleStoreCardMethodSubmission() {
      try {

        /* disable the submit button as we await tokenization and make a payment request. */
        const token = await this.tokenize(this.card);
        let verificationToken = await this.verifyBuyer(this.payments, token);
        const storeCardResults = await this.storeCard(token, verificationToken);

        this.displayResults('SUCCESS');
        console.debug('Store Card Success', storeCardResults);
        this.openModalNewCard();

      } catch (e) {
        this.displayResults('FAILURE');
        console.error('Store Card Failure', e);
      }
    },


    /** Funcion generar el token de la tarjeta ingresada por el usuario */
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        throw new Error(
          `Tokenization errors: ${JSON.stringify(tokenResult.errors)}`
        );
      }
    },


    /** Funcion para mostrar el resultado de las operaciones a las tarjetas de credito */
    displayResults(status) {

      const statusContainer = document.getElementById('payment-status-container');

      if (status === 'SUCCESS') {

        statusContainer.classList.remove('is-failure');
        statusContainer.classList.add('is-success');

      } else {

        statusContainer.classList.remove('is-success');
        statusContainer.classList.add('is-failure');
      }

      statusContainer.style.visibility = 'visible';
    },


    /** Funcion para verificar los datos del comprador (Tarjeta) */
    async verifyBuyer(payments, token) {

      const verificationDetails = {
        billingContact: {
          addressLines: [this.formCreditCards.address1, this.formCreditCards.address2],
          familyName: this.formCreditCards.last_name,
          givenName: this.formCreditCards.first_name,
          country: 'US',
          region: this.formCreditCards.state,
          city: this.formCreditCards.city,
        },
        intent: 'STORE',
      };

      const verificationResults = await payments.verifyBuyer(token, verificationDetails);
      return verificationResults.token;
    },


    /** Funcion para procesar los pagos de registro mediante la api */
    async storeCard(token, verificationToken) {

      const bodyParameters = {
        customerPaymentID: this.userInfo.pId,
        locationid: this.locationId,
        token: token,
        verification_token: verificationToken,
        billing_address1: this.formCreditCards.address1,
        billing_address2: this.formCreditCards.address2,
        billing_city: this.formCreditCards.city,
        billing_state: this.formCreditCards.state,
        billing_first_name: this.formCreditCards.first_name,
        billing_last_name: this.formCreditCards.last_name,
      };

      await this.registerNewBilling(bodyParameters);
    },


    /** Funcion para mostrar el modal para eliminar una tarjeta de credito */
    openModalDeleteCards(card = {}) {
      this.deleteCard = card;
      this.modalDeleteCards = !this.modalDeleteCards;
    },


    /** Funcion para eliminar una tarjeta de credito mediante la api */
    async deleteSelectCard() {

      await this.deleteBillingsData(this.deleteCard.uuid);
      if (checkError401(this.errorPayments)) { this.logOut(); }
      if (this.errorPayments == null) { this.openModalDeleteCards(); }
    },


    /** Funciones para la opcion de Metodos de Pago */


    /** Funcion para descargar la factura de un pago */

    async downloadOnePaymentsInvoice(uuid) {
      await this.downloadPaymentsInvoice(uuid);
      if (checkError401(this.errorPayments)) { this.logOut(); }
    },


    /** Funcion para cambiar de plan a un team */
    changeTeamPlan(team) {
      this.assignTeamForPlanChange(team);
      this.$router.push('/changingPlans');
    }

  },
  mounted: async function () {

    this.loadSystemBackground('');
    this.paymentHistoryData();
    this.getBillingsData();
    this.getUsersPlansinfo();
    if (checkError401(this.errorPayments)) { this.logOut(); }
  },
}
</script> 


<style scoped>

.btn-sm,
.btn-group-sm>.btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

@media (min-width: 300px) {
  .img-size-flash {
    width: 30%;
  }
}

@media (min-width: 375px) {
  .img-size-flash {
    width: 30%;
  }
}

@media (min-width: 576px) {
  .img-size-flash {
    width: 30%;
  }
}

@media (min-width: 768px) {
  .img-size-flash {
    width: 30%;
  }
}

@media (min-width: 992px) {
  .img-size-flash {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .img-size-flash {
    width: 50%;
  }
}

.delete-icon {
  color: red;
  font-size: 18px;
  margin-left: 15px;
}

.style-icon-file {
  background-color: white;
  border: 1px solid gray;
  margin-bottom: 5px;
}

.modal-mask {
  position: fixed;
  z-index: 990;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.dropzone {
  min-height: auto;
  padding: 1.5rem 1.75rem;
  text-align: center;
  cursor: pointer;
  border: 1.5px dashed rgb(46, 134, 193);
  background-color: rgb(214, 234, 248);
  border-radius: 0.475rem !important;
}

.loader-zone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.active-dropzone {
  color: rgb(0, 0, 0);
  background-color: rgba(169, 204, 227);
  border: 1.5px dashed rgb(31, 97, 141);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.inputdropzone {
  display: none;
}
</style>