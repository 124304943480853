<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Rols
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('add:Rols', userInfo.access).length > 0)"
                    type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary" :style="{'margin-left':'10px'}"
                    :title="t('headerData.createRols', {}, { locale: lang })" data-placement="bottom"
                    @click="changeModalAddEditRols()">
                    <i class="fas fa-plus-circle style-1icon"></i>
                  </button>
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalRols()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" :style="{ 'max-height': '65vh', 'min-height': '40vh', 'overflow-x':'hidden', 'overflow-y':'auto'}">
                <table id="rolsTable" class="table table-striped table-bordered cell-border compact stripe">
                  <thead class="text-center">
                    <tr>
                      <th>{{ t('headerData.displayName', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.codeName', { }, { locale: lang }) }}</th>
                      <th>{{ t('modalsCardsData.description', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.createdDate', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.options', {}, {locale: lang}) }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr v-for="rol in rols" :key="rol.id">
                      <td>{{ rol.display_name }}</td>
                      <td>{{ rol.name }}</td>
                      <td>{{ rol.description }}</td>
                      <td>{{ moment(rol.createdAt).format('MM-DD-YYYY') }}</td>
                      <td>
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('edit:Rols', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.updatedRols', {}, { locale: lang })" @click="changeModalAddEditRols(rol)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px'}" class="fas fa-edit"></i>
                        </button>
                        
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('assign:Permissions', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.permissions', {}, { locale: lang })" @click="changeModalAssignPermissions(rol)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px'}" class="fas fa-link"></i>
                        </button>

                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('delete:Rols', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.delete', {}, { locale: lang })" @click="changeModalDeleteRols(rol)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px', 'color':'red'}" class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="changeModalRols()">
                {{ t('button.close', { }, { locale: lang }) }}
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>


  <div v-if="modalAddEditNewRol">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t(formRol.new ? 'headerData.createRols' : 'headerData.updatedRols', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalAddEditRols()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">

                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.displayName', { }, { locale: lang }) }}</label>
                      <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                        v-model="formRol.displayName" required>
                      <span class="helper-text danger-text">{{ formRol.errDisplay }}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.codeName', { }, { locale: lang }) }}</label>
                      <input name="name" id="name" placeholder="" type="text" class="form-control form-control-sm" v-model="formRol.name"
                        required>
                      <span class="helper-text danger-text">{{ formRol.errName }}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('modalsCardsData.description', { }, { locale: lang }) }}</label>
                      <textarea rows="1" class="form-control autosize-input" :style="{'height':'50px'}" v-model="formRol.description"></textarea>
                    </div>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="registerNewRol()">
                  {{ t(formRol.new ? 'button.add' : 'button.save', { }, { locale:lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  @click="changeModalAddEditRols()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <div v-if="modalDeleteRol">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t('headerData.delete', { }, { locale: lang }) }} Rols
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalDeleteRols()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h6>{{ t('headerData.confirmationDelete', { }, { locale: lang }) }}</h6>
                    <b>{{ rolItem.display_name }}</b>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deteleOneRol()">
                  {{ t('button.delete', {}, {locale: lang}) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  @click="changeModalDeleteRols()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <div v-if="modalAssignPermissions">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t('headerData.permissions', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalAssignPermissions()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-left scroll-area-lg" :style="{'overflow':'auto'}">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center" :style="{'margin-bottom':'-1.5rem'}">
                    <div class="form-group" :style="{'padding':'8px'}">
                      <div class="form-check">
                        <input id="selectAll" type="checkbox" value="1" v-model="seletPerm" class="form-check-input"
                          @change="changeSeletPerm()"/>
                        <label class="form-check-label" for="selectAll">{{ t('headerData.selectAll', { }, { locale: lang }) }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="form-group row" :style="{'padding':'8px'}">
                      <div class="form-check col-sm-12 col-md-4 col-lg-4 col-xl-4" v-for="p in permissions" :key="p.id">
                        <input type="checkbox" :value="p.id" v-model="permissionsToAssign" :id="p.i + 'perm'" class="form-check-input" />
                        <label class="form-check-label" :for="p.i + 'perm'">{{ p.display_name }}</label>
                      </div> 
                    </div>
                  </div>
                </div>
  
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="savePermissionToRol()">
                  {{ t('button.add', { }, { locale:lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  @click="changeModalAssignPermissions()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>


<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { showInfoData, checkError401, checkSuperAdminRol, getTeamsByPermissions } from '@/utility/functions';
import { requiredFields } from '@/utility/validations';
import 'datatables.net-bs5';
import $ from 'jquery';
export default {
  name: 'ModalManageRols',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      showInfoData,
      moment,
      checkSuperAdminRol,
      getTeamsByPermissions
    };
  },
  components: {
  },
  data: function () {
    return {
      rolItem: {},
      modalAddEditNewRol: false,
      formRol: {
        new: true,
        name: '',
        displayName: '',
        description: '',
        errName: '',
        errDisplay: '',
      },
      modalDeleteRol: false,
      modalAssignPermissions: false,
      permissionsToAssign: [],
      seletPerm: [],
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang', 'modalRols']),
    ...mapState('rolsData', ['rols', 'permissions', 'errorRols']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['changeModalRols']),
    ...mapActions('rolsData', ['getRolsData', 'getPermissionsData', 'registerRols', 'updateRolsData', 'deleteRolsData', 
      'getPermissionsData', 'assignPermissionsToRol'
    ]),
    table() {
      this.$nextTick(() => {
        $('#rolsTable').DataTable();
      });
    },
  
    /** Funcion para abrir modal para añadir o editar rol */
    changeModalAddEditRols(item = {}) {

      if (Object.entries(item).length > 0) {

        this.rolItem = item;
        this.formRol.new = false;
        this.formRol.name = item.name;
        this.formRol.displayName = item.display_name;
        this.formRol.description = item.description;
        this.formRol.errName = item.errName;
        this.formRol.errDisplay = item.errDisplay;

      } else {

        this.rolItem = item;
        this.formRol = {
          new: true,
          name: '',
          displayName: '',
          description: '',
          errName: '',
          errDisplay: '',
        };
      }

      this.modalAddEditNewRol = !this.modalAddEditNewRol;
    },

    /** Funcion para registrar o editar un nuevo rol */
    async registerNewRol() {

      this.formRol.errDisplay = requiredFields(this.formRol.displayName, this.lang);
      this.formRol.errName = requiredFields(this.formRol.name, this.lang);

      if (this.formRol.errDisplay == '' && this.formRol.errName == '') {

        if (this.formRol.new) {

          await this.registerRols(this.formRol);
          if (checkError401(this.errorRols)) { this.logOut(); }
          if (this.errorRols == null) {
            this.formRol = {
              new: true,
              name: '',
              displayName: '',
              description: '',
              errName: '',
              errDisplay: '',
            };
          }

        } else {

          await this.updateRolsData({ ...{ id: this.rolItem.id }, ...this.formRol });
          if (checkError401(this.errorRols)) { this.logOut(); }
          if (this.errorRols == null) {
            this.changeModalAddEditRols();
          }
        }
      }

    },

    /** Funcion para cambiar el estado del modal eliminar rol */
    changeModalDeleteRols(item = {}) {

      this.rolItem = item;
      this.modalDeleteRol = !this.modalDeleteRol;
    },

    /** Funcion para eliminar un rol con todos sus permisos  */
    async deteleOneRol() {

      if(Object.entries(this.rolItem).length > 0){

        await this.deleteRolsData(this.rolItem.id);
        if (checkError401(this.errorRols)) { this.logOut(); }
        if (this.errorRols == null) {
          this.changeModalDeleteRols();
        }

      }
    },

    /** Funcion para mostar el modal de asignar permisos a los roles */
    async changeModalAssignPermissions(item = {}) {

      if(Object.entries(item).length > 0){
        
        await this.getPermissionsData();
        if (checkError401(this.errorRols)) { this.logOut(); }

        this.seletPerm = this.permissions.length == item.permissions.length ? ['1'] : [];
        if (item.permissions.length > 0) {
          item.permissions.forEach(item => {
            this.permissionsToAssign.push(item.id)
          })
        }
        
      
      }else{
        this.seletPerm = [];
        this.permissionsToAssign = [];
      }
      
      this.rolItem = item;
      this.modalAssignPermissions = !this.modalAssignPermissions;
    },

    /** Funcion para seleccionar o deseleccionar todos los permisos de un rol */
    changeSeletPerm() {
      if(this.seletPerm != '1') {

        this.permissionsToAssign = [];

      } else {
        this.permissions.forEach(item => {
          this.permissionsToAssign.push(item.id);
        });
      }
    },

    /** Funcion para alamcenar los permisos de un rol */
    async savePermissionToRol() {

      await this.assignPermissionsToRol({
        rol: this.rolItem.id,
        permissions: this.permissionsToAssign,
      });
      if (checkError401(this.errorRols)) { this.logOut(); }
      if (this.errorRols == null){
        this.changeModalAssignPermissions();
      }
    }

  },
  mounted: async function () {

    await this.getRolsData();
    if (checkError401(this.errorRols)) { this.logOut(); }
    if(this.errorRols == null){
      this.table();
    }

  }
}
</script> 


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>