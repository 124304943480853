<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-width2" role="document" :style="{ 'max-width':'700px', }">
            <div class="modal-content">
              <div class="modal-header">
                <ul class="nav nav-justified" :style="{ 'width': '100% !important' }">
                  <li class="nav-item">
                    <a data-toggle="tab" href="#tab-eg-0" class="active nav-link" @click="changeOptionMenu(0)">
                      <b class="modal-title" :style="{ 'color': (opMenu == 0 ? '#3f6ad8' : 'gray') }">
                        {{ t(this.newBoard ? 'modalsBoardsData.addBoard' : 'modalsBoardsData.editBoard', {}, { locale: lang }) }}
                      </b>
                    </a>
                  </li>
                  <li v-show="this.newBoard" class="nav-item">
                    <a data-toggle="tab" href="#tab-eg-1" class="nav-link" @click="changeOptionMenu(1)">
                      <b class="modal-title" :style="{ 'color': (opMenu == 1 ? '#3f6ad8' : 'gray') }">
                        {{ t('headerData.createPrivateTemplates', {}, { locale: lang }) }}
                      </b>
                    </a>
                  </li>
                  <li v-show="this.newBoard && (checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:PublicTemplateBoards', userInfo.access).length > 0)" class="nav-item">
                    <a data-toggle="tab" href="#tab-eg-2" class="nav-link" @click="changeOptionMenu(2)">
                      <b class="modal-title" :style="{ 'color': (opMenu == 2 ? '#3f6ad8' : 'gray') }">
                        {{ t('headerData.createPublicTemplates', {}, { locale: lang }) }}
                      </b>
                    </a>
                  </li>
                </ul>
                <button type="button" class="close" aria-label="Close" @click="changeModalAddEditDeleteBoard({ new: false, team: 0 })">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" :style="{ 'max-height': '400px', 'overflow-x': 'hidden' }">
                <div class="tab-content">
                  <!-- Formulario para crear un tablero desde cero -->
                  <div class="tab-pane active show" id="tab-eg-0" role="tabpanel">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="position-relative form-group">
                          <label for="examplePassword" class="">
                            {{ t('modalsBoardsData.title', {}, { locale: lang }) }}
                          </label>
                          <input name="boardTitle" id="boardTitle" placeholder="" type="text"
                            class="form-control form-control-sm" v-model="boardForm.title">
                          <span class="helper-text danger-text">
                            {{ boardForm.errTitle }}
                          </span>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="position-relative form-group">
                          <label for="exampleText" class="">
                            {{ t('modalsBoardsData.description', {}, { locale: lang }) }}
                          </label>
                          <input name="descriptionBoard" id="descriptionBoard" placeholder="" type="text"
                            class="form-control form-control-sm" :maxlength="max" v-model="boardForm.description">
                          <span class="helper-text danger-text">
                            {{ boardForm.errDescription }}
                          </span>
                          <div class="input-group-addon" :style="{ 'font-size': '14px', }"
                            v-text="`${boardForm.description.length}/${max}`"></div>
                        </div>
                      </div>
                    </div>
                    <h5 class="text-center">
                      {{ t('modalsBoardsData.backgroundBoard', {}, { locale: lang }) }}
                    </h5>
                    <div class="row">
                      <div class="input-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <input type="text" class="form-control form-control-sm" v-model="topics"
                          @keyup.enter="searchImg()" />
                        <div class="input-group-append">
                          <button class="btn btn-outline-primary btn-sm" type="button" @click="searchImg()">
                            {{ t('modalsBoardsData.searchTopic', {}, { locale: lang }) }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <br>
                    <div v-if="!loading" class="row text-center">
                      <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3" v-for="f in backgrounds" :key="f.id"
                        style="margin-bottom: 10px;">
                        <img :class="boardForm.link_img == f.urls.raw ? 'img-board select-img' : 'img-board'"
                          :src="f.urls.thumb" @click="selectPhoto(f.urls.raw)">
                      </div>
                    </div>
                    <div v-else class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                        <div class="line-scale-pulse-out">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    <span class="helper-text danger-text">
                      {{ boardForm.errImg }}
                    </span>
                  </div>
                    <!-- Formulario para crear Tun tablero desde cero-->

                  <!-- Formulario para crear un tablero seleccionando una plantilla privada -->
                   <div class="tab-pane" id="tab-eg-1" role="tabpanel">
                    <div class="row text-center">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="position-relative form-group">
                            <label class="">{{ t('modalsCardsData.selectTemplate', {}, { locale: lang }) }}</label>
                            <select class="form-control form-control-sm" v-model="formPrivateTempl.template">
                              <option :value='-1'>{{ t('modalsCardsData.selectTemplate', {}, { locale: lang }) }}</option>
                              <option v-for="tempb in templatesBoards" :key="tempb.id" :value="tempb.id">
                                {{ `${showInfoData(tempb.title, lang)}` }} 
                              </option>
                            </select>
                            <span class="helper-text danger-text">{{ formPrivateTempl.errTemplate }}</span>
                          </div>
                        </div>

                        <div v-show="formPrivateTempl.template >= 0" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="position-relative form-group">
                            <label for="" class="">{{ t('modalsCardsData.title', {}, { locale: lang }) }}</label>
                            <input name="titleTem" id="titleTem" type="text" class="form-control form-control-sm" v-model="formPrivateTempl.title" />
                            <span class="helper-text danger-text">{{ formPrivateTempl.errTitle }}</span>
                          </div>
                        </div>
                    </div>
                  </div>
                  <!-- Formulario para crear un tablero seleccionando una plantilla privada -->

                  <!-- Formulario para crear un tablero seleccionando una plantilla publica -->
                   <div class="tab-pane" id="tab-eg-2" role="tabpanel">
                    <div class="row text-center">
                      <div class="row text-center">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
                          <label class="">{{ t('headerData.categories', {}, { locale: lang }) }}</label>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <select class="form-control form-control-sm" v-model="categoriFilter">
                            <option :value='0'>{{ t('headerData.selectAll', {}, { locale: lang }) }}</option>
                            <option v-for="c in categories" :key="c.id" :value="c.id">
                                {{ c.name }} 
                            </option>
                          </select>
                        </div>
                      </div>
                      <br>
                      <div class="row text-center" :style="{'margin-top': '2%'}">
                        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4" v-for="(tem, index) in templatesBoards" :key="tem.id"
                          :style="{'margin-bottom':'10px'}" v-show="checkCategoriFilter(tem.categories)">
                          <div>
                            <b :style="{'font-size': '11px'}">{{ tem.title }}</b>
                            <img :class="formPublicTempl.template == tem.id ? 'select-img' : ''" :title="tem.title" :src="tem.img" :style="{ 'width': '180px', 'height': '130px' }" @click="showImgBoardTemplate(index)">
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="position-relative form-group">
                            <span class="helper-text danger-text">{{ formPublicTempl.errTemplate != '' ? t('headerData.selectATemplate', {}, { locale: lang }) : '' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Formulario para crear un tablero seleccionando una plantilla publica -->

                </div>
              </div>
              <div class="modal-footer">
                <button v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('delete:Boards', userAccess.permissions)) && !this.newBoard" 
                  type="button" class="btn btn-danger btn-sm" @click="changeModalDelete()">
                  {{ t('button.delete', {}, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-primary btn-sm" @click="saveBoard()">
                  {{ t(this.newBoard ? 'button.add' : 'button.save', {}, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" @click="changeModalAddEditDeleteBoard({ new: false, team: 0 })">
                  {{ t('button.close', {}, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>


    <!-- Modal Eliminar tablero -->
    <div v-if="modalDeleteBoard">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ t('modalsBoardsData.deleteBoard', {}, {locale: lang}) }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalDelete()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <p class="mb-0">
                    {{ t('modalsBoardsData.boardMsj', {}, {locale: lang}) }}
                  </p>
                  <b>
                    {{ showInfoData(boardItem.title, lang) }}
                  </b>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger btn-sm" @click="deleteBoard()">
                    {{ t('button.delete', {}, {locale: lang}) }}
                  </button>
                  <button type="button" class="btn btn-secondary" @click="changeModalDelete()">
                    {{ t('button.close', {}, {locale: lang}) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal Eliminar tablero -->


    <!-- Modal abrir la imagen del template de un tablero -->
    <div v-if="openImgTemplateBoard">
        <transition name="modal">
          <div class="modal-mask2">
            <div class="modal-wrapper2">
              <div class="row" :style="{'padding':'0.3%', 'width':'100vw !important', 'overflow':'auto'}">

                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                  <i class="fas fa-times" @click="showImgBoardTemplate(-1)" :style="{'color':'white', 'font-size':'25px'}"></i>
                </div>

                <div class="col-sm-12 col-md-10 col-lg-10 col-xl-10">
                  <img class="img-templateBoard" :src="itemTemplate.img">
                </div>

                <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2" :style="{'background-color':'white','padding':'0.5%'}">
                  <p class="text-right">
                    <button type="button" class="btn btn-primary btn-sm" @click="selectPublicTemplateBoard()">
                      {{ t('button.choose', {}, { locale: lang }) }}
                    </button>
                  </p>
                  <p>{{ t('headerData.categories', {}, { locale: lang }) }}: <i v-for="c in itemTemplate.categories" :key="c.id"> {{ c.name }}</i></p>
                  <p>{{ t('headerData.languages', {}, { locale: lang }) }}: <i>{{ itemTemplate.info.languages }}</i></p>
                  <p class="text-center" :style="{ 'color': 'black' }">{{ t('modalsBoardsData.description', {}, { locale: lang }) }}</p>
                  <br>
                  <p :style="{'color':'black'}">{{ itemTemplate.info.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </transition>
    </div>
    <!-- Modal abrir la imagen del template de un tablero -->


  </div>
</template>


<script>
import { showInfoData, checkError401, filterUserAccessByTeam, checkSuperAdminRol, getTeamsByPermissions, checPermissions } from '@/utility/functions';
import { requiredFields, requiredFiles, requiredFieldsNumber } from '@/utility/validations';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: 'ModalBoard',
  setup() {
    const { t, locale } = useI18n();
    return { 
      t,
      locale,
      showInfoData,
      checkSuperAdminRol,
      getTeamsByPermissions,
      checPermissions
    };
  },
  data: function () {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      opMenu: 0,
      boardForm: {
        id: '',
        title: '',
        errTitle: '',
        description: '',
        errDescription: '',
        link_img: '',
        errImg: '',
        team: 0,
      },
      formPrivateTempl: {
        template: -1,
        errTemplate: '',
        title: '',
        errTitle: '',
        team: 0,
      },
      categoriFilter: 0,
      itemTemplate: {},
      formPublicTempl: {
        template: -1,
        errTemplate: '',
        team: 0,
      },  
      max: 50,
      topics: "",
      modalDeleteBoard: false,
      openImgTemplateBoard: false,
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang']),
    ...mapState('boardsData', ['boardItem', 'loading', 'errorBoards', 'backgrounds', 'newBoard', 'teamToBoard', 'templatesBoards']),
    ...mapState('categoriesData', ['categories', 'errorCategories']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['loadSystemBackground']),
    ...mapActions('boardsData', [
      'getBackgroundsForBoards', 'addBoards', 'updateBoards', 'deleteBoards', 'changeModalAddEditDeleteBoard', 
      'getBoardsTemplatesData', 'createBoardByTemplate', 'getPublicBoardsTemplatesData', 'getPublicTemplateImg'
    ]),
    ...mapActions('categoriesData', ['getCategoriesData']),

    /** Funcion para buscar nuevas imagenes de fondo por el topico */
    async searchImg() {
      await this.getBackgroundsForBoards(this.topics);
      if (checkError401(this.errorBoards)) { this.logOut(); }
    },


    /** Seleccionar una imagen de fondo */
    selectPhoto(link) {

      /** Si se esta editando y se selecciona la imagen ya seleccionada con anterioridad se deselecciona */
      if (!this.newBoard && this.boardForm.link_img == link) {
        
        this.boardForm.link_img = this.boardItem.link_img;
      
      }else{
        this.boardForm.link_img = link;
      }  
    },

    /** Funcion para abrir o cerrar la imagen del template de un tablero */
    showImgBoardTemplate(index) {
      this.itemTemplate = index == -1 ? {} : this.templatesBoards[index];
      this.openImgTemplateBoard = !this.openImgTemplateBoard;
    },

    /** Seleccionar una template para crear un tablero */
    selectPublicTemplateBoard(){
      this.formPublicTempl.template = this.itemTemplate.id;
      this.openImgTemplateBoard = !this.openImgTemplateBoard;
    },

    /** Funcion para verificar si se debe mostrar el template publico dependiendo del filtro de categorias */
    checkCategoriFilter(categories) {

      let r = false;

      if(this.categoriFilter == 0){
        
        r = true;
      
      } else {

        categories.forEach(item => {
          if(item.id == this.categoriFilter) {
            r = true;
          }
        })
      }

      return r;
    },


    /** Funcion para Agregar o Editar un tablero */
    async saveBoard() {

      /** Agregar Tablero */
      if (this.newBoard) {

        /** Tablero desde cero */
        if (this.opMenu == 0) {

          console.log('entro en 0');

          this.boardForm.errTitle = requiredFields(this.boardForm.title, this.lang);
          this.boardForm.errDescription = requiredFields(this.boardForm.description, this.lang);
          this.boardForm.errImg = requiredFiles(this.boardForm.link_img, this.lang);

          if (this.boardForm.errTitle == '' && this.boardForm.errDescription == '' && this.boardForm.errImg == '') {
            this.boardForm.team = this.teamToBoard;
            this.addBoards(this.boardForm);
            if (checkError401(this.errorBoards)) { this.logOut(); }
            if (this.errorBoards == null) {
              this.changeModalAddEditDeleteBoard({ new: false, team: 0 });
            }
          }

        /** Tablero desde template privado */
        } else if(this.opMenu == 1) {

          this.formPrivateTempl.errTemplate = requiredFieldsNumber(this.formPrivateTempl.template, this.lang);
          this.formPrivateTempl.errTitle = requiredFields(this.formPrivateTempl.title, this.lang);

          if (this.formPrivateTempl.errTemplate == '' && this.formPrivateTempl.errTitle == '') {
            this.formPrivateTempl.team = this.teamToBoard;
            this.createBoardByTemplate(this.formPrivateTempl);
            if (checkError401(this.errorBoards)) { this.logOut(); }
            if (this.errorBoards == null) {
              this.changeModalAddEditDeleteBoard({ new: false, team: 0 });
            }
          }

        /** Tablero desde template publico */
        } else {

          this.formPublicTempl.errTemplate = requiredFieldsNumber(this.formPublicTempl.template, this.lang);

          if (this.formPublicTempl.errTemplate == '') {
            this.formPublicTempl.team = this.teamToBoard;
            this.createBoardByTemplate(this.formPublicTempl);
            if (checkError401(this.errorBoards)) { this.logOut(); }
            if (this.errorBoards == null) {
              this.changeModalAddEditDeleteBoard({ new: false, team: 0 });
            }
          }
        }

        /** Editar Tablero */
      } else {

        this.boardForm.errTitle = requiredFields(this.boardForm.title, this.lang);
        this.boardForm.errDescription = requiredFields(this.boardForm.description, this.lang);

        if (this.boardForm.errTitle == '' && this.boardForm.errDescription == '') {
          await this.updateBoards(this.boardForm);
          if (checkError401(this.errorBoards)) { this.logOut(); }
          if (this.errorBoards == null) {
            this.loadSystemBackground('');
            this.loadSystemBackground(this.boardItem.link_img);
            this.changeModalAddEditDeleteBoard({ new: false, team: 0 });
          }
        }
      }
    },


    /** Mostrar modal Eliminar tablero */
    changeModalDelete() {
      this.modalDeleteBoard = !this.modalDeleteBoard;
    },

    /** Funcion para eliminar el tablero */
    async deleteBoard() {
      await this.deleteBoards(this.boardItem.id);
      if (checkError401(this.errorBoards)) { this.logOut(); }
      if (this.errorBoards == null) {
        this.changeModalDelete();
        this.changeModalAddEditDeleteBoard({ new: false, team: 0 });
        this.$router.push('/dashboard');
      }
    },


    /** Funcion para cambiar las pestañas en el crear y limpiar los formularios */
    async changeOptionMenu(op){

      if(op != this.opMenu) {

        if(op == 0) {

          this.boardForm = {
            id: '',
            title: '',
            errTitle: '',
            description: '',
            errDescription: '',
            link_img: '',
            errImg: '',
          };

        } else if(op == 1) {
          
          this.getBoardsTemplatesData(1);
          if (checkError401(this.errorBoards)) { this.logOut(); }

          this.formPrivateTempl = {
            template: -1,
            errTemplate: '',
            title: '',
            errTitle: '',
            team: 0,
          };

        } else {

          this.categoriFilter = 0;

          await this.getPublicBoardsTemplatesData();
          if (checkError401(this.errorBoards)) { this.logOut(); }

          this.formPublicTempl = {
            template: -1,
            errTemplate: '',
            team: 0,
          };

          this.templatesBoards.forEach(item => {
            this.getPublicTemplateImg(item.info.id);
          })

          this.getCategoriesData();
        }
        this.opMenu = op;
      }
    }


  },
  mounted: async function () {

    await this.getBackgroundsForBoards('business');
    if (checkError401(this.errorBoards)) { this.logOut(); }

    /** Si se va a editar */
    if (!this.newBoard) {

      this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.boardItem.team_id);

      this.boardForm.id = this.boardItem.id;
      this.boardForm.title = showInfoData(this.boardItem.title, this.lang);
      this.boardForm.description = showInfoData(this.boardItem.description, this.lang);
      this.boardForm.link_img = this.boardItem.link_img;
    
    } else {
      
      this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.teamToBoard);
    }
  }
}
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-mask2 {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper2 {
  display: table-cell;
  vertical-align: middle;
}

.select-img {
  border-radius: 5%;
  box-shadow: 0px 0px 5px 5px #248bdf;
}

@media (min-width: 375px) {
  .img-board {
    width: 95%;
    height: 95%;
  }
  .img-templateBoard {
    width: 100%; 
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}

@media (min-width: 576px) {
  .img-board {
    width: 100%;
    height: 100%;
  }
  .img-templateBoard {
    width: 100%; 
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}

@media (min-width: 768px) {
  .img-board {
    width: 90%;
    height: 90%;
  }
  .img-templateBoard {
    width: 100%; 
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}

@media (min-width: 992px) {
  .img-board {
    width: 90%;
    height: 90%;
  }
  .img-templateBoard {
    width: 100%; 
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}

@media (min-width: 1200px) {
  .img-board {
    width: 90%;
    height: 90%;
  }
  .img-templateBoard {
    width: 100%; 
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}
</style>