export default {

  actionTeamsDataBegin(state) {
    state.loading = true;
  },

  actionTeamsDataErr(state, err) {
    state.loading = false;
    state.errorTeams = err;
  },


  actionTeamsDataFin(state) {
    state.loading = false;
    state.errorTeams = null;
  },


  /** Funcion para reiniciar la data a sus estados iniciales */
  resetDataTeamsSuccess(state) {
    state.loading = false;
    state.teams = [];
    state.errorTeams = null;
  },


  /** Funciones para Obtener los teams */
  getTeamsDataSuccess(state, data) {
    state.loading = false;
    state.teams = data;
    state.errorTeams = null;
  },


  /** Funcion para precesar el registro de un usuario */
  registerTeamsSuccess(state, data) {
    state.loading = false;
    state.teams.push(data);
    state.errorTeams = null;
  },


  /** Funcion para actualizar los datos de un usuario */
  updateTeamsDataSuccess(state, data) {
    state.loading = false;
    if (state.teams.length > 0) {
      state.teams.forEach(item => {
        if (item.id == data.id) {
          item.name = data.name;
          item.manager = data.manager;
          item.boards_limit = data.boards_limit;
          item.members_limit = data.members_limit;
          item.plan = data.plan;
          item.createdAt = data.createdAt;
        }
      });
    }
    state.errorTeams = null;
  },


  /** Funcion para eliminar un rol */
  deleteTeamsDataSuccess(state, data) {
    state.loading = false;
    if (state.teams.length > 0) {
      state.teams = state.teams.filter(item => item.id !== data);
    }
    state.errorTeams = null;
  },

};