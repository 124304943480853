<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('headerData.plan', { }, { locale: lang }) }}
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('add:Plans', userInfo.access).length > 0)" 
                    type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary" :style="{'margin-left':'10px'}"
                    :title="t('headerData.createRols', {}, { locale: lang })" data-placement="bottom"
                    @click="changeModalAddEditPlans()">
                    <i class="fas fa-plus-circle style-1icon"></i>
                  </button>
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalPlans()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" :style="{ 'max-height': '65vh', 'min-height': '40vh', 'overflow-x':'hidden', 'overflow-y':'auto'}">
                <table id="plansTable" class="table table-striped table-bordered cell-border compact stripe">
                  <thead class="text-center">
                    <tr>
                      <th>{{ t('headerData.name', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.codeName', {}, {locale: lang}) }}</th>
                      <th>{{ t('headerData.boardLimits', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.memberLimits', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.cost', {}, { locale: lang }) }}</th>
                      <th>{{ t('headerData.tp', {}, { locale: lang }) }}</th>
                      <th>{{ t('headerData.status', {}, {locale: lang}) }}</th>
                      <th>{{ t('headerData.options', {}, {locale: lang}) }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr v-for="plan in plans" :key="plan.id">
                      <td>{{ plan.name }}</td>
                      <td>{{ plan.code_id }}</td>
                      <td>{{ plan.boards_limit }}</td>
                      <td>{{ plan.members_limit }}</td>
                      <td>$ {{ plan.cost }}</td>
                      <td>{{ plan.tp != null ? t(plan.tp == 'plan' ? 'headerData.plan' : 'headerData.package', {}, { locale: this.lang }) : '' }}</td>
                      <td>{{ t(plan.status ? 'headerData.active' : 'headerData.inactive', { }, { locale: this.lang }) }}</td>
                      <td>
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('edit:Plans', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.updatedRols', {}, { locale: lang })" @click="changeModalAddEditPlans(plan)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px'}" class="fas fa-edit"></i>
                        </button>
                        
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('delete:Plans', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.delete', {}, { locale: lang })" @click="changeModalDeletePlan(plan)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px', 'color':'red'}" class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="changeModalPlans()">{{
                  t('button.close', { }, { locale: lang })
                }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>


  <div v-if="modalAddEditNewPlan">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t(formPlan.new ? 'headerData.createRols' : 'headerData.updatedRols', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalAddEditPlans()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">

                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.name', { }, { locale: lang }) }}</label>
                      <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                        v-model="formPlan.name" required>
                      <span class="helper-text danger-text">{{ formPlan.errName }}</span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.codeName', {}, { locale: lang }) }}</label>
                      <input name="code" id="code" placeholder="" type="text" class="form-control form-control-sm"
                          v-model="formPlan.code_id" required>
                      <span class="helper-text danger-text">{{ formPlan.errCode }}</span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('modalsBoardsData.description', {}, { locale: lang }) }}</label>
                      <textarea rows="1" class="form-control autosize-input" :style="{ 'height': '60px' }" v-model="formPlan.description"></textarea>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.boardLimits', { }, { locale: lang }) }}</label>
                      <input name="name" id="name" placeholder="" type="number" class="form-control form-control-sm" v-model="formPlan.boardLimit"
                        required>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.memberLimits', { }, { locale: lang }) }}</label>
                      <input name="name" id="name" placeholder="" type="number" class="form-control form-control-sm" v-model="formPlan.membersLimit">
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.cost', {}, { locale: lang }) }}</label>
                      <input name="name" id="name" placeholder="" type="number" class="form-control form-control-sm" v-model="formPlan.cost">
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.status', { }, { locale: lang }) }}</label>
                      <select class="multiselect-dropdown form-control form-control-sm" v-model="formPlan.status">
                        <option :value="true">{{ t('headerData.active', { }, { locale: this.lang }) }}</option>
                        <option :value="false">{{ t('headerData.inactive', { }, { locale: this.lang }) }}</option>
                      </select>
                      <span class="helper-text danger-text">{{ formPlan.errStatus }}</span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.tp', { }, { locale: lang }) }}</label>
                      <select class="multiselect-dropdown form-control form-control-sm" v-model="formPlan.tp">
                        <option value="plan">{{ t('headerData.plan', { }, { locale: this.lang }) }}</option>
                        <option value="package">{{ t('headerData.package', { }, { locale: this.lang }) }}</option>
                      </select>
                      <span class="helper-text danger-text">{{ formPlan.errTp }}</span>
                    </div>
                  </div>

                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="registerEditOnePlan()">
                  {{ t(formPlan.new ? 'button.add' : 'button.save', { }, { locale:lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalAddEditPlans()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <div v-if="modalDeletePlans">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t('headerData.delete', { }, { locale: lang }) }} Rols
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalDeletePlan()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h6>{{ t('headerData.confirmationDelete', { }, { locale: lang }) }}</h6>
                    <b>{{ planItem.name }}</b>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deteleOnePlan()">
                  {{ t('button.delete', {}, {locale: lang}) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalDeletePlan()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>


<script>
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { showInfoData, checkError401, checkSuperAdminRol, getTeamsByPermissions } from '@/utility/functions';
import { requiredFields } from '@/utility/validations';
import 'datatables.net-bs5';
import $ from 'jquery';
export default {
  name: 'ModalManagePlans',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      showInfoData,
      checkSuperAdminRol,
      getTeamsByPermissions 
    };
  },
  components: {
  },
  data: function () {
    return {
      planItem: {},
      modalAddEditNewPlan: false,
      formPlan: {
        new: true,
        name: '',
        errName: '',
        code_id: '',
        errCode: '',
        description: '',
        boardLimit: 0,
        membersLimit: 0,
        cost: 0,
        status: true,
        tp: 'plan',
        errTp: '',
      },
      modalDeletePlans: false,
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang',]),
    ...mapState('plansData', ['plans', 'errorPlans']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('plansData', ['getPlansData', 'registerPlans', 'updatePlansData', 'deletePlansData', 'changeModalPlans']),
    table() {
      this.$nextTick(() => {
        $('#plansTable').DataTable();
      });
    },
  
    /** Funcion para abrir modal para añadir o editar Plan */
    changeModalAddEditPlans(item = {}) {

      if (Object.entries(item).length > 0) {

        this.planItem = item;
        this.formPlan.new = false;
        this.formPlan.name = item.name;
        this.formPlan.code_id = item.code_id;
        this.formPlan.description = item.description;
        this.formPlan.boardLimit = item.boards_limit;
        this.formPlan.membersLimit = item.members_limit;
        this.formPlan.cost = item.cost;
        this.formPlan.status = item.status;
        this.formPlan.tp = item.tp;

      } else {

        this.planItem = item;
        this.formPlan = {
          new: true,
          name: '',
          errName: '',
          code_id: '',
          errCode: '',
          description: '',
          boardLimit: 0,
          membersLimit: 0,
          cost: 0,
          status: true,
          tp: 'plan',
          errTp: '',
        };
      }

      this.modalAddEditNewPlan = !this.modalAddEditNewPlan;
    },

    /** Funcion para registrar o editar un nuevo Plan */
    async registerEditOnePlan() {

      this.formPlan.errName = requiredFields(this.formPlan.name, this.lang);
      this.formPlan.errCode = requiredFields(this.formPlan.code_id, this.lang);
      this.formPlan.errTp = requiredFields(this.formPlan.tp, this.lang);

      if (this.formPlan.errName == '' && this.formPlan.errCode == '' && this.formPlan.errTp == '') {

        this.formPlan.status = this.formPlan.status == '1' ? true : false;

        if (this.formPlan.new) {

          await this.registerPlans(this.formPlan);
          if (checkError401(this.errorPlans)) { this.logOut(); }
          if (this.errorPlans == null) {
            this.formPlan = {
              new: true,
              name: '',
              errName: '',
              code_id: '',
              errCode: '',
              description: '',
              boardLimit: 0,
              membersLimit: 0,
              cost: 0,
              status: true,
              tp: 'plan',
              errTp: '',
            };

            this.table();
          }

        } else {

          await this.updatePlansData({ ...{ id: this.planItem.id }, ...this.formPlan });
          if (checkError401(this.errorPlans)) { this.logOut(); }
          if (this.errorPlans == null) {
            this.changeModalAddEditPlans();
          }
        }
      }

    },

    /** Funcion para cambiar el estado del modal eliminar Plan */
    changeModalDeletePlan(item = {}) {
      this.planItem = item;
      this.modalDeletePlans = !this.modalDeletePlans;
    },

    /** Funcion para eliminar un Plan  */
    async deteleOnePlan() {

      if(Object.entries(this.planItem).length > 0){

        await this.deletePlansData(this.planItem.id);
        if (checkError401(this.errorPlans)) { this.logOut(); }
        if (this.errorPlans == null) {
          this.changeModalDeletePlan();
        }

      }
    },
  },
  mounted: async function () {

    await this.getPlansData();
    if (checkError401(this.errorPlans)) { this.logOut(); }
    if(this.errorPlans == null){
      this.table();
    }

  }
}
</script> 


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>