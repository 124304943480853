export default {

  actionUserDataBegin(state) {
    state.loading = true;
  },

  actionUserDataErr(state, err) {
    state.loading = false;
    state.errorUsers = err;
  },


  /** Funcion para reiniciar la data a sus estados iniciales */
  resetDataUsersSuccess(state) {
    state.loading = false;
    state.users = [];
    state.usersFilters = [];
    state.plansInfo = [];
    state.errorUsers = null;
    state.uNotes = '';
  },


  /** Funciones para Obtener los usuarios  */
  getUsersDataSuccess(state, data) {
    state.loading = false;
    state.users = data;
    state.errorUsers = null;
  },

  /** Funciones para Obtener los usuarios by team */
  getUsersByTeamsDataSuccess(state, data) {
    state.loading = false;
    state.usersFilters = data;
    state.errorUsers = null;
  },


  /** Funcion para precesar el registro de un usuario */
  registerUsersSuccess(state, data) {
    state.loading = false;
    state.users.push(data);
    state.errorUsers = null;
  },


  /** Funcion para precesar el registro de un usuario desde la opcion de miembros de un tablero */
  registerUsersToBoardsMembersSuccess(state) {
    state.loading = false;
    state.errorUsers = null;
  },


  /** Funcion para actualizar los datos de un usuario */
  updateUsersDataSuccess(state, data) {
    state.loading = false;
    if (state.users.length > 0) {
      state.users.forEach(item => {
        if (item.id == data.id) {
          item.name = data.name;
          item.surname = data.surname;
          item.phone = data.phone;
          item.email = data.email;
          item.address = data.address;
          item.state = data.state;
          item.city = data.city;
          item.zip_code = data.zip_code;
          item.status = data.status;
          item.access = data.access;
        }
      });
    }
    state.errorUsers = null;
  },


  /** Funcion para eliminar un usuario */
  deleteUsersDataSuccess(state, data) {
    state.loading = false;
    if (state.users.length > 0) {
      state.users = state.users.filter(item => item.id !== data);
    }
    state.errorUsers = null;
  },


  /** Funcion para finalizar el reinicio de clave de un usuario */
  resetPasswordToUsersSuccess(state) {
    state.loading = false;
    state.errorUsers = null;
  },


  /** Funcion para finalizar el proceso de actualizacion de contraseña del usuario */
  updatePasswordDataSuccess(state) {
    state.loading = false;
    state.errorUsers = null;
  },


  /** Funcion para obtener la informacion de los planes de un usuario */
  getUsersPlansinfoSuccess(state, data) {
    state.loading = false;
    state.plansInfo = data;
    state.errorUsers = null;
  },




  /** Funcion para finalizar el proceso obtener las anotaciones del usuario */
  getUserNotesSuccess(state, data) {
    state.loading = false;
    state.uNotes = data.msj;
    state.errorUsers = null;
  },


  /** Funcion para finalizar el proceso de actualizar las anotaciones del usuario */
  updateUserNotesSuccess(state, data) {
    state.loading = false;
    state.uNotes = data.msj;
    state.errorUsers = null;
  },

};