import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
//import { getItem } from '@/utility/localStorageControl';
import toastr from 'toastr';


/** Notificacion Exito */
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};


/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};


const state = () => ({
  loading: false,
  groups: [],
  filter: [],
  errorGroups: null,
});


const actions = {

  /** Reset data */
  resetDataUserGroups({ commit }) {
    try {

      commit('resetDataUserGroupsSuccess');

    } catch (err) {

      console.log(err);
    }
  },


  /** Action para procesar Get data user groups */
  async getUserGroupsData({ commit }) {
    try {

      commit('actionUserGroupsDataBegin');

      let response = await DataService.get('/userGroups');

      console.log(response.data);

      await commit('getUserGroupsDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserGroupsDataErr', err);
    }
  },


  /** Action para obtner los grupos de usuarios de un team */
  async filterUserGroupsByTeam({ commit }, team) {
    try {

      commit('actionUserGroupsDataBegin');

      let response = await DataService.get(`/userGroups/filterByTeam/${team}`);

      console.log(response.data);

      await commit('filterUserGroupsByTeamSuccess', response.data.payload);

    } catch (err) {

      console.log(err);

      commit('actionUserGroupsDataErr', err);

      return 0;
    }
  },


  /** Action para procesar Registro de un Grupo de usuarios */
  async registerUserGroups({ commit }, data) {
    try {

      commit('actionUserGroupsDataBegin');

      let response = await DataService.post('UserGroups', data);

      console.log(response.data.payload);

      await commit('registerUserGroupsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('actionUserGroupsDataErr', err);
    }
  },


  /** Action para procesar actualizar Grupo */
  async updateUserGroupsData({ commit }, data) {
    try {

      await commit('actionUserGroupsDataBegin');

      let response = await DataService.put(`/UserGroups/${data.id}`, data);

      console.log(response.data.payload);

      commit('updateUserGroupsDataSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserGroupsDataErr', err);
    }
  },


  /** Action para procesar eliminar Grupo */
  async deleteUserGroupsData({ commit }, data) {
    try {

      await commit('actionUserGroupsDataBegin');

      let response = await DataService.delete(`UserGroups/${data}`);

      console.log(response.data.payload);

      commit('deleteUserGroupsDataSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserGroupsDataErr', err);
    }
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};