<template>
  <div>

    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-lg-flash">
            <div v-if="Object.entries(cardItem).length > 0" class="modal-content"
              :style="{ 'height': cardItem.template ? '70vh' : '95vh' }">
              <div class="modal-header row" @mouseleave="editTitle = false; openTitle = ''">
                <!-- Boton de cerrar -->
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    @click="changeModalShowCardItem()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Boton de cerrar -->

                <!-- Titulo tarjeta y editart titulo -->
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" :style="{ 'padding-left': '0px !important' }">
                  <div v-if="!editTitle" 
                    class="col-sm-12 col-md-12 col-lg-12 col-xl-12" id="contTitle">
                    <h5 class="modal-title" id="exampleModalLongTitle"
                      @click="checkEditTitleCard()">
                      <i class="fas fa-desktop" :style="{ 'margin-right': '10px' }"> </i>
                      <b> {{ showInfoData(cardItem.title, lang) }}</b>
                    </h5>
                  </div>
                  <div v-else class="col-sm-12 col-md-12 col-lg-12 col-xl-12" id="contEditTitle"
                    :style="{ 'margin-top': '0px', 'height': '70px' }">
                    <div class="row">
                      <div class="col-sm-12 col-md-10 col-lg-10 col-xl-10">
                        <div class="input-group">
                          <input type="text" class="form-control" v-model="openTitle">
                          <div class="input-group-append">
                            <button type="button" class="btn btn-primary clipboard-trigger"
                              :title="t('button.save', {}, { locale: lang })" @click="updateTitle()">
                              <i class="fas fa-save"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1" :style="{ 'padding-left': '0px !important' }">
                        <button type="button" class="btn" :title="t('button.close', {}, { locale: lang })"
                          @click="editTitle = false; openTitle = ''">
                          <i class="fas fa-times" :style="{ 'font-size': '25px' }"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Titulo tarjeta y editart titulo -->

                <!-- Informacion: Titulo Lista, Fec-Vencimineto y Estatus Tarjeta -->
                <div v-if="!cardItem.template" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                      <a :style="{ 'color': 'black' }">
                        {{ t('modalsCardsData.onList', { }, { locale: lang }) }} 
                        <u><b>{{ showInfoData(cardItem.listTitle, lang) }}</b></u>
                      </a>
                      <a v-if="cardItem.expiration != ''" :style="{ 'color': 'black', 'margin-left': '8px' }">
                        <b> {{ t('modalsCardsData.expiration', { }, { locale: lang }) }}: </b>
                      </a>
                      <a v-if="cardItem.expiration != ''" :style="{ 'color': cardItem.expired ? 'red' : 'black', 'font-size': '11px' }">
                        {{ cardItem.expiration }}
                      </a>
                    </div>

                    <div v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('mark:Cards', userAccess.permissions))"
                      class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" :style="{ 'display': 'inline-flex' }">
                      <label for="statusCard" class="form-check-label">
                        <b>{{ t('modalsCardsData.completed', { }, { locale: lang }) }}</b>
                      </label>
                      <input v-if="!cardItem.status" name="check" type="checkbox" class="form-check-input"
                        @click="updateStatusOpenCard()">
                      <input v-else name="check" type="checkbox" class="form-check-input" checked="checked"
                        @click="updateStatusOpenCard()">
                    </div>
                  </div>
                </div>
                <div v-else class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center" :style="{ 'display': 'inline-flex', 'margin-left':'5%' }">
                  <label for="statusCard" class="form-check-label">
                    <b>{{ t('modalsCardsData.specialTemplate', {}, { locale: lang }) }}</b>
                  </label>
                  <input v-if="!cardItem.special_template" name="check" type="checkbox" class="form-check-input"
                    @click="updateSpecialTemplate()">
                  <input v-else name="check" type="checkbox" class="form-check-input" checked="checked"
                    @click="updateSpecialTemplate()">
                </div>
                <!-- Informacion: Titulo Lista, Fec-Vencimineto y Estatus Tarjeta -->
              </div>

              <div class="modal-body row scroll-area-lg" :style="{'overflow':'auto'}">

                <!-- Seccion de Etiquetas -->
                <labelsSection v-if="!cardItem.template" />
                <br>
                <!-- Seccion de Miembros -->
                <usersSection v-if="!cardItem.template" />

                <!-- Descriccion de la tarjeta -->
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" :style="{'padding-left':'0px !important', 'margin-bottom':'0px'}"
                  @mouseleave="editDescrip = false; openDescription = ''">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <h5 class="modal-title" id="exampleModalLongTitle">
                        <i class="fas fa-sliders-h"> </i> 
                        {{ t('modalsCardsData.description', { }, { locale: lang }) }}
                      </h5>
                    </div>
                    <div v-if="!editDescrip" class="col-sm-12 col-md-12 col-lg-12 col-xl-12" @click="checkEditDescriptionCard()">
                      <a :style="{'margin-left':'20px','cursor':'default'}">
                        {{ showInfoData(cardItem.description, lang) }}
                      </a>
                    </div>
                    <div v-else class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="row">
                        <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                          <div class="input-group">
                            <textarea rows="1" class="form-control autosize-input" :style="{'height':'75px'}" v-model="openDescription"></textarea>
                            <div class="input-group-append">
                              <button type="button" class="btn btn-primary clipboard-trigger" :title="t('button.save', {}, { locale: lang })"
                                @click="updateDescription()">
                                <i class="fas fa-save"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1" :style="{'margin-top':'8px','padding-left':'0px !important'}">
                          <button type="button" class="btn" :title="t('button.close', {}, { locale: lang })"
                            @click="editDescrip = false; openDescription = ''">
                            <i class="fas fa-times" :style="{'font-size':'25px'}"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                
                  </div>
                </div>

                <!-- Seccion de Checklist y itemChecklist -->
                <checklistsSection />

                <!-- Seccion de Archivos -->
                <filesSection typeFile="card" />

                <!-- Seccion de Notas -->
                <notesSection v-if="!cardItem.template" />

                <!-- Seccion de Historico -->
                <historySection v-if="!cardItem.template && (checkSuperAdminRol(userInfo.access) || checPermissions('view:HistoyCard', userAccess.permissions))" />

              </div>

              <div v-if="cardItem.template == true || (checkSuperAdminRol(userInfo.access) || checPermissions('delete:Cards', userAccess.permissions))" class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="modalDeleteCard = !modalDeleteCard">
                  {{ t('button.delete', { }, { locale: lang }) }}
                </button>
                
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalShowCardItem()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
              <div v-else class="modal-footer row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalShowCardItem()">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>

            </div>
            <div v-else class="modal-content" :style="{ 'height': '20vh', 'padding': '10vh' }">
              <div class="text-center">
                <div class="line-scale-pulse-out">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Modal para confirmar eliminar tarjeta abierta -->
    <div v-if="modalDeleteCard">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    {{ t('modalsCardsData.deleteCard', { }, { locale: lang }) }}
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    @click="modalDeleteCard = !modalDeleteCard">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <h6>{{ t('modalsCardsData.deleteCardMsj', { }, { locale: lang }) }}</h6>
                  <b>{{ showInfoData(cardItem.title, lang) }}</b>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger btn-sm" @click="actionDelteCardItem()">
                    {{ t('button.delete', { }, { locale: lang }) }}
                  </button>
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                    @click="modalDeleteCard = !modalDeleteCard">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para confirmar eliminar tarjeta abierta -->

  </div>
</template>


<script>
import { showInfoData, checkError401, checkSuperAdminRol, checPermissions, filterUserAccessByTeam } from '@/utility/functions';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import labelsSection from '@/components/cards/labels/labelsSection.vue';
import usersSection from '@/components/cards/users/usersSection.vue';
import checklistsSection from '@/components/cards/checklists/checklistsSection.vue';
import filesSection from '@/components/cards/files/filesSection.vue';
import notesSection from '@/components/cards/notes/notesSection.vue';
import historySection from '@/components/cards/history/historySection.vue';
export default {
  name: 'ShowInfoCard',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      showInfoData,
      checkSuperAdminRol,
      checPermissions 
    };
  },
  components: {
    labelsSection,
    usersSection,
    checklistsSection,
    filesSection,
    notesSection,
    historySection,
  },
  data: function () {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      modalDeleteCard: false,
      editTitle: false,
      openTitle: '',
      editDescrip: false,
      openDescription: '',
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang']),
    ...mapState('listsAndCards', ['cardItemId', 'cardItem', 'errorLists']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('listsAndCards', [
      'updateCards', 'updateStatusCards', 'changeModalShowCardItem',
      'getCardItemData', 'deleteCardItem', 'resetDataCards', 'updateSpecialTemplateCards'
    ]),

    /** Funcion para permitir editar el titulo de la tarjeta si tiene los permisos */
    checkEditTitleCard(){
      if (this.cardItem.template == true || checkSuperAdminRol(this.userInfo.access) || checPermissions('edit:Cards', this.userAccess.permissions)){
        this.editTitle = true; 
        this.openTitle = showInfoData(this.cardItem.title, this.lang);
      }
    },

    /** Funcion para permitir editar la descripción de la tarjeta si tiene los permisos */
    checkEditDescriptionCard() {
      if (this.cardItem.template == true || checkSuperAdminRol(this.userInfo.access) || checPermissions('edit:Cards', this.userAccess.permissions)) {
        this.editDescrip = true; 
        this.openDescription = showInfoData(this.cardItem.description, this.lang);
      }
    },

    /** Funcion para actualizar el titulo de la tarjeta */
    async updateTitle() {
      if (this.openTitle != '') {
        await this.updateCards({
          id: this.cardItemId,
          title: this.openTitle,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.editTitle = false;
          this.openTitle = '';
        }
      }
    },


    /** Funcion para actualizar la descripción de la tarjeta  */
    async updateDescription() {
      if (this.openDescription != '') {
        await this.updateCards({
          id: this.cardItemId,
          description: this.openDescription,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.editDescrip = false;
          this.openDescription = '';
        }
      }
    },

    /** Funcion para actualizar el estatus de la tarjeta */
    async updateStatusOpenCard() {
      await this.updateStatusCards(this.cardItemId);
      if (checkError401(this.errorLists)) { this.logOut(); }
    },


    /** Funcion para actualizar el estatus de un template como template especial */
    async updateSpecialTemplate() {
      await this.updateSpecialTemplateCards(this.cardItemId);
      if (checkError401(this.errorLists)) { this.logOut(); }
    },


    /** Funcion para eliminar la tarjeta */
    async actionDelteCardItem() {

      await this.deleteCardItem(this.cardItemId);
      if (checkError401(this.errorLists)) { this.logOut(); }
      if (this.errorLists == null) {
        this.changeModalShowCardItem();
      }
    },
  },
  mounted: async function () {

    this.resetDataCards();
    
    await this.getCardItemData(this.cardItemId);
    if (checkError401(this.errorLists)) { this.logOut(); }
    if (this.errorLists != null) {

      if(!this.cardItem.template){
        this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.cardItem.team_id);
      }
      this.changeModalShowCardItem();
    }
  }
}
</script> 


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 999 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0;
}

.modal-header,
.modal-footer {
  background: #fff;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.2rem 1rem;
  border-bottom: 0px solid #fff;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 1rem 8px 1rem;
}

.modal-footer {
  display: flex;
  /*align-items: center;*/
  justify-content: space-between;
  padding: 0.7rem;
  border-top: 1px solid #e9ecef;
  /*border-bottom-right-radius: .3rem;*/
  /*border-bottom-left-radius: .3rem;*/
}

@media (min-width: 300px) {
  .modal-lg-flash {
    max-width: 90vw;
  }
}

@media (min-width: 375px) {
  .modal-lg-flash {
    max-width: 90vw;
  }
}

@media (min-width: 576px) {
  .modal-lg-flash {
    max-width: 90vw;
  }
}

@media (min-width: 768px) {
  .modal-lg-flash {
    max-width: 80vw;
  }
}

@media (min-width: 992px) {
  .modal-lg-flash {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-lg-flash {
    max-width: 800px;
  }
}
</style>