export default {
  /** Funciones para inicio de session Normal */
  loginBegin(state) {
    state.loading = true;
  },

  loginSuccess(state, data) {
    state.loading = false;
    state.loginIn = true;
    state.userInfo = data;
    state.errorAuth = null;
  },

  loginErr(state, err) {
    state.loading = false;
    state.loginIn = false;
    state.errorAuth = err;
  },
  /** Funciones para inicio de session Normal */


  /** Funciones para el inicio de session Live360 */
  loginLive360Begin(state) {
    state.login = true;
  },

  loginLive360Err(state, err) {
    state.loading = false;
    state.loginIn = false;
    state.errorAuth = err;
  },
  /** Funciones para el inicio de session Live360 */


  /** Funciones para el Cierre de sesion del sistema */
  logoutBegin(state) {
    state.loading = true;
  },

  logoutSuccess(state, data) {
    state.loading = false;
    state.loginIn = data;
    state.userInfo = { u21: '', n21: '', e21: '', ad21: '', term: '', access: '' }
    state.imgProfile = '';
    state.errorAuth = null;
  },

  logoutErr(state, err) {
    state.loading = false;
    state.errorAuth = err;
  },
  /** Funciones para el Cierre de sesion del sistema */


  /** Funcion para procesar el obtener Imagen de perfil del usuario */
  profileGetImgBegin(state) {
    state.loading = true;
  },

  profileGetImg(state, data) {
    state.loading = false;

    let blob = new Blob([data.data], { type: data.data.type });
    var reader = new window.FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let base64data = reader.result;
      state.imgProfile = base64data;
    }
    state.errorAuth = null;
  },

  profileGetImgErr(state, err) {
    state.loading = false;
    state.errorAuth = err;
  },
  /** Funcion para procesar el obtener Imagen de perfil del usuario */


  /** Funcion para procesar el actualizar la imagen de perfil del usuario */
  userUpdateImgBegin(state) {
    state.loading = true;
  },

  userUpdateImgErr(state, err) {
    state.loading = false;
    state.errorAuth = err;
  },
  /** Funcion para procesar el actualizar la imagen de perfil del usuario */


  /** Funcion para procesar el actualizar los terminos del sistema del usuario */
  userAgreeTermsToSystemBegin(state) {
    state.loading = true;
  },

  userAgreeTermsToSystemSuccess(state) {
    state.loading = false;
    state.userInfo.terms = state.userInfo.terms == 0 ? 1 : 0;
    state.errorAuth = null;
  },

  userAgreeTermsToSystemErr(state, err) {
    state.loading = false;
    state.errorAuth = err;
  },
  /** Funcion para procesar el actualizar los terminos del sistema del usuario */

  /** Funcion para cambiar el estado de el modal de Terminos y condiciones del sistema */
  changeModalTermsSuccess(state) {
    state.modalTerms = !state.modalTerms;
  },


  /** Funciones para el reinicio de clave de un usuario desde el login */
  resetPasswordToOneUserBegin(state) {
    state.loading = true;
  },

  resetPasswordToOneUserSuccess(state) {
    state.loading = false;
    state.errorAuth = null;
  },

  resetPasswordToOneUserErr(state, err) {
    state.loading = false;
    state.errorAuth = err;
  },
  /** Funciones para el reinicio de clave de un usuario desde el login */


  /** Funciones para el registro de un usuario gratis */
  registerUsersFreeBegin(state) {
    state.loading = true;
  },

  registerUsersFreeSuccess(state) {
    state.loading = false;
    state.errorAuth = null;
  },

  registerUsersFreeErr(state, err) {
    state.loading = false;
    state.errorAuth = err;
  },
  /** Funciones para el registro de un usuario gratis */


  /** Funciones para actualizar los datos del usuario login */
  updateLoginUsersDataBegin(state) {
    state.loading = true;
  },

  updateLoginUsersDataSuccess(state, data) {
    state.loading = false;
    state.userInfo.n21 = `${data.name} ${data.surname}`;
    state.userInfo.p21 = data.phone;
    state.userInfo.ad21 = data.address;
    state.userInfo.st21 = data.state;
    state.userInfo.c21 = data.city;
    state.userInfo.z21 = data.zip_code;
    state.errorAuth = null;
  },

  updateLoginUsersDataErr(state, err) {
    state.loading = false;
    state.errorAuth = err;
  },
  /** Funciones para actualizar los datos del usuario login */


  /** Funciones para verificar si el correo se encuentra registrado en el sistema */
  validateRegistrationEmailBegin(state) {
    state.loading = true;
    state.checkEmailRegister = false;
  },

  validateRegistrationEmailSuccess(state, data) {
    state.loading = false;
    state.checkEmailRegister = data;
    state.errorAuth = null;
  },

  validateRegistrationEmailErr(state, err) {
    state.loading = false;
    state.errorAuth = err;
  },
  /** Funciones para verificar si el correo se encuentra registrado en el sistema */


  /** Funciones para actualizar los datos del usuario login */
  validateRegistrationTeamBegin(state) {
    state.loading = true;
    state.checkEmailRegister = false;
  },

  validateRegistrationTeamSuccess(state, data) {
    state.loading = false;
    state.checkTeamName = data;
    state.errorAuth = null;
  },

  validateRegistrationTeamErr(state, err) {
    state.loading = false;
    state.errorAuth = err;
  },
  /** Funciones para actualizar los datos del usuario login */


  /** Funciones para el registro de un usuario de un plan Pago */
  registerUsersPaymentPlansBegin(state) {
    state.loading = true;
  },

  registerUsersPaymentPlansSuccess(state) {
    state.loading = false;
    state.errorAuth = null;
  },

  registerUsersPaymentPlansErr(state, err) {
    state.loading = false;
    state.errorAuth = err;
  },
  /** Funciones para el registro de un usuario de un plan Pago */


  /** Funcion para actualizar al usuario actual luego de realizar un cambio de plan */
  processChangeFromCurrentPlanSuccess(state, data) {
    state.loading = false;
    state.userInfo.access = data;
    state.errorAuth = null;
  },

};