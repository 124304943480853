export default {

  /** Inicio y final de las funciones */
  categoriesActionBegin(state) {
    state.loading = true;
  },

  categoriesActionErr(state, err) {
    state.loading = false;
    state.errorCategories = err;
  },
  /** Inicio y final de las funciones */


  /** Funcion para reiniciar la data a sus estados iniciales */
  resetDataCategoriesSuccess(state) {
    state.modalcategories = false;
    state.categories = [];
    state.errorCategories = null;
  },


  /** Funciones para cambiar el estatus del modal de categorias */
  changeModalCategoriesSuccess(state) {
    state.loading = false;
    state.modalcategories = !state.modalcategories;
    state.errorCategories = null;
  },
  /** Funciones para cambiar el estatus del modal de categorias */


  /** Funciones para Obtener los de las categorias  */
  getCategoriesDataSuccess(state, data) {
    state.loading = false;
    state.categories = data;
    state.errorCategories = null;
  },
  /** Funciones para Obtener los tde las categorias */


  /** Funciones para agregar una nueva categoria */
  addCategoriesSuccess(state, data) {
    state.loading = false;
    state.categories.push(data);
    state.errorCategories = null;
  },
  /** Funciones para agregar una nueva categoria */


  /** Funciones para editar una categoria */
  updateCategoriesSuccess(state, data) {
    state.loading = false;
    state.categories.forEach(item => {
      if (item.id == data.id) {
        item.name = data.name;
        item.description = data.description;
      }
    })
    state.errorCategories = null;
  },
  /** Funciones para editar una categoria */


  /** Funciones para eliminar una categoria */
  deleteCategoriesSuccess(state, data) {
    state.loading = false;
    state.categories = state.categories.filter(item => item.id !== data.id);
    state.errorCategories = null;
  },
  /** Funciones para eliminar una categoria */

};