<template>
  <div>
    <Header />
    <div class="app-main">
      <Sidebar />
      {{ closeMenu() }}
      <div class="app-main__outer" @mouseenter="closeMenu()" :style="{'background-color':'white'}">
        <div class="app-main__inner">
          <div class="app-page-title" :style="{ 'background': 'rgba(238, 233, 233, 0.45)' }">
            <!-- Header del Contenido  -->
            <div class="page-title-wrapper row">
              <!-- Detalles del Tablero -->
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="page-title-heading">
                  <div class="page-title-icon">
                    <i class="pe-7s-stopwatch icon-gradient bg-mean-fruit"></i>
                  </div>
                  <div>
                    <h4>{{ t('userSettings.store', {}, { locale: lang }) }}</h4>
                    <div class="page-title-subheading"></div>
                  </div>
                </div>
              </div>
              <!-- Detalles del Tablero -->

            </div>
            <!-- Header del Contenido  -->
          </div>

          <!-- Container Normal -->
          <div class="container" :style="{'max-width':'90vw !important', 'min-height':'80vh !important', 'margin-top':'3vh'}">

            <ul class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
              <li v-if="showPackage" class="nav-item">
                <a role="tab" :class="`nav-link ${showPackage ? 'active' : ''}`" id="tab-0" data-toggle="tab" href="#tab-content-0">
                  <span>{{ t('headerData.package', {}, { locale: this.lang }) }}</span>
                </a>
              </li>
              <li class="nav-item">
                <a role="tab" :class="`nav-link ${showPlan ? 'active' : ''}`" id="tab-1" data-toggle="tab" href="#tab-content-1">
                  <span>{{ t('headerData.plan', {}, { locale: this.lang }) }}</span>
                </a>
              </li>
            </ul>

            <div class="tab-content">

              <!-- Sección de paquetes -->
              <div v-if="showPackage" :class="`tab-pane tabs-animation fade ${showPackage ? 'show active' : ''}`" id="tab-content-0" role="tabpanel">
                <div class="row">
                  <div class="col-lg-8 col-md-12">
                    <div class="main-card mb-3 card">
                      <div class="card-body">
                        <div class="row">

                          <div class="col-md-4 col-6">
                            <div class="font-icon-wrapper">
                              <i class="fas fa-users bg-happy-fisher icon-gradient" style="font-size: 5em !important;"></i>
                              <p>{{ t('headerData.users', {}, { locale: lang }) }}</p>
                              <div class="position-relative form-group">
                                <select class="form-control form-control-sm" v-model="uPackageSelect" @change="changeSelectUsers()">
                                  <option :value="-1">{{ t('headerData.SelectOption', {}, { locale: lang }) }}</option>
                                  <option v-for="(u, index) in usersPackage " :value="index" :key="index">{{ u.name }}</option>
                                </select>
                              </div>
                              <br>
                              <h3>${{ uSelectPackage.cost }} USD</h3>
                              <br>
                              <button id="addUserButton" type="button" disabled="disabled" class="btn addCart btn-block btn-primary" @click="addToCart('users')">
                                {{ t('button.addToCart', {}, { locale: lang }) }}
                              </button>
                            </div>
                          </div>
      
                          <div class="col-md-4 col-6">
                            <div class="font-icon-wrapper">
                              <i class="fas fa-mail-bulk bg-deep-blue icon-gradient" style="font-size: 5em !important;"></i>
                              <p>{{ t('dashboardData.boards', {}, { locale: lang }) }}</p>
                              <div class="position-relative form-group">
                                <select class="form-control form-control-sm" v-model="bPackageSelect" @change="changeSelectBoards()">
                                  <option :value="-1">{{ t('headerData.SelectOption', {}, { locale: lang }) }}</option>
                                  <option v-for="(u, index) in boardsPackage " :value="index" :key="index">{{ u.name }}</option>
                                </select>
                              </div>
                              <br>
                              <h3>${{ bSelectPackage.cost }} USD</h3>
                              <br>
                              <button id="addBoardsButton" type="button" disabled="disabled" class="btn addCart btn-block btn-primary" @click="addToCart('boards')">
                                {{ t('button.addToCart', {}, { locale: lang }) }}
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                
                  <div class="col-lg-4 col-md-12">
                    <div class="main-card mb-3 card">
                      <div class="card-header">{{ t('userSettings.cart', {}, { locale: lang }) }}</div>
                        <ul class="todo-list-wrapper list-group list-group-flush list-cart">
                          <li v-if="cartData.length == 0" class="list-group-item text-center" id="emptyCart" style="display: none;"> 
                            {{ t('userSettings.emptyCart', {}, { locale: lang }) }}
                          </li>
                        
                          <li v-else class="list-group-item item-cart" id="item-8" v-for="(c, index) in cartData" :key="index" >
                            <div class="todo-indicator bg-focus"></div>
                            <div class="widget-content p-0">
                              <div class="widget-content-wrapper">
                                  <div class="widget-content-left mr-2">
                                    <span class="cant">{{ c.cant }}</span>
                                  </div>

                                  <div class="widget-content-left">
                                    <div class="widget-heading">{{ c.name }} 
                                      <button class="btn btn-remove-item badge badge-pill badge-danger ml-2" data-id="8" @click="removeItemToCard(index)">
                                        <i class="fas fa-trash"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="widget-content-right">
                                    ${{ c.cost }}
                                  </div>
                              </div>
                            </div>
                          </li>
                      
                        </ul>

                      <div class="d-block text-right card-footer">
                        <h3 id="totalCart">$ {{ totalCostCart }}</h3>
                        <button type="button" id="btnClear" class="mr-2 btn btn-default" @click="removeItemToCard()">
                          {{ t('button.clear', {}, { locale: lang }) }}
                        </button>
                        <button type="button" id="btnCheckout" disabled="disabled" class="ladda-button mb-2 mr-2 btn  btn-success" data-style="expand-right"
                          @click="checkStoreData()"
                        >
                          <span class="ladda-label">
                            {{ t('button.chaeckout', {}, { locale: lang }) }}
                          </span>
                          <span class="ladda-spinner"></span>
                          <div class="ladda-progress"></div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Sección de paquetes -->

              <!-- Sección de planes -->
              <div :class="`tab-pane tabs-animation fade ${showPlan ? 'show active' : ''}`" id="tab-content-1" role="tabpanel">
                  <div class="row">

                    <div class="col-md-12 col-lg-6 col-xl-3" v-for="(p) in plans" :key="p.id" v-show="checkPlan(p.cost)">
                      <div class="card-shadow-primary profile-responsive card-border mb-3 card" :style="{ 'border-color': 'silver' }">
                        <div class="dropdown-menu-header" :style="{ 'background-color': '#fffefb' }">
                          <div class="dropdown-menu-header-inner">
                            <div class="menu-header-image opacity-2"></div>
                            <div class="menu-header-content btn-pane-right">
                              <div class="avatar-icon-wrapper mr-2 avatar-icon-xl">
                                <img src="../assets/logo-m.png" alt="Avatar 5" :style="{ 'width': '100%', 'object-fit': 'contain' }">
                              </div>
                              <div :style="{ 'margin-top': '15px' }" class="text-center">
                                <h3 class="widget-heading" :style="{ 'color': '#495057' }">{{ p.name }}</h3>
                                <p class="widget-subheading" :style="{ 'color': '#495057' }">{{ p.description }}</p>
                                <h1 :style="{ 'color': '#495057' }">$ {{ p.cost }} </h1>
                              </div>

                              <div class="menu-header-btn-pane">
                                <div>
                                  <div role="group" class="btn-group text-center">
                                    <div class="nav">

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="tab-content" :style="{ 'border-color': 'silver' }">
                          <div class="tab-pane active show" id="tab-example-161">
                            <ul class="list-group list-group-flush">
                              <li class="p-0 list-group-item">
                                <div class="grid-menu grid-menu-2col">
                                  <div class="no-gutters row">
                                    <div class="col-sm-6">
                                      <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                        <i class="fas fa-users btn-icon-wrapper btn-icon-lg mb-3"> </i>{{ p.members_limit }}
                                      </button>
                                    </div>
                                    <div class="col-sm-6">
                                      <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                        <i class="fas fa-mail-bulk btn-icon-wrapper btn-icon-lg mb-3"> </i>{{ p.boards_limit }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                        </div>
                        <div class="text-center d-block card-footer">
                          <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-primary" @click="showModalchangePlan(p)">
                            <i class="fas fa-cart-arrow-down"></i> {{ t('button.addToCart', {}, { locale: lang }) }}
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
              </div>
              <!-- Sección de planes -->

            </div>

          </div>
          <!-- Container Normal -->

        </div>
      </div>
    </div>




    <!-- Modal para seleccionar el team al cual se aplicara la compra -->
    <div v-if="modalSelectTeamToPay">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ t('headerData.teams', {}, { locale: lang }) }}
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="modalSelectTeamToPay=false;">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row text-center">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('userSettings.SelectBeneficiaryTeam', {}, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="selectTeam">
                          <option :value="0">{{ t('headerData.SelectOption', {}, { locale: lang }) }}</option>
                          <option v-for="t in teamBenef " :value="t.id" :key="t.id">{{ t.name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ errSelectTeam }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-success" @click="processStoreData()">
                    {{ t('button.chaeckout', {}, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para seleccionar el team al cual se aplicara la compra -->



    <!-- Modal para confirmar la compra de un plan -->
    <div v-if="modalChangePlan">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog  modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="modalLabel"><i class=""></i>&nbsp;  
                    {{ t('userSettings.purchaseAPlan', {}, { locale: lang }) }}
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModalchangePlan()">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>

                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-6">
                      <h4>{{ t('userSettings.details', {}, { locale: lang }) }}</h4>

                      <table class="table">
                        <thead>
                          <tr>
                            <th width="50%">{{ t('userSettings.product', {}, { locale: lang }) }}</th>
                            <th width="25%">{{ t('userSettings.price', {}, { locale: lang }) }}</th>
                            <th width="25%">{{ t('userSettings.subtotal', {}, { locale: lang }) }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>{{ t('userSettings.traninig', {}, { locale: lang }) }}</td>
                            <td>$ {{ planItem.cost }}</td>
                            <td>$ {{ planItem.cost }}</td>
                          </tr>     
                        </tbody>
                                        
                        <tfoot>
                          <tr style="font-size: 2em;">
                            <th colspan="2" style="text-align: right; letter-spacing: 2px;">
                              {{ t('userSettings.total', {}, { locale: lang }) }}
                            </th>
                            <th><b>${{ planItem.cost }}</b></th>
                          </tr>
                        </tfoot>

                      </table>

                    </div>

                    <div class="col-md-6" id="colRightCheckout" v-if="planItem.cost > 0">

                      <h4 v-if="billing == undefined || billing.length == 0" class="text-center text-muted" :style="{ 'margin-top': '10px' }" >
                        <div class="text-rigth" :style="{'text-align':'right','margin':'10px'}">
                          <a data-toggle="modal" data-backdrop="static" class="btn-pill btn-wide active btn btn-outline-alternate btn-sm" @click="openModalNewCard()">
                            {{ t('button.newcard', {}, { locale: lang }) }} 
                          </a>
                        </div>
                        {{ t('userSettings.paymentMethodsMsm', {}, { locale: lang }) }}
                      </h4>

                      <table v-else  id="paymentMethodsTable" class="table">
                        <tr v-for="b in billing" :key="b.uuid">
                          <td>
                            <input v-if="b.default" :id="`check_${b.uuid}`" checked="checked" title="default" class="chk_default" name="default" type="radio"/>
                            <input v-else :id="`check_${b.uuid}`" title="default" class="chk_default" name="default" type="radio" @click="seletCredicCardToPay(b)"/>
                            <label class="far setDefault" title="default" :for="`check_${b.uuid}`"></label> 
                          </td>
                          <td>
                            <img :src="cardsIconBilling(b.type)" :style="{ 'width': '60px' }">
                            * * * *{{ b.last4 }}
                          </td>
                          <td>
                            <b :style="{ 'padding': '.0rem .0rem' }">{{ b.holdername }}</b><br>
                          </td>
                        </tr>  
                      </table>
                    </div>

                    <div class="col-md-12" v-if="planItem.cost > 0">
                      <div class="col-md-6">
                        <div class="position-relative form-group">
                          <label for="" class="">Team alias</label>
                          <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                            v-model="teamName" @change="checkTeamsName()">
                          <span class="helper-text danger-text">{{ errTeamName }}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="modal-footer" v-if="billing != undefined || billing.length > 0">
                  <button type="button" class="btn btn-success btn-sm" @click="buyAFlashTeamPlan()">
                    {{ t('button.chaeckout', {}, { locale: lang }) }}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para confirmar la compra de un plan -->


    <!-- Modal para agregar Tarjetas de credito -->
    <div v-if="modalAddNewCard">
      <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      {{ t('userSettings.addPaymentMethod', {}, { locale: lang }) }}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="openModalNewCard()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
        
                    <div class="row">

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="first_name" type="text" name="first_name" 
                          placeholder="First Name" 
                          class="form-control" 
                          v-model="formCreditCards.first_name"/>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="last_name" type="text" name="last_name" placeholder="Last Name"
                          class="form-control" 
                          v-model="formCreditCards.last_name"/>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <input id="address1" name="address1" type="text" placeholder="Address"
                          class="form-control" 
                          v-model="formCreditCards.address1"/>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <input id="address2" name="address1" type="text" placeholder="Address"
                          class="form-control" 
                          v-model="formCreditCards.address2"/>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="city" name="city" type="text" placeholder="City"
                          class="form-control" 
                          v-model="formCreditCards.city"/>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="state" name="State" type="text" placeholder="FL"
                          class="form-control" 
                          v-model="formCreditCards.state"/>
                        </div>
                      </div>

                    </div>
      
                    <br>
                    <!-- Contenedor para la informacion de la tarjeta de credito -->
                    <div id="card-container" :style="{ 'margin-top': '0' }"></div>
                    <div id="payment-status-container"></div> 
                    <!-- Contenedor para la informacion de la tarjeta de credito -->
                  
                    <div class="col-md-12">
                      <button id="card-button" class="btn btn-primary btn-block btn-pill" type="button" @click="handleStoreCardMethodSubmission()">
                        {{ t('button.storeCard', {}, { locale: lang }) }}
                      </button>
                    </div>

                  </div>

                </div>
              </div>
            </div>
        </div>
      </transition>
    </div>
    <!-- Modal para agregar Tarjetas de credito -->

  </div>
</template>


<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { closeMenu, showInfoData, checkSuperAdminRol, checPermissions, getTeamsByPermissions, checkError401 } from '@/utility/functions';
import { requiredFields, requiredFieldsNumber } from '@/utility/validations';
import Header from '@/components/template/Header.vue';
import Sidebar from '@/components/template/Sidebar.vue';
import toastr from 'toastr';

export default {
  name: 'Store Flash Team',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      closeMenu,
      showInfoData,
      checkSuperAdminRol,
      checPermissions,
      getTeamsByPermissions,
      moment
    };
  },
  components: {
    Header,
    Sidebar,
  },
  data() {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      showPackage: false,
      showPlan: false,
      teamBenef: [],

      usersPackage: [],
      uPackageSelect: -1,
      uSelectPackage: {
        id: null,
        name: '',
        cant: 0,
        cost: 0
      },
      boardsPackage: [],
      bPackageSelect: -1,
      bSelectPackage: {
        id: null,
        name: '',
        cant: 0,
        cost: 0
      },
      cartData: [],



      usersItem: {
        cost: 0,
      },
      boadsItem: {
        cost: 0,
      },
      

      seletUsersPackage: {},
      seletBoardPackage: {},


      cantUsers: 0,
      cantBoards: 0,
      cartUsers: {
        show: false,
        cant: 0,
        price: 0
      },
      cartBoards: {
        show: false,
        cant: 0,
        price: 0
      },





      onliViewers: true,
      modalSelectTeamToPay: false,
      selectTeam: 0,
      errSelectTeam: '',

      appId: 'sq0idp-8Vb-gFkiOlaB0sI9asg-1g',// 'sandbox-sq0idb-lqHc3vg149Bk37aA1Y8BAg'
      locationId: 'LWNMZDMNJ1VQR',
      payments: null,
      iti: null,
      card: null,
      cardsIcons: {
        VISA: require("@/assets/cardsIcon/Visa.svg"),
        MASTERCARD: require("@/assets/cardsIcon/Mastercard.svg"),
        AMERICAN_EXPRESS: require("@/assets/cardsIcon/Amex.svg"),
        DISCOVER: require("@/assets/cardsIcon/Discover.svg"),
        JCB: require("@/assets/cardsIcon/JCB.svg"),
      },
      modalChangePlan: false,
      planItem: {},
      cardItem: {},
      modalAddNewCard: false,
      formCreditCards: {
        first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
      },
      teamName: '',
      errTeamName: '',
    };
  },
  computed: {
    ...mapState('auth', ['userInfo', 'errorAuth', 'checkTeamName']),
    ...mapState('system', ['lang']),
    ...mapState('usersData', ['plansInfo','errorUsers']),
    ...mapState('plansData', ['plans', 'errorPlans']), 
    ...mapState('paymentsData', ['billing', 'errorPayments',]),
    totalCostCart() {

      let r = 0.00;

      if(this.cartData.length > 0) {

        this.cartData.forEach(item => {
          r = r + (item.cant * item.cost);
        })
      } 

      return r.toFixed(2);
    }
  },
  methods: {
    ...mapActions('auth', ['logOut', 'processBuyAPlanUsers', 'validateRegistrationTeam', 'buyItemsToTeamsByUsers']),
    ...mapActions('system', ['loadSystemBackground']),
    ...mapActions('usersData', ['getUsersPlansinfo']),
    ...mapActions('plansData', ['getPlansData']),
    ...mapActions('paymentsData', ['getBillingsData', 'registerNewBilling']),

    /** Funcion para procesar el cambio del seletc de usuarios */
    changeSelectUsers() {

      let but = document.getElementById('addUserButton');

      if(this.uPackageSelect == -1) {
        
        but.disabled = 'disabled';
        this.uSelectPackage = {
          id: null,
          name: '',
          cant: 0,
          cost: 0
        };
      
      } else {

        but.disabled = '';
        this.uSelectPackage = this.usersPackage[this.uPackageSelect];
        this.uSelectPackage.name = `Users [${this.uSelectPackage.name}]`;
      }
    },


    /** Funcion para procesar el cambio del seletc de tableros */
    changeSelectBoards() {
      let but = document.getElementById('addBoardsButton');

      if (this.bPackageSelect == -1) {

        but.disabled = 'disabled';
        this.bSelectPackage = {
          id: null,
          name: '',
          cant: 0,
          cost: 0
        };

      } else {

        but.disabled = '';
        this.bSelectPackage = this.boardsPackage[this.bPackageSelect];
        this.bSelectPackage.name = `Boards [${this.bSelectPackage.name}]`;
      }
    },


    /** Funcion para agregar al carrito un elemento */
    addToCart(tp) {

      let item = {};

      if(tp == 'users') {
        item = this.uSelectPackage;
      } else {
        item = this.bSelectPackage;
      }

      if(this.cartData.length == 0) {

        this.cartData.push({
          tp: tp,
          plan: item.id,
          name: item.name,
          cant: 1,
          cost: item.cost,
        });
      
      } else {

        let val = false;
        this.cartData.forEach(it => {
          if(it.plan == item.id) {
            it.cant++;
            it.cost = it.cost+item.cost;
            val = true;
          }
        })

        if(!val) {
          this.cartData.push({
            tp: tp,
            plan: item.id,
            name: item.name,
            cant: 1,
            cost: item.cost,
          });
        }
      }

      let but = document.getElementById('btnCheckout');
      but.disabled = '';
    },


    /** Funcion para eliminar un item del carrito */
    removeItemToCard(index = -1) { 
      if(index == -1) {
        
        this.cartData = [];

      } else {
        this.cartData.splice(index, 1);
      }

      if(this.cartData.length == 0) {
        let but = document.getElementById('btnCheckout');
        but.disabled = 'disabled';
      }
    },


    /** Funcion para verificacion previa antes de la compra del carrito  */
    async checkStoreData() {

      /** Si tiene mas de un team a que se le pueda aplicar la compra se muestra el modal para que elija al team  */
      if(this.teamBenef.length > 0 && this.teamBenef.length == 1) {

        this.selectTeam = this.teamBenef[0].id;
        this.processStoreData();

      } else {

        this.selectTeam = 0;
        this.modalSelectTeamToPay = !this.modalSelectTeamToPay;
      }
    },


    /** Funcion para procesar la compran de los item seleccionados */
    async processStoreData() {

      this.errSelectTeam = requiredFieldsNumber(this.selectTeam, this.lang);

      if(this.errSelectTeam == '') {

        /** Se busca la tarjeta */
        await this.getBillingsData();
        this.billing.forEach(item => {
          if (item.default == 1) {
            this.cardItem = item;
          }
        })

        if (Object.entries(this.cardItem).length > 0) {
          let body = {
            user: this.userInfo.u21,
            team: this.selectTeam,
            card: this.cardItem.uuid,
            buy: JSON.stringify(this.cartData),
          }

          await this.buyItemsToTeamsByUsers(body);
          if (checkError401(this.errorAuth)) { this.logOut(); }
          if (this.errorAuth == null) {
            this.modalSelectTeamToPay = false;
            this.removeItemToCard();
            this.uPackageSelect = -1;
            this.bPackageSelect = -1;
          }
        
        } else {
          toastr.error('There is no credit card associated with the user');
        }
      }
    },







    /** Funciones para la seccion de planes  */



    /** Funcion para verificar que planes se deben mostrar */
    checkPlan(value) {
      if(this.onliViewers) {
        return true;
      } else if(value > 0) {
        return true;
      }
      return false;
    },


    /** Funcion para mostrar el modal para cambiar el plan */
    async showModalchangePlan(plan = {}) {

      this.planItem = plan;
      if (Object.entries(plan).length > 0) {
        await this.getBillingsData();
        this.billing.forEach(item => {
          if (item.default == 1) {
            this.cardItem = item;
          }
        })
      }
      this.modalChangePlan = !this.modalChangePlan;

    },


    /** Funciones para la opciones de las tarjetas */

    cardsIconBilling(tp) {
      if (tp == 'VISA') {

        return this.cardsIcons.VISA;

      } else if (tp == 'MASTERCARD') {

        return this.cardsIcons.MASTERCARD;

      } else if (tp == 'AMERICAN_EXPRESS') {

        return this.cardsIcons.AMERICAN_EXPRESS;

      } else if (tp == 'DISCOVER') {

        return this.cardsIcons.DISCOVER;

      } else if (tp == 'JCB' || tp == 'DISCOVER_DINERS') {

        return this.cardsIcons.JCB;

      }
    },


    /** Funcion para seleccionar una tarjeta de credito para procesar el pago */
    seletCredicCardToPay(item) {
      this.cardItem = item;
    },


    /** Funcion para mostrar el modal para agregar Tarjetas de credito */
    async openModalNewCard() {
      this.modalAddNewCard = !this.modalAddNewCard;
      this.formCreditCards = {
        first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
      };

      if (this.modalAddNewCard) {

        /** Se inicializa el contenedor de la tarjeta de Square */
        if (!window.Square) {
          throw new Error('Square.js failed to load properly');
        }

        try {
          this.payments = window.Square.payments(this.appId, this.locationId);
        } catch (e) {
          console.error('Initializing', e);
          const statusContainer = document.getElementById('payment-status-container');
          statusContainer.className = 'missing-credentials';
          statusContainer.style.visibility = 'visible';
          return;
        }

        try {
          this.card = await this.initializeCard(this.payments);
        } catch (e) {
          console.error('Initializing Card failed', e);
          return;
        }
        /** Se inicializa el contenedor de la tarjeta de Square */
      }
    },


    /** Funcion para inicializar el contenedor de la tarjeta de credito */
    async initializeCard(payments) {
      const card = await payments.card();
      await card.attach('#card-container');
      return card;
    },


    /** Funcion para procesar el registro de la tarjeta de credito */
    async handleStoreCardMethodSubmission() {
      try {

        /* disable the submit button as we await tokenization and make a payment request. */
        const token = await this.tokenize(this.card);
        let verificationToken = await this.verifyBuyer(this.payments, token);
        const storeCardResults = await this.storeCard(token, verificationToken);

        console.debug('Store Card Success', storeCardResults);
        this.openModalNewCard();

      } catch (e) {
        console.error('Store Card Failure', e);
      }
    },


    /** Funcion generar el token de la tarjeta ingresada por el usuario */
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        throw new Error(
          `Tokenization errors: ${JSON.stringify(tokenResult.errors)}`
        );
      }
    },



    /** Funcion para verificar los datos del comprador (Tarjeta) */
    async verifyBuyer(payments, token) {

      const verificationDetails = {
        billingContact: {
          addressLines: [this.formCreditCards.address1, this.formCreditCards.address2],
          familyName: this.formCreditCards.last_name,
          givenName: this.formCreditCards.first_name,
          country: 'US',
          region: this.formCreditCards.state,
          city: this.formCreditCards.city,
        },
        intent: 'STORE',
      };

      const verificationResults = await payments.verifyBuyer(token, verificationDetails);
      return verificationResults.token;
    },


    /** Funcion para procesar los pagos de registro mediante la api */
    async storeCard(token, verificationToken) {

      const bodyParameters = {
        customerPaymentID: this.userInfo.pId,
        locationid: this.locationId,
        token: token,
        verification_token: verificationToken,
        billing_address1: this.formCreditCards.address1,
        billing_address2: this.formCreditCards.address2,
        billing_city: this.formCreditCards.city,
        billing_state: this.formCreditCards.state,
        billing_first_name: this.formCreditCards.first_name,
        billing_last_name: this.formCreditCards.last_name,
      };

      await this.registerNewBilling(bodyParameters);
    },

    /** Funciones para la opciones de las tarjetas */


    /** Funcion para verificar que el nombre del team no se encuentre registrado en la BD */
    async checkTeamsName() {

      if (this.teamName != '' && this.planItem.cost > 0) {
        await this.validateRegistrationTeam(this.teamName);
        if (this.checkTeamName == true) {
          this.errTeamName = 'Team alias already exists';
        } else {
          this.errTeamName = '';
        }
      } else {
        this.errTeamName = '';
      }
    },



    /** Funcion para adquirir un plan */
    async buyAFlashTeamPlan() {

      if(this.planItem.cost > 0) {

        if (Object.entries(this.cardItem).length > 0) {

          this.errTeamName = this.errTeamName == '' ? requiredFields(this.teamName, this.lang) : this.errTeamName;

          if (this.errTeamName == '') {

            let body = {
              plan: this.planItem.id,
              user: this.userInfo.u21,
              card: this.cardItem.uuid,
              team: this.teamName,
            }

            await this.processBuyAPlanUsers(body);
            if (checkError401(this.errorAuth)) { this.logOut(); }
            if (this.errorAuth == null) {
              this.showModalchangePlan();
              this.$router.push('/dashboard');
            }
          }
        } else {
          toastr.error('There is no credit card associated with the user');
        }

      } else {

        let body = {
          plan: this.planItem.id,
          user: this.userInfo.u21,
          card: '',
          team: 'Team ' + this.userInfo.n21,
        }

        await this.processBuyAPlanUsers(body);
        if (checkError401(this.errorAuth)) { this.logOut(); }
        if (this.errorAuth == null) {
          this.showModalchangePlan();
          this.$router.push('/dashboard');
        }

      }
    },


  },
  mounted: async function () {

    this.loadSystemBackground('');

    /** Se consulta los planes del usuario para determinar que puede hacer en la tienda */
    await this.getUsersPlansinfo();
    if (checkError401(this.errorUsers)) { this.logOut(); }


    /** Se recorren los planes del usuario para determinar que puede hacer */
    this.plansInfo.forEach(item => {
      if(item.showButton) {
        this.showPackage = true;
        this.teamBenef.push(item.team);
      }
      if(item.rol.name != 'viewersUser') {
        this.onliViewers = false;
      }
    })

    /** Si el usuario no tiene suscricciones solo se muestra el plan */
    if(!this.showPackage) {
      this.showPlan = true;
    }

    if(this.showPackage) {

      await this.getPlansData({ 'f1': 'active', 'f2': 'package' });
      if (checkError401(this.errorPlans)) { this.logOut(); }

      if (this.errorPlans == null) {

        this.plans.forEach(item => {

          if (item.members_limit != 0 && item.status == true) {
            this.usersPackage.push({
              id: item.id,
              name: item.name,
              cant: item.members_limit,
              cost: item.cost
            });
          }

          if (item.boards_limit != 0 && item.status == true) {
            this.boardsPackage.push({
              id: item.id,
              name: item.name,
              cant: item.boards_limit,
              cost: item.cost
            });
          }
        })

      }
    }

    await this.getPlansData({ 'f1': 'active', 'f2': 'plan' });
    if (checkError401(this.errorPlans)) { this.logOut(); }   

  },
}
</script> 


<style scoped>

</style>