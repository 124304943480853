<template>
  <div class="app-header header-shadow">
    <!-- Logo version normal -->
    <div class="app-header__logo">
      <div class="logo-src" @click="$router.push('/dashboard')"></div>
      <div class="header__pane ml-auto">
        <div>
          <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <!-- Logo version movil -->

    <!-- Boton Sidebar Menu Movil -->
    <div class="app-header__mobile-menu">
      <div>
        <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav" title="Menu"
          @click="openMenuMovil()">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <!-- Boton Menu Movil -->

    <!-- Boton opciones user movil -->
    <div class="app-header__menu" @click="changeMenuValue('change')">
      <button type="button"
        class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
        :style="{'background-color':'transparent !important', 'border-color':'transparent !important',}">
        <img width="42" class="rounded-circle" :src="imgProfile" alt="">
      </button>
    </div>
    <!-- Boton opciones user movil -->

    <div id="menuMovil2" :class="`app-header__content ${userMenu == false ? '' : 'header-mobile-open'}`">

      <div class="app-header-right">
        
        <div class="header-dots">

          <!-- Boton Carrito para compas individuales -->
          <button type="button" class="p-0 mr-2 btn btn-link" id="btnIconStore" :title="t('userSettings.store', {}, { locale: lang })" @click="$router.push('/storeFlash')">
            <span class="icon-wrapper icon-wrapper-alt rounded-circle">
              <span class="icon-wrapper-bg bg-primary"></span>
              <i class="fa fa-shopping-cart icon-gradient bg-malibu-beach"> </i>
            </span>
          </button>
          <!-- Boton Carrito para compas individuales -->

          <!-- Seccion de Opciones -->
          <div class="dropdown">
            <button type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown" 
              :title="t('headerData.menu', {}, { locale: lang })"
              class="p-0 mr-2 btn btn-link"
            >
              <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                <span class="icon-wrapper-bg bg-primary"></span>
                <i class="icon text-primary ion-android-apps"></i>
              </span>
            </button>
            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-xl rm-pointers dropdown-menu dropdown-menu-right">
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-plum-plate">
                  <div class="menu-header-image optionBackground"></div>
                  <div class="menu-header-content text-white">
                    <h5 class="menu-header-title">{{ t('headerData.menu', {}, { locale: lang })  }}</h5>
                  </div>
                </div>
              </div>
              <div class="grid-menu grid-menu-xl grid-menu-3col">
                <div class="no-gutters row">
                  <!-- configurar sistemas usuarios -->
                  <div class="col-sm-6 col-xl-4">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link"
                      v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:systemConfig', userInfo.access).length > 0)"
                      @click="$router.push('/systemConfig')"
                    >
                      <i class="pe-7s-config icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>
                      {{ t('sidebarData.systemSettings', {}, { locale: lang }) }}
                    </button>
                  </div>
                  <!-- configurar sistemas usuarios -->

                  <!-- Ver tableros almacenados -->
                  <div class="col-sm-6 col-xl-4">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link"
                      v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:StoreBoards', userInfo.access).length > 0)"
                      @click="changeModalStoredBoards(true)"
                    >
                      <i class="pe-7s-box1 icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i>
                      {{ t('modalsBoardsData.storeBoardTitle', {}, { locale: lang }) }}
                    </button>
                  </div>
                  <!-- Ver tableros almacenados -->

                  <!-- Ver Template de tableros -->
                  <div class="col-sm-6 col-xl-4">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link"
                      v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:TemplateBoards', userInfo.access).length > 0)"
                      @click="showModalTemplateBoard()"
                    >
                      <i class="pe-7s-notebook icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i>
                      {{ t('modalsBoardsData.boardTemplates', {}, { locale: lang }) }}
                    </button>
                  </div>
                  <!-- Ver Template de tableros -->

                  <!-- Ver Template de tarjetas -->
                  <div class="col-sm-6 col-xl-4">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link"
                      v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:TemplateCards', userInfo.access).length > 0)"
                      @click="showModalTemplateCard()"
                    >
                      <i class="pe-7s-browser icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i>
                      {{ t('modalsCardsData.cardTemplates', {}, { locale: lang }) }}
                    </button>
                  </div>
                  <!-- Ver Template de tarjetas -->

                  <!-- Ver Gestion de etiquetas -->
                  <div class="col-sm-6 col-xl-4">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link"
                      v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:LabelsConfig', userInfo.access).length > 0)"
                      @click="changeModalAddEditLabes()"
                    >
                      <i class="pe-7s-ticket icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i>
                      {{ t('labelCardData.userLabels', {}, { locale: lang }) }}
                    </button>
                  </div>
                  <!-- Ver Gestion de etiquetas -->

                </div>
              </div>
            </div>
          </div>
          <!-- Seccion de Opciones -->


          <!-- Seccion de Notificaciones Mensiones -->
          <div class="dropdown">
            <button type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown" class="p-0 mr-2 btn btn-link">
              <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                <span class="icon-wrapper-bg bg-danger"></span>
                <i class="icon text-danger icon-anim-pulse ion-android-notifications"></i>
                <span class="badge badge-dot badge-dot-sm badge-danger" v-if="mentions.length > 0">
                  {{ t('modalsNotesData.notifications', {}, { locale: lang }) }}
                </span>
              </span>
            </button>
            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-xl rm-pointers dropdown-menu dropdown-menu-right">
              <div class="dropdown-menu-header mb-0">
                <div class="dropdown-menu-header-inner bg-deep-blue">
                  <div class="menu-header-image opacity-1"></div>
                  <div class="menu-header-content text-dark">
                    <h5 class="menu-header-title">{{ t('modalsNotesData.notifications', {}, { locale: lang }) }}</h5>
                    <h6 class="menu-header-subtitle">{{ t('modalsNotesData.mentionedMsj', {cant: mentions.length}, { locale: lang }) }}</h6>
                  </div>
                </div>
              </div>
              <div class="tab-content">

                <div class="tab-pane active" id="tab-messages-header" role="tabpanel">
                  <div class="scroll-area-sm">
                    <div class="scrollbar-container">
                      <div class="p-3">
                        <div class="notifications-box">
                          <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--one-column">
                            <div class="vertical-timeline-item dot-primary vertical-timeline-element row" 
                              :style="{'cursor':'pointer'}"
                              v-for="m in mentions" :key="m.id"
                            >
                              <div class="col-sm-12 col-md-10 col-lg-10 col-xl-10" @click="openBoardToMentions(m.link)">
                                <span class="vertical-timeline-element-icon bounce-in"></span>
                                <div class="vertical-timeline-element-content bounce-in">
                                  <h4 class="timeline-title">
                                    <div v-html="t('modalsNotesData.mentionedMsj2', {name: m.Note.user_data.name, card: m.cardName}, { locale: lang })"></div>
                                    <a :style="{'font-size':'12px'}">{{ moment(m.Note.createdAt).format('MM-DD-YYYY') }}</a>
                                  </h4>
                                  <span class="vertical-timeline-element-date"></span>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2" :title="t(m.show ? 'button.markRead' : 'button.markUnread', {}, { locale: lang })" @click="hidenNotification(m)">
                                <i :style="{'color':'#5593d5', 'font-size':'18px'}" :class="m.show ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- Seccion de Notificaciones Mensiones-->


          <!-- Boton Mis notas (Notas del usuario) -->
          <button type="button" class="p-0 mr-2 btn btn-link" id="btnIconNotes" :title="t('headerData.myNotes', {}, { locale: lang })" @click="openUserNotesModal()">
            <span class="icon-wrapper icon-wrapper-alt rounded-circle">
              <span class="icon-wrapper-bg bg-primary"></span>
              <i class="fa fa-sticky-note icon-gradient bg-arielle-smile"> </i>
            </span>
          </button>
          <!-- Boton Mis notas (Notas del usuario) -->


          <!-- Seccion de Idiomas -->
          <div class="dropdown">
            <button type="button" data-toggle="dropdown" class="p-0 mr-2 btn btn-link"
              :title="t('headerData.chooseLanguage', {}, { locale: lang })">
              <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                <span class="icon-wrapper-bg bg-focus"></span>
                <span :class="'language-icon opacity-8 flag large ' + flagLang[lang]"></span>
              </span>
            </button>
            <div tabindex="-1" role="menu" aria-hidden="true" class="rm-pointers dropdown-menu dropdown-menu-right">
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                  <div class="menu-header-image opacity-05 languageBackground"></div>
                  <div class="menu-header-content text-center text-white">
                      <h6 class="menu-header-subtitle mt-0">{{ t('headerData.chooseLanguage', {}, { locale: lang }) }}</h6>
                  </div>
                </div>
                <h6 tabindex="-1" class="dropdown-header">{{ t('headerData.languages', {}, { locale: lang }) }}</h6>
                <button type="button" tabindex="0" class="dropdown-item" @click="changeLanguage('en')"
                  :style="{'background-color': lang == 'en' ? '#b0c6ff' : '',}">
                  <span class="mr-3 opacity-8 flag large US"></span>
                  {{ t('headerData.english', {}, { locale: lang }) }}
                </button>
                <button type="button" tabindex="0" class="dropdown-item" @click="changeLanguage('es')"
                  :style="{'background-color': lang == 'es' ? '#b0c6ff' : '',}">
                  <span class="mr-3 opacity-8 flag large ES"></span>
                  {{ t('headerData.spanish', {}, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
          <!-- Seccion de Idiomas -->
        </div>
        
        <div class="header-btn-lg pr-0">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="btn-group">
                  <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="p-0 btn">
                    <img width="42" class="rounded-circle" :src="imgProfile" alt="">
                    <i class="fa fa-angle-down ml-2 opacity-8"></i>
                  </a>
                  <div tabindex="-1" role="menu" aria-hidden="true"
                    class="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right">
                    <!-- Informacion del usuario -->
                    <div class="dropdown-menu-header">
                      <div class="dropdown-menu-header-inner bg-info">
                        <div class="menu-header-image opacity-2 userBackground"></div>
                        <div class="menu-header-content text-left">
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-3">
                                <img width="42" class="rounded-circle" :src="imgProfile">
                              </div>
                              <div class="widget-content-left">
                                <div class="widget-heading">
                                  {{ userInfo.n21 }}
                                </div>
                                <div class="widget-subheading opacity-8">
                                  {{ userInfo.e21 }}
                                </div>
                                <div class="widget-subheading opacity-8">

                                </div>
                              </div>
                              <div class="widget-content-right mr-2">
                                <button class="btn-pill btn-shadow btn-shine btn btn-focus" @click="logOut()">
                                  {{ t('headerData.logout', {}, { locale: lang }) }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Informacion del usuario -->

                    <!-- Perfil del usuario -->
                    <div class="scroll-area-xs">
                      <div class="scrollbar-container ps">

                        <ul class="nav flex-column">

                          <li class="nav-item-header nav-item">
                            {{ t('userSettings.myAccount', {}, { locale: lang }) }}
                          </li>
  
                          <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link"
                              @click="$router.push('/settingsAccount')"
                            >
                              {{ t('userSettings.settings', {}, { locale: lang }) }}
                            </a>
                          </li>

                          <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link"
                              @click="$router.push('/userPlans')"
                            >
                              {{ t('userSettings.myPlan', {}, { locale: lang }) }}
                            </a>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <!-- Perfil del usuario -->
                  </div>
                </div>
              </div>
              <div class="widget-content-left  ml-3 header-user-info">
                <div class="widget-heading">{{ userInfo.n21 }}</div>
                <div class="widget-subheading"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Modal para Mostrar la informacion sobre Flash Team 360 -->
    <div v-if="showModalInfoFlash">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" @click="changeModalFlashInfo(false)">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12 text-justify">
                      <p>
                        Flash Team 360 is an online project management software, based on <b>the Kanban
                          methodology</b>, using work cards on a board, maintaining a flow of tasks with their
                        corresponding status.
                      </p>
                    </div>
                    <div class="col-md-12 text-justify">
                      <p>
                        Thanks to its simple interface and easy handling, <b>Flash Team 360</b> is ideal for carrying
                        out projects or tasks, both professional and personal, in an organized, simple, agile,
                        flexible and powerful way: whether they are work projects or day-to-day projects, tasks,
                        planning and execution of trips and itineraries, among other activities that require
                        establishing lists of activities to be carried out.
                      </p>
                    </div>
                    <div class="col-md-12 text-center">
                      <b>About 360 Tech USA</b>
                    </div>
                    <div class="col-md-12 text-justify">
                      <p>
                        360 Technologies USA is a company dedicated to developing software for small and medium-sized
                        businesses and thus supporting growth with the use of cutting-edge technology. To learn more
                        about our company or want to present your own use case, write to us at info@360techusa.com
                      </p>
                    </div>
                    <div class="col-md-12 text-left">
                      Version: <b>BETA {{version}}</b>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary btn-sm" @click="changeModalFlashInfo(false)">{{
                  t('button.close', {}, {locale: lang}) }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para Mostrar la informacion sobre Flash Team 360 -->


    <!-- modal para mostrat los tableros almacenados por el usuario  -->
    <div v-if="modalStoredBoard">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" style="max-width: 450px;">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    {{ t('modalsBoardsData.storeBoardTitle', {}, {locale: lang}) }}
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    @click="changeModalStoredBoards(false)">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" v-if="storesBoards.length > 0">
                  <div class="scrollbar-container">
                    <div
                      class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column"
                      style="cursor: default;">
                      <div class="vertical-timeline-item dot-success vertical-timeline-element"
                        v-for="b in storesBoards" :key="b.id" @click="openBoard(b.id)">
                        <div>
                          <span class="vertical-timeline-element-icon bounce-in"></span>
                          <div class="vertical-timeline-element-content bounce-in">
                            <h4 class="timeline-title">{{ showInfoData(b.title, lang) }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body" v-else :style="{'text-align': 'center'}">
                  <p><b>{{ t('boardData.noStoredBoards', {}, {locale: lang}) }}</b></p>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                    @click="changeModalStoredBoards(false)">{{ t('button.close', {}, {locale: lang}) }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- modal para mostrat los tableros almacenados por el usuario  -->


    <!-- modal para mostrat las etiquetas de un usuario para gestionarlas o agregarlas a una tarjeta  -->
    <div v-if="modalAddEditLabes">
      <ModalAddEditLabels />
    </div>
    <!-- modal para mostrat las etiquetas de un usuario para gestionarlas o agregarlas a una tarjeta  -->


    <!-- modal para mostrar los templates almacenados por el usuario  -->
    <div v-if="modalShowUserTemplateCard">
      <transition name="modal">
        <div class="modal-mask" :style="{ 'z-index':'950!important'}">
          <div class="modal-wrapper">
            <div class="modal-dialog" :style="{'max-width':'390px'}">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    {{ t('modalsCardsData.cardTemplates', {}, {locale: lang}) }}
                    <i class="fas fa-plus-circle" @click="createBaseTemplate()" :style="{ 'font-size': '26px', 'margin-left': '20px', 'color':'#16aaff'}"></i>                    
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModalTemplateCard()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="scrollbar-container">
        
                    <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column"
                        :style="{'cursor':'default'}">
                        
                        <div class="vertical-timeline-item dot-success vertical-timeline-element" 
                          v-for="b in cardsTemplate" :key="b.id">
                          <div>
                            <span class="vertical-timeline-element-icon bounce-in"></span>
                            <div class="vertical-timeline-element-content bounce-in" @click="changeModalShowCardItem(b.id)">
                              <h4 class="timeline-title">{{ showInfoData(b.title, lang) }}</h4>
                            </div>
                          </div>
                        </div>
                    </div>
        
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="showModalTemplateCard()">
                    {{ t('button.close', {}, {locale: lang}) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- modal para mostrar los templates almacenados por el usuario  -->


    <!-- modal para mostrar el contenido de una tarjeta  -->
    <div v-if="modalShowInfoCard">
      <ModalCardInfo />
    </div>
    <!-- modal para mostrar el contenido de una tarjeta  -->


    <!-- modal para mostrar los templates de tableros de un usuario  -->
    <div v-if="modalShowUserTemplateBoard">
      <ModalBoardsTemplates />
    </div>
    <!-- modal para mostrar los templates de tableros de un usuario  -->


    <!-- Modal para las notas personales del usuario -->
    <div v-if="showMyNote">
      <transition name="modal">
        <div class="modal-mask-note">
          <div class="modal-wrapper-note">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-header" :style="{'height':'20px', 'background-color':'rgb(255, 185, 2)'}">
                  <b :style="{'padding':'0.2rem 0.2rem', 'margin':'-1rem -1rem -1rem auto'}">
                    {{ t('headerData.myNotes', {}, { locale: lang }) }}
                  </b>
                  <button type="button" class="close" data-dismiss="modal" @click="openUserNotesModal()" :style="{'padding':'0.2rem 0.2rem', 'margin':'-1rem -1rem -1rem auto'}">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" :style="{'background-color':'rgb(254, 244, 181)', 'max-height':'25rem'}">
                  <div class="position-relative form-group">
                    <textarea name="text" rows="10" v-model="uMsj" id="exampleText" class="form-control" :style="{'background-color':'rgb(254, 244, 181)'}"></textarea>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para las notas personales del usuario -->

  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { openMenuMovil, openMenuMovilUser, checkError401, showInfoData, checkRol, checPermissions, getTeamsByPermissions, checkSuperAdminRol, closeMenu } from '@/utility/functions';
import ModalAddEditLabels from '@/components/cards/labels/ModalAddEditLabels.vue';
import ModalCardInfo from '@/components/cards/ModalCardInfo.vue';
import ModalBoardsTemplates from '@/components/boards/ModalBoardsTemplates.vue';
export default {
  setup() {
    const { t, locale } = useI18n();
    return { 
      t, 
      locale, 
      openMenuMovil, 
      openMenuMovilUser,
      showInfoData,
      checkRol,
      checPermissions,
      getTeamsByPermissions,
      checkSuperAdminRol,
      closeMenu,
      moment
    };
  },
  components: {
    ModalAddEditLabels,
    ModalCardInfo,
    ModalBoardsTemplates,
  },
  data: function () {
    return {
      modalStoredBoard: false,
      modalShowUserTemplateCard: false,
      showMyNote: false,
      uMsj: '',
    }
  },
  computed: {
    ...mapState('auth', ['userInfo', 'imgProfile', 'errorAuth', 'modalTerms']),
    ...mapState('system', ['lang', 'flagLang', 'showModalInfoFlash', 'version', 'modalAddEditLabes', 'userMenu']),
    ...mapState('boardsData', ['storesBoards', 'modalShowUserTemplateBoard', 'errorBoards']),
    ...mapState('listsAndCards', ['cardsTemplate', 'errorLists', 'modalShowInfoCard', 'mentions']),
    ...mapState('usersData', ['errorUsers', 'uNotes'],)
  },
  methods: {
    ...mapActions('auth', ['logOut', 'profileGetImg', 'changeModalTerms']),
    ...mapActions('system', ['changeLanguage', 'changeModalFlashInfo', 'changeModalAddEditLabes', 'changeMenuValue']),
    ...mapActions('boardsData', ['getStoresBoads', 'showModalTemplateBoard']),
    ...mapActions('listsAndCards', ['getCardsTemplatesData', 'changeModalShowCardItem', 'createTemplateByCard', 'getMentionsInNotesData', 'changeNotificationStatusMention']),
    ...mapActions('usersData', ['getUserNotes', 'updateUserNotes']),


    /** FUncion para mostrar modal con los tableros almacenados */
    async changeModalStoredBoards(status){
      
      if(status){
        await this.getStoresBoads();
        if (checkError401(this.errorBoards)) { this.logOut(); }
      }

      this.modalStoredBoard = status;
    },


    /** Funcion para abrir un tablero almacenado */
    openBoard(id) {
      this.$router.push(`/board/${id}`);
      this.changeModalStoredBoards(false);
    },


    /** Funcion para mostrar la lista de template de tarjetas */
    async showModalTemplateCard(){

      if (!this.modalShowUserTemplateCard){
        await this.getCardsTemplatesData();
        if (checkError401(this.errorLists)) { this.logOut(); }
      }

      this.modalShowUserTemplateCard = !this.modalShowUserTemplateCard;
    },


    /** Funcion para crear un template base de tarjetas */
    createBaseTemplate(){
      this.createTemplateByCard({
        card: 'base',
        title: '',
        copyFile: '',
      });
      if (checkError401(this.errorLists)) { this.logOut(); }
    },


    /** Funcion para abril el modal con las anotaciones del usuario */
    async openUserNotesModal() {
      if(!this.showMyNote) {

        await this.getUserNotes();
        if (checkError401(this.errorUsers)) { this.logOut(); }
        if(this.errorUsers == null) {
          this.uMsj = this.uNotes;
          this.showMyNote = !this.showMyNote
        }

      } else {
        this.showMyNote = !this.showMyNote;
      }
    },


    /** Funcion para abrir el tablero donde el usuario fue mencionado */
    openBoardToMentions(link){ 
      this.$router.push('/board/'+link);
    },


    /** Funcion para cambiar el estatus de una notificacion de una mension */
    hidenNotification(item) {
      this.changeNotificationStatusMention(item.id);
    },

  },
  watch: {
    uMsj(){
      this.updateUserNotes({
        msj: this.uMsj,
      });
    },
  },
  mounted: async function () {
    if(this.imgProfile == '') {
      /** Se carga la imagen de perfil  */
      await this.profileGetImg();
      if (checkError401(this.errorAuth)) { this.logOut(); }
    }

    this.getMentionsInNotesData();
  }
}
</script>


<style scoped>

  .app-header__logo .logo-src {
    width: 183.4px;
    height: 46px;
    background: url(../../assets/logo-sm.png);
  }

  .userBackground {
    background-image: url(../../assets/city3.jpg);
  }

  .languageBackground {
    background-image: url(../../assets/city2.jpg);
  }

  .optionBackground {
    background-image: url(../../assets/abstract4.jpg);
  }

  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #515253;
    border: 2px dashed #6c757d;
  }
        
  .loader-zone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
        
  .inputdropzone {
    display: none;
  }
        
  .active-dropzone {
    color: rgb(0, 0, 0);
    background-color: rgba(108, 117, 125, 0.19);
    border: 2px dashed #6c757d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
 
  .modal-mask-note {
    position: fixed;
    z-index: 9999;
    bottom: 0;
    right: 0;
    top: 0;
    /*width: 100%;*/
    height: 80%;
    transition: opacity .3s ease;
  }

  .modal-wrapper-note {
    margin-top: 10rem;
    margin-right: 2rem;
  }
</style>
