<template>
  <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3" @mouseenter="visibility = !visibility"
    @mouseleave="visibility = !visibility">
    <div class="mb-3 card text-white card-body board-card"
      :style="`background-image:linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)),url(${board.link_img});background-size: 100% 150px;z-index:1;max-width:320px;`">
      <div class="row" :style="{ 'z-index':'5', }">
        <div class="col-md-9" @click="openBoard(board.id)">
          <h5 class="text-white card-title">
            {{ showInfoData(board.title, lang) }}
          </h5>
        </div>
        <div class="col-md-2">
          <i :class="board.favorite ? 'fa fa-star btn-icon-wrapper' : 'lnr-star btn-icon-wrapper'"
            :title="t(board.favorite ? 'sidebarData.removeBookmark' : 'sidebarData.addBookmark', {}, {locale: lang})"
            :style="{ 'color':'gold', 'font-size':'22px', }" @click="bookmark(!board.favorite, board.id)"></i>
        </div>
        <div class="col-md-12" @click="openBoard(board.id)" :style="{ 'min-height':'80px', }">
          <p v-show="visibility" class="tam-info" @click="openBoard(board.id)">
            <b>{{ showInfoData(board.description, lang) }}</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { showInfoData, checkError401 } from '@/utility/functions';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: 'BoardsCard',
  setup() {
    const { t, locale } = useI18n();
    return { 
      t, 
      locale,
      showInfoData,
    };
  },
  props: {
    board: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function () {
    return {
      visibility: false,
    }
  },
  computed: {
    ...mapState('system', ['lang']),
  },
  methods: {
    ...mapActions('boardsData', ['addBoardToFavorites', 'deleteBoardToFavorites']),

    /** Funcion para abrir un tablero */
    openBoard(id) {
      this.$router.push(`/board/${id}`);
    },

    /** Funcion para actualizar los tableros favoritos */
    async bookmark(tp, board) {

      /** Agreagar tablero a favorito */
      if (tp) {

        await this.addBoardToFavorites(board);
        if (checkError401(this.errorBoards)) { this.logOut(); }

        /** Eliminar tablero de favoritos */
      } else {

        await this.deleteBoardToFavorites(board);
        if (checkError401(this.errorBoards)) { this.logOut(); }
      }
    },
  },
}
</script> 


<style scoped>
.board-card {
  min-height: 150px;
  max-height: 150px;
}

.tam-info {
  font-size: 15px;
}

.adelante {
  z-index: 20;
}
</style>