<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <ul class="nav nav-justified" :style="{'width':'90% !important'}">
                  <li class="nav-item">
                    <a data-toggle="tab" href="#tab-eg-0" class="active nav-link" @click="opMenu = 0; form.title = '';">
                      <b class="modal-title" :style="{'color':(opMenu == 0 ? '#3f6ad8' : 'gray')}">
                        {{ t(this.newItemCard ? 'modalsCardsData.editCard' : 'modalsCardsData.addCard', {}, {locale:lang }) }}
                      </b>
                    </a>
                  </li>
                  <li v-show="(cardsTemplate.length != 0 && newItemCard == false)" class="nav-item">
                    <a data-toggle="tab" href="#tab-eg-1" class="nav-link" @click="opMenu = 1; form.title = '';">
                      <b class="modal-title" :style="{'color':(opMenu == 1 ? '#3f6ad8' : 'gray')}">
                        {{ t('modalsCardsData.createFTemplate', { }, { locale:lang }) }}
                      </b>
                    </a>
                  </li>
                </ul>
                <button type="button" class="close" aria-label="Close" @click="changeModalAddEditCards()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                
                <div class="tab-content">
                  <!-- Formulario para crear Tarjeta incluyendo la informacion por cada campo -->
                  <div class="tab-pane active show" id="tab-eg-0" role="tabpanel">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="position-relative form-group">
                          <label for="" class="">{{ t('modalsCardsData.title', { }, {locale:lang }) }}</label>
                          <input name="title" id="title" type="text" class="form-control form-control-sm" v-model="form.title" />
                          <span class="helper-text danger-text">{{ form.errorTitle }}</span>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="position-relative form-group">
                          <label for="" class="">{{ t('modalsCardsData.description', { }, {locale:lang }) }}</label>
                          <textarea rows="1" class="form-control autosize-input" :style="{'height':'60px'}" v-model="form.description"></textarea>
                          <span class="helper-text danger-text">{{ form.errorDescription }}</span>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="position-relative form-group">
                          <label for="" class="">{{ t('modalsCardsData.expirationDate', { }, {locale:lang}) }}</label>
                          <div class="date-picker input-group mb-3">
                            <input type="date" id="cardExpiration" name="cardExpiration" class="form-control form-control-sm" 
                              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" v-model="form.expiration"
                            >
                          </div>
                        </div>
                      </div>

                      <!-- Si se crea la tarjeta a partir del calendario se muestan las lista del tablero -->
                      <div v-if="showListOp" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="position-relative form-group">
                          <label class="">{{ t('modalsCardsData.selectList', { }, {locale: lang}) }}</label>
                          <select class="form-control form-control-sm" v-model="form.list" required>
                            <option value="">{{ t('modalsCardsData.select', { }, { locale:lang }) }}</option>
                            <option v-for="l in lists" :key="l.id" :value="l.id">
                              {{ showInfoData(l.title, lang) }}
                            </option>
                          </select>
                          <span class="helper-text danger-text">{{ form.errorList }}</span>
                        </div>
                      </div>
                      <!-- Si se crea la tarjeta a partir del calendario se muestan las lista del tablero -->
                    </div>
                  </div>
                  <!-- Formulario para crear Tarjeta incluyendo la informacion por cada campo -->

                  <!-- Formulario para crear Tarjeta seleccionando una plantilla -->
                  <div class="tab-pane" id="tab-eg-1" role="tabpanel">

                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="position-relative form-group">
                          <label class="">{{ t('modalsCardsData.selectTemplate', { }, { locale: lang }) }}</label>
                          <select class="form-control form-control-sm" v-model="form.template" @change="updateTitleTemplate()">
                            <option :value='-1'>{{ t('modalsCardsData.selectList', { }, { locale: lang }) }}</option>
                            <option v-for="(temp, index) in cardsTemplate" :key="temp.id" :value="index">
                              {{ `${showInfoData(temp.title, lang)} - ${t('modalChecklistData.checkList', {}, { locale: lang })}: ${temp.checklist_cant}` }} 
                            </option>
                          </select>
                          <span class="helper-text danger-text">{{ form.errorTemplate }}</span>
                        </div>
                      </div>

                      <div v-show="form.template >= 0" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="position-relative form-group">
                          <label for="" class="">{{ t('modalsCardsData.title', { }, { locale: lang }) }}</label>
                          <input name="titleTem" id="titleTem" type="text" class="form-control form-control-sm" v-model="form.title" />
                          <span class="helper-text danger-text">{{ form.errorTitle }}</span>
                        </div>
                      </div>

                      <!-- Si se crea la tarjeta a partir del calendario se muestan las lista del tablero -->
                      <div v-if="showListOp" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="position-relative form-group">
                          <label class="">{{ t('modalsCardsData.selectList', { }, {locale: lang}) }}</label>
                          <select class="form-control form-control-sm" v-model="form.list" required>
                            <option :value="-1">{{ t('modalsCardsData.select', { }, { locale:lang }) }}</option>
                            <option v-for="l in lists" :key="l.id" :value="l.id">
                              {{ showInfoData(l.title, lang) }} - {{ l.id }}
                            </option>
                          </select>
                          <span class="helper-text danger-text">{{ form.errorList }}</span>
                        </div>
                      </div>
                      <!-- Si se crea la tarjeta a partir del calendario se muestan las lista del tablero -->
                    </div>
                  </div>
                  <!-- Formulario para crear Tarjeta seleccionando una plantilla -->
                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="saveCard()">
                  {{ t(this.newItemCard ? 'button.save' : 'button.add', {}, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" @click="changeModalAddEditCards()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import { showInfoData, checkError401 } from '@/utility/functions';
import { requiredFields, requiredFieldsNumber } from '@/utility/validations';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: 'ModalAddEditCard',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      showInfoData
    };
  },
  props: {
    newItemCard: Boolean,
    listId: Number,
    calendarDate: String,
    cardItem: {
      type: Object,
      default: () => ({}),
    }
  },
  data: function () {
    return {
      opMenu: 0,
      showListOp: false,
      form: {
        title: '',
        errorTitle: '',
        description: '',
        errorDescription: '',
        expiration: '',
        list: -1,
        errorList: '',
        template: -1,
        errorTemplate: '',
      },
    }
  },
  computed: {
    ...mapState('system', ['lang']),
    ...mapState('boardsData', ['boards', 'boardItem']),
    ...mapState('listsAndCards', ['lists', 'cardsTemplate', 'errorLists']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['changeModalAddEditCards',]),
    ...mapActions('boardsData', ['getCalendarSingleBoardData']),
    ...mapActions('listsAndCards', ['getCardsTemplatesData', 'addNewCards', 'addNewCardsByTemplate', 'updateCards']),

    /** Funcion para colocar el titulo por defecto al seleccionar un Template */
    updateTitleTemplate() {

      if (this.form.template != '') {

        this.form.title = showInfoData(this.cardsTemplate[this.form.template].title, this.lang);

      } else {

        this.form.title = '';
      }
    },

    /** Funcion para darle formato a la fecha al editar tarjeta */
    formatoDate(fecha) {
      if (!fecha) {
        return '';
      }
      if ('1600-01-01' == fecha.substring(0, 10)) {
        return '';
      }
      fecha = fecha.split("-");
      let year = fecha[0];
      let mes = fecha[1];
      let dia = fecha[2].substring(0, 2);
      return year + '-' + mes + '-' + dia;
    },

    /** Funcion para crear o editar tarjeta */
    async saveCard() {

      /* Add una nueva tarjeta */
      if (!this.newItemCard) {

        /** Agregan los datos de la tarjeta uno por uno */
        if (this.opMenu == 0) {

          this.form.errorTitle = requiredFields(this.form.title, this.lang);
          this.form.errorDescription = requiredFields(this.form.description, this.lang);
          if (this.showListOp) {
            this.form.errorList = requiredFieldsNumber(this.form.list, this.lang);
          }

          if (this.form.errorTitle == '' && this.form.errorDescription == '' && this.form.errorList == '') {

            this.form.expiration = this.form.expiration == '' ? '1600-01-01' : this.form.expiration;

            let l = this.showListOp ? this.form.list : this.listId;

            await this.addNewCards({
              title: this.form.title,
              description: this.form.description,
              expiration: this.form.expiration,
              list_id: l,
            });
            if (checkError401(this.errorLists)) { this.logOut(); }

            /** Si se creo a partir del calendario se recarga la data */
            if (this.showListOp){
              await this.getCalendarSingleBoardData(this.boardItem.id);
              if (checkError401(this.errorLists)) { this.logOut(); }
            }

            if (this.errorLists == null){
              this.changeModalAddEditCards();
            }
          }


        /** Se crea la tarjeta en base a un template */
        } else { 

          this.form.errorTemplate = requiredFieldsNumber(this.form.template, this.lang);
          this.form.errorTitle = requiredFields(this.form.title, this.lang);
          if (this.showListOp) {
            this.form.errorList = requiredFieldsNumber(this.form.list, this.lang);
          }

          if (this.form.errorTemplate == '' && this.form.errorTitle == '' && this.form.errorList == '') {

            this.form.expiration = this.form.expiration == '' ? '1600-01-01' : this.form.expiration;
            let l = this.showListOp ? this.form.list : this.listId;

            await this.addNewCardsByTemplate({
              template: this.cardsTemplate[this.form.template].id,
              title: this.form.title,
              list: l,
              date: this.form.expiration,
            });
            if (checkError401(this.errorLists)) { this.logOut(); }

            /** Si se creo a partir del calendario se recarga la data */
            if (this.showListOp) {
              await this.getCalendarSingleBoardData(this.boardItem.id);
              if (checkError401(this.errorLists)) { this.logOut(); }
            }

            if (this.errorLists == null) {
              this.changeModalAddEditCards();
            }
          } 
        }


      /** Editar una tarjeta */
      } else { 

        this.form.errorTitle = requiredFields(this.form.title, this.lang);
        this.form.errorDescription = requiredFields(this.form.description, this.lang);
        
        if (this.form.errorTitle == '' && this.form.errorDescription == '') {

          this.form.expiration = this.form.expiration == '' ? '1600-01-01' : this.form.expiration;

          await this.updateCards({
            id: this.cardItem.id,
            title: this.form.title,
            description: this.form.description,
            expiration: this.form.expiration,
          });
          if (checkError401(this.errorLists)) { this.logOut(); }
          if(this.errorLists == null){
            this.changeModalAddEditCards();
          }
        }
      }
    },
  },
  mounted: async function () {

    /** Si se va a crear una tarjeta */
    if (!this.newItemCard) {

      /** Se verifica si viene del calendario */      
      if (this.calendarDate != ''){

        this.form.expiration = this.calendarDate;
        this.showListOp = true;
      }

      this.getCardsTemplatesData();
      if (checkError401(this.errorLists)) { this.logOut(); }

    /** Si se esta editando una tarjeta */
    } else {

      this.form.title = showInfoData(this.cardItem.title, this.lang);
      this.form.description = showInfoData(this.cardItem.description, this.lang);
      this.form.expiration = this.formatoDate(this.cardItem.expiration);
    }

  }
}
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.img-board {
  width: 90%;
  height: 90%;
}

.select-img {
  border-radius: 5%;
  box-shadow: 0px 0px 5px 5px #248bdf;
}
</style>