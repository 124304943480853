import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
//import { getItem } from '@/utility/localStorageControl';
import toastr from 'toastr';


/** Notificacion Exito */
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};


/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};


const state = () => ({
  loading: false,
  teams: [],
  errorTeams: null,
});


const actions = {

  /** Reset data */
  resetDataTeams({ commit }) {
    try {

      commit('resetDataTeamsSuccess');

    } catch (err) {

      console.log(err);
    }
  },


  /** Action para procesar Get data Teams */
  async getTeamsData({ commit }) {
    try {

      commit('actionTeamsDataBegin');

      let response = await DataService.get('/teams');

      console.log(response.data);

      await commit('getTeamsDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionTeamsDataErr', err);
    }
  },


  /** Action para obtner la cantidad disponible de usuarios diferente a observadores de un team  */
  async checkTeamsLimitsUsers({ commit }, team) {
    try {

      commit('actionTeamsDataBegin');

      let response = await DataService.get(`/teams/checkUsers/${team}`);

      console.log(response.data);

      commit('actionTeamsDataFin');

      return response.data.payload;

    } catch (err) {

      console.log(err);

      commit('actionTeamsDataErr', err);

      return 0;
    }
  },


  /** Action para procesar Registro de Teams en el sistema */
  async registerTeams({ commit }, data) {
    try {

      commit('actionTeamsDataBegin');

      let response = await DataService.post('teams', data);

      console.log(response.data.payload);

      await commit('registerTeamsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('actionTeamsDataErr', err);
    }
  },


  /** Action para procesar actualizar Teams */
  async updateTeamsData({ commit }, data) {
    try {

      await commit('actionTeamsDataBegin');

      let response = await DataService.put(`/teams/${data.id}`, data);

      console.log(response.data.payload);

      commit('updateTeamsDataSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionTeamsDataErr', err);
    }
  },


  /** Action para procesar eliminar Teams */
  async deleteTeamsData({ commit }, data) {
    try {

      await commit('actionTeamsDataBegin');

      let response = await DataService.delete(`teams/${data}`);

      console.log(response.data.payload);

      commit('deleteTeamsDataSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionTeamsDataErr', err);
    }
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};