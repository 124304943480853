<template>
  <div class="col-md-12" @mouseleave="showHistory=false">
    <br>
    <div class="row">
      <div class="col-md-12">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <i class="fas fa-align-left"></i>
          {{ t('activityCardData.activity', { }, { locale: lang }) }}
          <button class="mb-2 mr-2 btn-icon-only btn-shadow btn-dashed btn btn-outline-secondary btn-sm"
            :style="{'margin-left':'10px'}" :title="t('activityCardData.showActivity', {}, { locale: lang })"
            @click="showHistory = !showHistory">
            <i class="pe-7s-look btn-icon-wrapper"> </i>
          </button>
        </h5>
      </div>
      <br>
      <transition name="fade">
        <div v-show="showHistory" class="col-md-12">
          <div class="row" v-for="history in cardItem.history" :key="history.id">
            <div class="col-md-1" :style="{'margin-right':'-20px !important'}">
              <div class="avatar-icon-wrapper avatar-icon-sm text-center">
                <div class="avatar-icon user-card"><b>{{ iniciales(history.user) }}</b></div>
              </div>
            </div>
            <div class="col-md-11">
              <b>{{ history.user }}</b> {{ t(`activityCardData.${history.action}`, {}, { locale: lang }) }} 
              <b>{{ t(`activityCardData.${history.objet }`, {}, { locale: lang }) }}</b> 
              {{ showInfoData(history.element, lang) }}
              <br>
              <a :style="{'font-size':'11px'}">{{ moment(history.createdAt).format('MM-DD-YYYY') }}</a>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import moment from 'moment';
import { iniciales, showInfoData } from '@/utility/functions';
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: 'HistorySection',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      moment,
      iniciales,
      showInfoData
    };
  },
  data: function () {
    return {
      showHistory: false,
    }
  },
  computed: {
    ...mapState('system', ['lang']),
    ...mapState('listsAndCards', ['cardItem']),
  },
  methods: {
  },
}
</script>

<style scoped>
.user-card {
  background: rgb(31, 162, 238);
  color: white;
}

.avatar-icon-wrapper {
  display: inline-block;
  margin-right: 13px;
  position: relative;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 8.2rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .6s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>