export default {

  /** Funcion para reiniciar la data a sus estados iniciales */
  resetDataListAndCardsSuccess(state) {
    state.loading = false;
    state.lists = [];
    state.cardsTemplate = [];
    state.calendarList = [];
    state.modalShowInfoCard = false;
    state.cardItemId = 0;
    state.cardItem = {};
    state.errorLists = null;
    state.mentions = [];
  },

  /** Funciones para Iniciar una accion y cargar error de un proceso */
  actionBegin(state) {
    state.loading = true;
  },

  actionErr(state, err) {
    state.loading = false;
    state.errorLists = err;
  },
  /** Funciones para Iniciar una accion y cargar error de un proceso */


  /** Funciones para Obtener las listas de un tablero  */
  getListsSuccess(state, data) {
    state.loading = false;
    state.lists = data;
    state.errorLists = null;
  },
  /** Funciones para Obtener las listas de un tablero  */


  /** Funciones para obtener las data de la vista calendario de un tablero especifico  */
  getCalendarListsDataSuccess(state, data) {
    state.loading = false;
    state.calendarList = data;
    state.errorBoards = null;
  },
  /** Funciones para obtener las data de la vista calendario de un tablero especifico  */


  /** Funciones para agregar una nueva lista */
  addNewListSuccess(state, data) {
    state.loading = false;
    state.lists.push(data);
    state.errorLists = null;
  },
  /** Funciones para agregar una nueva lista */


  /** Funciones para editar una lista */
  updateListSuccess(state, data) {
    state.loading = false;
    state.lists.forEach((item, index) => {
      if (item.id === data.id) {
        state.lists[index].title = data.title;
      }
    })
    state.errorLists = null;
  },
  /** Funciones para editar una lista */


  /** Funciones para eliminar una lista */
  deleteListSuccess(state, data) {
    state.loading = false;
    state.lists = state.lists.filter(item => item.id !== data);
    for (let index = 0; index < state.lists.length; index++) {
      state.lists[index].nivel = index + 1;
    }
    state.errorLists = null;
  },
  /** Funciones para eliminar una lista */


  /** Funciones para cambiar el estatus archivado de una lista */
  storedListSuccess(state, data) {
    state.loading = false;
    state.lists = state.lists.filter(item => item.id !== data);
    for (let index = 0; index < state.lists.length; index++) {
      state.lists[index].nivel = index + 1;
    }
    state.errorLists = null;
  },
  /** Funciones para cambiar el estatus archivado de una lista */


  /** Funciones para copiar una lista en un tablero */
  copyListSuccess(state, data) {
    state.loading = false;
    if (Object.entries(data).length != 0) {
      state.lists.push(data);
    }
    state.errorLists = null;
  },
  /** Funciones para copiar una lista en un tablero */


  /** Funciones para actualizar los niveles de la lista */
  updateListLevelsSuccess(state) {
    state.loading = false;
    state.errorLists = null;
  },
  /** Funciones para actualizar los niveles de la lista */


  /** Funciones para agregar un miembro a una lista */
  addNewMemberInListsSuccess(state, data) {
    state.loading = false;
    if (state.lists.length > 0) {
      state.lists.forEach(item => {
        if (item.id == data.list_id) {
          item.members.push(data);
        }
      })
    }
    state.errorLists = null;
  },
  /** Funciones para agregar un miembro a una lista */


  /** Funciones para eliminar un miembro de una lista */
  deleteOneUserToListsSuccess(state, data) {
    state.loading = false;
    if (state.lists.length > 0) {
      state.lists.forEach(item => {
        if (item.id == data.list) {
          if(data.type == 'user') {
            item.members = item.members.filter(item => item.user_email !== data.email);
          } else {
            item.members = item.members.filter(item => item.group_id !== data.group);
          }
        }
      })
    }
    state.errorLists = null;
  },
  /** Funciones para eliminar un miembro de una lista */



  /** Funciones para actualizar una etiqueta en las listas */
  updateLabelInListsSuccess(state, data) {
    state.loading = false;
    state.lists.forEach(item => {
      item.cards.forEach(item2 => {
        item2.labels.forEach(item3 => {
          if (item3.id == data.id) {
            item3.title = data.title;
            item3.color = data.color;
          }
        })
      })
    })
    state.errorLists = null;
  },
  /** Funciones para actualizar una etiqueta en las listas */


  /** Funciones para agregar una etiqueta en una de las tarjetas de las listas */
  addLabelInListsSuccess(state, data) {
    state.loading = false;
    state.lists.forEach(item => {
      item.cards.forEach(item2 => {
        if (data.card == item2.id) {
          item2.labels.push(data.label);
        }
      })
    })
    state.errorLists = null;
  },
  /** Funciones para agregar una etiqueta en una de las tarjetas de las listas */

  /** Funciones para eliminar una etiqueta en las listas */
  deleteLabelInListsSuccess(state, data) {
    state.loading = false;
    state.lists.forEach(item => {
      if (data.card != 'l') {
        item.cards.forEach(item2 => {
          if (item2.id == data.card) {
            item2.labels = item2.labels.filter(item => item.id !== data.label);
          }
        })
      } else {
        item.cards.forEach(item2 => {
          item2.labels = item2.labels.filter(item => item.id !== data.label);
        })
      }

    })
    state.errorLists = null;
  },
  /** Funciones para eliminar una etiqueta en las listas */


  /** Funciones para Obtener los templates de las tarjetas  */
  getCardsTemplatesDataSuccess(state, data) {
    state.loading = false;
    state.cardsTemplate = data;
    state.errorLists = null;
  },
  /** Funciones para Obtener los templates de las tarjetas  */


  /** Funciones para agregar una nueva tarjeta */
  addNewCardsSuccess(state, data) {
    state.loading = false;
    state.lists.forEach(item => {
      if (item.id === data.list_id) {
        item.cards.push(data);
      }
    })
    state.errorLists = null;
  },
  /** Funciones para agregar una nueva tarjeta */


  /** Funciones para crear un template de tarjeta */
  createTemplateByCardSuccess(state, data) {
    state.loading = false;
    state.cardsTemplate.push(data);
    state.errorLists = null;
  },
  /** Funciones para crear un template de tarjeta */


  /** Funciones para editar una tarjeta */
  updateCardsSuccess(state, data) {
    state.loading = false;
    if (state.lists.length > 0) {
      state.lists.forEach(item => {
        if (item.id === data.card.list_id) {
          item.cards.forEach(item2 => {
            if (item2.id == data.card.id) {
              item2.title = data.card.title;
              item2.description = data.card.description;
              item2.expiration = data.card.expiration;
              item2.status = data.card.status;
            }
          })
        }
      })
    }
    if (state.cardsTemplate.length > 0) {
      state.cardsTemplate.forEach(item => {
        if (item.id == data.card.id) {
          item.title = data.card.title;
          item.description = data.card.description;
          item.expiration = data.card.expiration;
          item.status = data.card.status;
        }
      })
    }
    if (state.calendarList.length > 0) {
      state.calendarList.forEach(item => {
        if (item.id == data.card.id && item.tp == 1) {
          item.title = `Card: ${data.card.title}`
        }
      })
    }
    if (Object.entries(state.cardItem).length > 0) {
      state.cardItem.title = data.card.title;
      state.cardItem.description = data.card.description;
      state.cardItem.expiration = data.card.expiration;
      state.cardItem.status = data.card.status;
      if (Object.entries(data.hist).length > 0) {
        if (data.hist.updateTitel != false) {
          state.cardItem.history.unshift(data.hist.updateTitel);
        }
        if (data.hist.updateDescrip != false) {
          state.cardItem.history.unshift(data.hist.updateDescrip);
        }
        if (data.hist.updateDate != false) {
          state.cardItem.history.unshift(data.hist.updateDate);
        }
      }
    }
    state.errorLists = null;
  },
  /** Funciones para editar una tarjeta */


  /** Funciones para actualizar una tarjeta luego de cambiar su estatus */
  updateStatusCardsSuccess(state, data) {
    state.loading = false;
    if (state.lists.length > 0) {
      state.lists.forEach(item => {
        if (item.id === data.card.list_id) {
          item.cards.forEach(item2 => {
            if (item2.id == data.card.id) {
              item2.title = data.card.title;
              item2.description = data.card.description;
              item2.expiration = data.card.expiration;
              item2.status = data.card.status;
            }
          })
        }
      })
    }
    if (state.cardsTemplate.length > 0) {
      state.cardsTemplate.forEach(item => {
        if (item.id == data.card.id) {
          item.title = data.card.title;
          item.description = data.card.description;
          item.expiration = data.card.expiration;
          item.status = data.card.status;
        }
      })
    }
    if (state.calendarList.length > 0) {
      state.calendarList.forEach(item => {
        if (item.id == data.card.id && item.tp == 1) {
          item.title = `Card: ${data.card.title}`
        }
      })
    }
    if (Object.entries(state.cardItem).length > 0) {
      state.cardItem.title = data.card.title;
      state.cardItem.description = data.card.description;
      state.cardItem.expiration = data.card.expiration;
      state.cardItem.status = data.card.status;
      state.cardItem.history.push(data.hist);
    }
    state.errorLists = null;
  },
  /** Funciones para actualizar una tarjeta luego de cambiar su estatus */


  /** Funciones para actualizar el estatus especial de un template */
  updateSpecialTemplateCardsSuccess(state, data) {
    state.loading = false;
    if (state.cardsTemplate.length > 0) {
      state.cardsTemplate.forEach(item => {
        if (item.id == data.id) {
          item.special_template = data.special_template;
        }
      })
    }
    state.errorLists = null;
  },
  /** Funciones para actualizar el estatus especial de un template */


  /** Funciones para actualizar los niveles de la lista */
  updateCardsLevelsSuccess(state) {
    state.loading = false;
    state.errorLists = null;
  },
  /** Funciones para actualizar los niveles de la lista */


  /** Funciones para cambiar el estus del modal abrir tarjeta */
  changeModalShowCardItemSuccess(state, id) {
    state.cardItemId = id;
    state.modalShowInfoCard = !state.modalShowInfoCard;
    state.loading = true;
    state.errorLists = null;
  },
  /** Funciones para cambiar el estus del modal abrir tarjeta */


  /** Funciones para Obtener la data de una tarjeta  */
  getCardItemDataSuccess(state, data) {
    state.loading = false;
    state.cardItem = data;
    state.errorLists = null;
  },
  /** Funciones para Obtener la data de una tarjeta  */


  /** Funciones para eliminar una tarjeta de las listas o template */
  deleteCardItemSuccess(state, data) {
    state.loading = false;
    if (state.lists.length > 0) {
      state.lists.forEach(item => {
        item.cards = item.cards.filter(item => item.id !== data);
      })
    }
    if (state.cardsTemplate.length > 0) {
      state.cardsTemplate = state.cardsTemplate.filter(item => item.id !== data);
    }
    state.errorLists = null;
  },
  /** Funciones para eliminar una tarjeta de las listas o template */


  /** Funciones para agregar un miembro a una tarjeta */
  addNewMemberInCardSuccess(state, data) {
    state.loading = false;
    if (state.lists.length > 0) {
      state.lists.forEach(item => {
        item.cards.forEach(item2 => {
          if (item2.id == data.member.card_id) {
            item2.members.push(data.member);
          }
        })
      })
    }
    if (Object.entries(state.cardItem).length > 0) {
      state.cardItem.members.push(data.member);
      state.cardItem.history.push(data.hist);
    }
    state.errorLists = null;
  },
  /** Funciones para agregar un miembro a una tarjeta */


  /** Funciones para eliminar un miembro de una tarjeta */
  deleteOneUserFromCardSuccess(state, data) {
    state.loading = false;
    if (state.lists.length > 0) {
      state.lists.forEach(item => {
        item.cards.forEach(item2 => {
          if (item2.id == data.member.card) {
            if(data.member.type == 'user') {
              item2.members = item2.members.filter(item => item.user_email !== data.member.email);
            } else {
              item2.members = item2.members.filter(item => item.group_id !== data.member.group_id);
            }
          }
        })
      })
    }
    if (Object.entries(state.cardItem).length > 0) {
      if(data.member.type == 'user') {
        state.cardItem.members = state.cardItem.members.filter(item => item.user_email !== data.member.email);
      } else {
        console.log('caso error: '+ data.member.group_id);
        state.cardItem.members = state.cardItem.members.filter(item => item.group_id.toString() !== data.member.group_id);
      }
      state.cardItem.history.push(data.hist);
    }

    state.errorLists = null;
  },
  /** Funciones para eliminar un miembro de una tarjeta */


  /** Funciones para obtener las menciones de un usuario */
  getMentionsInNotesDataSuccess(state, data) {
    state.loading = false;
    state.mentions = data;
    state.errorLists = null;
  },
  /** Funciones para obtener las menciones de un usuario */


  /** Funciones para agregar una nueva nota a una tarjeta */
  addNewNoteInCardSuccess(state, data) {
    state.loading = false;
    state.cardItem.notes.unshift(data);
    state.errorLists = null;
  },
  /** Funciones para agregar una nueva nota a una tarjeta */


  /** Funciones para editar una nota */
  updateNoteInCardSuccess(state, data) {
    state.loading = false;
    state.cardItem.notes.forEach(item => {
      if (item.id == data.id) {
        item.msj = data.msj;
        item.user_data = data.user_data;
      }
    })
    state.errorLists = null;
  },
  /** Funciones para editar una nota */


  /** Funciones para eliminar una nota */
  deleteNoteInCardSuccess(state, data) {
    state.loading = false;
    state.cardItem.notes = state.cardItem.notes.filter(item => item.id !== data);
    state.errorLists = null;
  },
  /** Funciones para eliminar una nota */


  /** Funciones para agregar un archivo a una tarjeta */
  addNewFielInCardSuccess(state, data) {
    state.loading = false;
    data.forEach(item => {
      state.cardItem.attachments.push(item.file);
      state.cardItem.history.unshift(item.hist);
    })
    state.errorLists = null;
  },
  /** Funciones para agregar un archivo a una tarjeta */


  /** Funciones para finalizar el cambio de estatus de una mencion */
  changeNotificationStatusMentionSuccess(state, data) {
    state.loading = false;
    state.mentions.forEach(item => {
      if(item.id == data) {
        item.show = !item.show;
      }
    })
    state.errorLists = null;
  },
  /** Funciones para finalizar el cambio de estatus de una mencion */



  /** Funciones para abrir un archivo */
  getFileToOneCardSuccess(state) {
    state.loading = false;
    state.errorLists = null;
  },
  /** Funciones para abrir un archivo */


  /** Funciones para eliminar un archivo de una tarjeta */
  deleteFileInCardSuccess(state, data) {
    state.loading = false;
    state.cardItem.attachments = state.cardItem.attachments.filter(item => item.id !== data.id);
    state.cardItem.history.unshift(data.hist);
    state.errorLists = null;
  },
  /** Funciones para eliminar un archivo de una tarjeta */


  /** Funciones para añadir una clase de checklist a una tarjeta */
  addNewChecklistClassInCardSuccess(state, data) {
    state.loading = false;
    state.cardItem.checklistsClass.push(data.cclass);
    state.cardItem.history.unshift(data.hist)
    state.errorLists = null;
  },
  /** Funciones para añadir una clase de checklist a una tarjeta */


  /** Funciones para actualizar una clase de checklist en una tarjeta */
  updateOneChecklistClassSuccess(state, data) {
    state.loading = false;
    state.cardItem.checklistsClass.forEach(item => {
      if (item.id == data.cclass.id) {
        item.class_name = data.cclass.class_name;
      }
    })
    state.cardItem.history.unshift(data.hist);
    state.errorLists = null;
  },
  /** Funciones para actualizar una clase de checklist en una tarjeta */


  /** Funciones para eliminar una clase checklist de una tarjeta */
  deleteOneChecklistClassSuccess(state, data) {
    state.loading = false;
    state.cardItem.checklistsClass = state.cardItem.checklistsClass.filter(item => item.id !== data.cclass);
    state.cardItem.history.unshift(data.hist);
    if (state.lists.length > 0) {
      state.lists.forEach(item => {
        if (item.id === data.list) {
          item.cards.forEach(item2 => {
            if (item2.id == data.card) {
              item2.checklist_cant = data.cantTotal;
              item2.checklist_completed = data.cantComplete;
            }
          })
        }
      })
    }
    state.errorLists = null;
  },
  /** Funciones para eliminar una clase checklist de una tarjeta */


  /** Funciones para finalizar la actualizacion de los niveles de las clases checklist */
  updateChecklistClassLevelInCardSuccess(state) {
    state.loading = false;
    state.errorLists = null;
  },
  /** Funciones para finalizar la actualizacion de los niveles de las clases checklist */

  /** Funcion para filtrar los items de una clase por el filtro */
  getItemsClasssByFilterSuccess(state, data) {
    state.loading = false;
    state.cardItem.checklistsClass.forEach(item => {
      if (item.id == data.class) {
        item.showCheck = data.showCheck;
        item.relChecklist = data.relChecklist;
        item.totalChecklists = data.totalChecklists;
        item.checklists = data.checklists;
      }
    });
    state.errorLists = null;
  },

  /** Funciones para añadir un iten a una clase de checklist */
  addNewItemInChecklistClassSuccess(state, data) {
    state.loading = false;
    let d = data.checklist;

    /** Se recorren las clases (Checklist) */
    state.cardItem.checklistsClass.forEach(item => {
      /** Se ubica la clase (Checklist) */
      if (item.id === d.class_id) {

        /** Si el item a añadir es un padre */
        if (d.type == 1) {

          if (d.priority) {
            item.checklists.unshift(d);
          } else {
            item.checklists.push(d);
          }

        } else {

          item.checklists.forEach(item2 => {

            if (item2.id == d.parent) {
              if (d.priority) {
                item2.subItems.unshift(d);
              } else {
                item2.subItems.push(d);
              }
            }
          })
        }   
      }
    })

    if(d.type == 1) {
      if (state.lists.length > 0) {
        state.lists.forEach(item => {
          item.cards.forEach(item2 => {
            if (item2.id == data.checklist.card_id) {
              item2.checklist_cant++;
            }
          })
        })
      }
    }

    state.cardItem.history.unshift(data.hist)
    state.errorLists = null;
  },
  /** Funciones para añadir un iten a una clase de checklist */


  /** Funciones para actualizar un item de una Checklist */
  updateOneItemInChecklistClassSuccess(state, data) {
    state.loading = false;
    if (Object.entries(state.cardItem).length > 0) {
      state.cardItem.checklistsClass.forEach(item => {
        if (item.id == data.class_id) {
          
          item.checklists.forEach(item2 => {

            if(data.type == 1) {


              if (item2.id == data.id) {
                item2.id = data.id;
                item2.card_id = data.card_id;
                item2.class_id = data.class_id;
                item2.title = data.title;
                item2.completed = data.completed;
                item2.creator = data.creator;
                item2.start_date = data.start_date;
                item2.expiration = data.expiration;
                item2.is_responsable = data.is_responsable,
                item2.user_completed = data.user_completed;
                item2.user_responsable = data.user_responsable;
                item2.nivel = data.nivel;
                item2.priority = data.priority;
                item2.priority_level = data.priority_level;
                item2.status = data.status;
                item2.createdAt = data.createdAt;
                item2.updatedAt = data.updatedAt;
                item2.visibility = data.visibility;
                item2.showInfo = data.showInfo;
                item2.itemColor = data.itemColor;
                item2.itemClass = data.itemClass;             
              }
            
            } else {

              item2.subItems.forEach(item3 => {

                if (item3.id == data.id) {
                  item3.id = data.id;
                  item3.card_id = data.card_id;
                  item3.class_id = data.class_id;
                  item3.title = data.title;
                  item3.completed = data.completed;
                  item3.creator = data.creator;
                  item3.start_date = data.start_date;
                  item3.expiration = data.expiration;
                  item3.is_responsable = data.is_responsable,
                  item3.user_completed = data.user_completed;
                  item3.user_responsable = data.user_responsable;
                  item3.nivel = data.nivel;
                  item3.priority = data.priority;
                  item3.priority_level = data.priority_level;
                  item3.status = data.status;
                  item3.createdAt = data.createdAt;
                  item3.updatedAt = data.updatedAt;
                  item3.visibility = data.visibility;
                  item3.showInfo = data.showInfo;
                  item3.itemColor = data.itemColor;
                  item3.itemClass = data.itemClass;   
                }

              })
            }
          })
        }
      })
    }
    state.errorLists = null;
  },
  /** Funciones para actualizar un item de una Checklist */


  /** Funciones para eliminar un item de una checklists */
  deleteOneItemInChecklistClassSuccess(state, data) {
    state.loading = false;

    state.cardItem.checklistsClass.forEach(item => {
      if (item.id === data.cclass) {
        if(data.type == 1) {
          item.checklists = item.checklists.filter(item => item.id !== data.check);
        } else {
          item.checklists.forEach(item2 =>{
            if(item2.id == data.parent) {
              item2.subItems = item2.subItems.filter(item => item.id !== data.check);
            }
          })
        }
      }
    })

    state.cardItem.history.unshift(data.hist);
    if (state.lists.length > 0) {
      state.lists.forEach(item => {
        if (item.id === data.list) {
          item.cards.forEach(item2 => {
            if (item2.id == data.card) {
              item2.checklist_cant = data.cantTotal;
              item2.checklist_completed = data.cantComplete;
            }
          })
        }
      })
    }
    state.errorLists = null;
  },
  /** Funciones para eliminar un item de una checklists */


  /** Funciones para actualizar el estatus de un Item */
  updateStatusOneItemChecklistClassSuccess(state, data) {
    state.loading = false;

    state.cardItem.checklistsClass.forEach(item => {
      if (item.id === data.cclass) {
        item.checklists.forEach(item2 => {

          if(data.type == 1) {

            if (item2.id == data.check) {
              item2.status = data.status;
              item2.user_completed = data.user_completed;
            }

          } else {

            item2.subItems.forEach(item3 => {
              if (item3.id == data.check) {
                item3.status = data.status;
                item3.user_completed = data.user_completed;
                if(data.fatherComplete) {
                  item2.status = true;
                  item2.user_completed = data.user_completed;
                }
              }
            })

          }
        })
      }
    })
    if (state.lists.length > 0) {
      state.lists.forEach(item => {
        if (item.id === data.list) {
          item.cards.forEach(item2 => {
            if (item2.id == data.card) {
              item2.checklist_completed = data.cantComplete;
            }
          })
        }
      })
    }
    if (Object.entries(data.hist).length > 0) {
      state.cardItem.history.unshift(data.hist);
    }
    if (Object.entries(data.histParent).length > 0) {
      state.cardItem.history.unshift(data.histParent);
    }
    state.errorLists = null;
  },
  /** Funciones para actualizar el estatus de un Item */


  /** Funciones para asiganr un responsable de un Item */
  assignItemChecklistClassToUserSuccess(state, data) {
    state.loading = false;
    state.cardItem.checklistsClass.forEach(item => {
      if (item.id === data.class_id) {
        item.checklists.forEach(item2 => {
          if(data.type == 1) {
            if (item2.id == data.id) {
              item2.is_responsable = data.is_responsable;
              item2.user_responsable = data.user_responsable;
            }
          } else {
            if (item2.id == data.parent) {
              item2.subItems.forEach(item3 => {
                if (item3.id == data.id) {
                  item3.is_responsable = data.is_responsable;
                  item3.user_responsable = data.user_responsable;
                }
              })
            }
          }
        })
      }
    })
    state.errorLists = null;
  },
  /** Funciones para asiganr un responsable de un Item */


  /** Funciones para finalizar la actualizacion de los niveles de los items */
  updateItemsLevelInChecklistClassSuccess(state, data) {
    state.loading = false;
    state.lists.forEach(item => {
      item.cards.forEach(item2 => {
        if(item2.id == data.idCard) {
          item2.checklist_cant = data.cc;
          item2.checklist_completed = data.cc2;
        }
      })
    })
    state.errorLists = null;
  },
  /** Funciones para finalizar la actualizacion de los niveles de los items */



  /** Funciones para reiniciar las variables */
  resetDataListsSuccess(state) {
    state.lists = [];
    state.calendarList = [];
  },

  resetDataCardsSuccess(state) {
    state.cardItem = {};
  },
  /** Funciones para reiniciar las variables */
};