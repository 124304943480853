export default {

  actionUserGroupsDataBegin(state) {
    state.loading = true;
  },

  actionUserGroupsDataErr(state, err) {
    state.loading = false;
    state.errorGroups = err;
  },

  /** Funcion para reiniciar la data a sus estados iniciales */
  resetDataUserGroupsSuccess(state) {
    state.loading = false;
    state.groups = [];
    state.filter = [];
    state.errorGroups = null;
  },


  /** Funciones para Obtener los grupos de un usuario */
  getUserGroupsDataSuccess(state, data) {
    state.loading = false;
    state.groups = data;
    state.errorGroups = null;
  },


  /** Funciones para Obtener los grupos de un team */
  filterUserGroupsByTeamSuccess(state, data) {
    state.loading = false;
    state.filter = data;
    state.errorGroups = null;
  },


  /** Funcion para precesar el registro de un grupo */
  registerUserGroupsSuccess(state, data) {
    state.loading = false;
    state.groups.push(data);
    state.errorGroups = null;
  },


  /** Funcion para actualizar los datos de un grupo */
  updateUserGroupsDataSuccess(state, data) {
    state.loading = false;
    if (state.groups.length > 0) {
      state.groups.forEach(item => {
        if (item.id == data.id) {
          item.name = data.name;
          item.team_id = data.team_id;
          item.users = data.users;
          item.createdAt = data.createdAt;
        }
      });
    }
    state.errorGroups = null;
  },


  /** Funcion para eliminar un rol */
  deleteUserGroupsDataSuccess(state, data) {
    state.loading = false;
    if (state.groups.length > 0) {
      state.groups = state.groups.filter(item => item.id !== data);
    }
    state.errorGroups = null;
  },

};