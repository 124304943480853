export default {

  actionPaymentsDataBegin(state) {
    state.loading = true;
  },

  actionPaymentsDataErr(state, err) {
    state.loading = false;
    state.errorPayments = err;
  },


  /** Funcion para reiniciar la data a sus estados iniciales */
  resetDataPaymentsSuccess(state) {
    state.loading = false;
    state.billing = [];
    state.paymentHistory = [];
    state.teamToChangePlan = {};
    state.errorPayments = null;
  },


  /** Funciones para Obtener el historico de pagos de un usuario */
  paymentHistoryDataSuccess(state, data) {
    state.loading = false;
    state.paymentHistory = data;
    state.errorPayments = null;
  },


  /** Funcion para procesar obtener factura de un pago */
  downloadPaymentsInvoice(state) {
    state.loading = false;
    state.errorPayments = null;
  },


  /** Funcion para precesar obtener lista de tarjetas de credito  */
  getBillingsDataSuccess(state, data) {
    state.loading = false;
    state.billing = data;
    state.errorPayments = null;
  },


  /** Funcion para agregar una nueva tarjetas de credito  */
  registerNewBillingSuccess(state, data) {
    state.loading = false;
    state.billing.push(data);
    state.errorPayments = null;
  },


  /** Funcion para procesar eliminar tarjeta de credito */
  deleteBillingsDataSuccess(state, data) {
    state.loading = false;
    if (state.billing.length > 0) {
      state.billing = state.billing.filter(item => item.uuid !== data);
    }
    state.errorPayments = null;
  },


  /** Funcion para asignar team para cambio de plan */
  assignTeamForPlanChangeSuccess(state, data) {
    state.teamToChangePlan = data;
  }

};