<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('headerData.permissions', { }, { locale: lang }) }}
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('add:Permissions', userInfo.access).length > 0)"
                    type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary" :style="{'margin-left':'10px'}"
                    :title="t('headerData.createRols', {}, { locale: lang })" data-placement="bottom"
                    @click="changeModalAddEditPermissions()">
                    <i class="fas fa-plus-circle style-1icon"></i>
                  </button>
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalPermissions()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" :style="{ 'max-height': '65vh', 'min-height': '40vh', 'overflow-x':'hidden', 'overflow-y':'auto'}">
                <table id="permTable" class="table table-striped table-bordered cell-border compact stripe">
                  <thead class="text-center">
                    <tr>
                      <th>{{ t('headerData.displayName', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.codeName', { }, { locale: lang }) }}</th>
                      <th>{{ t('modalsCardsData.description', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.createdDate', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.options', {}, {locale: lang}) }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr v-for="perm in permissions" :key="perm.id">
                      <td>{{ perm.display_name }}</td>
                      <td>{{ perm.name }}</td>
                      <td>{{ perm.description }}</td>
                      <td>{{ moment(perm.createdAt).format('MM-DD-YYYY') }}</td>
                      <td>
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('edit:Permissions', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.updatedRols', {}, { locale: lang })" @click="changeModalAddEditPermissions(perm)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px'}" class="fas fa-edit"></i>
                        </button>
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('delete:Permissions', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.delete', {}, { locale: lang })" @click="changeModalDeletePermissions(perm)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px', 'color':'red'}" class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="changeModalPermissions()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>


  <div v-if="modalAddEditNewPermiss">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t(formPermiss.new ? 'headerData.createRols' : 'headerData.updatedRols', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalAddEditPermissions()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">

                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.displayName', { }, { locale: lang }) }}</label>
                      <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                        v-model="formPermiss.displayName" required>
                      <span class="helper-text danger-text">{{ formPermiss.errDisplay }}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.codeName', { }, { locale: lang }) }}</label>
                      <input name="name" id="name" placeholder="" type="text" class="form-control form-control-sm" v-model="formPermiss.name"
                        required>
                      <span class="helper-text danger-text">{{ formPermiss.errName }}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('modalsCardsData.description', { }, { locale: lang }) }}</label>
                      <textarea rows="1" class="form-control autosize-input" :style="{'height':'50px'}" v-model="formPermiss.description"></textarea>
                    </div>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="registerNewPermissions()">
                  {{ t(formPermiss.new ? 'button.add' : 'button.save', { }, { locale:lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  @click="changeModalAddEditPermissions()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <div v-if="modalDeletePermiss">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t('headerData.delete', { }, { locale: lang }) }} Rols
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalDeletePermissions()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h6>{{ t('headerData.confirmationDelete', { }, { locale: lang }) }}</h6>
                    <b>{{ permItem.display_name }}</b>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deteleOnePermissions()">
                  {{ t('button.delete', {}, {locale: lang}) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  @click="changeModalDeletePermissions()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>


<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { showInfoData, checkError401, checkSuperAdminRol, getTeamsByPermissions } from '@/utility/functions';
import { requiredFields } from '@/utility/validations';
import 'datatables.net-bs5';
import $ from 'jquery';
export default {
  name: 'ModalManagePermissions',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      showInfoData,
      moment,
      checkSuperAdminRol,
      getTeamsByPermissions 
    };
  },
  components: {
  },
  data: function () {
    return {
      permItem: {},
      modalAddEditNewPermiss: false,
      formPermiss: {
        new: true,
        name: '',
        displayName: '',
        description: '',
        errName: '',
        errDisplay: '',
      },
      modalDeletePermiss: false,
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang', 'modalPermissions']),
    ...mapState('rolsData', ['permissions', 'errorRols']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['changeModalPermissions']),
    ...mapActions('rolsData', ['getPermissionsData', 'registerPermissions', 'updatePermissionsData', 'deletePermissionsData']),
    table() {
      this.$nextTick(() => {
        $('#permTable').DataTable();
      });
    },

    /** Funcion para abrir modal para añadir o editar Permisos */
    changeModalAddEditPermissions(item = {}){

      if (Object.entries(item).length > 0) {

        this.permItem = item;
        this.formPermiss.new = false;
        this.formPermiss.name = item.name;
        this.formPermiss.displayName = item.display_name;
        this.formPermiss.description = item.description;
        this.formPermiss.errName = item.errName;
        this.formPermiss.errDisplay = item.errDisplay;

      } else {

        this.permItem = item;
        this.formPermiss = {
          new: true,
          name: '',
          displayName: '',
          description: '',
          errName: '',
          errDisplay: '',
        };
      }

      this.modalAddEditNewPermiss = !this.modalAddEditNewPermiss;
    },

    /** Funcion para registrar o editar un nuevo Permisos */
    async registerNewPermissions() {

      this.formPermiss.errDisplay = requiredFields(this.formPermiss.displayName, this.lang);
      this.formPermiss.errName = requiredFields(this.formPermiss.name, this.lang);

      if (this.formPermiss.errDisplay == '' && this.formPermiss.errName == '') {

        if (this.formPermiss.new) {

          await this.registerPermissions(this.formPermiss);
          if (checkError401(this.errorUsers)) { this.logOut(); }
          if (this.errorUsers == null) {
            this.formPermiss = {
              new: true,
              name: '',
              displayName: '',
              description: '',
              errName: '',
              errDisplay: '',
            };
            this.table();
          }

        } else {

          await this.updatePermissionsData({ ...{ id: this.permItem.id }, ...this.formPermiss });
          if (checkError401(this.errorUsers)) { this.logOut(); }
          if (this.errorUsers == null) {
            this.changeModalAddEditPermissions();
          }
        }
      }

    },

    /** Funcion para cambiar el estado del modal eliminar rol */
    changeModalDeletePermissions(item = {}){

      this.permItem = item;
      this.modalDeletePermiss = !this.modalDeletePermiss;
    },

    /** Funcion para eliminar un rol con todos sus permisos  */
    async deteleOnePermissions(){

      if(Object.entries(this.permItem).length > 0){

        await this.deletePermissionsData(this.permItem.id);
        if (checkError401(this.errorUsers)) { this.logOut(); }
        if (this.errorUsers == null) {
          this.changeModalDeletePermissions();
        }

      }
    },

  },
  mounted: async function () {

    await this.getPermissionsData();
    if (checkError401(this.errorUsers)) { this.logOut(); }
    if (this.errorUsers == null) {
      this.table();
    }
  }
}
</script> 


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>