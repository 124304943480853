<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12">
        <h5 class="modal-title" id="exampleModalLongTitle"><i class="fas fa-users"></i> 
          {{ t('modalsMembersData.members', { }, { locale: lang }) }}
        </h5>
      </div>
      <div class="col-md-12 avatar-wrapper mt-2 avatar-wrapper-overlap">
        <div class="avatar-icon-wrapper avatar-icon-sm text-center" v-for="member in cardItem.members"
          :key="member.id" :title="member.type == 'user' ? `${member.user_name} - ${member.user_email}` : `${t('headerData.userGroups', {}, { locale: lang })} - ${member.user_name}`" @click="checkDeleteUsersPermission(member)">
          <div class="avatar-icon" :style="{'background': member.type=='user'?'rgb(31, 162, 238)':'rgb(38, 213, 213)','color':'white'}">
            <b>{{ iniciales(member.user_name) }}</b>
          </div>        
        </div>
        <div v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('add:UserCard', userAccess.permissions))"
          class="avatar-icon-wrapper avatar-icon-sm avatar-icon-add"
          :title="t('modalsMembersData.addMember', {}, { locale: lang })" @click="changeModalAdd()">
          <div class="avatar-icon"><i>+</i></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal para agregar un miembro a la tarjeta -->
  <div v-if="modalAddMemberInCard">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalsMembersData.addMember', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalAdd()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('modalsMembersData.members', { }, { locale: lang }) }}</label>
                      <select class="multiselect-dropdown form-control form-control-sm" v-model="newMember">
                        <option value="">{{ t('modalsMembersData.chooseMember', { }, { locale: lang }) }}</option>
                        <option v-for="m in filterBoardMembers" :key="m.id" :value="m">
                          {{ m.user_name }}
                        </option>
                      </select>
                      <span class="helper-text danger-text">{{ errorNewMember }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="saveNewMemberInCard()">
                  {{ t('button.add', { }, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalAdd()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para agregar un miembro a la tarjeta -->

  <!-- Modal para eliminar un miembro de la tarjeta -->
  <div v-if="modalDeleteUserInCard">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalsMembersData.deleteMember', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalDelete()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <h6>{{ t('modalsMembersData.deleteMemberMsj', { }, { locale: lang }) }}</h6>
                <b v-if="memberItem.type == 'user'">{{ memberItem.user_name }} - {{ memberItem.user_email }}</b>
                <b v-else>{{ t('headerData.userGroups', {}, { locale: lang }) }} - {{ memberItem.user_name }}</b>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deleteUserInCard()">
                  {{ t('button.delete', { }, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalDelete()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para eliminar un miembro de la tarjeta -->

</template>


<script>
import { checkError401, iniciales, checkSuperAdminRol, checPermissions, filterUserAccessByTeam } from '@/utility/functions';
import { requiredFields } from '@/utility/validations';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: 'UsersSection',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      iniciales,
      checkSuperAdminRol,
      checPermissions 
    };
  },
  data: function () {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      modalAddMemberInCard: false,
      modalDeleteUserInCard: false,
      memberItem: {},
      newMember: '',
      errorNewMember: '',
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang']),
    ...mapState('boardsData', ['boardItem']),
    ...mapState('listsAndCards', ['cardItemId', 'cardItem', 'errorLists']),

    /** Funcion para obtener un arreglo de miembros de los tableros configurados para mostrarlos en las listas */
    filterBoardMembers() {
      let r = [];
      for (let i = 0; i < this.boardItem.members.length; i++) {
        const item = this.boardItem.members[i];
        if(item.type == 'user'){
          r.push({
            type: item.type,
            user_email: item.user_email,
            user_name: `${item.user_name} - ${item.user_email}`,
            group_id: item.group_id,
          });
        } else {
          r.push({
            type: item.type,
            user_email: item.user_email,
            user_name: `${this.t('headerData.userGroups', {}, { locale: this.lang })}: ${item.user_name}`,
            group_id: item.group_id,
          });
          item.group.forEach(it => {
            r.push({
              type: 'user',
              user_email: it.email,
              user_name: `${item.user_name}: ${it.name} - ${it.email}`,
              group_id: item.group_id,
            });
          })
        }
      }
      return r;
    }
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('listsAndCards', ['addNewMemberInCard', 'deleteOneUserFromCard']),

    /** Funcion para verificar si tiene permiso para eliminar usuarios de las tarjetas */
    checkDeleteUsersPermission(item){
      if (checkSuperAdminRol(this.userInfo.access) || checPermissions('delete:UsersCard', this.userAccess.permissions)){
        this.changeModalDelete(item);
      }
    },

    /** Funcion para abrir modal Add usuarios */
    changeModalAdd(){
      this.newMember = '';
      this.modalAddMemberInCard = !this.modalAddMemberInCard;
    },

    /** Funcion para agregar un miembro a la tarjeta */
    async saveNewMemberInCard(){

      this.errorNewMember = requiredFields(this.newMember, this.lang);

      if(this.errorNewMember == ''){
        this.addNewMemberInCard({
          type: this.newMember.type,
          card_id: this.cardItemId,
          user_email: this.newMember.user_email,
          group_id: this.newMember.group_id,
        });
        if (checkError401(this.errorLists)) { this.logOut(); } 
        if(this.errorLists == null){
          this.newMember = '';
        }
      }
    },

    /** Funcion para abrir modal Eliminar usuario */
    changeModalDelete(item = {}) {
      this.memberItem = item;
      this.modalDeleteUserInCard = !this.modalDeleteUserInCard;
    },

    /** Funcion para eliminar un usuario de la tarjeta */
    async deleteUserInCard(){
      
      await this.deleteOneUserFromCard({
        card: this.cardItemId,
        email: this.memberItem.type == 'user' ? this.memberItem.user_email : '-',
        type: this.memberItem.type,
        group: this.memberItem.group_id,
      });
      if (checkError401(this.errorLists)) { this.logOut(); }
      if (this.errorLists == null) {
        this.changeModalDelete();
      }
    }
  },
  mounted: async function () {

    this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.cardItem.team_id);

  }
}
</script>


<style scoped>
.user-card {
  background: rgb(31, 162, 238);
  color: white;
}

.avatar-icon-wrapper {
  display: inline-block;
  margin-right: 13px;
  position: relative;
}
</style>