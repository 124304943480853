import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import { setItem, removeItem, getItem } from '@/utility/localStorageControl';
import router from '@/router'
import toastr from 'toastr';


/** Notificaciones del resultado de los procesos  */
/** Notificacion Exito */
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};


/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};



const state = () => ({
  loading: false,
  loginIn: false,
  userInfo: {
    u21: getItem('u21'),
    n21: getItem('n21'),
    e21: getItem('e21'),
    p21: getItem('p21'),
    ad21: getItem('ad21'),
    st21: getItem('st21'),
    c21: getItem('c21'),
    z21: getItem('z21'),
    pId: getItem('pId'),
    terms: getItem('terms'),
    access: getItem('access')
  },
  errorAuth: null,
  imgProfile: '',
  modalTerms: false,
  checkEmailRegister: false,
  checkTeamName: false,
});


const actions = {

  /** Action para procesar Login usuario normal */
  async login({ commit }, data) {
    try {

      commit('loginBegin');

      let response = await DataService.post('login', data);

      console.log(response.data.payload);

      const user = response.data.payload;
      setItem('u21', user.u21);
      setItem('n21', user.n21);
      setItem('e21', user.e21);
      setItem('p21', user.p21);
      setItem('ad21', user.ad21);
      setItem('st21', user.st21);
      setItem('c21', user.c21);
      setItem('z21', user.z21);
      setItem('pId', user.pId);
      setItem('token', user._token);
      setItem('terms', user.terms);
      setItem('access', user.access);

      await commit('loginSuccess', {
        u21: user.u21,
        n21: user.n21,
        e21: user.e21,
        p21: user.p21,
        ad21: user.ad21,
        st21: user.st21,
        c21: user.c21,
        z21: user.z21,
        pId: user.pId,
        terms: user.terms,
        access: user.access
      });

      return true

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('loginErr', err);
    }
  },


  /** Action para procesar Login usuario normal */
  async loginLive360({ commit }, data) {
    try {

      commit('loginLive360Begin');

      let response = await DataService.post('loginLive360', data);

      console.log(response.data);

      const user = response.data.payload;
      setItem('u21', user.u21);
      setItem('n21', user.n21);
      setItem('e21', user.e21);
      setItem('p21', user.p21);
      setItem('ad21', user.ad21);
      setItem('st21', user.st21);
      setItem('c21', user.c21);
      setItem('z21', user.z21);
      setItem('pId', user.pId);
      setItem('token', user._token);
      setItem('terms', user.terms);

      await commit('loginSuccess', {
        u21: user.u21,
        n21: user.n21,
        e21: user.e21,
        p21: user.p21,
        ad21: user.ad21,
        st21: user.st21,
        c21: user.c21,
        z21: user.z21,
        pId: user.pId,
        terms: user.terms,
      });

      return true

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('loginLive360Err', err);
    }
  },


  /** Action para procesar LogOut del sistema */
  async logOut({ commit }) {
    try {

      commit('logoutBegin');

      removeItem('u21');
      removeItem('n21');
      removeItem('e21');
      removeItem('p21');
      removeItem('ad21');
      removeItem('st21');
      removeItem('c21');
      removeItem('z21');
      removeItem('pId');
      removeItem('token');
      removeItem('terms');
      removeItem('access');

      commit('logoutSuccess', false);

      router.push('/');

    } catch (err) {

      addNotificationError(err.message);

      commit('logoutErr', err);
    }
  },


  /** Action para obtener la Img de perfil del usuario */
  async profileGetImg({ commit }) {
    try {

      await commit('profileGetImgBegin');

      const query = await DataService.getFile(`users/profile/${getItem('e21')}`);

      console.log(query)

      await commit('profileGetImg', query);

    } catch (err) {

      console.log(err);

      addNotificationError(err.message);

      await commit('profileGetImgErr', err);
    }
  },

  /** Action para procesar Actualizar Img perfil */
  async userUpdateImg({ commit }, data) {
    try {

      let formData = new FormData();
      formData.append('file', data);
      formData.append('user', getItem('e21'));

      await commit('userUpdateImgBegin');

      const response = await DataService.postFile('users/profile', formData);

      console.log(response)

      await commit('profileGetImg', response);

      addNotificationSuccess('Updated profile image');

    } catch (err) {

      console.log(err.response);

      await commit('userUpdateImgErr', err);

      addNotificationError(err.response.data.message);
    }
  },


  /** Action para procesar el aceptar los terminos */
  async userAgreeTermsToSystem({ commit }, data) {
    try {

      await commit('userAgreeTermsToSystemBegin');

      let response = await DataService.put(`users/terms/${getItem('e21')}`);

      console.log(response.data);

      setItem('terms', (getItem('terms') == 0 ? 1 : 0));

      commit('userAgreeTermsToSystemSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      await commit('userAgreeTermsToSystemErr', err);

      addNotificationError(err.response.data.message);
    }
  },

  /** Funcion para cambiar el estatus del modal de los terminos */
  changeModalTerms({ commit }) {
    try {

      commit('changeModalTermsSuccess');

    } catch (err) {

      console.log(err.response);
      commit('userAgreeTermsToSystemErr', err);
      addNotificationError(err.response.data.message);
    }
  },

  /** Action para procesar el reset password de un usuario */
  async resetPasswordToOneUser({ commit }, data) {
    try {

      await commit('resetPasswordToOneUserBegin');

      let response = await DataService.put(`/passwordReset/${data}`);

      console.log(response.data);

      commit('resetPasswordToOneUserSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      await commit('resetPasswordToOneUserErr', err);

      addNotificationError(err.response.data.message);
    }
  },


  /** Action para procesar el reset password de un usuario Jhorfrank */
  async resetPasswordToOneUserJhor({ commit }, data) {
    try {

      await commit('resetPasswordToOneUserBegin');

      let response = await DataService.put(`/passwordReset2/${data}`);

      console.log(response.data);

      commit('resetPasswordToOneUserSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      await commit('resetPasswordToOneUserErr', err);

      addNotificationError(err.response.data.message);
    }
  },


  /** Action para registrar un usuario gratis Free */
  async registerUsersFree({ commit }, data) {
    try {

      commit('registerUsersFreeBegin');

      let response = await DataService.post('registerSubscriptions', data);

      console.log(response.data.payload);

      await commit('registerUsersFreeSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('registerUsersFreeErr', err);
    }
  },


  /** Action para actualizar los datos basicos del usuario que inicio sesion */
  async updateLoginUsersData({ commit }, data) {
    try {
      await commit('updateLoginUsersDataBegin');

      let response = await DataService.put(`/users/${getItem('u21')}`, data);

      console.log(response.data.payload);

      setItem('n21', `${response.data.payload.name} ${response.data.payload.surname}`);
      setItem('p21', response.data.payload.phone);
      setItem('ad21', response.data.payload.address);
      setItem('st21', response.data.payload.state);
      setItem('c21', response.data.payload.city);
      setItem('z21', response.data.payload.zip_code);

      commit('updateLoginUsersDataSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('updateLoginUsersDataErr', err);
    }
  },


  /** Action para verificar si un email ya se encuentra registrado en la base de datos con algun rol que no sea observador */
  async validateRegistrationEmail({ commit }, data) {
    try {

      commit('validateRegistrationEmailBegin');

      let response = await DataService.get(`/validateRegistration/${data.email}/${data.tp}`);

      console.log(response.data);

      await commit('validateRegistrationEmailSuccess', response.data.status);

    } catch (err) {

      console.log(err);
      commit('validateRegistrationEmailErr', err);
    }
  },


  /** Action para verificar si un nombre de team ya se encuentra registrado */
  async validateRegistrationTeam({ commit }, data) {
    try {

      commit('validateRegistrationTeamBegin');

      let response = await DataService.get(`/validateTeamName/${data}`);

      console.log(response.data);

      await commit('validateRegistrationTeamSuccess', response.data.status);

    } catch (err) {

      console.log(err);
      commit('validateRegistrationTeamErr', err);
    }
  },


  /** Action para registrar un usuario de un plan Pago */
  async registerUsersPaymentPlans({ commit }, data) {
    try {

      commit('registerUsersPaymentPlansBegin');

      let response = await DataService.post('registerSubscriptions', data);

      console.log(response.data.payload);

      await commit('registerUsersPaymentPlansSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('registerUsersPaymentPlansErr', err);
    }
  },


  /** Action para procesar el cambio de plan de un usuario */
  async processChangeFromCurrentPlan({ commit }, data) {
    try {

      commit('loginBegin');

      let response = await DataService.post('/users/processingPlanChange', data);

      console.log(response.data.payload);

      const user = response.data.payload;
      setItem('access', user.access);

      await commit('processChangeFromCurrentPlanSuccess', user.access);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('loginErr', err);
    }
  },


  /** Action para procesar la compra de un plan desde la tienda de flash */
  async processBuyAPlanUsers({ commit }, data) {
    try {

      commit('loginBegin');

      let response = await DataService.post('/users/buyAPlanUsers', data);

      console.log(response.data.payload);

      const user = response.data.payload;
      setItem('access', user.access);

      await commit('processChangeFromCurrentPlanSuccess', user.access);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('loginErr', err);
    }
  },


  /** Action para procesar la compra de items desde la tienda de flash */
  async buyItemsToTeamsByUsers({ commit }, data) {
    try {

      commit('loginBegin');

      let response = await DataService.post('/users/buyItemsToTeamsByUsers', data);

      console.log(response.data.payload);

      const user = response.data.payload;
      setItem('access', user.access);

      await commit('processChangeFromCurrentPlanSuccess', user.access);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('loginErr', err);
    }
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};