import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';
import toastr from 'toastr';


/** Notificacion Exito */
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};


/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};


const state = () => ({
  loading: false,
  boards: [],
  modalAddEditDeleteBoards: false,
  newBoard: true,
  teamToBoard: 0,
  boardItem: {},
  storesBoards: [],
  latestBoards: [],
  stadistBoards: [],
  calendarBoards: [],
  backgrounds: [],
  modalShowUserTemplateBoard: false,
  templatesBoards: [],
  errorBoards: null,
});


const actions = {

  /** Reset data */
  resetDataBoards({ commit }) {
    try {

      commit('resetDataBoardsSuccess');

    } catch (err) {

      console.log(err);
    }
  },

  /** Action para procesar Get data Boards */
  async getBoadsData({ commit }) {
    try {

      commit('boardsActionBegin');

      let response = await DataService.get(`/boards/user/${getItem('e21')}`);

      console.log(response.data);

      await commit('getBoadsSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para cambiar el modal de Crear, Editar y eliminar Tablero */
  changeModalAddEditDeleteBoard({ commit }, data) {
    try {

      commit('boardsActionBegin');

      commit('changeModalAddEditDeleteBoardSuccess', data);

    } catch (err) {

      console.log(err); if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para agregar un nuevo tablero */
  async addBoards({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ owner: getItem('e21') },
      };

      commit('boardsActionBegin');

      let response = await DataService.post('boards', d);

      console.log(response.data.payload);

      await commit('addBoardsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para actualizar un tablero */
  async updateBoards({ commit }, data) {
    try {

      await commit('boardsActionBegin');

      let response = await DataService.put(`/boards/${data.id}`, data);

      console.log(response.data.payload);

      commit('updateBoardsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para obtener la data de un tablero */
  async getSingleBoad({ commit }, data) {
    try {

      commit('boardsActionBegin');

      let response = await DataService.get(`boards/${data}`);

      console.log(response.data);

      await commit('getSingleBoadSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para eliminar un tablero  */
  async deleteBoards({ commit }, data) {
    try {

      await commit('boardsActionBegin');

      let response = await DataService.delete(`boards/${data}`);

      console.log(response.data);

      await commit('deleteBoardsSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para agregar un tablero a favoritos */
  async addBoardToFavorites({ commit }, data) {
    try {

      commit('boardsActionBegin');

      let response = await DataService.post('boards/favorites', { board: data, user: getItem('e21'), });

      console.log(response.data);

      await commit('addBoardToFavoritesSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para eliminar un tablero de favorito  */
  async deleteBoardToFavorites({ commit }, data) {
    try {

      await commit('boardsActionBegin');

      let response = await DataService.delete(`boards/favorites/${data}/${getItem('e21')}`);

      console.log(response.data);

      await commit('deleteBoardToFavoritesSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para procesar Get store boards */
  async getStoresBoads({ commit }) {
    try {

      commit('boardsActionBegin');

      let response = await DataService.get(`boards/storesBoards/${getItem('e21')}`);

      console.log(response.data);

      await commit('getStoresBoadsSuccess', response.data);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Funcion para buscar las imagenes para los tableros */
  async getBackgroundsForBoards({ commit }, topic) {
    try {

      commit('boardsActionBegin');

      let response = await DataService.get(`https://api.unsplash.com/photos/random/?query=${topic}&orientation=landscape&count=12&client_id=c_8Xp9EAefr7zZnhUOEusIRPYyu8CVS6uO7XOs8mcAs`);

      console.log(response.data);

      await commit('getBackgroundsForBoardsSuccess', response.data);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para Obtener los ultimos tableros visitasdos por el usuario */
  async getLatestBoards({ commit }) {
    try {

      commit('boardsActionBegin');

      let response = await DataService.get(`boards/latest/${getItem('e21')}`);

      console.log(response.data);

      await commit('getLatestBoardsSuccess', response.data.payload);

    } catch (err) {

      console.log(err);

      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para agregar un tablero a los ultimos visitados */
  async addLatestBoards({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ user: getItem('e21') },
      };

      console.log(d);

      commit('boardsActionBegin');

      let response = await DataService.post('boards/latest', d);

      console.log(response.data.payload);

      await commit('addLatestBoardsSuccess');

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para obtener las estadisticas de tareas por tableros */
  async getStatisticsBoards({ commit }) {
    try {

      commit('boardsActionBegin');

      let response = await DataService.get(`boards/statistics/${getItem('e21')}`);

      console.log(response.data);

      await commit('getStatisticsBoardsSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para obtener la data para la vista de calendario de todos los calendatios del usuario */
  async getCalendarBoardsData({ commit }) {
    try {

      commit('boardsActionBegin');

      let response = await DataService.get(`boards/userDate/${getItem('e21')}`);

      console.log(response.data);

      await commit('getCalendarBoardsDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para crear un duplicado de un tablero */
  async createDuplicateBoards({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      commit('boardsActionBegin');

      let response = await DataService.post(`/boards/duplicate`, d);

      console.log(response.data.payload);

      await commit('createDuplicateBoardsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para crear un tablero en base a un template */
  async createBoardByTemplate({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      commit('boardsActionBegin');

      let response = await DataService.post(`/boards/byTemplate`, d);

      console.log(response.data.payload);

      await commit('createDuplicateBoardsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para crear un template de un tablero */
  async createTemplateBoards({ commit }, data) {
    try {

      commit('boardsActionBegin');

      let response = await DataService.post(`/boards/createTemplate`, data);

      console.log(response.data.payload);

      await commit('createTemplateBoardsSuccess');

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /**Action para Archivar o desarchivar un tablero */
  async updateStoreStatus({ commit }, data) {
    try {

      await commit('boardsActionBegin');

      let response = await DataService.put(`boards/store/${data.id}`, data);

      console.log(response.data.payload);

      commit('updateStoreStatusSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para obtener la imagen de un miembro de un tablero */
  async getProfileMembersImg({ commit }, email) {
    try {

      await commit('boardsActionBegin');

      const query = await DataService.getFile(`users/profile/${email}`);

      console.log(query)

      await commit('getProfileMembersImg', { profile: query, email: email });

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      await commit('boardsActionErr', err);
    }
  },

  /** Action para agregar un nuevo miembro a un tablero */
  async addNewMembersBoards({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      commit('boardsActionBegin');

      let response = await DataService.post(`boards/members/`, d);

      console.log(response.data.payload);

      await commit('addNewMembersBoardsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para eliminar un miembro del tablero  */
  async deleteMembersBoards({ commit }, data) {
    try {

      await commit('boardsActionBegin');

      let response = await DataService.delete(`/boards/members/${data.board}/${data.email}/${getItem('e21')}/${data.type}/${data.group}`);

      console.log(response.data);

      await commit('deleteMembersBoardsSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para agregar un archivo a un tablero (Drive y OneDrive) */
  async addNewFielInBoard({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21'), },
      };

      commit('boardsActionBegin');

      let response = await DataService.post('attachments', d);

      console.log(response.data.payload);

      await commit('addNewFielInBoardSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para agregar un archivo a un tablero (Local) */
  async addNewFielInBoardLocal({ commit }, data) {
    try {

      data.append('creator', getItem('e21'));

      commit('boardsActionBegin');

      let response = await DataService.post('attachments', data);

      console.log(response.data.payload);

      await commit('addNewFielInBoardSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para eliminar un archivo de un tablero */
  async deleteFileInBoard({ commit }, data) {
    try {

      await commit('boardsActionBegin');

      let response = await DataService.delete(`attachments/board/${data.objet}/${data.file}/${getItem('e21')}`);

      console.log(response.data.payload);

      await commit('deleteFileInBoardSuccess', data.file);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para cambiar el estado de la variable para mostrar los templates de tableros */
  async showModalTemplateBoard({ commit }) {
    try {

      await commit('boardsActionBegin');
      await commit('showModalTemplateBoardSuccess');

    } catch (err) {

      console.log(err);
      commit('boardsActionErr', err);
    }
  },


  /** Action para obtener la data de los templates de tableros */
  async getBoardsTemplatesData({ commit }, data) {
    try {

      commit('boardsActionBegin');

      let response = await DataService.get(`boards/templateBoards/users/${getItem('e21')}/${data}`);

      console.log(response.data);

      await commit('getBoardsTemplatesDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },


  /** Action para obtener la data de los templates publicos de tableros */
  async getPublicBoardsTemplatesData({ commit }) {
    try {

      commit('boardsActionBegin');

      let response = await DataService.get(`boards/public/templateBoards`);

      console.log(response.data);

      await commit('getBoardsTemplatesDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('boardsActionErr', err);
    }
  },



  /** Action para obtener la Img de perfil del usuario */
  async getPublicTemplateImg({ commit }, id) {
    try {

      await commit('boardsActionBegin');

      const query = await DataService.getFile(`boards/getPublic/templateImg/${id}`);

      console.log(query)

      await commit('getPublicTemplateImgSuccefus', { query: query, id: id });

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      await commit('boardsActionErr', err);
    }
  },


};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};