export default {

  actionPlansDataBegin(state) {
    state.loading = true;
  },

  actionPlansDataErr(state, err) {
    state.loading = false;
    state.errorPlans = err;
  },


  /** Funcion para reiniciar la data a sus estados iniciales */
  resetDataPlansSuccess(state) {
    state.loading = false;
    state.plans = [];
    state.planItem = {};
    state.modalPlans = false;
    state.errorPlans = null;
  },


  /** Funciones para Obtener los plans */
  getPlansDataSuccess(state, data) {
    state.loading = false;
    state.plans = data;
    state.errorPlans = null;
  },


  /** Funcion para precesar el obtern un plan Item */
  getPlansItemSuccess(state, data) {
    state.loading = false;
    state.planItem = data;
    state.errorPlans = null;
  },


  /** Funcion para precesar el registro de un plan */
  registerPlansSuccess(state, data) {
    state.loading = false;
    state.plans.push(data);
    state.errorPlans = null;
  },


  /** Funcion para actualizar los datos de un plan */
  updatePlansDataSuccess(state, data) {
    state.loading = false;
    if (state.plans.length > 0) {
      state.plans.forEach(item => {
        if (item.id == data.id) {
          item.name = data.name;
          item.code_id = data.code_id;
          item.description = data.description;
          item.boards_limit = data.boards_limit;
          item.members_limit = data.members_limit;
          item.cost = data.cost;
          item.status = data.status;
          item.tp = data.tp;
        }
      });
    }
    state.errorPlans = null;
  },


  /** Funcion para eliminar un rol */
  deletePlansDataSuccess(state, data) {
    state.loading = false;
    if (state.plans.length > 0) {
      state.plans = state.plans.filter(item => item.id !== data);
    }
    state.errorPlans = null;
  },



  changeModalPlansSuccess(state) {
    state.loading = false;
    state.modalPlans = !state.modalPlans;
    state.errorPlans = null;
  },

};