import es from '../translations/es.json';
import en from '../translations/en.json';


/** Cerrar sidebar menu normal */
const closeMenu = () => {
  let content = document.getElementById('cont360');
  if (content.clientWidth > 750) {
    content.className = 'app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar closed-sidebar-mobile';
    let bMovil = document.getElementById('b-movil');
    if (bMovil != null) {
      bMovil.className = 'hamburger hamburger--elastic mobile-toggle-nav';
    }
  }
};


/** Cerrar menu 2 */
const closeMenu2 = () => {
  let menu = document.getElementById('cont360');
  if (menu.className === 'app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sideba closed-sidebar-mobile sidebar-mobile-open') {
    let b = document.getElementById('b-movil');
    b.className = 'hamburger hamburger--elastic mobile-toggle-nav';
    menu.className = 'app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar closed-sidebar-mobile';
  }
};


/** Abrir el menu version movil */
const openMenuMovil = () => {
  let menu = document.getElementById('cont360');
  let bmovil = document.getElementById('b-movil');
  if (menu.className === 'app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar closed-sidebar-mobile') {
    bmovil.className = 'hamburger hamburger--elastic mobile-toggle-nav is-active';
    menu.className = 'app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sideba closed-sidebar-mobile sidebar-mobile-open';
  } else {
    bmovil.className = 'hamburger hamburger--elastic mobile-toggle-nav';
    menu.className = 'app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar closed-sidebar-mobile';
  }
};


/** Abrir el menu usuario version movil */
const openMenuMovilUser = () => {
  console.log('Open Menu Vs Movil')
  let bmovil = document.getElementById('menuMovil2');
  console.log(bmovil);
  /*if (bmovil.className == 'app-header__content') {
    bmovil.className = 'app-header__content header-mobile-open';
  } else {
    bmovil.className = 'app-header__content';
  }*/
  console.log(bmovil);
};


/** Verificar error 401 no Autorizado */
const checkError401 = (e) => {
  if (e !== null && e != undefined) {
    if (e.response.status == '401') {
      return true;
    }
  }
  return false;
};


/** Funcion para mostrar contenido de los textos codificados o colocados por el usuario */
const showInfoData = (text, l, tp = 1) => {

  if (text != undefined) {
    let c = text.split("-");

    if (c[0] == '%270491%') { /** Texto codificado para los elementos del tablero de bienvenida multi-idioma */

      return l == 'en' ? en.welcomeBoard[text] : es.welcomeBoard[text];

    } else { /** Texto agregado por el usuario  */

      if (tp == 2) { /** Si se debe verificar la longitud del texto */

        if (text.length > 30) {
          return text.slice(0, 30) + '...';
        }
      }
      return text;
    }
  }
  return text;
};


/** Funcion para retornar las iniciarles de un nombre con formato => Nombre Apellido */
const iniciales = (completo) => {
  if (completo != "") {
    completo = completo.split(" ");
    let nombre = completo[0] != null || completo[0] != undefined ? completo[0].substring(0, 1) : '';
    let apellido = completo[1] != null || completo[1] != undefined ? completo[1].substring(0, 1) : '';
    return nombre + apellido;
  } else {
    return "";
  }
};


/** Funcion para filtrar el rol, permiso, plan y team por el team  */
const filterUserAccessByTeam = (access, team) => {
  let r = {};
  if(access != null) {
    for (let i = 0; i < access.length; i++) {
      if (access[i].team.id == team) {
        r = access[i];
      }
    }
  }
  return r;
};


/** Funcion para verificar si un usuario tiene el rol superAdmin */
const checkSuperAdminRol = (access) => {
  let r = false;
  if(access != null){
    for (let i = 0; i < access.length; i++) {
      if (access[i].rol.name == 'superAdmin') {
        r = true;
      }
    }
  }
  return r;
};


/** Funcion para verificar si es el rol */
const checkRol = (validateRol, userRol) => {
  if (validateRol == userRol) {
    return true;
  } else {
    return false;
  }
};

/** Funcion para verificar si el usuario tiene el permiso */
const checPermissions = (valPerm, userPermiss) => {
  let exists = false;
  for (let i = 0; i < userPermiss.length; i++) {
    if (userPermiss[i].name == valPerm) {
      exists = true;
    }
  }
  return exists;
};


/** Funcion obtener los teams donde el usuario tiene un permiso */
const getTeamsByPermissions = (permissio, access) => {
  let r = [];
  if(access != null) {
    for (let i = 0; i < access.length; i++) {
      for (let j = 0; j < access[i].permissions.length; j++) {
        if (access[i].permissions[j].name == permissio) {
          r.push(access[i].team.id);
        }
      }
    }
  }
  return r;
};


/** Funcion obtener los Rols donde el usuario tiene un permiso */
const getRolsByPermissions = (permissio, access) => {
  let r = [];
  if(access != null){
    for (let i = 0; i < access.length; i++) {
      for (let j = 0; j < access[i].permissions.length; j++) {
        if (access[i].permissions[j].name == permissio) {
          r.push(access[i].rol.name);
        }
      }
    }
  }
  return r;
};


/** Funcion para validad la creacion de tableros en base al limite del team */
const validateCreateBoardByLimitPlam = (boards, user, limit) => {
  let cant = 0;
  for (let i = 0; i < boards.length; i++) {
    if (boards[i].owner == user) {
      cant++;
    }
  }

  if (cant >= limit) {
    return false;
  } else {
    return true;
  }
};


const convertText = (txtData) => {
  
  /* eslint-disable */
  var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  txtData = txtData.replace(urlRegex, '<a href="$1" target=\"_blank\">$1</a>');
  /* eslint-disable */
  var urlRegex =/(\b(\swww).[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  txtData = txtData.replace(urlRegex, ' <a href="$1" target=\"_blank\">$1</a>');
  /* eslint-disable */
  var urlRegex =/(>\swww)/ig;
  txtData = txtData.replace(urlRegex, '>www');
  /* eslint-disable */
  var urlRegex =/(\"\swww)/ig;
  txtData = txtData.replace(urlRegex, '"http://www');

  return txtData;
}



export {
  closeMenu, closeMenu2, openMenuMovil, openMenuMovilUser, showInfoData, checkError401, iniciales,
  checkRol, checPermissions, filterUserAccessByTeam, checkSuperAdminRol, getTeamsByPermissions, validateCreateBoardByLimitPlam,
  getRolsByPermissions, convertText,
};
