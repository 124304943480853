import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import axios from 'axios'
import vueAxios from 'vue-axios'
import en from './translations/en.json';
import es from './translations/es.json';
import { createI18n } from "vue-i18n";
import toastr from 'toastr';

const i18n = createI18n({
    messages: {
        en: en,
        es: es,
    },
    fallbackLocale: 'en'
});


import './assets/main.87c0748b313a1dda75f5.js';

const app = createApp(App)
app.use(store)
app.use(router)
app.use(vueAxios, axios)
app.use(moment)
app.use(i18n)
app.use(toastr);
app.mount('#app')
