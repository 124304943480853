<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('headerData.categories', { }, { locale: lang }) }}
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('add:Categories', userInfo.access).length > 0)"
                    type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary" :style="{'margin-left':'10px'}"
                    :title="t('headerData.createRols', {}, { locale: lang })" data-placement="bottom"
                    @click="changeModalAddEditCategories()">
                    <i class="fas fa-plus-circle style-1icon"></i>
                  </button>
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalCategories()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" :style="{ 'max-height': '65vh', 'min-height': '40vh', 'overflow-x':'hidden', 'overflow-y':'auto'}">
                <table id="categTable" class="table table-striped table-bordered cell-border compact stripe">
                  <thead class="text-center">
                    <tr>
                      <th>{{ t('headerData.name', { }, { locale: lang }) }}</th>
                      <th>{{ t('modalsCardsData.description', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.createdDate', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.options', {}, {locale: lang}) }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr v-for="categ in categories" :key="categ.id">
                      <td>{{ categ.name }}</td>
                      <td>{{ categ.description }}</td>
                      <td>{{ moment(categ.createdAt).format('MM-DD-YYYY') }}</td>
                      <td>
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('edit:Categories', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.updatedRols', {}, { locale: lang })" @click="changeModalAddEditCategories(categ)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px'}" class="fas fa-edit"></i>
                        </button>
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('delete:Categories', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.delete', {}, { locale: lang })" @click="changeModalDeleteCategories(categ)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px', 'color':'red'}" class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="changeModalCategories()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>


  <div v-if="modalAddEditNewCategories">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t(formCateg.new ? 'headerData.createRols' : 'headerData.updatedRols', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalAddEditCategories()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">

                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.name', { }, { locale: lang }) }}</label>
                      <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                        v-model="formCateg.name" required>
                      <span class="helper-text danger-text">{{ formCateg.errName }}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('modalsBoardsData.description', { }, { locale: lang }) }}</label>
                      <textarea rows="1" class="form-control autosize-input" :style="{ 'height': '60px' }" v-model="formCateg.description"></textarea>
                    </div>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="saveCategories()">
                  {{ t(formCateg.new ? 'button.add' : 'button.save', { }, { locale:lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  @click="changeModalAddEditCategories()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <div v-if="modalDeleteCategories">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t('headerData.delete', {}, { locale: lang }) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalDeleteCategories()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">

                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h6>{{ t('modalChecklistData.deleteChecklist', {}, { locale: lang }) }}: <b>{{ deletCateg.name }}</b></h6>
                  </div>
                  <br>
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label for="" class="">{{ t('headerData.deleteMsmCategories', {}, { locale: lang }) }}</label>
                    <select class="multiselect-dropdown form-control form-control-sm" v-model="deletCateg.newCategories">
                      <option :value="0">{{ t('headerData.SelectOption', {}, { locale: lang }) }}</option>
                      <option v-for="cat in categories" :key="cat.id" :value="cat.id" v-show="cat.id != deletCateg.id">{{ cat.name }}</option>
                    </select>
                    <span class="helper-text danger-text">{{ deletCateg.errNewCategories }}</span>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deteleOneCategorie()">
                  {{ t('button.delete', {}, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalDeleteCategories()">
                  {{ t('button.close', {}, { locale: lang }) }}
                </button>
              </div>    
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>


<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { showInfoData, checkError401, checkSuperAdminRol, getTeamsByPermissions } from '@/utility/functions';
import { requiredFields, requiredFieldsNumber } from '@/utility/validations';
import 'datatables.net-bs5';
import $ from 'jquery';
export default {
  name: 'ModalManageCategories',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      showInfoData,
      moment,
      checkSuperAdminRol,
      getTeamsByPermissions 
    };
  },
  components: {
  },
  data: function () {
    return {
      modalAddEditNewCategories: false,
      formCateg: {
        new: true,
        id: 0,
        name: '',
        errName: '',
        description: '',
      },
      deletCateg: {
        id: 0,
        name: '',
        newCategories: 0,
        errNewCategories: '',
      },
      modalDeleteCategories: false,
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang',]),
    ...mapState('categoriesData', ['categories', 'errorCategories']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('categoriesData', ['changeModalCategories', 'getCategoriesData', 'addCategories', 'updateCategories', 'deleteCategories']),
    table() {
      this.$nextTick(() => {
        $('#categTable').DataTable();
      });
    },

    /** Funcion para abrir modal para añadir o editar categoria */
    changeModalAddEditCategories(item = {}){

      if (Object.entries(item).length > 0) {

        this.formCateg.new = false;
        this.formCateg.id = item.id;
        this.formCateg.name = item.name;
        this.formCateg.description = item.description;

      } else {

        this.formCateg = {
          new: true,
          id: 0,
          name: '',
          errName: '',
          description: '',
        };
      }

      this.modalAddEditNewCategories = !this.modalAddEditNewCategories;
    },

    /** Funcion para registrar o editar una categoria */
    async saveCategories() {

      this.formCateg.errName = requiredFields(this.formCateg.name, this.lang);

      if(this.formCateg.errName == '') {

        if (this.formCateg.new) {

          await this.addCategories(this.formCateg);
          if (checkError401(this.errorCategories)) { this.logOut(); }
          if (this.errorCategories == null) {
            this.formCateg = {
              new: true,
              id: 0,
              name: '',
              errName: '',
              description: '',
            };
            this.table();
          }

        } else {

          await this.updateCategories(this.formCateg);
          if (checkError401(this.errorCategories)) { this.logOut(); }
          if (this.errorCategories == null) {
            this.changeModalAddEditCategories();
          }
        }

      }
    },

    /** Funcion para cambiar el estado del modal eliminar categoria */
    changeModalDeleteCategories(item = {}){

      if (Object.entries(item).length > 0) {

        this.deletCateg.id = item.id;
        this.deletCateg.name = item.name;
        this.newCategories = 0;
        this.errNewCategories = '';

      } else {

        this.deletCateg = {
          id: 0,
          name: '',
          newCategories: 0,
          errNewCategories: '',
        };
      }
      this.modalDeleteCategories = !this.modalDeleteCategories;
    },

    /** Funcion para eliminar una categoria  */
    async deteleOneCategorie(){

      this.deletCateg.errNewCategories = requiredFieldsNumber(this.deletCateg.newCategories, this.lang);

      if(this.deletCateg.errNewCategories == '') {

        await this.deleteCategories({ id: this.deletCateg.id, new: this.deletCateg.newCategories});
        if (checkError401(this.errorCategories)) { this.logOut(); }
        if (this.errorCategories == null) {
          this.changeModalDeleteCategories();
        }
      }
    },

  },
  mounted: async function () {

    await this.getCategoriesData();
    if (checkError401(this.errorCategories)) { this.logOut(); }
    if (this.errorCategories == null) {
      this.table();
    }
  }
}
</script> 


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>