<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('headerData.teams', { }, { locale: lang }) }}
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('add:Teams', userInfo.access).length > 0)"
                    type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary" :style="{'margin-left':'10px'}"
                    :title="t('headerData.createRols', {}, { locale: lang })" data-placement="bottom"
                    @click="changeModalAddEditTeams()">
                    <i class="fas fa-plus-circle style-1icon"></i>
                  </button>
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalTeams()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" :style="{ 'max-height': '65vh', 'min-height': '40vh', 'overflow-x':'hidden', 'overflow-y':'auto'}">
                <table id="teamsTable" class="table table-striped table-bordered cell-border compact stripe">
                  <thead class="text-center">
                    <tr>
                      <th>{{ t('headerData.name', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.manager', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.managerEmail', {}, { locale: lang }) }}</th>
                      <th>{{ t('headerData.plan', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.users', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.createdDate', { }, { locale: lang }) }}</th>
                      <th>{{ t('headerData.options', {}, {locale: lang}) }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr v-for="team in teams" :key="team.id">
                      <td>{{ team.name }}</td>
                      <td>{{ team.manager }}</td>
                      <td>{{ team.manager_email }}</td>
                      <td>{{ team.plan.name }}</td>
                      <td>{{ team.users }}</td>
                      <td>{{ moment(team.createdAt).format('MM-DD-YYYY') }}</td>
                      <td>
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('edit:Teams', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.updatedRols', {}, { locale: lang })" @click="changeModalAddEditTeams(team)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px'}" class="fas fa-edit"></i>
                        </button>
                        
                        <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('delete:Teams', userInfo.access).length > 0)"
                          type="button" :title="t('headerData.delete', {}, { locale: lang })" @click="changeModalDeleteTeams(team)"
                          class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important'}">
                          <i :style="{'font-size':'16px', 'color':'red'}" class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="changeModalTeams()">{{
                  t('button.close', { }, { locale: lang })
                }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>


  <div v-if="modalAddEditNewTeams">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t(formTeam.new ? 'headerData.createRols' : 'headerData.updatedRols', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalAddEditTeams()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">

                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.name', { }, { locale: lang }) }}</label>
                      <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                        v-model="formTeam.name" required>
                      <span class="helper-text danger-text">{{ formTeam.errName }}</span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.manager', { }, { locale: lang }) }}</label>
                      <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                        v-model="formTeam.manager" required>
                      <span class="helper-text danger-text">{{ formTeam.errManager }}</span>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.managerEmail', {}, { locale: lang }) }}</label>
                        <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                          v-model="formTeam.managerEmail" required>
                        <span class="helper-text danger-text">{{ formTeam.errManagerEmail }}</span>
                      </div>
                    </div>

                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('headerData.plan', { }, { locale: lang }) }}</label>
                      <select class="multiselect-dropdown form-control form-control-sm" v-model="formTeam.plan">
                        <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                        <option v-for="p in plans" :key="p.id" :value="p.id">
                          {{ `${p.name}: ${t('headerData.boardLimits', {}, { locale: lang })} (${p.boards_limit}) - ${t('headerData.memberLimits', {}, { locale: lang })} (${p.members_limit})` }} 
                        </option>
                      </select>
                      <span class="helper-text danger-text">{{ formTeam.errPlan }}</span>
                    </div>
                  </div>


                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="registerEditOneTeam()">
                  {{ t(formTeam.new ? 'button.add' : 'button.save', { }, { locale:lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  @click="changeModalAddEditTeams()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <div v-if="modalDeleteTeams">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t('headerData.delete', { }, { locale: lang }) }} Rols
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalDeleteTeams()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h6>{{ t('headerData.confirmationDelete', { }, { locale: lang }) }}</h6>
                    <b>{{ teamItem.display_name }}</b>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deteleOneTeam()">
                  {{ t('button.delete', {}, {locale: lang}) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  @click="changeModalDeleteTeams()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>


<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { showInfoData, checkError401, checkSuperAdminRol, getTeamsByPermissions } from '@/utility/functions';
import { requiredFields, numbersType } from '@/utility/validations';
import 'datatables.net-bs5';
import $ from 'jquery';
export default {
  name: 'ModalManageTeams',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      showInfoData,
      moment,
      checkSuperAdminRol,
      getTeamsByPermissions 
    };
  },
  components: {
  },
  data: function () {
    return {
      teamItem: {},
      modalAddEditNewTeams: false,
      formTeam: {
        new: true,
        name: '',
        errName: '',
        manager: '',
        errManager: '',
        managerEmail: '',
        errManagerEmail: '',
        plan: 0,
        errPlan: '',
      },
      modalDeleteTeams: false,
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang',]),
    ...mapState('teamsData', ['teams', 'errorTeams']),
    ...mapState('plansData', ['plans']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['changeModalTeams']),
    ...mapActions('teamsData', ['getTeamsData', 'registerTeams', 'updateTeamsData', 'deleteTeamsData']),
    ...mapActions('plansData', ['getPlansData']),
    table() {
      this.$nextTick(() => {
        $('#teamsTable').DataTable();
      });
    },
  
    /** Funcion para abrir modal para añadir o editar Teams */
    changeModalAddEditTeams(item = {}) {

      if (Object.entries(item).length > 0) {

        this.teamItem = item;
        this.formTeam.new = false;
        this.formTeam.name = item.name;
        this.formTeam.manager = item.manager;
        this.formTeam.managerEmail = item.manager_email;
        this.formTeam.plan = item.plan.id;

      } else {

        this.teamItem = item;
        this.formTeam = {
          new: true,
          name: '',
          errName: '',
          manager: '',
          errManager: '',
          managerEmail: '',
          errManagerEmail: '',
          plan: 0,
          errPlan: '',
        };
      }

      this.getPlansData({'f1': 'all', 'f2': 'plan' });
      this.modalAddEditNewTeams = !this.modalAddEditNewTeams;
    },

    /** Funcion para registrar o editar un nuevo Team */
    async registerEditOneTeam() {

      this.formTeam.errName = requiredFields(this.formTeam.name, this.lang);
      this.formTeam.errManager = requiredFields(this.formTeam.manager, this.lang);
      this.formTeam.errPlan = numbersType(this.formTeam.plan, this.lang);

      if (this.formTeam.errName == '' && this.formTeam.errManager == '' && this.formTeam.errPlan == '') {

        if (this.formTeam.new) {

          await this.registerTeams(this.formTeam);
          if (checkError401(this.errorTeams)) { this.logOut(); }
          if (this.errorTeams == null) {
            this.formTeam = {
              new: true,
              name: '',
              errName: '',
              manager: '',
              errManager: '',
              plan: 0,
              errPlan: '',
            };

            this.table();
          }

        } else {

          await this.updateTeamsData({ ...{ id: this.teamItem.id }, ...this.formTeam });
          if (checkError401(this.errorTeams)) { this.logOut(); }
          if (this.errorTeams == null) {
            this.changeModalAddEditTeams();
          }
        }
      }

    },

    /** Funcion para cambiar el estado del modal eliminar team */
    changeModalDeleteTeams(item = {}) {
      this.teamItem = item;
      this.modalDeleteTeams = !this.modalDeleteTeams;
    },

    /** Funcion para eliminar un team  */
    async deteleOneTeam() {

      if(Object.entries(this.teamItem).length > 0){

        await this.deleteTeamsData(this.teamItem.id);
        if (checkError401(this.errorTeams)) { this.logOut(); }
        if (this.errorTeams == null) {
          this.changeModalDeleteTeams();
        }

      }
    },
  },
  mounted: async function () {

    await this.getTeamsData();
    if (checkError401(this.errorTeams)) { this.logOut(); }
    if(this.errorTeams == null){
      this.table();
    }

  }
}
</script> 


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>