
<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('labelCardData.label', { }, { locale: lang }) }}
                </h5>
                <button 
                  type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalAddEditLabes()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">

                <div v-if="labels.length != 0" class="row">
                  <div class="col-md-12 text-center">
                    <label for="" class="">
                      <b>{{ t('labelCardData.userLabels', {}, {locale: lang}) }}</b>
                    </label>
                  </div>
                  <div class="col-md-3 text-center" :style="{'padding-right':'2px!important', 'padding-left':'2px!important',}"
                    v-for="(l, index) in labels" :key="l.id" :value="l.id"
                  >
                    <button class="mb-2 mr-2 btn" :style="{'background-color':l.color}" @click="selectUserLabel(index)">
                      <b :style="{'color':'cornsilk'}">
                        {{ l.title }}
                      </b>
                      <span :id="`select${l.id}`" class="badge badge-secondary badge-dot badge-dot-lg" :style="{'display':'none'}"></span>
                    </button>
                  </div>
                </div>

                <div v-if="labels.length != 0" tabindex="-1" class="dropdown-divider"></div>

                <div class="row">
                  <div class="col-md-8" :style="{'padding-right':'8px!important', 'padding-left':'8px!important'}">
                    <div class="position-relative form-group">
                      <label for="" class="">
                        {{ t('modalsCardsData.title', { }, { locale:lang }) }}
                      </label>
                      <input name="titleLabel" id="titleLabel" type="text" class="form-control form-control-sm" v-model="title" />
                      <span class="helper-text danger-text">{{ errorTitle }}</span>
                    </div>
                  </div>
                  <div class="col-md-4" :style="{'padding-right':'8px!important', 'padding-left':'8px!important'}">
                    <div class="position-relative form-group">
                      <label for="" class="">
                        {{ t('labelCardData.colorLabel', { }, { locale: lang }) }}
                      </label>
                      <input type="color" name="colorLabel" id="colorLabel" class="form-control form-control-sm">
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button v-if="opUpdate && (checkSuperAdminRol(userInfo.access) || checPermissions('edit:Labels', userAccess.permissions))" 
                  type="button" class="btn btn-primary btn-sm" @click="updateLabels()">
                  {{ t('button.save', { }, { locale:lang }) }}
                </button>
                <button v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('add:Labels', userAccess.permissions))"
                  type="button" class="btn btn-primary btn-sm" @click="addLabel()">
                  {{ t('button.add', { }, { locale:lang }) }}
                </button>
                <button v-if="opDelete && (checkSuperAdminRol(userInfo.access) || checPermissions('delete:Labels', userAccess.permissions))" 
                  type="button" class="btn btn-danger btn-sm" @click="deleteLabels()">
                  {{ t('button.delete', { }, { locale:lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalAddEditLabes()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import { checkError401, checkSuperAdminRol, checPermissions, filterUserAccessByTeam } from '@/utility/functions';
import { requiredFields } from '@/utility/validations';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: 'AddEditLabels',
  setup() {
    const { t, locale } = useI18n();
    return { 
      t, 
      locale,
      checkSuperAdminRol,
      checPermissions 
    };
  },
  data: function () {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      title: '',
      errorTitle: '',
      selectLabel: null,

      opUpdate: false,
      opDelete: false,
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang', 'cardToLabel']),
    ...mapState('labelsData', ['labels', 'resultAction', 'errorLabels']),
    ...mapState('listsAndCards', ['cardItem', 'errorLists']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['changeModalAddEditLabes']),
    ...mapActions('listsAndCards', ['updateLabelInLists', 'deleteLabelInLists', 'addLabelInLists']),
    ...mapActions('labelsData', ['getLabelsData', 'addNewLabel', 'addLabelRelationToCard', 'updateLabel', 'deleteLabel']),

    /** Funcion para seleccionar una etiqueta */
    selectUserLabel(i) { 

      let label = document.getElementById(`select${this.labels[i].id}`);

      /** No hay etiqueta seleccionada */
      if (this.selectLabel == null) {

        label.style.display = '';
        this.selectLabel = i;

      /** Hay una seleccionada */
      } else { 

        /** Se esta seleccionado la misma etiqueta */
        if (this.selectLabel == i) {

          label.style.display = 'none';
          this.selectLabel = null;

          this.title = '';
          let color = document.getElementById('colorLabel');
          color.value = "#060606";

          this.opUpdate = false;
          this.opDelete = false;

        /** Se selecciona una etiqueta diferente */
        } else {

          let label2 = document.getElementById(`select${this.labels[this.selectLabel].id}`);
          label2.style.display = 'none';

          label.style.display = '';
          this.selectLabel = i;
        }
      }

      /** Se verifica si el modal abrio desde el menu */
      if (this.cardToLabel == 0 && this.selectLabel != null) {

        this.title = this.labels[i].title;
        let color = document.getElementById('colorLabel');
        color.value = this.labels[i].color;

        this.opUpdate = true;
        this.opDelete = true;

        /** Se abrio desde una tarjeta */
      } else {

        this.title = "";
        let color = document.getElementById('colorLabel');
        color.value = "#060606";
      }
    },    

    /** Funcion para crear una Etiqueta */
    async addLabel() {

      /* Crear etiqueta para uso futuro */
      if (this.cardToLabel == 0) {

        this.errorTitle = requiredFields(this.title, this.lang);
        let color = document.getElementById('colorLabel');

        if (this.errorTitle == '') {

          await this.addNewLabel({
            tp: 0,
            title: this.title,
            color: color.value,
          });
          if (checkError401(this.errorLabels)) { this.logOut(); }
          if (this.errorLabels == null) {
            this.title = '';
            let color = document.getElementById('colorLabel');
            color.value = "#060606";
          }
        }

      /* Añadir etiqueta a tarjeta */
      } else {

        /** Agregar la etiqueta seleccionada a una tarjeta */
        if (this.selectLabel != null) {

          await this.addLabelRelationToCard({
            card: this.cardToLabel,
            label: this.labels[this.selectLabel].id,
          });
          if (checkError401(this.errorLabels)) { this.logOut(); }
          
          if (this.errorLabels == null){

            /** Se actualiza las etiquetas y el historico de la tarjeta abierta */
            this.cardItem.labels.push(this.resultAction.label);
            this.cardItem.history.push(this.resultAction.hist);
            /** Se actualiza la tarjeta que tiene la lista a la que pertenece la tarjeta */
            this.addLabelInLists({card: this.cardItem.id, label:this.resultAction.label});

            let label = document.getElementById(`select${this.labels[this.selectLabel].id}`);
            label.style.display = 'none';
            this.selectLabel = null;
            this.title = '';
            let color = document.getElementById('colorLabel');
            color.value = "#060606";
          }

        /* Crear una etiqueta y añadirla a la tarjeta */
        } else { 

          this.errorTitle = requiredFields(this.title, this.lang);
          let color = document.getElementById('colorLabel');

          if (this.errorTitle == '') {

            await this.addNewLabel({
              tp: 1,
              title: this.title,
              color: color.value,
              card_id: this.cardToLabel,
            });
            if (checkError401(this.errorLabels)) { this.logOut(); }
            if (this.errorLabels == null) {
              this.title = '';
              let color = document.getElementById('colorLabel');
              color.value = "#060606";

              /** Se actualiza las etiquetas y el historico de la tarjeta abierta */
              this.cardItem.labels.push(this.resultAction.label);
              this.cardItem.history.push(this.resultAction.hist);
              /** Se actualiza la tarjeta que tiene la lista a la que pertenece la tarjeta */
              this.addLabelInLists({ card: this.cardItem.id, label: this.resultAction.label });

            }
          }

        }
      }
    },

    /** Funcion para actualizar una etiqueta */
    async updateLabels() {

      if (this.cardToLabel == 0 && this.selectLabel != null) {

        this.errorTitle = requiredFields(this.title, this.lang);
        let color = document.getElementById('colorLabel');

        if (this.errorTitle == '') {

          await this.updateLabel({
            id: this.labels[this.selectLabel].id,
            title: this.title,
            color: color.value,
          });
          if (checkError401(this.errorLabels)) { this.logOut(); }
          if (this.errorLabels == null) {

            let b = document.getElementById(`select${this.labels[this.selectLabel].id}`);
            b.style.display = 'none';
            this.selectLabel = null;
            this.title = '';
            let color = document.getElementById('colorLabel');
            color.value = "#060606";
            this.opUpdate = false;
            this.opDelete = false;

            this.updateLabelInLists(this.resultAction);
          }
        }
      }
    },

    /** Funcion para eliminar una etiqueta */
    async deleteLabels() {

      if (this.cardToLabel == 0 && this.selectLabel != null) {

        await this.deleteLabel({
          label: this.labels[this.selectLabel].id,
          card: 'l',
        });
        if (checkError401(this.errorLabels)) { this.logOut(); }
        if (this.errorLabels == null) {

          this.selectLabel = null;
          this.title = '';
          let color = document.getElementById('colorLabel');
          color.value = "#060606";
          this.opUpdate = false;
          this.opDelete = false;

          this.deleteLabelInLists(this.resultAction);
        }
      }
    },
  },
  mounted: async function () {

    this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.cardItem.team_id);

    await this.getLabelsData();
    if (checkError401(this.errorLabels)) { this.logOut(); }    
  }
}
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 1000 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.btn .badge-dot.badge-dot-lg {
  width: 14px;
  height: 16px;
}

.btn .badge-dot {
  position: absolute;
  border: #fff solid 2px;
  top: -5px;
  right: -5px;
  width: 11px;
  height: 11px;
}

.btn .badge {
  margin-left: 8px;
}

.badge-dot {
  text-indent: -999em;
  padding: 0;
  width: 8px;
  height: 8px;
  border: transparent solid 1px;
  border-radius: 30px;
  min-width: 2px;
}

.badge {
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
  min-width: 19px;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 8px;
}
</style>