import { createStore } from 'vuex'

import auth from './axios/authentication/actionCreator';
import system from './axios/systemSetup/actionCreator';
import boardsData from './axios/boards/actionCreator';
import listsAndCards from './axios/listsAndCards/actionCreator';
import labelsData from './axios/labels/actionCreator';
import usersData from './axios/users/actionCreator';
import rolsData from './axios/rolsAndPermissions/actionCreator';
import teamsData from './axios/teams/actionCreator';
import plansData from './axios/plans/actionCreator';
import categoriesData from './axios/categories/actionCreator';
import paymentsData from './axios/payments/actionCreator';
import userGroupsData from './axios/userGroups/actionCreator';

export default createStore({
  modules: {
    auth,
    system,
    boardsData,
    listsAndCards,
    labelsData,
    usersData,
    rolsData,
    teamsData,
    plansData,
    categoriesData,
    paymentsData,
    userGroupsData,
  }
})
