<template>
  <div>
    <Header />
    <div class="app-main">
      <Sidebar />
      {{ closeMenu() }}
      <div class="app-main__outer" @mouseenter="closeMenu()" :style="{'background-color':'white'}">
        <div class="app-main__inner">
          <div class="app-page-title" :style="{ 'background': 'rgba(238, 233, 233, 0.45)' }">
            <!-- Header del Contenido  -->
            <div class="page-title-wrapper row">
              <!-- Detalles del Tablero -->
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="page-title-heading">
                  <div class="page-title-icon">
                    <i class="pe-7s-stopwatch icon-gradient bg-mean-fruit"></i>
                  </div>
                  <div>
                    <h4>{{ t('userSettings.myPlan', {}, { locale: lang }) }}</h4>
                    <div class="page-title-subheading">
                      {{ t('userSettings.infoYBalance', {}, { locale: lang }) }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- Detalles del Tablero -->

            </div>
            <!-- Header del Contenido  -->
          </div>

          <!-- Container Normal -->
          <div class="container" :style="{'max-width':'85vw !important', 'min-height':'80vh !important', 'margin-top':'3vh'}">

            <div class="row col-equal-height" v-for="(p, index) in plansInfo" :key="index">

              <!-- Datos basicos del plan -->
              <div class="col-md-3">
                <div class="card card-primary card-outline mb-2" :style="{'height':'250px'}">
                  <div class="card-body text-center">
                    <img src="@/assets/box-cartoon.png" :style="{ 'width': '30%' }">
                    <br>
                    <h2>{{ p.plan.name }}</h2>
                    <small>{{ p.plan.description }}</small>
                    <br>
                    <p v-if="p.showButton">
                      <b>{{ t('userSettings.nextInvoice', {}, { locale: lang }) }}</b>:
                      {{ moment(p.next_payment).format("YYYY-MM-DD") }}
                    </p>
                    <button v-if="p.showButton" 
                      @click="changeTeamPlan(p.team)"
                      type="button" id="btnChangePlan" class="btn btn-primary btn-pill">
                      <i class="fas fa-medal"></i>  {{ t('button.changePlan', {}, { locale: lang }) }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- Datos basicos del plan -->

              <!-- Detalles del plan -->
              <div class="col-md-9  ">
                <div class="card card-primary card-outline p-2 mb-2" :style="{'height':'250px'}">
                  <div class="row mt-3">
                    <div class="col-md-3 col-6 mt-3">
                      <div class="font-icon-wrapper">
                        <i class="fas fa-users bg-happy-fisher icon-gradient" style="font-size: 5em !important;"></i>
                        <p>{{ t('headerData.users', {}, { locale: lang }) }}</p>
                        <h3>
                          <span class="small">{{ p.limit.users }} </span> / {{ p.limitPlan.users }}
                        </h3>
                      </div>
                    </div>
                    <div class="col-md-3 col-6 mt-3">
                      <div class="font-icon-wrapper">
                        <i class="fas fa-mail-bulk bg-deep-blue icon-gradient" style="font-size: 5em !important;"></i>
                        <p>{{ t('dashboardData.boards', {}, { locale: lang }) }}</p>
                        <h3>
                          <span class="small">{{ p.limit.boards }} </span> / {{ p.limitPlan.boards }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Detalles del plan -->
                            
            </div>

          </div>
          <!-- Container Normal -->

        </div>
      </div>
    </div>

  </div>
</template>


<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { closeMenu, showInfoData, checkSuperAdminRol, checPermissions, getTeamsByPermissions, checkError401 } from '@/utility/functions';
import Header from '@/components/template/Header.vue';
import Sidebar from '@/components/template/Sidebar.vue';

export default {
  name: 'InfoUserPlans',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      closeMenu,
      showInfoData,
      checkSuperAdminRol,
      checPermissions,
      getTeamsByPermissions,
      moment
    };
  },
  components: {
    Header,
    Sidebar,
  },
  data() {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
    };
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang']),
    ...mapState('usersData', ['plansInfo','errorUsers']), 
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['loadSystemBackground']),
    ...mapActions('usersData', ['getUsersPlansinfo']),
    ...mapActions('paymentsData', ['assignTeamForPlanChange']),


    /** Funcion para cambiar de plan a un team */
    changeTeamPlan(team) {
      this.assignTeamForPlanChange(team);
      this.$router.push('/changingPlans');
    }
  },
  mounted: async function () {

    this.loadSystemBackground('');
    this.getUsersPlansinfo();
    if (checkError401(this.errorUsers)) { this.logOut(); }
  },
}
</script> 


<style scoped>

</style>