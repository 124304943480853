export default {

  /** Funcion para reiniciar la data a sus estados iniciales */
  resetDataSystemSetupSuccess(state) {
    state.loading = false;
    state.lang = 'en';
    state.showModalInfoFlash = false;
    state.errorSyst = null;
    state.modalAddEditLists = false;
    state.modalAddEditLabes = false;
    state.cardToLabel = 0;
    state.modalAddEditCards = false;
    state.modalRols = false;
    state.modalPermissions = false;
    state.modalTeams = false;
    state.systemBackground = '';
    state.userMenu = false;
  },


  /** Funciones para cambiar estados del lenguaje */
  changeLanguageBegin(state) {
    state.loading = true;
  },

  changeLanguageSuccess(state, data) {
    state.loading = false;
    state.lang = data;
    state.error = null;
  },

  changeLanguageErr(state, err) {
    state.loading = false;
    state.lang = 'en';
    state.error = err;
  },
  /** Funciones para cambiar estados del lenguaje */


  /** Funciones para cambiar el estus del modal informacion flash team */
  changeModalFlashInfoSuccess(state, data) {
    state.showModalInfoFlash = data;
    state.error = null;
  },

  changeModalFlashInfoErr(state, err) {
    state.error = err;
  },
  /** Funciones para cambiar el estus del modal informacion flash team */


  /** Funcion para obtener el archivo Beta Terster */
  getFileBetaTersterBegin(state) {
    state.loading = true;
  },

  getFileBetaTersterSuccess(state) {
    state.loading = false;
    state.errorSyst = null;
  },

  getFileBetaTersterErr(state, err) {
    state.loading = false;
    state.errorSyst = err;
  },
  /** Funcion para obtener el archivo Beta Terster */


  /** Funciones para cambiar el estus del modal Add y Edit List */
  changeModalAddEditListsSuccess(state) {
    state.modalAddEditLists = !state.modalAddEditLists;
    state.error = null;
  },

  changeModalAddEditListsErr(state, err) {
    state.error = err;
  },
  /** Funciones para cambiar el estus del modal Add y Edit List */


  /** Funciones para cambiar el estus del modal Add y Edit Labels */
  changeModalAddEditLabesSuccess(state, data) {
    state.cardToLabel = data;
    state.modalAddEditLabes = !state.modalAddEditLabes;
    state.error = null;
  },

  changeModalAddEditLabesErr(state, err) {
    state.error = err;
  },
  /** Funciones para cambiar el estus del modal Add y Edit Labels */


  /** Funciones para cambiar el estus del modal Add y Edit Cards */
  changeModalAddEditCardsSuccess(state) {
    state.modalAddEditCards = !state.modalAddEditCards;
    state.error = null;
  },

  changeModalAddEditCardsErr(state, err) {
    state.error = err;
  },
  /** Funciones para cambiar el estus del modal Add y Edit Cards */


  /** Funciones para cambiar el estus del modal rols */
  changeModalRolsSuccess(state) {
    state.modalRols = !state.modalRols;
    state.error = null;
  },

  changeModalRolsErr(state, err) {
    state.error = err;
  },
  /** Funciones para cambiar el estus del modal rols */


  /** Funciones para cambiar el estus del modal permisos */
  changeModalPermissionsSuccess(state) {
    state.modalPermissions = !state.modalPermissions;
    state.error = null;
  },

  changeModalPermissionsErr(state, err) {
    state.error = err;
  },
  /** Funciones para cambiar el estus del modal permisos */


  /** Funciones para cambiar el estus del modal permisos */
  changeModalTeamsSuccess(state) {
    state.modalTeams = !state.modalTeams;
    state.error = null;
  },

  changeModalTeamsErr(state, err) {
    state.error = err;
  },
  /** Funciones para cambiar el estus del modal permisos */


  /** Funciones para cargar la imagen de fondo del sistema */
  loadSystemBackgroundSuccess(state, data) {
    state.systemBackground = data;
    state.error = null;
  },

  loadSystemBackgroundErr(state, err) {
    state.error = err;
  },
  /** Funciones para cargar la imagen de fondo del sistema */



  /** Funciones para cambiar el estus del modal grupos de usuarios */
  changeModalUserGroupsSuccess(state) {
    state.modalUsersGroups = !state.modalUsersGroups;
    state.error = null;
  },

  changeModalUserGroupsErr(state, err) {
    state.error = err;
  },
  /** Funciones para cambiar el estus del modal grupos de usuarios */



  /** Funciones para cambiar el estado del menu del perfil del usuario */
  changeMenuValueClose(state) {
    state.userMenu = false;
    state.error = null;
  },

  changeMenuValueChange(state) {
    state.userMenu = !state.userMenu;
    state.error = null;
  },

  changeMenuValueErr(state, err) {
    state.error = err;
  },
  /** Funciones para cambiar el estado del menu del perfil del usuario */

};