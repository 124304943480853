import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
//import { getItem } from '@/utility/localStorageControl';
import toastr from 'toastr';


/** Notificacion Exito */
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};


/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};


const state = () => ({
  loading: false,
  plans: [],
  planItem: {},
  modalPlans: false,
  errorPlans: null,
});


const actions = {

  /** Reset data */
  resetDataPlans({ commit }) {
    try {

      commit('resetDataPlansSuccess');

    } catch (err) {

      console.log(err);
    }
  },


  /** Action para procesar Get data plans */
  async getPlansData({ commit }, data = { 'f1': 'all', 'f2': 'all' }) {
    try {

      commit('actionPlansDataBegin');

      let response = await DataService.get(`/plans/${data.f1}/${data.f2}`);

      console.log(response.data);

      await commit('getPlansDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionPlansDataErr', err);
    }
  },


  /** Action para procesar Get data plans */
  async getPlansItem({ commit }, data) {
    try {

      commit('actionPlansDataBegin');

      let response = await DataService.get(`/planInf/info/${data}`);

      console.log(response.data);

      await commit('getPlansItemSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionPlansDataErr', err);
    }
  },


  /** Action para procesar Registro de plans en el sistema */
  async registerPlans({ commit }, data) {
    try {

      commit('actionPlansDataBegin');

      let response = await DataService.post('plans', data);

      console.log(response.data.payload);

      await commit('registerPlansSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('actionPlansDataErr', err);
    }
  },


  /** Action para procesar actualizar plans */
  async updatePlansData({ commit }, data) {
    try {

      await commit('actionPlansDataBegin');

      let response = await DataService.put(`/plans/${data.id}`, data);

      console.log(response.data.payload);

      commit('updatePlansDataSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionPlansDataErr', err);
    }
  },


  /** Action para procesar eliminar plans */
  async deletePlansData({ commit }, data) {
    try {

      await commit('actionPlansDataBegin');

      let response = await DataService.delete(`plans/${data}`);

      console.log(response.data.payload);

      commit('deletePlansDataSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionPlansDataErr', err);
    }
  },


  changeModalPlans({ commit }) {
    try {

      commit('changeModalPlansSuccess');

    } catch (err) {

      commit('actionPlansDataErr', err);
    }
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};