<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  {{ t(this.newItem == false ? 'modalsListsData.editList' : 'modalsListsData.addNewList', {}, {locale: lang}) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalAddEditLists()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label for="examplePassword" class="">
                          {{ t('modalsListsData.title', { }, { locale: lang }) }}
                        </label>
                        <input name="boardTitle" id="boardTitle" placeholder="" type="text"
                          class="form-control form-control-sm" v-model="title">
                        <span class="helper-text danger-text">{{ ErrorTitle }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                  <button v-if="(newItem == false) && (checkSuperAdminRol(userInfo.access) || checPermissions('delete:Lists', userAccess.permissions))" 
                    type="button" class="btn btn-danger btn-sm" @click="changeModalDelete()">
                    {{ t('button.delete', {}, {locale: lang}) }}
                  </button>
                  <button type="button" class="btn btn-info btn-sm" @click="saveList()">
                    {{ t(this.newItem == false ? 'button.save' : 'button.add', {}, {locale: lang}) }}
                  </button>
                  <button type="button" class="btn btn-secondary" @click="changeModalAddEditLists()">
                    {{ t('button.close', {}, {locale: lang}) }}
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Modal Eliminar List -->
    <div v-if="modalDeleteList">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">{{ t('modalsListsData.deleteList', {}, {locale: lang}) }}</h5>
                    <button type="button" class="close" aria-label="Close" @click="changeModalDelete()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                  <div class="modal-body text-center">
                    <p class="mb-0">{{ t('modalsListsData.msj', {}, {locale: lang}) }}</p>
                    <b>{{ showInfoData(listItem.title, lang) }}</b>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn-sm" @click="deleteLists()">
                      {{ t('button.delete', {}, {locale: lang}) }}
                    </button>
                    <button type="button" class="btn btn-secondary" @click="changeModalDelete()">
                      {{ t('button.close', {}, {locale: lang}) }}
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal Eliminar List -->

  </div>
</template>


<script>
import { showInfoData, checkError401, checkSuperAdminRol, checPermissions, filterUserAccessByTeam } from '@/utility/functions';
import { requiredFields } from '@/utility/validations';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: 'ModalLists',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      showInfoData,
      checkSuperAdminRol,
      checPermissions
    };
  },
  props: {
    newItem: Boolean,
    listItem: {
      type: Object,
      default: () => ({}),
    }
  },
  data: function () {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      title: '',
      ErrorTitle: '',
      modalDeleteList: false,
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang']),
    ...mapState('boardsData', ['boardItem']),
    ...mapState('listsAndCards', ['errorLists']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['changeModalAddEditLists']),
    ...mapActions('listsAndCards', ['addNewList', 'updateList', 'deleteList']),

    /** Funcion para Agregar o Editar una Lista */
    async saveList() {

      console.log('Nueva Lista: ' + this.newItem);

      this.ErrorTitle = requiredFields(this.title, this.lang);

      if (this.ErrorTitle == '') {

        /** Crear Lista */
        if (this.newItem) {
          this.addNewList({ title: this.title, board_id: this.boardItem.id });
          /** Editar lista */
        } else {
          this.updateList({ title: this.title, id: this.listItem.id });
        }
        
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.changeModalAddEditLists();
        }
      }
    },


    /** Mostrar modal Eliminar list */
    changeModalDelete() {
      this.modalDeleteList = !this.modalDeleteList;
    },

    /** Funcion para Eliminar una lista */
    async deleteLists() {

      await this.deleteList(this.listItem.id);
      if (checkError401(this.errorLists)) { this.logOut(); }
      if (this.errorLists == null) {
        this.changeModalDelete();
        this.changeModalAddEditLists();
      }
    },

  },
  mounted: function () {

    this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.boardItem.team_id);
    this.title = this.newItem == false ? this.showInfoData(this.listItem.title, this.lang) : '';
  }
}
</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>