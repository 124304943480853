import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';
import toastr from 'toastr';


/** Notificacion Exito */
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};


/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};


const state = () => ({
  loading: false,
  labels: [],
  errorLabels: null,
  resultAction: {},
});


const actions = {

  /** Reset data */
  resetDataLabels({ commit }) {
    try {

      commit('resetDataLabelsSuccess');

    } catch (err) {

      console.log(err);
    }
  },


  /** Action para procesar Get data Labels */
  async getLabelsData({ commit }) {
    try {

      commit('getLabelsDataBegin');

      let response = await DataService.get(`/labels/user/${getItem('e21')}`);

      console.log(response.data);

      await commit('getLabelsDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('getLabelsDataErr', err);
    }
  },

  /** Action para agregar una nueva etiqueta */
  async addNewLabel({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      commit('addNewLabelBegin');

      let response = await DataService.post('labels', d);

      console.log(response.data.payload);

      await commit('addNewLabelSuccess', { tp: data.tp, data: response.data.payload });

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('addNewLabelErr', err);
    }
  },

  /** Action para agregar una relacion (etiqueta - tarjeta) */
  async addLabelRelationToCard({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      commit('addLabelRelationToCardBegin');

      let response = await DataService.post('/labels/relationCard', d);

      console.log(response.data.payload);

      await commit('addLabelRelationToCardSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('addLabelRelationToCardErr', err);
    }
  },

  /** Action para actualizar una etiqueta */
  async updateLabel({ commit }, data) {
    try {

      await commit('updateLabelBegin');

      let response = await DataService.put(`/labels/${data.id}`, data);

      console.log(response.data.payload);

      commit('updateLabelSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('updateLabelErr', err);
    }
  },

  /** Action para eliminar una etiqueta  */
  async deleteLabel({ commit }, data) {
    try {

      await commit('deleteLabelBegin');

      let response = await DataService.delete(`labels/${data.label}/${data.card}`);

      console.log(response.data);

      await commit('deleteLabelSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('deleteLabelErr', err);
    }
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};