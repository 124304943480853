<template>
  <div>
    <Header />
    <div class="app-main" :style="{ 'background-color':'white', }" @mouseenter="closeMenu()">
      <Sidebar />
      <div class="app-main__outer ">
        <div class="app-main__inner">
          <div class="app-page-title" :style="{'background':'rgba(238, 233, 233, 0.45)'}">
            <div class="page-title-wrapper">
              <div class="page-title-heading">
                <div class="" style="margin-right:5px;">
                  <img src="@/assets/Icon-1B.png" alt="">
                </div>
                <div>
                  {{ t('dashboardData.dashboard', {}, {locale: lang}) }}
                  <div class="page-title-subheading">
                    {{ t('dashboardData.example', {}, {locale: lang}) }}
                  </div>
                </div>
              </div>
              <div class="page-title-actions">

                <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:CalendarBoards', userInfo.access).length > 0)"
                  type="button" :title="calendarView ? t('dashboardData.boardView', {}, {locale: lang}):t('dashboardData.calendarView', {}, {locale: lang})"
                  @click="calendarViewOp()" class="btn-shadow mr-3 btn btn-primary">
                  <i :class="calendarView ? 'fas fa-th-large btn-icon-wrapper':'fas fa-calendar btn-icon-wrapper'"
                    style="font-size:20px;"></i>
                </button>

                <!-- Boton añadir nuevo tablero  
                <button class="mb-2 mr-2 btn-icon btn-shadow btn-dashed btn btn-outline-primary"
                  @click="validateCreateNewBoard()">
                  <i class="pe-7s-plus btn-icon-wrapper"> </i>
                  {{ t('dashboardData.addNewBoard', {}, {locale: lang}) }}
                </button>
                -->
              </div>
            </div>
          </div>
        </div>

        <!-- Container Normal -->
        <div v-if="!calendarView" class="container text-center container-b">

          <!-- Bloque estadisticas -->
          <div class="row text-center" :style="{'margin-top':'4vh',}">
            <h2>{{ `${showTimeMessaje}, ${userInfo.n21 != null ? userInfo.n21.split(" ")[0] : ''}` }}</h2>

            <!-- <StatisticsBoard v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:BoardStatistics', userInfo.access).length > 0)"/> -->
          </div>
          <!-- Bloque estadisticas -->

          <!-- Bloque favoritos -->
          <div v-if="favoritesboards.length > 0" class="row text-center" :style="{ 'margin-bottom':'15px', }">
            <div class="col-md-12">
              <h5 class="text-left">
                {{ t('dashboardData.bookmark', {}, {locale: lang}) }}
              </h5>
            </div>
            <BoardCard v-for="bookmark in favoritesboards" :key="bookmark.id" :board="bookmark" />
          </div>
          <!-- Bloque favoritos -->


          <!-- Bloque de tableros separados por teams -->
          <BoardBlock v-for="b in boards" :key="b.id" :boardsFilter="b" />
          <!-- Bloque de tableros separados por teams -->


          <!-- Bloque Ultimos Visitados -->
          <div v-if="this.latestBoards.length > 0" class="row no-movil" :style="{ 'margin-bottom':'15px' }">
            <div class="col-md-12">
              <h5 class="text-left">
                {{ t('dashboardData.latestBoards', {}, {locale: lang}) }}
              </h5>
            </div>
            <BoardCard v-for="latest in latestBoards" :key="latest.id" :board="latest" />
          </div>
          <!-- Bloque Ultimos Visitados -->

        </div>
        <!-- Container Normal -->

        <!-- Vista de calendario -->
        <div v-if="calendarView">
          <FullCalendar :options="calendarOptions" class="container" />
        </div>
        <!-- Vista de calendario -->

      </div>
    </div>

    <div v-if="modalAddEditDeleteBoards">
      <ModalAddEditBoard />
    </div>

  </div>
</template>


<script>
import Header from '@/components/template/Header.vue';
import Sidebar from '@/components/template/Sidebar.vue';
import moment from 'moment';
import { closeMenu, checkError401, checkSuperAdminRol, getTeamsByPermissions } from '@/utility/functions';
import { useI18n } from 'vue-i18n';
import { mapState, mapActions } from 'vuex';
import BoardBlock from '@/components/boards/boardBlock.vue';
import BoardCard from '@/components/boards/BoardCard.vue';
import ModalAddEditBoard from '@/components/boards/ModalAddEditBoard.vue';
//import StatisticsBoard from '@/components/boards/StatisticsBoard.vue';
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
export default {
  name: 'Dashboard',
  setup() {
    const {t, locale} = useI18n();
    return {
      t, 
      locale,
      closeMenu,
      checkSuperAdminRol, 
      getTeamsByPermissions
    };
  },
  components: {
    Header,
    Sidebar,
    BoardCard,
    ModalAddEditBoard,
    //StatisticsBoard,
    FullCalendar,
    BoardBlock,
  },
  data() {
    return {
      calendarView: false,
      calendarOptions: {
        locale: 'en',
        weekends: true,
        editable: false,
        droppable: true,
        eventDrop: this.handleDropEvent,
        selectable: false,
        selecHelper: false,
        selectMirror: false,
        dayMaxEvents: true,
        plugins: [dayGridPlugin, interactionPlugin],
        dateClick: this.handleDateClick,
        eventClick: this.onEventClick,
        eventMouseEnter: function (info) {
          info.el.title = info.el.text;
        },
        events: [],
        views: {
          dayGridWeek: {
            displayEventEnd: false,
            buttonText: 'Week',
          },
          dayGridMonth: {
            buttonText: 'Month',
            displayEventEnd: false,
            showNonCurrentDates: false,
          },
        },
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridWeek,dayGridMonth'
        },
        initialView: 'dayGridMonth',
        contentHeight: 700,
        aspectRatio: 1.5,
        eventColor: '#16aaff',
        eventClassNames: ['cardView'],
      },
    };
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang']),
    ...mapState('boardsData', ['boards', 'latestBoards', 'calendarBoards', 'errorBoards', 'modalAddEditDeleteBoards']),
    /** Se filtran los tableros favoritos del usuario */
    favoritesboards() {
      let data = [];
      this.boards.forEach(element => {
        element.boards.forEach(item => {
          if (item.favorite) {
            data.push(item);
          }
        })
      });
      return data;
    },
    /** Mostrar el mensaje de Bienvenida al usuario */
    showTimeMessaje() {
      const today = new Date();
      const dateAct = moment().format('YYYY-MM-DD');
      const actHour = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const actDate = dateAct + " " + actHour;

      if ((moment(actDate).unix() >= moment(dateAct + ' 05:00:00').unix()) && (moment(actDate).unix() <= moment(dateAct + ' 12:00:59').unix())) {

        return this.t('timeMsj.goodMorning', {}, { locale: this.lang });

      } else if ((moment(actDate).unix() >= moment(dateAct + ' 12:01:00').unix()) && (moment(actDate).unix() <= moment(dateAct + ' 17:00:59').unix())) {

        return this.t('timeMsj.goodAfternoon', {}, { locale: this.lang });

      }

      return this.t('timeMsj.goodEvening', {}, { locale: this.lang });
    },
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['loadSystemBackground']),
    ...mapActions('boardsData', ['getBoadsData', 'getLatestBoards', 'getCalendarBoardsData']),
    ...mapActions('listsAndCards', ['updateCards', 'updateOneItemInChecklistClass']),   
    /** Funcion para mostrar la vista calendario */
    async calendarViewOp(){ 
      if(!this.calendarView){
        await this.getCalendarBoardsData();
        if (checkError401(this.errorBoards)) { this.logOut(); }
        if(this.errorBoards == null) {
          this.calendarOptions.locale = this.lang;
          this.calendarOptions.events = this.calendarBoards;
        }
      }
      this.calendarView = !this.calendarView;
    },


    /** Funcion para procesar el evento clic del calendario : abrir tablero y mostrar tarjeta de la tarea */
    onEventClick(arg){ 
      let r = arg.event._def.publicId.split("-");
      this.$router.push('/board/'+r[0]+'.'+r[1]+'.'+r[2]);
    },


    /** Funcion para actualizar la fecha de una tarea desde el calendario */
    handleDropEvent: async function (arg) {
      let date = moment(arg.event.start.toISOString()).format('YYYY-MM-DD');

      if (arg.event.extendedProps.tp == 1) {
        await this.updateCards({
          id: arg.event.id.split('-')[2],
          expiration: date,
        });
      } else {
        await this.updateOneItemInChecklistClass({
          id: arg.event.id.split('-')[3],
          expiration: date,
        })
      }
      await this.getCalendarBoardsData();
    },
  },
  watch: {
    /** Se detecta el cambio en la variable del lenguaje */
    lang(){
      this.calendarOptions.locale = this.lang;
    },
    calendarBoards() {
      this.calendarOptions.events = this.calendarBoards;
    }
  },
  mounted: async function () {
    /** Se carga el fondo por defecto null */
    this.loadSystemBackground('');


    /** Se cargan los tableros del usuario */
    await this.getBoadsData();
    if (checkError401(this.errorBoards)) { this.logOut(); }


    /** Se cargan los ultimos tableros abiertos por el usuario */
    await this.getLatestBoards();
    if (checkError401(this.errorBoards)) { this.logOut(); }
  }
}
</script> 


<style scoped>
@media only screen and (min-width: 0px) and (max-width: 450px) {
  .no-movil {
    display: none;
  }
}

.container-b {
  max-width: 90vw !important;
  min-height: 80vh !important;
}
</style>