<template>
  <div class="wrapper fadeInDown">
    <div id="formContent">
      <div class="fadeIn first">
        <img src="@/assets/logo-m.png" id="icon" alt="User Icon" />
      </div>
      <form v-on:submit.prevent="signIn()">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <input type="email" id="email" name="email" class="fadeIn second validate" placeholder="Email"
              v-model="fLogin.email" required>
            <br>
            <span class="helper-text danger-text">{{ emailCheck }}</span>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <input type="password" id="password" name="password" class="fadeIn third validate" placeholder="Password"
              v-model="fLogin.password" required>
            <br>
            <span class="helper-text danger-text">{{ passwordCheck }}</span>
          </div>
        </div>

        <input type="submit" class="fadeIn fourth btn-sm" value="Login">
      </form>
      <div id="formFooter">
        <a class="underlineHover" @click="changeModalResetPassword()"><b class="underlineHover link-footer">Reset password</b></a>
        <br>
        <a class="underlineHover" :style="{ 'margin-top': '8px' }" @click="signUp()">
          <b class="underlineHover link-footer">Sign-up</b>
        </a>
      </div>
    </div>
  </div>

  <!-- Modal para reiniciar la clave de un usuario -->
  <div v-if="modalResetPassword">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header" :style="{'background-color':'#f8f9fa'}">
                <h5 class="modal-title" id="exampleModalLabel"> Reset password</h5>
                <button type="button" class="close" aria-label="Close" @click="changeModalResetPassword()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <br>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <label for="" class="">Enter your registered email address</label>
                      <input name="boardTitle" id="boardTitle" type="text" class="form-control form-control-sm" v-model="emailToReset" />
                      <span class="helper-text danger-text">{{ errEmailToReset }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" id="botonCopiBoard" class="btn btn-primary btn-sm" @click="resetPasswordToEmail()">
                 Reset
                </button>
                <button type="button" class="btn btn-secondary" @click="changeModalResetPassword()">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para reiniciar la clave de un usuario -->

</template>


<script>
import { useI18n } from 'vue-i18n';
import { mapState, mapActions } from 'vuex';
import { requiredFields, emailFields } from '../utility/validations';
export default {
  name: 'Login',
  setup() {
    const { t, locale } = useI18n();
    return { t, locale, };
  },
  data: function () {
    return {
      fLogin: {
        email: '',
        password: '',
      },
      emailCheck: '',
      passwordCheck: '',
      modalResetPassword: false,
      emailToReset: '',
      errEmailToReset: '',
    }
  },
  computed: {
    ...mapState('auth', ['loginIn', 'errorAuth']),
  },
  methods: {
    ...mapActions('auth', ['login', 'loginLive360', 'resetPasswordToOneUser']),
    ...mapActions('boardsData', ['resetDataBoards']),
    ...mapActions('labelsData', ['resetDataLabels']),
    ...mapActions('listsAndCards', ['resetDataListAndCards']),
    ...mapActions('plansData', ['resetDataPlans']),
    ...mapActions('rolsData', ['resetDataRolsAndPermissions']),
    ...mapActions('system', ['loadSystemBackground', 'resetDataSystemSetup']),
    ...mapActions('teamsData', ['resetDataTeams']),
    ...mapActions('usersData', ['resetDataUsers']),
    ...mapActions('categoriesData', ['resetDataCategories']),
    ...mapActions('paymentsData', ['resetDataPayments']),

    /** Funcion para Procesar Login con la API */
    async signIn() {

      this.emailCheck = requiredFields(this.fLogin.email, 'en');
      if (this.emailCheck == '') {
        this.emailCheck = emailFields(this.fLogin.email, 'en');
      }
      this.passwordCheck = requiredFields(this.fLogin.password, 'en');

      if (this.emailCheck == '' && this.passwordCheck == '') {
        await this.login(this.fLogin);
      }
    },

    /** Funcion para redirigir a la vista de registro (Solo disponible para el local por los momentos) */
    signUp() {
      this.$router.push(`/registers/FREE`);
    },

    /** Funcion para cambiar el modal para resetear la clave de un usaurio */
    changeModalResetPassword(){
      this.emailToReset = '';
      this.modalResetPassword = !this.modalResetPassword;
    },
    
    /** Funcion para solicitar el cambio de clave de un usuario */
    async resetPasswordToEmail(){
      
      this.errEmailToReset = requiredFields(this.emailToReset, 'en');
      if (this.errEmailToReset == ''){
        this.errEmailToReset = emailFields(this.emailToReset, 'en');
      }

      if (this.errEmailToReset == ''){
        await this.resetPasswordToOneUser(this.emailToReset);
        if (this.errorAuth == null) {
          this.changeModalResetPassword();
        }
      }
    },
  },
  watch: {
    async loginIn() {
      if (this.loginIn) {
        this.$router.push('dashboard');
      } else {
        this.$router.push('/');
      }
    }
  },
  mounted: async function () {

    this.loadSystemBackground('');

    /** Se verifica si el ingreso al sistema lo realiza un usuario de Live 360 que ya inicio sesion en live 360 */
    const web = window.location.href;
    const r = web.split("t=");

    /** Si se envia el token en la direccion  */
    if (r.length > 1) {
      /** Se toma el token */
      let token = r[1];
      /** Se solicita el inicio de sesion con los datos de Live360 */
      await this.loginLive360({ token: token, });
    }

    this.resetDataBoards();
    this.resetDataLabels();
    this.resetDataListAndCards();
    this.resetDataPlans();
    this.resetDataRolsAndPermissions();
    this.resetDataSystemSetup();
    this.resetDataTeams();
    this.resetDataUsers();
    this.resetDataCategories();
    this.resetDataPayments();
  }
}
</script>


<style scoped>
html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

.link-footer {
  font-size: 15px;
  color: #56baed;
}

.wrapper {
  margin-top: 18vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

input[type=button],
input[type=submit],
input[type=reset] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type=button]:hover,
input[type=submit]:hover,
input[type=reset]:hover {
  background-color: #39ace7;
}

input[type=button]:active,
input[type=submit]:active,
input[type=reset]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type=password] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=password]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type=password]:placeholder {
  color: #cccccc;
}

input[type=email] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=email]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type=email]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}

.alert-danger {
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 5px;
  width: 95%;
}


.modal-mask {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40vh;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0;
}

.modal-header,
.modal-footer {
  background: #fff;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.2rem 1rem;
  border-bottom: 0px solid #fff;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 1rem 8px 1rem;
}
</style>
