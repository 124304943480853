<template>
  <div id="cont360"
    class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar closed-sidebar-mobile"
    :style="`
    background-image: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(${systemBackground});
    background-position:center center;
    background-size:cover;
    background-repeat:space;
    z-index:-1;`">
    <router-view />

    <!-- Modal para mostrar el modal de los terminos del sistema -->
    <div v-if="modalTerms">
      <ModalTerms />
    </div>
    <!-- Modal para mostrar el modal de los terminos del sistema -->
  </div>
</template>


<script>
import ModalTerms from '@/components/users/ModalTerms.vue';
import { mapState, mapActions } from 'vuex';
    export default {
      name: 'App',
      components: {
        ModalTerms,
      },
      computed: {
        ...mapState('auth', ['userInfo', 'loginIn', 'modalTerms']),
        ...mapState('system', ['systemBackground']),
      },
      methods:{
        ...mapActions('auth', ['changeModalTerms']),
      },
      mounted: function(){ 
        let menu = document.getElementById('cont360');
        menu.className = 'app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar closed-sidebar-mobile'; 
      },
      watch: {
        userInfo() {
          if(this.userInfo.terms == 0) {
            this.changeModalTerms();
          }
        },
      }       
    }
</script> 


<style>
  @import 'assets/main.87c0748b313a1dda75f5.css';

  @media (min-width: 300px){
    .margen-footer{
      margin-left: 0vw;
    }
    .modal-width2{
      max-width: 95vw !important;
    }
  }
  @media (min-width: 375px){
    .margen-footer{
      margin-left: 0vw;
    }
    .modal-width2{
      max-width: 95vw !important;
    }
  }
  @media (min-width: 576px){
    .margen-footer{
      margin-left: 0vw;
    }
    .modal-width2{
      max-width: 95vw !important;
    }
  }
  @media (min-width: 768px){
    .margen-footer{
      margin-left: 20vw;
    }
    .modal-width2{
      max-width: 600px !important;
    }
    .movil-show{
      display: none;
    } 
  }
  @media (min-width: 992px){
    .margen-footer{
      margin-left: 35vw;
    }
    .modal-width2{
      max-width: 600px !important;
    }
    .movil-show{
      display: none;
    }
  }
  @media (min-width: 1200px){
    .margen-footer{
      margin-left: 35vw;
    }
    .modal-width2{
      max-width: 600px !important;
    }
    .movil-show{
      display: none;
    }
  }
  .container-princ{
    position: fixed; 
    height: 100vh; 
    width: 100vw;
  }
  .content-list{
    max-height: 60vh;
    overflow-x: hidden;
  }
  .app-page-title {
    padding: 15px;
    margin: -30px -30px 10px;
    position: relative;
  }
  .container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 1% !important;
    padding-left: 1% !important;
    margin-right:auto;
    margin-left:auto;
  }

  /* Works on Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: silver aliceblue;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: aliceblue;
  }

  *::-webkit-scrollbar-thumb {
    background-color: silver;
    border-radius: 20px;
    border: 3px solid aliceblue;
  }

  .fixed-footer .app-footer .app-footer__inner{
    margin-left:0px;
    box-shadow:0.3rem -0.46875rem 2.1875rem transparent,0.3rem -0.9375rem 1.40625rem transparent,0.3rem -0.25rem 0.53125rem transparent,0.3rem -0.125rem 0.1875rem transparent;
  }
  #hr {
    /* margin-top: 1rem; */
    /* margin-bottom: 1rem; */
    border: 0;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: 0px;
    border-top: 1px solid rgba(0,0,0,0.1);
  }
  .borde{
    border: 1px solid rgb(163, 1, 28);
  }
  .fc .fc-scrollgrid {
    border-collapse: separate;
    border-right-width: 0;
    border-bottom-width: 0;
    background-color: rgb(243, 239, 239);
  }
  .fc-h-event .fc-event-title {
    display: inline-block;
    vertical-align: top;
    left: 0;
    right: 0;
    max-width: 90%;
    overflow-x: hidden;
    font-size: 12px;
    font-weight: bold;
  }
  .cardView{
    height: 30px;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: rgba(192, 164, 8, 0.8);
    background-color: var(--fc-today-bg-color, rgba(231, 198, 8, 0.8));
  }
  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    position: relative;
    min-height: 45px;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .event-style{
    height: 1.2rem;
    padding: 0px;
  }
  .danger-text {
    color: red;
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }
    
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
</style>
