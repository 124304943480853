<template>
  <div class="col-md-12" :style="{'margin-top':'1.5rem'}" @click="closeEmojisPicket">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <i class="fas fa-comments"> </i> 
          {{ t('modalsNotesData.note', { }, { locale: lang }) }}
        </h5>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('add:Notes', userAccess.permissions))" :style="{'text-align':'right', 'position':'relative'}">

        <div class="btn-actions-pane-right actions-icon-btn" :style="{'margin-right':'5rem'}">
          <div id="alfaArea" class="btn-group dropdown" :title="t('button.emojis', { }, { locale: lang })">
            <button type="button" aria-haspopup="true" aria-expanded="false" class="btn-icon btn-icon-only btn btn-link" @click="showAlfa = !showAlfa;">
              <i class="lnr-mustache btn-icon-wrapper"></i>
            </button>
            <div tabindex="-1" role="menu" aria-hidden="true" :style="{'height':'18rem'}" :class="`dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu ${showAlfa ? 'show':'hidden'}`">
              <emoji-picker class="light" id="emojisAlfa"></emoji-picker>
            </div>
          </div>

          <div class="btn-group dropdown" :title="t('button.mention', { }, { locale: lang })">
            <button type="button" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" class="btn-icon btn-icon-only btn btn-link">
              <i class="fas fa-user-plus btn-icon-wrapper"></i>
            </button>
            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu">
              <button type="button" tabindex="0" class="dropdown-item" v-for="(user, index) in userMentions" :key="index" @click="addMentionToNote(user.user_name, false)">
                {{ user.user_name }}
              </button>
            </div>
          </div>
        </div>

      </div>

      <div v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('add:Notes', userAccess.permissions))" class="col-md-12">
        <div class="input-group mb-3">
          <textarea rows="1" class="form-control autosize-input" :style="{'height':'50px'}" v-model="newNote"></textarea>
          <div class="input-group-append">
            <button class="btn btn-outline-primary btn-sm" type="button" @click="addNewNote()" :title="t('modalsNotesData.addNote', { }, { locale: lang })">
              <i :style="{'font-size':'1.8rem'}" class="fas fa-save"></i>
            </button>
            <button class="btn btn-outline-danger btn-sm" type="button" @click="newNote='';" :title="t('button.delete', { }, { locale: lang })">
              <i :style="{'font-size':'1.8rem'}" class="fas fa-eraser"></i>
            </button>
          </div>
        </div>

      </div>

      <div class="col-md-12" v-for="(note, index) in cardItem.notes" :key="note.id" @mouseenter="note.visibility = true" @mouseleave="note.visibility = false">
        <div class="row">
          <div v-show="!note.editObjet" class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <b>{{ note.user_data.name }}</b>,
                <a class="let-peq">{{ moment(note.createdAt).format('MM-DD-YYYY') }}</a>
              </div>
              <div class="col-md-12">
                <div class="alert alert-secondary fade show" role="alert">
                  <a :style="{'cursor':'default'}" :title="t('modalsNotesData.editNote', {}, { locale: lang })">
                    <div v-html="convertText(note.msj)"></div>
                  </a>
                  <div class="text-right" :style="{'cursor':'default'}">
                    <a v-if="((note.user_data.email == userInfo.e21) && note.visibility)" class="movil-show" :style="{'color':'blue'}" @click="editOneNote(note)">
                      {{ t('button.edit', { }, { locale: lang }) }}
                    </a>
                    <a v-if="((checkSuperAdminRol(userInfo.access) || checPermissions('delete:Notes', userAccess.permissions)) && note.visibility)" class="movil-show" :style="{'color':'red', 'margin-left':'6px'}" @click="changeModalDeleteNotes(note)">
                      {{ t('button.delete', { }, { locale: lang }) }}
                    </a>
                  </div>
                  <div class="text-right" :style="{'cursor':'default'}">
                    <a v-if="((note.user_data.email == userInfo.e21) && note.visibility)" :style="{'color':'blue'}" @click="editOneNote(note)">
                      {{ t('button.edit', { }, { locale: lang }) }}
                    </a>
                    <a v-if="((checkSuperAdminRol(userInfo.access) || checPermissions('delete:Notes', userAccess.permissions)) && note.visibility)" :style="{'color':'red', 'margin-left':'6px'}" @click="changeModalDeleteNotes(note)">
                      {{ t('button.delete', { }, { locale: lang }) }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="note.editObjet" class="col-md-12">
            <div class="row">
              <div class="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                <div class="input-group">
                  <textarea rows="1" class="form-control autosize-input" :style="{'height':'60px'}" v-model="editNote"></textarea>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-outline-primary clipboard-trigger" :title="t('button.save', {}, { locale: lang })" @click="updateTheNote()">
                      <i :style="{'font-size':'1.4rem'}" class="fas fa-save"></i>
                    </button>
                    <button class="btn btn-outline-danger btn-sm" type="button" @click="editNote='';" :title="t('button.delete', { }, { locale: lang })">
                      <i :style="{'font-size':'1.4rem'}" class="fas fa-eraser"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1" :style="{'margin-top':'8px','padding-left':'0px !important'}">

                <div class="btn-actions-pane-right actions-icon-btn">
                  <div :id="`betaArea${index}`" class="btn-group dropdown" :title="t('button.emojis', { }, { locale: lang })">
                    <button type="button" aria-haspopup="true" aria-expanded="false" class="btn-icon btn-icon-only btn btn-link" @click="showBetaEmojis(index)">
                      <i class="lnr-mustache btn-icon-wrapper"></i>
                    </button>
                    <div tabindex="-1" role="menu" :style="{'height':'18rem'}" :class="`dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu ${showBeta[index] ? 'show':'hidden'}`">
                      <emoji-picker class="light" :id="'emojisBeta'+note.id"></emoji-picker>
                    </div>
                  </div>

                  <div class="btn-group dropdown" :title="t('button.mention', { }, { locale: lang })">
                    <button type="button" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" class="btn-icon btn-icon-only btn btn-link">
                      <i class="fas fa-user-plus btn-icon-wrapper"></i>
                    </button>
                    <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu">
                      <button type="button" tabindex="0" class="dropdown-item" v-for="(user, index) in userMentions" :key="index" @click="addMentionToNote(user.user_name, true)">
                        {{ user.user_name }}
                      </button>
                    </div>
                  </div>

                  <div class="btn-group dropdown" :title="t('button.close', {}, { locale: lang })">
                    <button type="button" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" class="btn-icon btn-icon-only btn btn-link" @click="editOneNote()">
                      <i class="fas fa-times btn-icon-wrapper"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div v-if="modalDeleteNotes">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalsNotesData.deleteNote', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalDeleteNotes()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <h6>{{ t('modalsNotesData.deleteNoteMsj', { }, { locale: lang }) }}</h6>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deleteTheNote()">
                  {{ t('button.delete', { }, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalDeleteNotes()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import twitter from 'twitter-text';
import moment from 'moment';
import { checkError401, iniciales, checkSuperAdminRol, checPermissions, filterUserAccessByTeam, convertText } from '@/utility/functions';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: 'NotesSection',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      iniciales,
      moment,
      checkSuperAdminRol,
      checPermissions,
      convertText,
    };
  },
  data: function () {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      newNote: '',
      itemNote: {},
      editNote: '',
      modalDeleteNotes: false,
      userMentions: [],
      showAlfa: false,
      showBeta: [],
      betaActive: 0,
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang']),
    ...mapState('listsAndCards', ['cardItemId', 'cardItem', 'errorLists']),
    ...mapState('boardsData', ['boardItem']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('listsAndCards', ['addNewNoteInCard', 'updateNoteInCard', 'deleteNoteInCard']),

    /** Funcion para agregar una nueva nota */
    async addNewNote(){
      if(this.newNote != ''){

        let mentions = twitter.extractMentions(this.newNote);
        let r = [];
        for (let i = 0; i < mentions.length; i++) {
          for (let j = 0; j < this.userMentions.length; j++) {
            if(mentions[i] == this.userMentions[j].user_name) {
              r.push(this.userMentions[j].user_email)
            }   
          }
        }
        await this.addNewNoteInCard({
          msj: this.newNote,
          card_id: this.cardItemId,
          mentions: r,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.newNote = '';
        }
      }
    },


    /** Funcion para mostrar la nota a editar */
    editOneNote(item = {}){
      if(Object.entries(this.itemNote).length > 0){
        this.itemNote.editObjet = false;
        this.editNote = '';
      }
      this.itemNote = item;
      this.itemNote.editObjet = true;
      this.editNote = item.msj;
      for (let i = 0; i < this.showBeta.length; i++) {
        this.showBeta[i] = false;
      }
      this.betaActive = 0;
    },

    
    /** Funcion para editar una nota */
    async updateTheNote(){
      if (Object.entries(this.itemNote).length > 0 && this.editNote != ''){

        let mentions = twitter.extractMentions(this.editNote);
        let r = [];
        for (let i = 0; i < mentions.length; i++) {
          for (let j = 0; j < this.userMentions.length; j++) {
            if(mentions[i] == this.userMentions[j].user_name) {
              r.push(this.userMentions[j].user_email)
            }   
          }
        }
        
        await this.updateNoteInCard({
          id: this.itemNote.id,
          msj: this.editNote,
          mentions: r,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.editOneNote();
        }
      }
    },


    /** Funcion para abrir modal Eliminar notas */
    changeModalDeleteNotes(item = {}){
      this.itemNote = item;
      this.modalDeleteNotes = !this.modalDeleteNotes;
    },


    /** Funcion para eliminar una nota */
    async deleteTheNote(){
      if (Object.entries(this.itemNote).length > 0) {
        await this.deleteNoteInCard(this.itemNote.id);
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.itemNote = {};
          this.modalDeleteNotes = false;
        }
      }
    },


    /** Añadir mencion a un usuario a la nota */
    addMentionToNote(user, tp) {
      if(!tp) {
        this.newNote = `${this.newNote}@${user}`;
      } else {
        this.editNote = `${this.editNote}@${user}`;
      }
    },


    checkInArray(value) {
      for (let i = 0; i < this.userMentions.length; i++) {
        if(this.userMentions[i].user_email == value) {
          return true;
        }
      }
      return false;
    },

    addTotext(emoji, tp) {
      if(tp) {
        this.newNote = `${this.newNote}${emoji}`;
      } else {
        this.editNote = `${this.editNote}${emoji}`;
      }
      
    },

    showBetaEmojis(i) {
      this.showBeta[i] = !this.showBeta[i];
      this.betaActive = this.betaActive == i ? 0 : i;
    },

    closeEmojisPicket(e) {
      if (!document.getElementById('alfaArea').contains(e.target)) {
        this.showAlfa = false;
      }

      if(this.betaActive != 0) {
        if (!document.getElementById(`betaArea${this.betaActive}`).contains(e.target)) {
          this.showBeta[this.betaActive] = !this.showBeta[this.betaActive];
          this.betaActive =  0;
        }
      }
    },

  },
  mounted: async function () {

    this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.cardItem.team_id);

    for (let i = 0; i < this.boardItem.members.length; i++) {
      const item = this.boardItem.members[i];
      if(item.type == 'user'){

        if(!this.checkInArray(item.user_email)) {
          this.userMentions.push({
            user_email: item.user_email,
            user_name: `${item.user_name.replace(/\s+/g, '')}`,
          });
        }
      } else {
        item.group.forEach(it => {
          if(!this.checkInArray(it.email)) {
            this.userMentions.push({
              user_email: it.email,
              user_name: `${it.name.replace(/\s+/g, '')}`,
            });
          }
        })
      }
    }

    document.getElementById('emojisAlfa').addEventListener('emoji-click', event => this.addTotext(event.detail.unicode, true));

    for (let i = 0; i < this.cardItem.notes.length; i++) {
      document.getElementById('emojisBeta'+this.cardItem.notes[i].id).addEventListener('emoji-click', event => this.addTotext(event.detail.unicode, false));
      this.showBeta.push(false);
    }
    

  }
}
</script>


<style scoped>
.let-peq {
  font-size: 10px;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 0.3rem;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: .25rem;
}

emoji-picker {
  height: 17rem;
  --input-font-color: #010101;
  --num-columns: 6;
}
</style>