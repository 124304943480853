import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';
import toastr from 'toastr';


/** Notificacion Exito */
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};


/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};

const state = () => ({
  loading: false,
  billing: [],
  paymentHistory: [],
  teamToChangePlan: {},
  errorPayments: null,
});


const actions = {

  /** Reset data */
  resetDataPayments({ commit }) {
    try {

      commit('resetDataPaymentsSuccess');

    } catch (err) {

      console.log(err);
    }
  },


  /** Action para procesar Get Historico de pagos de un usuario */
  async paymentHistoryData({ commit }) {
    try {

      commit('actionPaymentsDataBegin');

      let response = await DataService.get(`/payments/list/${getItem('e21')}`);

      console.log(response.data);

      await commit('paymentHistoryDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionPaymentsDataErr', err);
    }
  },


  /** Obtener archivo para descargar una factura de un pago */
  async downloadPaymentsInvoice({ commit }, data) {
    try {

      await commit('actionPaymentsDataBegin');

      const query = await DataService.getFile(`/payments/invoice/${data}`);

      console.log(query)

      await commit('downloadPaymentsInvoiceSuccess');

      const r = new Blob([query.data], { type: query.data.type });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(r);
      link.download = 'invoice.pdf';
      link.click();

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionPaymentsDataErr', err);
    }
  },


  /** Action para procesar Get billing data (Tarjetas de credito) */
  async getBillingsData({ commit }) {
    try {

      commit('actionPaymentsDataBegin');

      let response = await DataService.get(`/payments/cards/${getItem('e21')}`);

      console.log(response.data);

      await commit('getBillingsDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionPaymentsDataErr', err);
    }
  },


  /** Action para procesar add nueva Tarjetas de credito */
  async registerNewBilling({ commit }, data) {
    try {

      commit('actionPaymentsDataBegin');

      let response = await DataService.post('/payments/cards', data);

      console.log(response.data.payload);

      await commit('registerNewBillingSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('actionPaymentsDataErr', err);
    }
  },


  /** Action para procesar eliminar una Tarjeta de credito */
  async deleteBillingsData({ commit }, data) {
    try {

      await commit('actionPaymentsDataBegin');

      let response = await DataService.delete(`/payments/cards/${getItem('pId')}/${data}`);

      console.log(response.data.payload);

      commit('deleteBillingsDataSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionPaymentsDataErr', err);
    }
  },


  /** Action para asignar valor a la varibale de team al cual se le va a hacer cambio de plan */
  async assignTeamForPlanChange({ commit }, team) {
    try {

      commit('assignTeamForPlanChangeSuccess', team);

    } catch (err) {

      console.log(err);
    }
  },


};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};