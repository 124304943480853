<template>
  <div>
    <Header />
    <div class="app-main">
      <Sidebar />
      {{ closeMenu() }}
      <div class="app-main__outer" @mouseenter="closeMenu()" :style="{'background-color':'white'}">
        <div class="app-main__inner">
          <div class="app-page-title" :style="{ 'background': 'rgba(238, 233, 233, 0.45)' }">
            <!-- Header del Contenido  -->
            <div class="page-title-wrapper row">
              <!-- Detalles del Tablero -->
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="page-title-heading">
                  <div class="" :style="{'margin-right':'5px'}">
                    <img src="@/assets/Icon-1B.png" alt="">
                  </div>
                  <div>
                    <h4>{{ t('sidebarData.systemSettings', {}, { locale: lang }) }}</h4>
                  </div>
                </div>
              </div>
              <!-- Detalles del Tablero -->

              <!-- Grupo # 1 de Opciones -->
              <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                <div class="page-title-actions">

                  <!-- Registrar usuarios -->
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('add:Users', userInfo.access).length > 0)" type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary"
                    :title="t('headerData.registerUsers', {}, { locale: lang })" data-placement="bottom" @click="changeModalAddEditNewUser()">
                    <i class="fas fa-plus-circle style-1icon"></i>
                  </button>

                  <!-- Registrar grupos de usuarios -->
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:UserGroups', userInfo.access).length > 0)" type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary"
                    :title="t('headerData.userGroups', {}, { locale: lang })" data-placement="bottom" @click="changeModalUserGroups()">
                    <i class="fas fa-users style-1icon"></i>
                  </button>                  

                  <!-- Gestionar permisos --->
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:Permissions', userInfo.access).length > 0)" type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary"
                    :title="t('headerData.permissions', {}, { locale: lang })" data-placement="bottom" @click="changeModalPermissions()">
                    <i class="fas fa-link style-1icon"></i>
                  </button>

                  <!-- Gestionar Roles -->
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:Rols', userInfo.access).length > 0)" type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary" 
                    title="Rols" data-placement="bottom" @click="changeModalRols()">
                    <i class="fas fa-cogs style-1icon"></i>
                  </button>

                  <!-- Gestionar Planes-->
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:Plans', userInfo.access).length > 0)" type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary" 
                    :title="t('headerData.plan', {}, { locale: lang })" data-placement="bottom" @click="changeModalPlans()">
                    <i class="fas fa-shopping-cart style-1icon"></i>
                  </button>
                  
                  <!-- Gestionar Teams -->
                  <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:Teams', userInfo.access).length > 0)" type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary" 
                    :title="t('headerData.teams', {}, { locale: lang })" data-placement="bottom" @click="changeModalTeams()">
                    <i class="fas fa-address-card style-1icon"></i>
                  </button>

                  <!-- Gestionar Categorias -->
                  <button  v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('view:Categories', userInfo.access).length > 0)" type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary" 
                    :title="t('headerData.categories', {}, { locale: lang })" data-placement="bottom" @click="changeModalCategories()">
                    <i class="fas fa-indent style-1icon"></i>
                  </button>
                  
                </div>
              </div>
              <!-- Grupo # 1 de Opciones -->

            </div>
            <!-- Header del Contenido  -->
          </div>

          <!-- Container Normal -->
          <div class="container" :style="{'max-width':'85vw !important', 'min-height':'90vh !important', 'margin-top':'3vh'}">
            <table id="userTable" class="table table-striped table-bordered cell-border compact stripe">
              <thead class="text-center">
                <tr>
                  <th>{{ t('headerData.name', {}, {locale: lang}) }}</th>
                  <th>{{ t('headerData.email', {}, {locale: lang}) }}</th>
                  <th>{{ t('headerData.teams', {}, {locale: lang}) }}</th>
                  <th>Rol</th>
                  <th>{{ t('headerData.plan', {}, {locale: lang}) }}</th>
                  <th>{{ t('headerData.status', {}, {locale: lang}) }}</th>
                  <th :style="{'width':'15%'}">{{ t('headerData.options', {}, {locale: lang}) }}</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="user in users" :key="user.id">
                  <td>{{ user.name }} {{ user.surname }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ showInfoUsers(user.access, 'team') }}</td>
                  <td>{{ showInfoUsers(user.access, 'rol') }}</td>
                  <td>{{ showInfoUsers(user.access, 'plan') }}</td>
                  <td>{{ t(user.status ? 'headerData.active' : 'headerData.inactive', { }, { locale: this.lang }) }}</td>
                  <td :style="{'width':'15%'}">

                    <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('edit:Users', userInfo.access).length > 0)"
                      @click="changeModalAddEditNewUser(user)" type="button" :title="t('headerData.updatedRols', {}, { locale: lang })"
                      class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important', 'margin-left':'3px'}">
                      <i :style="{'font-size':'16px'}" class="fas fa-edit"></i>
                    </button>

                    <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('edit:UsersTeamRols', userInfo.access).length > 0)"
                      @click="changeModalChangeTeamRolsToUsers(user)" type="button" :title="t('headerData.updatedRols', {}, { locale: lang })"
                      class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important', 'margin-left':'3px'}">
                      <i :style="{'font-size':'18px'}" class="fas fa-users"></i>
                    </button>

                    <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('resetPassword:Users', userInfo.access).length > 0)"
                      @click="changeModalResetPasswordUser(user)" type="button" :title="t('modalChangePassword.resetPassword', {}, { locale: lang })"
                      class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important', 'margin-left':'3px'}">
                      <i :style="{'font-size':'18px'}" class="fas fa-lock"></i>
                    </button>

                    <button v-if="(checkSuperAdminRol(userInfo.access) || getTeamsByPermissions('delete:Users', userInfo.access).length > 0)"
                      @click="changeModalDeleteUser(user)" type="button" :title="t('modalChecklistData.deleteUser', {}, {locale: lang})"
                      class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important', 'margin-left':'3px'}">
                      <i :style="{ 'font-size': '18px', 'color':'#E72B1F'}" class="fas fa-trash"></i>
                    </button>

                    <button v-if="userInfo.e21 == 'brody027@gmail.com'"
                      @click="resetPasswordToOneUserJhor(user.email)" type="button" :title="t('modalChangePassword.resetPassword', {}, { locale: lang })"
                      class="btn-icon btn-icon-only btn btn-link" :style="{'padding':'.10rem .10rem !important', 'margin-left':'3px'}">
                      <i :style="{'font-size':'18px'}" class="fas fa-lock"></i>
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Container Normal -->

        </div>
      </div>
    </div>


    <!-- Modal para registrar o editar un usuario -->
    <div v-if="modalAddEditNewUser">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ t(forUser.new ? 'headerData.registerUsers' : 'headerData.updatesUsers', { }, { locale: lang }) }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalAddEditNewUser()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center" :style="{ 'max-height': '400px', 'overflow-x': 'hidden' }">
    
                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.name', { }, { locale: lang }) }}</label>
                        <input name="name" id="name" placeholder="" type="text" class="form-control form-control-sm"  v-model="forUser.name" required>
                        <span class="helper-text danger-text">{{ forUser.errName }}</span>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.surname', { }, { locale: lang }) }}</label>
                        <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                          v-model="forUser.surname" required>
                        <span class="helper-text danger-text">{{ forUser.errSurname }}</span>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.phone', { }, { locale: lang }) }}</label>
                        <input name="phone" id="phone" placeholder="" type="text" class="form-control form-control-sm"
                          v-model="forUser.phone" required>
                        <span class="helper-text danger-text">{{ forUser.errPhone }}</span>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.email', { }, { locale: lang }) }}</label>
                        <input name="email" id="email" placeholder="" type="text" class="form-control form-control-sm"
                          v-model="forUser.email" required>
                        <span class="helper-text danger-text">{{ forUser.errEmail }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.address', {}, { locale: lang }) }}</label>
                        <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                          v-model="forUser.address" required>
                        <span class="helper-text danger-text">{{ forUser.errAddress }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.state', {}, { locale: lang }) }}</label>
                        <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                          v-model="forUser.state" required>
                        <span class="helper-text danger-text">{{ forUser.errState }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.city', {}, { locale: lang }) }}</label>
                        <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                          v-model="forUser.city" required>
                        <span class="helper-text danger-text">{{ forUser.errCity }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.zipCode', {}, { locale: lang }) }}</label>
                        <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                          v-model="forUser.zip_code" required>
                        <span class="helper-text danger-text">{{ forUser.errZipCode }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUser.new == true">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.teams', { }, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUser.team" @change="loadRolsByTeams()">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="t in teamsFilters" :key="t.id" :value="t.id">{{ t.name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errTeam }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUser.new == true">
                      <div class="position-relative form-group">
                        <label for="" class="">Rols</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUser.rol">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="r in rolsFilters" :key="r.id" :value="r.id">{{ r.display_name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errRol }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.status', { }, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUser.status">
                          <option value="">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option value="1">{{ t('headerData.active', { }, { locale: this.lang }) }}</option>
                          <option value="0">{{ t('headerData.inactive', { }, { locale: this.lang }) }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errStatus }}</span>
                      </div>
                    </div>
                  </div>
    
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm" @click="registerEditUser()">
                    {{ t(forUser.new ? 'button.add' : 'button.save', { }, { locale:lang }) }}
                  </button>
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalAddEditNewUser()">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para registrar un usuario -->


    <!-- Modal para modificar el team y rol de un usuario -->
    <div v-if="modalChangeTeamRolsUser">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ t('headerData.updatesUsers', { }, { locale: lang }) }} : {{ `${userItem.name} ${userItem.surname}` }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalChangeTeamRolsToUsers()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center scroll-area-lg" :style="{'overflow':'auto'}">

                  <div class="row">

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <button type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-primary"
                          :title="t('button.add', {}, { locale: lang })" data-placement="bottom" @click="addTeamRols()">
                          <i class="fas fa-plus-circle style-1icon"></i>
                        </button>

                        <button type="button" data-toggle="tooltip" class="btn-shadow mr-3 btn btn-danger"
                          :title="t('button.delete', {}, { locale: lang })" data-placement="bottom" @click="deleteTeamRols()">
                          <i class="fas fa-minus-circle style-1icon"></i>
                        </button>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 1">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.teams', { }, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.teams[0]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="t in teamsFilters" :key="t.id" :value="t.id">{{ t.name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errTeam }}</span>
                      </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 1">
                      <div class="position-relative form-group">
                        <label for="" class="">Rols</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.rols[0]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="r in rolsFilters" :key="r.id" :value="r.id">{{ r.display_name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errRol }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 2">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.teams', { }, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.teams[1]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="t in teamsFilters" :key="t.id" :value="t.id">{{ t.name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errTeam }}</span>
                      </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 2">
                      <div class="position-relative form-group">
                        <label for="" class="">Rols</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.rols[1]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="r in rolsFilters" :key="r.id" :value="r.id">{{ r.display_name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errRol }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 3">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.teams', { }, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.teams[2]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="t in teamsFilters" :key="t.id" :value="t.id">{{ t.name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errTeam }}</span>
                      </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 3">
                      <div class="position-relative form-group">
                        <label for="" class="">Rols</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.rols[2]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="r in rolsFilters" :key="r.id" :value="r.id">{{ r.display_name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errRol }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 4">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.teams', { }, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.teams[3]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="t in teamsFilters" :key="t.id" :value="t.id">{{ t.name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errTeam }}</span>
                      </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 4">
                      <div class="position-relative form-group">
                        <label for="" class="">Rols</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.rols[3]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="r in rolsFilters" :key="r.id" :value="r.id">{{ r.display_name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errRol }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 5">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.teams', { }, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.teams[4]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="t in teamsFilters" :key="t.id" :value="t.id">{{ t.name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errTeam }}</span>
                      </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 5">
                      <div class="position-relative form-group">
                        <label for="" class="">Rols</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.rols[4]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="r in rolsFilters" :key="r.id" :value="r.id">{{ r.display_name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errRol }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 6">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.teams', { }, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.teams[5]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="t in teamsFilters" :key="t.id" :value="t.id">{{ t.name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errTeam }}</span>
                      </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 6">
                      <div class="position-relative form-group">
                        <label for="" class="">Rols</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.rols[5]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="r in rolsFilters" :key="r.id" :value="r.id">{{ r.display_name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errRol }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 7">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.teams', { }, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.teams[6]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="t in teamsFilters" :key="t.id" :value="t.id">{{ t.name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errTeam }}</span>
                      </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 7">
                      <div class="position-relative form-group">
                        <label for="" class="">Rols</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.rols[6]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="r in rolsFilters" :key="r.id" :value="r.id">{{ r.display_name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errRol }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 8">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('headerData.teams', { }, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.teams[7]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="t in teamsFilters" :key="t.id" :value="t.id">{{ t.name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errTeam }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="forUserTeamRol.cant >= 8">
                      <div class="position-relative form-group">
                        <label for="" class="">Rols</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="forUserTeamRol.rols[7]">
                          <option :value="0">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                          <option v-for="r in rolsFilters" :key="r.id" :value="r.id">{{ r.display_name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ forUser.errRol }}</span>
                      </div>
                    </div>
                                        
                  </div>

                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm" @click="updateTeamsAndRolsToOneUsers()">
                    {{ t('button.save', { }, { locale:lang }) }}
                  </button>
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                    @click="changeModalChangeTeamRolsToUsers()">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para modificar el team y rol de un usuario -->


    <!-- Modal para eliminar un usuario -->
    <div v-if="modalDeleteUser">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-sm" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ t('modalChecklistData.deleteUser', { }, { locale: lang }) }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalDeleteUser()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <h6>{{ t('modalChecklistData.deleteUserMsj', { }, { locale: lang }) }}</h6>
                      <b>{{ userItem.name }} {{ userItem.surname }}</b>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger btn-sm" @click="deleteOneUser()">
                    {{ t('button.delete', {}, {locale: lang}) }}
                  </button>
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                    @click="changeModalDeleteUser()">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para eliminar un usuario -->


    <!-- Modal para aceptar reiniciar la clave de un usuario -->
    <div v-if="modalResetPasswordUser">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-sm" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ t('modalChangePassword.resetPassword', { }, { locale: lang }) }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalResetPasswordUser()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <h6>{{ t('modalChangePassword.resetMsm', { }, { locale: lang }) }}</h6>
                      <b>{{ userItem.name }} {{ userItem.surname }}</b>
                      <br>
                      {{ userItem.email }}
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm" @click="resetPasswordToOneUserf()">
                    {{ t('button.save', {}, {locale: lang}) }}
                  </button>
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                    @click="changeModalResetPasswordUser()">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para aceptar reiniciar la clave de un usuario -->



    <!-- Modal para los grupos de usuarios -->
    <div v-if="modalUsersGroups">
      <ModalManageUserGroups />
    </div>
    <!-- Modal para los grupos de usuarios -->


    <!-- Modal para los roles -->
    <div v-if="modalRols">
      <ModalManageRols />
    </div>
    <!-- Modal para los roles -->


    <!-- Modal para los permisos -->
    <div v-if="modalPermissions">
      <ModalManagePermissions />
    </div>
    <!-- Modal para los permisos -->


    <!-- Modal para los teams -->
    <div v-if="modalTeams">
      <ModalManageTeams />
    </div>
    <!-- Modal para los teams -->


    <!-- Modal para los plans -->
    <div v-if="modalPlans">
      <ModalManagePlans />
    </div>
    <!-- Modal para los plans -->

    <!-- Modal para las categorias de los tableros -->
    <div v-if="modalcategories">
      <ModalManageCategories />
    </div>
    <!-- Modal para las categorias de los tableros -->

  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { closeMenu, showInfoData, checkError401, checkSuperAdminRol, checPermissions, getTeamsByPermissions, getRolsByPermissions } from '@/utility/functions';
import { requiredFields, emailFields, numbersType } from '@/utility/validations';
import Header from '@/components/template/Header.vue';
import Sidebar from '@/components/template/Sidebar.vue';
import ModalManageRols from '@/components/systemConfig/ModalManageRols.vue';
import ModalManagePermissions from '@/components/systemConfig/ModalManagePermissions.vue';
import ModalManageTeams from '@/components/systemConfig/ModalManageTeams.vue';
import ModalManagePlans from '@/components/systemConfig/ModalManagePlans.vue';
import ModalManageCategories from '@/components/systemConfig/ModalManageCategories.vue';
import ModalManageUserGroups from '@/components/systemConfig/ModalManageUserGroups.vue';
import 'datatables.net-bs5';
import $ from 'jquery'; 

export default {
  name: 'SystemConfig',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      closeMenu,
      showInfoData,
      checkSuperAdminRol,
      checPermissions,
      getTeamsByPermissions
    };
  },
  components: {
    Header,
    Sidebar,
    ModalManageRols,
    ModalManagePermissions,
    ModalManageTeams,
    ModalManagePlans,
    ModalManageCategories,
    ModalManageUserGroups
  },
  data() {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      modalAddEditNewUser: false,
      userItem: {},
      forUser: {
        new: true,
        name: '',
        errName: '',
        surname: '',
        errSurname: '',
        phone: '',
        errPhone: '',
        email: '',
        errEmail: '',
        address: '',
        errAddress: '',
        state: '',
        errState: '',
        city: '',
        errCity: '',
        zip_code: '',
        errZipCode: '',
        rol: 0,
        errRol: '',
        team: 0,
        errTeam: '',
        status: '',
        errStatus: '',
      },
      forUserTeamRol: {
        id: 0,
        cant: 0,
        teams: [],
        rols: [],
      },
      forUserTeamRolOriginal: {
        id: 0,
        cant: 0,
        teams: [],
        rols: [],
      },
      modalChangeTeamRolsUser: false,
      modalChangeStatus: false,
      modalChangeLimit: false,
      modalDeleteUser: false,
      rolsFilters: [],
      teamsFilters: [],
      modalResetPasswordUser: false,
    };
  },
  computed: {
    ...mapState('auth', ['userInfo', 'errorAuth']),
    ...mapState('system', ['lang', 'modalRols', 'modalPermissions', 'modalTeams', 'modalUsersGroups']),
    ...mapState('usersData', ['users', 'errorUsers']),
    ...mapState('rolsData', ['rols', 'errorRols']),
    ...mapState('teamsData', ['teams', 'errorTeams']),
    ...mapState('plansData', ['modalPlans']),
    ...mapState('categoriesData', ['modalcategories']),
  },
  methods: {
    ...mapActions('auth', ['logOut', 'resetPasswordToOneUser', 'resetPasswordToOneUserJhor']),
    ...mapActions('system', ['changeModalRols', 'changeModalPermissions', 'changeModalTeams', 'loadSystemBackground', 'changeModalUserGroups']),
    ...mapActions('usersData', ['getUsersData', 'registerUsers', 'updateUsersData', 'deleteUsersData', 'updateUsersTeamsRolToUsers']),
    ...mapActions('rolsData', ['getRolsData']),
    ...mapActions('teamsData', ['getTeamsData', 'checkTeamsLimitsUsers']),
    ...mapActions('plansData', ['changeModalPlans']),
    ...mapActions('categoriesData', ['changeModalCategories']),
    /** Funcion para iniciar la table de datos */
    table() {
        this.$nextTick(() => {
          $('#userTable').DataTable();
        });
    },


    /** Funcion para mostrar los team y roles de los usuarios */
    showInfoUsers(access, tp) {

      let r = '';

      if (access != undefined) {
        for (let i = 0; i < access.length; i++) {

          if (tp == 'team') {

            r = r + access[i].team.name;

          } else if (tp == 'rol') {

            r = r + access[i].rol.display_name;

          } else {

            r = r + access[i].plan.name;
          }
          if (i + 1 < access.length) {
            r = r + ' - ';
          }
        }
      }
      
      return r;
    },


    /** Funcion para cambiar el estado del modal para registrar un nuevo usuario */
    async changeModalAddEditNewUser(item = {}) {

      /** Editar datos basicos del usuario */
      if(Object.entries(item).length > 0) {

        this.forUser.new = false;
        this.forUser.name = item.name;
        this.forUser.surname = item.surname;
        this.forUser.phone = item.phone;
        this.forUser.email = item.email;
        this.forUser.address = item.address;
        this.forUser.state = item.state;
        this.forUser.city = item.city;
        this.forUser.zip_code = item.zip_code;
        this.forUser.status = item.status ? '1' : '0';
      
      /** Crear usuario */
      } else{

        this.forUser = {
          new: true,
          name: '',
          errName: '',
          surname: '',
          errSurname: '',
          phone: '',
          errPhone: '',
          address: '',
          errAddress: '',
          state: '',
          errState: '',
          city: '',
          errCity: '',
          zip_code: '',
          errZipCode: '',
          email: '',
          errEmail: '',
          rol: 0,
          errRol: '',
          team: 0,
          errTeam: '',
          status: '',
          errStatus: '',
        };

        /** Filtrar los team */
        if (checkSuperAdminRol(this.userInfo.access) || getTeamsByPermissions('add:UserFull', this.userInfo.access).length > 0) {

          this.teamsFilters = this.teams;

        } else if (getTeamsByPermissions('add:Users', this.userInfo.access).length > 0) {

          let teamsData = getTeamsByPermissions('add:Users', this.userInfo.access);
          this.teams.forEach(item => {
            if (teamsData.includes(item.id)) {
              this.teamsFilters.push(item);
            }
          });
        }        
      }
      
      this.userItem = item;
      this.modalAddEditNewUser = !this.modalAddEditNewUser;
    },


    /** Funcion para cargar los roles que se pueden agregar del team seleccionado */
    async loadRolsByTeams() {

      if(this.forUser.team != 0) {

        this.forUser.rol = 0;
        this.rolsFilters = [];

        /** Si es super administrador o si tiene permisos para añadir usuarios full */
        if (checkSuperAdminRol(this.userInfo.access) || getTeamsByPermissions('add:UserFull', this.userInfo.access).length > 0) {

          this.rolsFilters = this.rols;

          /** Se verifica la cantidad de usuarios con respecto al plan para verificar que pueda agregar nuevos usuarios diferentes a observadores */
        } else {

          let r = await this.checkTeamsLimitsUsers(this.forUser.team);

          if (r > 0) {

            this.rols.forEach(item => {
              if (item.name != 'superAdmin' && item.name != 'freeUser' && item.name != 'payUser') {
                this.rolsFilters.push(item);
              }
            });

          } else {

            this.rols.forEach(item => {
              if (item.name == 'viewersUser') {
                this.rolsFilters.push(item);
              }
            });
          }
        }
      }
    },


    /** Funcion para registrar o editar un usuario */
    async registerEditUser() {
      this.forUser.errName = requiredFields(this.forUser.name, this.lang);
      this.forUser.errSurname = requiredFields(this.forUser.surname, this.lang);
      this.forUser.errPhone = requiredFields(this.forUser.phone, this.lang);
      this.forUser.errAddress = requiredFields(this.forUser.address, this.lang);
      this.forUser.errState = requiredFields(this.forUser.state, this.lang);
      this.forUser.errCity = requiredFields(this.forUser.city, this.lang);
      this.forUser.errZipCode = requiredFields(this.forUser.zip_code, this.lang);
      this.forUser.errEmail = requiredFields(this.forUser.email, this.lang);
      if (this.forUser.errEmail == ''){
        this.forUser.errEmail = emailFields(this.forUser.email, this.lang);
      }
      this.forUser.errStatus = requiredFields(this.forUser.status, this.lang);
      
      /** Crear */
      if(this.forUser.new){

        this.forUser.errRol = numbersType(this.forUser.rol, this.lang);
        this.forUser.errTeam = numbersType(this.forUser.team, this.lang);

        if (this.forUser.errName == '' && this.forUser.errSurname == '' && this.forUser.errPhone == '' && this.forUser.errAddress == '' 
          && this.forUser.errEmail == '' && this.forUser.errRol == '' && this.forUser.errTeam == '' && this.forUser.errStatus == '' 
          && this.forUser.errState == '' && this.forUser.errCity == '' && this.forUser.errZipCode == '') {
          
          this.forUser.status = this.forUser.status == '1' ? true : false;
          await this.registerUsers(this.forUser);
          if (this.errorUsers == null) {
            this.changeModalAddEditNewUser();
          }
        }

      /** Editar */
      } else {

        if (this.forUser.errName == '' && this.forUser.errSurname == '' && this.forUser.errPhone == '' && this.forUser.errAddress == '' 
          && this.forUser.errEmail == '' && this.forUser.errStatus == '' && this.forUser.errState == '' && this.forUser.errCity == '' 
          && this.forUser.errZipCode == '') {
          
          this.forUser.status = this.forUser.status == '1' ? true : false;
          await this.updateUsersData({ ...{ id: this.userItem.id }, ...this.forUser });
          if (this.errorUsers == null) {
            this.changeModalAddEditNewUser();
          }
        }
      }
    },


    /** Funcion para cambiar el estado del modal eliminar un usuario */
    changeModalDeleteUser(item = {}) {
      this.userItem = item;
      this.modalDeleteUser = !this.modalDeleteUser;
    },


    /** Funcion para eliminar un usuario */
    async deleteOneUser() { 
      await this.deleteUsersData(this.userItem.id);
      if (checkError401(this.errorUsers)) { this.logOut(); }
      if (this.errorUsers == null) {
        this.changeModalDeleteUser();
      }
    },


    /** Funcion para cambiar el estatus del modal para aceptar reiniciar la clave de un usuario */
    changeModalResetPasswordUser(item = {}) {
      this.userItem = item;
      this.modalResetPasswordUser = !this.modalResetPasswordUser;
    },


    /** Funcion para reiniciar la clave de un usuario */
    async resetPasswordToOneUserf() {
      await this.resetPasswordToOneUser(this.userItem.email);
      if (checkError401(this.errorAuth)) { this.logOut(); }
      if (this.errorAuth == null) {
        this.changeModalResetPasswordUser();
      }
    },


    /** Funcion para cambiar el estado del modal para modificar el team y rol de un usuario */
    changeModalChangeTeamRolsToUsers(item = {}) {
      this.userItem = item;

      if(Object.entries(item).length > 0) {

        this.forUserTeamRol.id = item.id;

        item.access.forEach(ele => {
          this.forUserTeamRol.cant++;
          this.forUserTeamRol.teams.push(ele.team.id);
          this.forUserTeamRol.rols.push(ele.rol.id);
        })

        /** Filtrar los team */
        if (checkSuperAdminRol(this.userInfo.access) || getTeamsByPermissions('add:UserFull', this.userInfo.access).length > 0) {

          this.rolsFilters = this.rols;
          this.teamsFilters = this.teams;

        } else if (getTeamsByPermissions('add:Users', this.userInfo.access).length > 0) {


          let rolsDara = getRolsByPermissions('add:Users', this.userInfo.access);
          this.rols.forEach(item => {
            if (item.name != 'superAdmin' && item.name != 'freeUser') {

              if (rolsDara.includes('payUser')) {
                this.rolsFilters.push(item);
              } else {
                if (item.name != 'payUser') {
                  this.rolsFilters.push(item);
                }
              }
            }
          });

          let teamsData = getTeamsByPermissions('add:Users', this.userInfo.access);
          this.teams.forEach(item => {
            if (teamsData.includes(item.id)) {
              this.teamsFilters.push(item);
            }
          });
        }

      } else {

        this.forUserTeamRol = {
          id: 0,
          cant: 0,
          teams: [],
          rols: [],
        };
      }

      this.forUserTeamRolOriginal = this.forUserTeamRol;
      this.modalChangeTeamRolsUser = !this.modalChangeTeamRolsUser;
    },


    /** Funcion para mostar una opcion de añadir un team Rols a un usuario */
    addTeamRols() {
      if (this.forUserTeamRol.cant < 9) {
        this.forUserTeamRol.cant++;
      }
    },


    /** Funcion para ocultar una opcion de añadir un team Rols a un usuario */
    deleteTeamRols() {
      if (this.forUserTeamRol.cant > 1) {
        this.forUserTeamRol.cant--;
      }
    },


    /** Funcion para actualizar los team y roles de un usuario */
    async updateTeamsAndRolsToOneUsers() {

      let data = {
        ...this.forUserTeamRol,
        original: this.forUserTeamRolOriginal,
      }

      await this.updateUsersTeamsRolToUsers(data);
      if (checkError401(this.errorUsers)) { this.logOut(); }
      if (this.errorUsers == null) {
        this.changeModalChangeTeamRolsToUsers();
        await this.getUsersData();
      }

    }

  },
  mounted: async function () {

    this.loadSystemBackground('');

    await this.getUsersData();
    if (checkError401(this.errorUsers)) { this.logOut(); }
    if(this.errorUsers == null){
      this.table();
    }

    await this.getRolsData();
    await this.getTeamsData();
  },
}
</script> 


<style>
</style>