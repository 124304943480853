<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalsBoardsData.boardTemplates', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" aria-label="Close" @click="showModalTemplateBoard()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" :style="{ 'max-height': '65vh', 'min-height': '40vh', 'overflow-x':'hidden', 'overflow-y':'auto'}">
                <table id="tableTemplate" class="table table-striped table-bordered cell-border compact stripe">
                  <thead class="text-center">
                    <tr>
                      <th>{{ t('modalsBoardsData.title', { }, { locale: lang }) }}</th>
                      <th>{{ t('modalsBoardsData.description', {}, { locale: lang }) }}</th>
                      <th>{{ t('modalsBoardsData.templateType', {}, { locale: lang }) }}</th>
                      <th>{{ t('headerData.createdDate', {}, { locale: lang }) }}</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr v-for="btemp in templatesBoards" :key="btemp.id" @click="openBoard(btemp.id)">
                      <td>{{ btemp.title }}</td>
                      <td>{{ btemp.info.description }}</td>
                      <td>{{ t(btemp.info.tp == 1 ? 'button.private' : 'button.public', {}, { locale: lang }) }}</td>
                      <td>{{ moment(btemp.createdAt).format('MM-DD-YYYY') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="showModalTemplateBoard()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>


<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { showInfoData, checkError401, checkSuperAdminRol, getTeamsByPermissions } from '@/utility/functions';
import 'datatables.net-bs5';
import $ from 'jquery';
export default {
  name: 'ModalTemplateBoards',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      showInfoData,
      moment,
      checkSuperAdminRol,
      getTeamsByPermissions 
    };
  },
  components: {
  },
  data: function () {
    return {
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang', 'modalPermissions']),
    ...mapState('boardsData', ['templatesBoards', 'errorBoards']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['changeModalPermissions']),
    ...mapActions('boardsData', ['showModalTemplateBoard', 'getBoardsTemplatesData']),
    table() {
      this.$nextTick(() => {
        $('#tableTemplate').DataTable();
      });
    },
    openBoard(id) {
      this.$router.push(`/board/${id}`);
      this.showModalTemplateBoard();
    },

  },
  mounted: async function () {

    await this.getBoardsTemplatesData(999);
    if (checkError401(this.errorBoards)) { this.logOut(); }
    if (this.errorBoards == null) {
      this.table();
    }
  }
}
</script> 


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>