import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';
import toastr from 'toastr';


/** Notificacion Exito */
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};


/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};


const state = () => ({
  loading: false,
  users: [],
  usersFilters: [],
  plansInfo: [],
  errorUsers: null,
  uNotes: '',
});


const actions = {

  /** Reset data */
  resetDataUsers({ commit }) {
    try {

      commit('resetDataUsersSuccess');

    } catch (err) {

      console.log(err);
    }
  },


  /** Action para procesar Get data Users */
  async getUsersData({ commit }) {
    try {

      commit('actionUserDataBegin');

      let response = await DataService.get('/users/');

      console.log(response.data);

      await commit('getUsersDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserDataErr', err);
    }
  },


  /** Action para procesar Get data Users by Team ID */
  async getUsersByTeamsData({ commit }, data) {
    try {

      commit('actionUserDataBegin');

      let response = await DataService.get(`/users/teams/${data}`);

      console.log(response.data);

      await commit('getUsersDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserDataErr', err);
    }
  },


  /** Action para procesar Get data Users by Team ID */
  async getUsersByTeamsData2({ commit }, data) {
    try {

      commit('actionUserDataBegin');

      let response = await DataService.get(`/users/teams/${data}`);

      console.log(response.data);

      await commit('getUsersByTeamsDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserDataErr', err);
    }
  },


  /** Action para procesar Registro de usuarios en el sistema */
  async registerUsers({ commit }, data) {
    try {

      commit('actionUserDataBegin');

      let response = await DataService.post('users', data);

      console.log(response.data.payload);

      await commit('registerUsersSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('actionUserDataErr', err);
    }
  },


  /** Action para procesar Registro de usuarios desde la opcion de miembros del tablero */
  async registerUsersToBoardsMembers({ commit }, data) {
    try {

      commit('actionUserDataBegin');

      let response = await DataService.post('users', data);

      console.log(response.data.payload);

      await commit('registerUsersToBoardsMembersSuccess');

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('actionUserDataErr', err);
    }
  },


  /** Action para procesar actualizar usuario */
  async updateUsersData({ commit }, data) {
    try {

      await commit('actionUserDataBegin');

      let response = await DataService.put(`/users/${data.id}`, data);

      console.log(response.data.payload);

      commit('updateUsersDataSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserDataErr', err);
    }
  },


  /** Action para procesar actualizar los team y roles de usuario */
  async updateUsersTeamsRolToUsers({ commit }, data) {
    try {

      await commit('actionUserDataBegin');

      let response = await DataService.put(`/users/updateTeamsRols/${data.id}`, data);

      console.log(response.data.payload);

      commit('updateUsersDataSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserDataErr', err);
    }
  },


  /** Action para procesar eliminar usuario */
  async deleteUsersData({ commit }, data) {
    try {

      await commit('actionUserDataBegin');

      let response = await DataService.delete(`/users/${data}`);

      console.log(response.data.payload);

      commit('deleteUsersDataSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserDataErr', err);
    }
  },


  /** Action para reiniciar la clave de un usuario */
  async resetPasswordToUsers({ commit }, data) {
    try {

      await commit('actionUserDataBegin');

      let response = await DataService.put(`/users/resetPassword/${data}`);

      console.log(response.data.payload);

      commit('resetPasswordToUsersSuccess');

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserDataErr', err);
    }
  },


  /** Action para procesar actualizar contraseña */
  async updatePasswordData({ commit }, data) {
    try {

      console.log(data);

      await commit('actionUserDataBegin');

      let response = await DataService.put(`/users/resetPassword/${getItem('e21')}`, data);

      console.log(response.data.payload);

      commit('updatePasswordDataSuccess');

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserDataErr', err);
    }
  },


  /** Action para obtner la informacion de los planes del usuario */
  async getUsersPlansinfo({ commit }) {
    try {

      commit('actionUserDataBegin');

      let response = await DataService.get(`/users/plans/info/${getItem('e21')}`);

      console.log(response.data);

      await commit('getUsersPlansinfoSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserDataErr', err);
    }
  },





  /** Action para procesar Get anotaciones del usaurio */
  async getUserNotes({ commit }) {
    try {

      commit('actionUserDataBegin');

      let response = await DataService.get(`/users/notes/${getItem('u21')}`);

      console.log(response.data);

      await commit('getUserNotesSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserDataErr', err);
    }
  },


  /** Action para procesar actualizar las anotaciones del usuario */
  async updateUserNotes({ commit }, data) {
    try {

      console.log(data);

      await commit('actionUserDataBegin');

      let response = await DataService.put(`/users/notes/${getItem('u21')}`, data);

      console.log(response.data.payload);

      await commit('updateUserNotesSuccess', response.data.payload);

      //addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionUserDataErr', err);
    }
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};