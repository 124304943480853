<template>
  <div>
    <Header />
    <div class="app-main">
      <Sidebar />
      {{ closeMenu() }}
      <div class="app-main__outer" @mouseenter="closeMenu()">
        <div class="app-main__inner">
          <div class="app-page-title">
            <!-- Header del Contenido  -->
            <div class="page-title-wrapper row">
              <!-- Detalles del Tablero -->
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div class="page-title-heading">
                  <div class="" :style="{ 'margin-right':'5px' }">
                    <img src="@/assets/Icon-1B.png" alt="">
                  </div>
                  <div :style="{ 'color':'white' }">
                    <h5>{{ showInfoData(boardItem.title, lang) }}</h5>
                    <h6>{{ showInfoData(boardItem.description, lang) }}</h6>
                  </div>
                </div>
              </div>
              <!-- Detalles del Tablero -->

              <!-- Grupo # 1 de Opciones del Tablero -->
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="page-title-actions">

                  <!-- Opciones del tablero -->
                  <div class="d-inline-block dropleft margin-movil">
                      <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        class="btn-shadow dropdown-toggle btn mr-3 btn btn-primary">
                        <span class="btn-icon-wrapper pr-2 opacity-7">
                          <i class="fas fa-ellipsis-h fa-w-20"></i>
                        </span>
                      </button>
                      <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-left">
                        <ul class="nav flex-column">

                          <!-- Editar o eliminar Tablero -->
                          <li class="nav-item" v-if="(userInfo.e21 == boardItem.owner) || checkSuperAdminRol(userInfo.access) || checPermissions('edit:Boards', userAccess.permissions)">
                            <button type="button" tabindex="0" class="dropdown-item" @click="changeModalAddEditDeleteBoard({ new: false, team: boardItem.team_id })">
                              <i class="dropdown-icon ion-android-create"> </i>
                              <span> {{ t('boardData.editBoard', {}, { locale: lang }) }}</span>
                            </button>
                          </li>
                          <!-- Editar o eliminar Tablero -->

                          <!-- Duplicar Tablero -->
                          <li class="nav-item" v-if="!boardItem.template && validateCreateBoardByLimitPlam(filterBoardByTeam, userInfo.e21, userAccess.limits.boards) && ((userInfo.e21 == boardItem.owner) || (checkSuperAdminRol(userInfo.access) || checPermissions('duplicate:Boards', userAccess.permissions)))">
                            <button type="button" tabindex="0" class="dropdown-item" @click="changeModalDuplicateBoard()">
                              <i class="dropdown-icon fas fa-copy"> </i>
                              <span> {{ t('modalsBoardsData.duplicateBoard', {}, { locale: lang }) }}</span>
                            </button>
                          </li>                
                          <!-- Duplicar Tablero -->

                          <!-- Archivar o Desarchivar Tablero -->
                          <li class="nav-item" v-if="!boardItem.template && ((boardItem.owner == userInfo.e21) || (checkSuperAdminRol(userInfo.access) || checPermissions('store:Boards', userAccess.permissions)))">
                            <button type="button" tabindex="0" class="dropdown-item" @click="changeModalStoreBoard()">
                              <i class="dropdown-icon fas fa-save"> </i>
                              <span> {{ t(boardItem.stored ? 'boardData.unarchiveBoard' : 'boardData.store', {}, { locale: lang }) }}</span>
                            </button>
                          </li>
                          <!-- Archivar o Desarchivar Tablero -->

                          <!-- Crear template de un tablero -->
                          <li class="nav-item" v-if="!boardItem.template && ((boardItem.owner == userInfo.e21) || (checkSuperAdminRol(userInfo.access) || checPermissions('template:Boards', userAccess.permissions)))">
                            <button type="button" tabindex="0" class="dropdown-item" @click="changeModalTemplateBoard()">
                              <i :style="{'font-size':'1rem', 'margin-right':'.325rem', 'width':'30px', 'text-align':'center', 'margin-left':'-10px'}">
                                <img src="@/assets/template-icon.svg" :style="{ 'width': '70%' }">
                              </i>
                              <span> {{ t('modalsCardsData.storeTemplate', {}, { locale: lang }) }}</span>
                            </button>
                          </li>
                          <!-- Crear template de un tablero -->

                          <!-- Archivos adjuntos del tablero -->
                          <li class="nav-item" v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('view:BoardsFiles', userAccess.permissions))">
                            <button type="button" tabindex="0" class="dropdown-item" @click="changeModalFilesOpenBoards()">
                              <i class="dropdown-icon fas fa-file"> </i>
                              <span> {{ t('modalsFilesData.attachedFiles', {}, { locale: lang }) }}</span>
                            </button>
                          </li>                          
                          <!-- Archivos adjuntos del tablero -->

                          <!-- Vista listas Archivadas -->
                          <li class="nav-item" v-if="!boardItem.template && (checkSuperAdminRol(userInfo.access) || checPermissions('view:StoreLists', userAccess.permissions))">
                            <button type="button" tabindex="0" class="dropdown-item" @click="loadStoreListView()">
                              <i :class="storeListView ? 'fas fa-list dropdown-icon' : 'fas fa-archive dropdown-icon'"></i>
                              <span> {{ t(storeListView ? 'dashboardData.listView' : 'modalsListsData.storeListsLabel', {}, { locale: lang }) }}</span>
                            </button>
                          </li>
                          <!-- Vista listas Archivadas -->

                        </ul>
                      </div>
                  </div>
                  <!-- Opciones del tablero -->

                  <!-- Vista Calendario -->
                  <button 
                    v-if="!boardItem.template && (checkSuperAdminRol(userInfo.access) || checPermissions('view:CalendarLists', userAccess.permissions))"
                    type="button" data-toggle="tooltip"
                    :title="t(calendarView ? 'dashboardData.listView' : 'dashboardData.calendarView', {}, { locale: lang })"
                    data-placement="bottom" class="btn-shadow mr-3 btn btn-primary" @click="showCalendarView()">
                    <i :class="calendarView ? 'fas fa-list style-1icon' : 'fas fa-calendar style-1icon'"></i>
                  </button>
                  <!-- Vista Calendario -->

                  <!-- Miembros del Tablero -->
                  <div v-if="!boardItem.template" class="d-inline-block dropright margin-movil">
                    <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      class="btn-shadow dropdown-toggle btn btn-info">
                      <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fas fa-users fa-w-20"></i>
                      </span>
                      <b>{{ t('boardData.members', {}, { locale: lang }) }}</b>
                    </button>
                    <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                      <ul class="nav flex-column">
                        <li v-for="member in boardItem.members" :key="member.id" class="nav-item">
                          <a class="row nav-link">
                            
                            <img v-if="member.type == 'user'" :src="member.img_user" width="32" class="col-md-4 rounded-circle style-img-user">
                            <img v-else src="@/assets/groups-icon.svg" width="32" class="col-md-4 rounded-circle style-img-user">

                            <span class="col-md-8 style-data-user">
                              <span>
                                {{ member.user_name }} <br> {{ member.user_email }}
                              </span>
                              <a v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('delete:UsersToBoards', userAccess.permissions)) && ((boardItem.owner == userInfo.e21) && (boardItem.owner != member.user_email))"
                                @click="changeModalDeleteMembers(member)"
                                :title="t('boardData.delete', {}, { locale: lang })">
                                <i class="fas fa-trash-alt icon-g style-icon-delete-user"></i>
                              </a>
                              <i :style="{ 'display':'none' }"></i>
                            </span>
                          </a>
                        </li>

                        <!-- Boton para agregar miembros al tablero -->
                        <div v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('add:UsersToBoards', userAccess.permissions))" tabindex="-1" class="dropdown-divider"></div>
                        <li v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('add:UsersToBoards', userAccess.permissions))"
                          class="nav-item-btn text-center nav-item">
                          <button class="btn-wide btn btn-primary btn-sm" @click="changeModalAddMembers()">
                            {{ t('boardData.addMembers', {}, { locale: lang }) }}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- Miembros del Tablero -->

                </div>
              </div>
              <!-- Grupo # 1 de Opciones del Tablero -->

              <!-- Grupo # 2 de Opciones del Tablero -->
              <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 button-align" :style="{'margin-top':'2px'}">
                <button v-if="boardItem.template || (checkSuperAdminRol(userInfo.access) || checPermissions('add:Lists', userAccess.permissions))"
                  type="button" @click="changeModalLists(true)" class="mb-2 mr-2 btn-shadow mr-3 btn btn-outline-dark margin-movil">
                  <i class="fas fa-plus espac"></i>
                  <b class="button-font">{{ t('boardData.addNewList', {}, { locale: lang }) }}</b>
                </button>
              </div>
              <!-- Grupo # 2 de Opciones del Tablero -->
            </div>
            <!-- Header del Contenido  -->
          </div>

          <!-- Container general Dispositivos Grandes -->
          <Container v-if="listView" class="hiden-big container" orientation="horizontal" @drop="onColumnDrop($event)"
            drag-handle-selector=".column-drag-handle" :drop-placeholder="upperDropPlaceholderOptions"
            :style="{'display':'inline-block', 'max-width':'90vw!important', 'overflow-y':'hidden', 'min-height':'79vh' }">
                      
            <!-- Listas -->
            <Draggable v-for="(list, index) in lists" :key="list.id" v-show="showListsToUser(list.members)">

              <div class="card-container mb-3 card-body" :style="{'min-width':'260px !important'}"
                @mouseenter="showOption('List',list.id)" @mouseleave="hidenOption('List',list.id)">
                          
                <div class="card-header">
                  <b :style="{'margin-top':'5px', 'font-size':'14px', 'margin-left':'5px'}">
                    <span class="column-drag-handle" :style="{'cursor':'move'}">&#x2630;</span>
                    {{ showInfoData(list.title, lang) }}
                  </b>
                  <div class="btn-actions-pane-right actions-icon-btn">
                    <!-- Miembros de las listas -->
                    <div v-if="!boardItem.template" class="btn-group dropdown">
                      <button type="button" :title="t('boardData.members', {}, { locale: lang })" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" class="btn-icon btn-icon-only btn btn-link">
                        <i class="fas fa-users" :style="{'font-size':'1.2rem'}"></i>
                      </button>
                      <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu">
                        
                        <li v-for="listMember in list.members" :key="listMember.id" class="nav-item">
                            <a class="row nav-link">
                              <span class="col-md-12">
                                <span :style="{'font-size': '12px'}">
                                  {{ listMember.user_name }} <br> {{ listMember.user_email }}
                                </span>
                                <a v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('delete:UsersToLists', userAccess.permissions))"
                                  @click="changeModalDeleteMembersOfLists(list, listMember)"
                                  :title="t('boardData.delete', {}, { locale: lang })">
                                  <i class="fas fa-trash-alt icon-g style-icon-delete-user"></i>
                                </a>
                                <i :style="{ 'display': 'none' }"></i>
                              </span>
                            </a>
                          </li>

                        <div v-if="list.members.length > 0 && (checkSuperAdminRol(userInfo.access) || checPermissions('add:UsersToLists', userAccess.permissions))" tabindex="-1" class="dropdown-divider"></div>
                        <div class="p-3 text-center" v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('add:UsersToLists', userAccess.permissions))">
                          <button class="btn-wide btn btn-primary btn-sm" @click="changeModalAddMembersOfLists(list)">
                            {{ t('boardData.addMembers', {}, { locale: lang }) }}
                          </button>
                        </div>
                      </div>                                                                  
                    </div>
                    <!-- Miembros de las listas -->


                    <!-- Opciones de las listas -->
                    <div class="btn-group dropdown">
                      <button :id="'iconList'+list.id" :style="{'display':'none'}" type="button" aria-haspopup="true"
                        data-toggle="dropdown" aria-expanded="false" class="btn-icon btn-icon-only btn btn-link">
                        <i class="pe-7s-more btn-icon-wrapper"></i>
                      </button>
                      <div :id="'menuList'+list.id" tabindex="-1" role="menu" aria-hidden="true"
                        class="dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu">
                        <!-- Editar lista -->
                        <button v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('edit:Lists', userAccess.permissions))"
                          type="button" tabindex="0" class="dropdown-item" @click="changeModalLists(false, list)">
                          <i class="dropdown-icon ion-android-create"> </i>
                          <span> {{ t('boardData.editList', {}, {locale: lang}) }}</span>
                        </button>

                        <!-- Almacenar lista -->
                        <button v-if="!boardItem.template && (checkSuperAdminRol(userInfo.access) || checPermissions('store:Lists', userAccess.permissions))"
                          type="button" tabindex="0" class="dropdown-item" @click="changeModalStoreList(list)">
                          <i class="dropdown-icon fas fa-save"> </i>
                          <span>{{ t(list.stored ? 'modalsListsData.unarchiveList' : 'boardData.storeList', {}, {locale: lang}) }}</span>
                        </button>

                        <!-- Copiar lista -->
                        <button v-if="!boardItem.template && (boardItem.owner == userInfo.e21) && (checkSuperAdminRol(userInfo.access) || checPermissions('copy:Lists', userAccess.permissions))"
                          type="button" tabindex="0" class="dropdown-item" @click="changeModalCopyLists(list)">
                          <i class="dropdown-icon fas fa-copy"> </i>
                          <span>{{ t('modalsListsData.copyLists', {}, {locale: lang}) }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Contenido de la Lista (Tarjetas) -->
                <Container group-name="col" @drop="(e) => onCardDrop(list.id, e)" :get-child-payload="getCardPayload(list.id)"
                  drag-class="card-ghost" drop-class="card-ghost-drop" :drop-placeholder="dropPlaceholderOptions" class="content-list">

                  <!-- Tarjeta -->
                  <Draggable v-for="card in list.cards" :key="card.id" v-show="showCardToUser(card.members)">
                    <div class="card mb-3 text-dark card-border text-white bg-light" :style="`${validateBorder(card)})`"
                      @mouseenter="showOption('Card',card.id)" @mouseleave="hidenOption('Card',index)"
                    >

                      <div v-if="card.labels.length != 0" class="card-header"
                        :style="{'margin-left':'5px','margin-bottom':'-10px!important','background':'rgb(238, 238, 238)'}"
                        @click="changeModalShowCardItem(card.id)">

                        <a v-for="l in card.labels" :key="l.id" class="mb-2 mr-2 badge"
                          :style="{'background-color':l.color,'color':'cornsilk','font-size':'9px','margin-right':'3px!important','padding':'4px 3px !important'}">
                          {{ l.title }}
                        </a>
                      </div>

                      <div class="card-header" :style="{'background':'rgb(238, 238, 238)'}">
                        <b class="card-title" @click="changeModalShowCardItem(card.id)"
                          :style="{'margin-top':'8px','margin-left':'5px','min-width':'183px','margin-right':'2px','margin-bottom':'2px'}">
                          {{ showInfoData(card.title, lang) }}
                        </b>

                        <div class="btn-actions-pane-right actions-icon-btn">
                          <div class="dropright btn-group">
                            <button :id="'iconCard'+card.id" :style="{'display':'none'}" type="button" aria-haspopup="true"
                              data-toggle="dropdown" aria-expanded="false" class="btn-icon btn-icon-only btn btn-link">
                              <i class="pe-7s-more btn-icon-wrapper"></i>
                            </button>
                            <div :id="'menuCard'+card.id" tabindex="-1" role="menu" aria-hidden="true"
                              class="dropdown-menu-shadow dropdown-menu-left dropdown-menu-hover-link dropdown-menu"
                              :style="{'min-width':'8rem','max-width':'8rem'}"
                            >
                              <!-- Editar tarjetas -->
                              <i v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('edit:Cards', userAccess.permissions))"
                                :style="{'color':'#16aaff','margin-left':'10px'}" class="dropdown-icon ion-android-create"
                                :title="t('boardData.editCard', {}, {locale: lang})"
                                @click="openModalAddEditCards(true, list.id, card)">
                              </i>
                              <!-- Copiar tarjetas -->
                              <i v-if="!boardItem.template && (checkSuperAdminRol(userInfo.access) || checPermissions('copy:Cards', userAccess.permissions))"
                                :style="{'color':'#16aaff','margin-left':'10px'}" class="dropdown-icon fas fa-copy"
                                :title="t('modalsCardsData.copyTo', {}, {locale: lang})"
                                @click="changeModalCopyCards(card)">
                              </i>
                              <!-- Convertir tarjeta en template -->
                              <i v-if="!boardItem.template && (checkSuperAdminRol(userInfo.access) || checPermissions('template:Cards', userAccess.permissions))"
                                :style="{'color':'#16aaff','margin-left':'10px'}" class="dropdown-icon fas fa-archive"
                                :title="t('modalsCardsData.storeTemplate', {}, {locale: lang})"
                                @click="changeModalSaveCardAsTemplate(card)">
                              </i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card-body text-right" @click="changeModalShowCardItem(card.id)">
                        <b v-show="card.checklist_cant != 0" class="text-right">
                          <div class="ml-auto badge badge-pill badge-success" :style="{'font-size':'10px'}">
                            {{ card.checklist_completed }} / {{ card.checklist_cant }}
                          </div>
                        </b>
                      </div>
                    </div>
                  </Draggable>
                  <!-- Tarjeta -->

                </Container>
                <!-- Contenido de la Lista (Tarjetas) -->
                          
                <button v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('add:Cards', userAccess.permissions))"
                  :style="{'margin-top':'30px',}" class="mb-2 mr-2 btn btn-dashed btn-outline-dark btn-sm btn-block"
                  @click="openModalAddEditCards(false, list.id, {})">
                  <b>{{ t('boardData.addCards', { }, { locale: lang }) }}</b>
                </button>
              </div>
            
            </Draggable>
            <!-- Listas -->
          
          </Container>
          <!-- Container general Dispositivos Grandes-->


          <!-- Container para dispositivos pequeños -->
          <div v-if="listView" class="row hiden-small" :style="{'max-width':'94vw', 'min-height':'65vh', 'min-width':'94vw'}">
          
            <button v-show="lists.length > 1" :style="{'margin-right':'5px'}" class="slick-prev slick-arrow" aria-label="Previous"
              type="button" @click="buttonMovilList('-')">
              {{ t('button.previous', {}, {locale: lang}) }}
            </button>

            <div v-for="(list, index) in lists" :key="list.id" v-show="index == actualListMovil" class="col-sm-12 col-md-12 col-lg-12 col-xl-12 movil-conten-style">
          
              <!-- Listas -->
              <div class="card-container mb-3 card-body" :style="{'max-height':'60vh','overflow-x':'hidden'}">
                <div class="card-header">
                  <b :style="{'margin-top':'5px','font-size':'14px','margin-left':'5px'}">
                    <span class="column-drag-handle" :style="{'cursor':'default'}" @click="changeModalMoveListsInMovil(list)">&#x2630;</span>
                    {{ showInfoData(list.title, lang) }}
                  </b>
                  <div class="btn-actions-pane-right actions-icon-btn">
                    <div class="btn-group dropdown">
                      <!-- Miembros de las listas -->
                      <div class="btn-group dropdown">
                        <button type="button" :title="t('boardData.members', {}, { locale: lang })" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" class="btn-icon btn-icon-only btn btn-link">
                          <i class="fas fa-users" :style="{ 'font-size': '1.2rem' }"></i>
                        </button>
                        <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu">
                        
                          <li v-for="listMember in list.members" :key="listMember.id" class="nav-item">
                              <a class="row nav-link">
                                <span class="col-md-12">
                                  <span :style="{ 'font-size': '12px' }">
                                    {{ listMember.user_name }} <br> {{ listMember.user_email }}
                                  </span>
                                  <a
                                    @click="changeModalDeleteMembersOfLists(list, listMember)"
                                    :title="t('boardData.delete', {}, { locale: lang })">
                                    <i class="fas fa-trash-alt icon-g style-icon-delete-user"></i>
                                  </a>
                                  <i :style="{ 'display': 'none' }"></i>
                                </span>
                              </a>
                            </li>

                          <div v-if="list.members.length > 0" tabindex="-1" class="dropdown-divider"></div>
                          <div class="p-3 text-center">
                            <button class="btn-wide btn btn-primary btn-sm" @click="changeModalAddMembersOfLists(list)">
                                {{ t('boardData.addMembers', {}, { locale: lang }) }}
                              </button>
                          </div>
                        </div>                                                                  
                      </div>

                      <button type="button" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false"
                        class="btn-icon btn-icon-only btn btn-link">
                        <i class="pe-7s-more btn-icon-wrapper"></i>
                      </button>
                      <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu">
                        
                        <!-- Editar listas -->
                        <button v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('edit:Lists', userAccess.permissions))"
                          type="button" tabindex="0" class="dropdown-item" @click="changeModalLists(false, list)">
                          <i class="dropdown-icon ion-android-create"> </i>
                          <span> {{ t('boardData.editList', {}, {locale: lang}) }}</span>
                        </button>

                        <!-- Almacenar listas -->
                        <button v-if="(boardItem.owner == userInfo.e21) && (checkSuperAdminRol(userInfo.access) || checPermissions('store:Lists', userAccess.permissions))" 
                          type="button" tabindex="0" class="dropdown-item" @click="changeModalStoreList(list)">
                          <i class="dropdown-icon fas fa-save"> </i>
                          <span>{{ t(list.stored ? 'modalsListsData.unarchiveList' : 'boardData.storeList', {}, {locale: lang}) }}</span>
                        </button>

                        <!-- Copiar listas -->
                        <button v-if="(boardItem.owner == userInfo.e21) && (checkSuperAdminRol(userInfo.access) || checPermissions('copy:Lists', userAccess.permissions))" 
                          type="button" tabindex="0" class="dropdown-item" @click="changeModalCopyLists(list)">
                          <i class="dropdown-icon fas fa-copy"> </i>
                          <span>{{ t('modalsListsData.copyLists', {}, {locale: lang}) }}</span>
                        </button>

                      </div>
                    </div>
                  </div>
                </div>

                <!-- Tarjeta -->
                <div v-for="card in list.cards" :key="card.id" v-show="showCardToUser(card.members)"
                  class="card mb-3 text-dark card-border text-white bg-light" :style="`${validateBorder(card)})`">
                  
                  <div v-if="card.labels.length != 0" class="card-header"
                    :style="{'margin-left':'5px','margin-bottom':'-10px!important','background':'rgb(238, 238, 238)'}"
                    @click="changeModalShowCardItem(card.id)">
                    <a v-for="l in card.labels" :key="l.id" class="mb-2 mr-2 badge"
                      :style="{'background-color':l.color,'color':'cornsilk','font-size':'9px','margin-right':'3px!important','padding':'4px 3px !important'}">
                      {{ l.title }}
                    </a>
                  </div>

                  <div class="card-header" :style="{'background':'rgb(238, 238, 238)'}">
                    <b class="card-title" @click="changeModalShowCardItem(card.id)"
                      :style="{'margin-top':'8px','margin-left':'5px','min-width':'183px','margin-right':'2px','margin-bottom':'2px'}">
                      {{ showInfoData(card.title, lang) }}
                    </b>
                    <div class="btn-actions-pane-right actions-icon-btn">
                      <div class="btn-group dropright">
                        <button type="button" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false"
                          class="btn-icon btn-icon-only btn btn-link">
                          <i class="pe-7s-more btn-icon-wrapper"></i>
                        </button>
                        <div tabindex="-1" role="menu" aria-hidden="true" :style="{'min-width':'8rem','max-width':'8rem'}"
                          class="dropdown-menu-shadow dropdown-menu-left dropdown-menu-hover-link dropdown-menu"
                        >
                          <!-- Mover listas vista movil -->
                          <i :style="{'color':'#16aaff','margin-left':'10px'}" class="dropdown-icon fas fa-arrows-alt"
                            :title="t('modalsCardsData.moveCard', {}, {locale: lang})" @click="changeModalMoveCardsInMovil(card, index)">
                          </i>

                          <!-- Editar tarjetas -->
                          <i v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('edit:Cards', userAccess.permissions))"
                            :style="{'color':'#16aaff','margin-left':'10px'}" class="dropdown-icon ion-android-create"
                            :title="t('boardData.editCard', {}, {locale: lang})"
                            @click="openModalAddEditCards(true, list.id, card)">
                          </i>
                          <!-- Copiar tarjetas -->
                          <i v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('copy:Cards', userAccess.permissions))"
                            :style="{'color':'#16aaff','margin-left':'10px'}" class="dropdown-icon fas fa-copy"
                            :title="t('modalsCardsData.copyTo', {}, {locale: lang})"
                            @click="changeModalCopyCards(card)">
                          </i>
                          <!-- Combertir tarjeta en template -->
                          <i v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('template:Cards', userAccess.permissions))"
                            :style="{'color':'#16aaff','margin-left':'10px'}" class="dropdown-icon fas fa-archive"
                            :title="t('modalsCardsData.storeTemplate', {}, {locale: lang})"
                            @click="changeModalSaveCardAsTemplate(card)">
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body text-right" @click="changeModalShowCardItem(card.id)">
                    <b v-show="card.checklist_cant != 0" class="text-right">
                      <div class="ml-auto badge badge-pill badge-success" :style="{'font-size':'10px'}">
                        {{ card.checklist_completed }} / {{ card.checklist_cant }}
                      </div>
                    </b>
                  </div>
                </div>
                <!-- Tarjeta -->

                <button v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('add:Cards', userAccess.permissions))"
                  :style="{'margin-top':'30px',}" class="mb-2 mr-2 btn btn-dashed btn-outline-dark btn-sm btn-block" @click="openModalAddEditCards(false, list.id, {})">
                  <b>{{ t('boardData.addCards', {}, {locale: lang}) }}</b>
                </button>
              </div>
              <!-- Listas -->

            </div>
          
            <button v-show="lists.length > 1" class="slick-next slick-arrow" aria-label="Next" type="button" style=""
              @click="buttonMovilList('+')">
              {{ t('button.next', {}, {locale: lang}) }}
            </button>
          
            <ul v-show="lists.length > 1" class="slick-dots" style="" role="tablist">
              <li role="presentation" v-for="(l, index) in lists" :key="l.id" :class="index == actualListMovil ? 'slick-active' : ''" @click="movilMenuListIr(index)">
                <button type="button" role="tab" id="slick-slide-control01" aria-controls="slick-slide01" aria-label="2 of 6" tabindex="-1">
                  {{ index }}
                </button>
              </li>
            </ul>
          </div>
          <!-- Container para dispositivos pequeños -->


          <!-- Vista de calendario -->
          <div v-if="calendarView">
            <FullCalendar :options="calendarOptions"
              style="width:90vw; height:90vh; padding-bottom:20px; opacity: 0.9;" />
          </div>
          <!-- Vista de calendario -->

        </div>
      </div>
    </div>


    <!-- Componente para Agregar, Editar y Eliminar Tableros -->
    <div v-if="modalAddEditDeleteBoards">
      <ModalAddEditBoard />
    </div>
    <!-- Componente para Agregar, Editar y Eliminar Tableros -->


    <!-- Modal para copiar un tablero -->
    <div v-if="modalDuplicateBoard">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ t('modalsBoardsData.duplicateBoard', {}, {locale:lang}) }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalDuplicateBoard()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label for="" class="">
                          {{ t('modalsBoardsData.duplicateMsj', {}, {locale: lang}) }}
                        </label>
                        <input name="boardTitle" id="boardTitle" type="text" class="form-control form-control-sm"
                          v-model="duplicate.title" />
                        <span class="helper-text danger-text">
                          {{ duplicate.titleError }}
                        </span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group text-center">
                        <label class="">{{ t('modalsBoardsData.copyAttachments', {}, {locale: lang}) }}</label>
                        <select class="form-control form-control-sm" v-model="duplicate.copyFile">
                          <option value=''>{{ t('headerData.SelectOption', {}, {locale: lang}) }}</option>
                          <option value="1">{{ t('button.yes', {}, {locale: lang}) }}</option>
                          <option value="0">{{ t('button.not', {}, {locale: lang}) }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ duplicate.errorCopyFile }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" id="botonCopiBoard" class="btn btn-primary btn-sm" @click="applyDuplicate()">
                    {{ t('button.save', {}, {locale: lang}) }}
                  </button>
                  <button type="button" class="btn btn-secondary" @click="changeModalDuplicateBoard()">
                    {{ t('button.close', {}, {locale: lang}) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para copiar un tablero -->


    <!-- Modal para archivar o desarchivar un tablero -->
    <div v-if="modalStoreBoard">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ boardItem.stored ? t('boardData.unarchiveBoard', {}, { locale: lang }) :
                    t('modalsBoardsData.storeBoard', {}, {locale: lang}) }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalStoreBoard()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <p class="mb-0">
                    {{ boardItem.stored ? t('boardData.unarchiveMsj', {}, {locale: lang}) :
                    t('modalsBoardsData.storeMsj1', {}, {locale: lang}) }}
                    <b>{{ showInfoData(boardItem.title, lang) }}</b>?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-info btn-sm" @click="storeBoard()">
                    {{ t('button.save', {}, {locale: lang}) }}
                  </button>
                  <button type="button" class="btn btn-secondary" @click="changeModalStoreBoard()">
                    {{ t('button.close', {}, {locale: lang}) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para archivar o desarchivar un tablero -->


    <!-- Modal para agregar un miembro al tablero -->
    <div v-if="modalAddMembers">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <ul class="nav nav-justified" :style="{'width':'90% !important'}">
                    <li class="nav-item">
                      <a data-toggle="tab" href="#tab-eg3-0" class="active nav-link" @click="addMember.menuActive = 0; addMember.seletMember = '';">
                        <b class="modal-title" :style="{'color':(addMember.menuActive == 0 ? '#3f6ad8' : 'gray')}">
                          {{ t('modalsBoardsData.addMemberBoard', {}, {locale:lang}) }}
                        </b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a data-toggle="tab" href="#tab-eg3-1" class="nav-link" @click="addMember.menuActive = 1; addMember.seletMember = '';">
                        <b class="modal-title" :style="{'color':(addMember.menuActive == 1 ? '#3f6ad8' : 'gray')}">
                          {{ t('modalsBoardsData.addUserAndAddBoard', { }, { locale:lang }) }}
                        </b>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a data-toggle="tab" href="#tab-eg3-2" class="nav-link" @click="addMember.menuActive = 2; addMember.seletMember = '';">
                        <b class="modal-title" :style="{'color':(addMember.menuActive == 2 ? '#3f6ad8' : 'gray')}">
                          {{ t('modalsBoardsData.addUserGroupsBoard', {}, {locale:lang}) }}
                        </b>
                      </a>
                    </li>
                  </ul>
                  <button type="button" class="close" aria-label="Close" @click="changeModalAddMembers()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
      
                  <div class="tab-content">

                    <!-- Formulario para seleccionar un miembro ya registrado en el team -->
                    <div class="tab-pane active show" id="tab-eg3-0" role="tabpanel">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="position-relative form-group text-center">
                            <label class="">{{ t('modalsBoardsData.addMsjBoard', {}, {locale: lang}) }}</label>
                            <select class="form-control form-control-sm" v-model="addMember.seletMember">
                              <option value=''>{{ t('modalsBoardsData.addMsjBoard', {}, {locale: lang}) }}</option>
                              <option v-for="user in users" :key="user.id" :value="user.user_email">
                                {{ user.user_name }} - {{ user.rol.display_name }}
                              </option>
                            </select>
                            <span class="helper-text danger-text">{{ addMember.seletMemberError }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Formulario para seleccionar un miembro ya registrado en el team -->
      

                    <!-- Formulario para registrar un miembro en el tablero y equipo -->
                    <div class="tab-pane" id="tab-eg3-1" role="tabpanel">
                      <div class="row">      
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="position-relative form-group">
                            <label for="" class="">{{ t('headerData.name', { }, { locale: lang }) }}</label>
                            <input name="name" id="name" placeholder="" type="text" class="form-control form-control-sm" v-model="addMember.name"
                              required>
                            <span class="helper-text danger-text">{{ addMember.nameError }}</span>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="position-relative form-group">
                            <label for="" class="">{{ t('headerData.surname', { }, { locale: lang }) }}</label>
                            <input name="surname" id="surname" placeholder="" type="text" class="form-control form-control-sm"
                              v-model="addMember.surname" required>
                            <span class="helper-text danger-text">{{ addMember.surnameError }}</span>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="position-relative form-group">
                            <label for="" class="">{{ t('headerData.phone', { }, { locale: lang }) }}</label>
                            <input name="phone" id="phone" placeholder="" type="text" class="form-control form-control-sm"
                              v-model="addMember.phone" required>
                            <span class="helper-text danger-text">{{ addMember.phoneError }}</span>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="position-relative form-group">
                            <label for="" class="">{{ t('headerData.email', { }, { locale: lang }) }}</label>
                            <input name="email" id="email" placeholder="" type="text" class="form-control form-control-sm"
                              v-model="addMember.email" required>
                            <span class="helper-text danger-text">{{ addMember.emailError }}</span>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div class="position-relative form-group">
                            <label for="" class="">Rols</label>
                            <select class="multiselect-dropdown form-control form-control-sm" v-model="addMember.rol">
                              <option :value="-1">{{ t('headerData.SelectOption', { }, { locale: lang }) }}</option>
                              <option v-for="r in filterRols" :key="r.id" :value="r.id">{{ r.display_name }}</option>
                            </select>
                            <span class="helper-text danger-text">{{ addMember.rolError }}</span>
                          </div>
                        </div>
      
                      </div>
                    </div>
                    <!-- Formulario para registrar un miembro en el tablero y equipo -->


                    <!-- Formulario para seleccionar un Grupo de usuarios asociados al team -->
                    <div class="tab-pane" id="tab-eg3-2" role="tabpanel">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="position-relative form-group text-center">
                            <label class="">{{ t('modalsBoardsData.addMsjBoard2', {}, {locale: lang}) }}</label>
                            <select class="form-control form-control-sm" v-model="addMember.seletGroup">
                              <option value=''>{{ t('modalsBoardsData.addMsjBoard2', {}, {locale: lang}) }}</option>
                              <option v-for="group in filter" :key="group.id" :value="group.id">
                                {{ group.name }}
                              </option>
                            </select>
                            <span class="helper-text danger-text">{{ addMember.seletGroupError }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Formulario para seleccionar un Grupo de usuarios asociados al team -->

                  </div>
                </div>
      
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm" @click="addNewMember()">
                    {{ t(this.addMember.menuActive == 0 || this.addMember.menuActive == 2 ? 'button.save' : 'button.add', {}, { locale: lang }) }}
                  </button>
                  <button type="button" class="btn btn-secondary btn-sm" @click="changeModalAddMembers()">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para agregar un miembro al tablero -->

    
    <!-- Modal eliminar un miembro del tablero -->
    <div v-if="modalDeleteMembers">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ t('modalsBoardsData.deleteMemberBoard', {}, {locale: lang}) }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalDeleteMembers()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <p class="mb-0">
                    {{ t('modalsBoardsData.deleteMemberMsj', {}, {locale: lang}) }}
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger btn-sm" @click="actionDeleteMember()">
                    {{ t('button.delete', {}, {locale: lang}) }}
                  </button>
                  <button type="button" class="btn btn-secondary" @click="changeModalDeleteMembers()">
                    {{ t('button.close', {}, {locale: lang}) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal eliminar un miembro del tablero -->


    <!-- Modal para crear un template de un tablero -->
    <div v-if="modalSaveBoardAsTemplate">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ `${t('modalsCardsData.storeTemplate', {}, { locale: lang })}: ${showInfoData(boardItem.title, lang)}` }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalTemplateBoard()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>      
                <div class="modal-body text-center">
                  <div class="row" :style="{ 'max-height': '400px', 'overflow-x': 'hidden' }">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label class="">{{ t('modalsCardsData.nameTemplate', {}, { locale: lang }) }}</label>
                        <input name="cardTitle" id="cardTitle" type="text" class="form-control form-control-sm" v-model="formBoardTemplate.title" />
                        <span class="helper-text danger-text">{{ formBoardTemplate.errTitle }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label class="">{{ t('modalsBoardsData.description', {}, { locale: lang }) }}</label>
                        <textarea rows="1" class="form-control autosize-input" :style="{ 'height': '60px' }" v-model="formBoardTemplate.description"></textarea>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('templateType:Boards', userAccess.permissions))" >
                      <div class="position-relative form-group text-center">
                        <label class="">{{ t('modalsBoardsData.templateType', {}, { locale: lang }) }}</label>
                        <select class="form-control form-control-sm" v-model="formBoardTemplate.tp">
                          <option :value="1">{{ t('button.private', {}, { locale: lang }) }}</option>
                          <option :value="0">{{ t('button.public', {}, { locale: lang }) }}</option>
                        </select>
                      </div>
                    </div>  

                    <div v-show="formBoardTemplate.tp == 1" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group text-center">
                        <label class="">{{ t('modalsBoardsData.copyAttachments', {}, { locale: lang }) }}</label>
                        <select class="form-control form-control-sm" v-model="formBoardTemplate.copyFile">
                          <option :value="1">{{ t('button.yes', {}, { locale: lang }) }}</option>
                          <option :value="0">{{ t('button.not', {}, { locale: lang }) }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ formBoardTemplate.errorCopyFile }}</span>
                      </div>
                    </div>

                    <div v-show="formBoardTemplate.tp == 0" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group text-center">
                        <label class="">{{ t('headerData.templateImage', {}, { locale: lang }) }}</label>
                        <input name="file" type="file" id="file" ref="file" @change="handleFileUpload()" class="form-control-file">
                      </div>
                    </div>

                    <div v-show="formBoardTemplate.tp == 0" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group text-center">
                        <label class="">{{ t('headerData.categories', {}, { locale: lang }) }}</label>
                        <select class="form-control form-control-sm" v-model="formBoardTemplate.categories">
                          <option :value="0">{{ t('headerData.SelectOption', {}, { locale: lang }) }}</option>
                          <option v-for="categ in categories" :key="categ.id" :value="categ.id">{{ categ.name }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ formBoardTemplate.errCategories }}</span>
                      </div>
                    </div> 

                    <div v-show="formBoardTemplate.tp == 0" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label class="">{{ t('headerData.languages', {}, { locale: lang }) }}</label>
                        <input name="cardTitle" id="cardTitle" type="text" class="form-control form-control-sm" v-model="formBoardTemplate.languages" />
                        <span class="helper-text danger-text">{{ formBoardTemplate.errLanguages }}</span>
                      </div>
                    </div>   

                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm" @click="saveBoardAsTemplate()">
                    {{ t('button.save', {}, { locale: lang }) }}
                  </button>
                  <button type="button" class="btn btn-secondary" @click="changeModalTemplateBoard()">
                    {{ t('button.close', {}, { locale: lang }) }}
                  </button>
                </div>
              </div>    
            </div>
          </div>
        </div>  
      </transition>
    </div>
    <!-- Modal para crear un template de un tablero -->



    <!-- Modal para Add, Edit o Delete una lista -->
    <div v-if="modalAddEditLists">
      <ModalAddEditLists :newItem="newItemList" :listItem="listItem" />
    </div>
    <!-- Modal para Add, Edit o Delete una lista -->


    <!-- Modal para Archivar o desarchivar una Lista -->
    <div v-if="modalStoredLists">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ t(listItem.stored ? 'modalsListsData.unarchiveList' : 'boardData.storeList', {}, {locale: lang}) }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalStoreList()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <p class="mb-0">
                    {{ t(listItem.stored ? 'modalsListsData.unarchiveMsj' : 'modalsListsData.storeMsj', {}, {locale: lang}) }}
                    <b>{{ showInfoData(listItem.title, lang) }}</b>?
                  </p>
                </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-info btn-sm" @click="saveStoreLists()">
                      {{ t('button.save', {}, {locale: lang}) }}
                    </button>
                    <button type="button" class="btn btn-secondary" @click="changeModalStoreList()">
                      {{ t('button.close', {}, {locale: lang}) }}
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para Archivar o desarchivar una Lista -->

    
    <!-- Modal para copiar una Lista -->
    <div v-if="modalCopyLists">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ t('modalsListsData.copyLists', { }, { locale: lang }) }}: {{ showInfoData(listItem.title) }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalCopyLists()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label class="">{{ t('modalsListsData.msj1CopyList', { }, { locale: lang }) }}</label>
                        <select class="form-control form-control-sm" v-model="copyListForm.board">
                          <option value="-1">{{ t('modalsBoardsData.seletBoard', { }, { locale: lang }) }}</option>
                          <option v-for="b in myBoadsCopy" :key="b.id" :value="b.id">
                            {{ showInfoData(b.title, lang) }}
                          </option>
                        </select>
                        <span class="helper-text danger-text">{{ copyListForm.errorBoard }}</span>
                      </div>
                    </div>
    
                    <div v-show="copyListForm.board != -1" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('modalsListsData.msj2CopyList', { }, { locale: lang }) }}</label>
                        <input name="listTitle" id="listTitle" type="text" class="form-control form-control-sm"
                          v-model="copyListForm.title" />
                        <span class="helper-text danger-text">{{ copyListForm.errorTitle }}</span>
                      </div>
                    </div>

                    <div v-show="copyListForm.board != -1" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group text-center">
                        <label class="">{{ t('modalsBoardsData.copyAttachments', {}, {locale: lang}) }}</label>
                        <select class="form-control form-control-sm" v-model="copyListForm.copyFile">
                          <option value=''>{{ t('headerData.SelectOption', {}, {locale: lang}) }}</option>
                          <option value="1">{{ t('button.yes', {}, {locale: lang}) }}</option>
                          <option value="0">{{ t('button.not', {}, {locale: lang}) }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ copyListForm.errorCopyFile }}</span>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="modal-footer">
                  <button type="button" id="botonCopyList" class="btn btn-primary btn-sm" @click="copyLists()">
                    {{ t('button.save', { }, { locale: lang }) }}
                  </button>
                  <button type="button" class="btn btn-secondary" @click="changeModalCopyLists()">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para copiar una Lista -->


    <!-- Modal para Add y Editar una Tarjeta -->
    <div v-if="modalAddEditCards">
      <ModalAddEditCards :newItemCard="newItemCard" :listId="listToAddCard" :calendarDate="calendarDate" :cardItem="cardItem"/>
    </div>
    <!-- Modal para Add y Editar una Tarjeta -->


    <!-- Modal para copiar una Tarjeta -->
    <div v-if="modalCopyOneCard">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ `${t('modalsCardsData.copy', {}, { locale: lang })}: ${showInfoData(cardItem.title, lang)}` }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalCopyCards()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label class="">{{ t('modalsCardsData.copyMsj1', {}, {locale: lang}) }}</label>
                        <select class="form-control form-control-sm" v-model="formCopyCard.list">
                          <option value="">{{ t('modalsCardsData.selectList', {}, {locale: lang}) }}</option>
                          <option v-for="list in lists" :key="list.id" :value="list.id">
                            {{ showInfoData(list.title, lang) }}
                          </option>
                        </select>
                        <span class="helper-text danger-text">{{ formCopyCard.errorList }}</span>
                      </div>
                    </div>
                    <div v-show="formCopyCard.list != ''" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('modalsCardsData.copyMsj2', {}, {locale: lang}) }}</label>
                        <input name="cardCopyTitle" id="cardCopyTitle" type="text" class="form-control form-control-sm"
                          v-model="formCopyCard.title" />
                        <span class="helper-text danger-text">{{ formCopyCard.errorTitle }}</span>
                      </div>
                    </div>

                    <div v-show="formCopyCard.list != ''" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group text-center">
                        <label class="">{{ t('modalsBoardsData.copyAttachments', {}, {locale: lang}) }}</label>
                        <select class="form-control form-control-sm" v-model="formCopyCard.copyFile">
                          <option value=''>{{ t('headerData.SelectOption', {}, {locale: lang}) }}</option>
                          <option value="1">{{ t('button.yes', {}, {locale: lang}) }}</option>
                          <option value="0">{{ t('button.not', {}, {locale: lang}) }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ formCopyCard.errorCopyFile }}</span>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm" @click="applyCopyToCard()">
                    {{ t('button.save', {}, {locale: lang}) }}
                  </button>
                  <button type="button" class="btn btn-secondary" @click="changeModalCopyCards()">
                    {{ t('button.close', {}, {locale: lang}) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para copiar una Tarjeta -->


    <!-- Modal para crear un template de una Tarjeta -->
    <div v-if="modalSaveCardAsTemplate">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{`${t('modalsCardsData.storeTemplate', {}, { locale: lang })}: ${showInfoData(cardItem.title)}` }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalSaveCardAsTemplate()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label class="">{{ t('modalsCardsData.nameTemplate', { }, { locale: lang }) }}</label>
                        <input name="cardTitle" id="cardTitle" type="text" class="form-control form-control-sm" v-model="formTemplat.title" />
                        <span class="helper-text danger-text">{{ formTemplat.errorTitle }}</span>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group text-center">
                        <label class="">{{ t('modalsBoardsData.copyAttachments', {}, {locale: lang}) }}</label>
                        <select class="form-control form-control-sm" v-model="formTemplat.copyFile">
                          <option value=''>{{ t('headerData.SelectOption', {}, {locale: lang}) }}</option>
                          <option value="1">{{ t('button.yes', {}, {locale: lang}) }}</option>
                          <option value="0">{{ t('button.not', {}, {locale: lang}) }}</option>
                        </select>
                        <span class="helper-text danger-text">{{ formTemplat.errorCopyFile }}</span>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm" @click="saveCardAsTemplate()">
                    {{ t('button.save', {}, {locale: lang}) }}
                  </button>
                  <button type="button" class="btn btn-secondary" @click="changeModalSaveCardAsTemplate()">
                    {{ t('button.close', {}, {locale: lang}) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para crear un template de una Tarjeta -->


    <!-- Modal para mover una lista en vista movil -->
    <div v-if="modalMoveListInMovil">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ `${t('modalsListsData.move', {}, { locale: lang })} ${showInfoData(listItem.title, lang)} ${t('modalsListsData.to', {}, { locale: lang }) }`}}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalMoveListsInMovil()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <div class="position-relative form-group">
                    <label for="" class="">{{ t('modalsListsData.newPosition', { }, { locale: lang }) }}</label>
                    <select class="form-control form-control-sm" v-model="listNewLevel">
                      <option :value="0">{{ t('modalsListsData.levels', { }, { locale: lang }) }}</option>
                      <option v-for="list in lists" :key="list.id" :value="list.id" v-show="list.id != listItem.id">
                        {{ `${t('modalsCardsData.level', {}, { locale: lang })} ${list.nivel}: ${showInfoData(list.title, lang)}` }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm" @click="applyDropToListInMovil()">
                    {{ t('button.save', { }, { locale: lang }) }}
                  </button>
                  <button type="button" class="btn btn-secondary" @click="changeModalMoveListsInMovil()">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para mover una lista en vista movil -->


    <!-- Modal para mover una tarjeta en vista movil -->
    <div v-if="modalMoveCardInMovil">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ `${t('modalsCardsData.move', {}, { locale: lang })} ${showInfoData(cardItem.title, lang)} ${t('modalsCardsData.to', {},
                    { locale: lang })}` }}
                  </h5>
                  <button type="button" class="close" aria-label="Close" @click="changeModalMoveCardsInMovil()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
    
                  <div class="row">
    
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label class="">{{ t('modalsCardsData.actionApllu', { }, { locale: lang }) }}</label>
                        <select class="form-control form-control-sm" v-model="formMoveCard.action">
                          <option value="">{{ t('modalsCardsData.select', { }, { locale: lang }) }}</option>
                          <option value="order">{{ t('modalsCardsData.order', { }, { locale: lang }) }}</option>
                          <option value="move">{{ t('modalsCardsData.move', { }, { locale: lang }) }}</option>
                        </select>
                      </div>
                    </div>
    
                    <div v-if="formMoveCard.action == 'move'" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label class="">{{ t('modalsCardsData.moveList', { }, { locale: lang }) }}</label>
                        <select class="form-control form-control-sm" v-model="formMoveCard.listToMove">
                          <option :value="0">{{ t('modalsCardsData.selectList', { }, { locale: lang }) }}</option>
                          <option v-for="(list, index) in lists" :key="list.id" :value="index" v-show="list.id != cardItem.list_id">
                            {{ showInfoData(list.title, lang) }}
                          </option>
                        </select>
                      </div>
                    </div>
    
                    <div v-if="formMoveCard.action == 'order' || formMoveCard.listToMove != 0" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="position-relative form-group">
                        <label class="">{{ t('modalsCardsData.orderCard', { }, { locale: lang }) }}</label>
                        <select class="form-control form-control-sm" v-model="formMoveCard.newlevel">
                          <option :value="0">{{ t('modalsCardsData.levels', { }, { locale: lang }) }}</option>
                          <option v-for="card in formMoveCard.action == 'order' ? lists[formMoveCard.indexList].cards : lists[formMoveCard.listToMove].cards" :key="card.id" :value="card.id" v-show="card.id != cardItem.id">
                            {{ `${t('modalsCardsData.level', {}, { locale: lang })} ${card.nivel}: ${showInfoData(card.title, lang)}` }}
                          </option>
                        </select>
                      </div>
                    </div>
    
                  </div>
    
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm" @click="applyDropToCardInMovil()">
                    {{ t('button.save', { }, { locale: lang }) }}
                  </button>
                  <button type="button" class="btn btn-secondary" @click="changeModalMoveCardsInMovil()">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para mover una tarjeta en vista movil -->


    <!-- Modal para los archivos adjuntos del tablero -->
    <div v-if="modalFilesOpenBoards">
      <transition name="modal">  
        <div class="modal-mask" :style="{'z-index': '800 !important'}">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header" >
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                    <span aria-hidden="true" @click="changeModalFilesOpenBoards()">&times;</span>
                  </button>
                </div>
                <div class="modal-body scroll-area-lg" :style="{'overflow':'auto'}">

                  <filesSection typeFile="board" />
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                    @click="changeModalFilesOpenBoards()">
                    {{ t('button.close', { }, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para los archivos adjuntos del tablero -->


    <!-- Modal para agregar un miembro en una lista -->
    <div v-if="modalAddMembersOnLists">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    {{ t('modalsMembersData.addMember', {}, { locale: lang }) }}
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalAddMembersOfLists()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="position-relative form-group">
                        <label for="" class="">{{ t('modalsMembersData.members', {}, { locale: lang }) }}</label>
                        <select class="multiselect-dropdown form-control form-control-sm" v-model="memberAddLists">
                          <option value="">{{ t('modalsMembersData.chooseMember', {}, { locale: lang }) }}</option>
                          <option v-for="m in filterBoardMembers" :key="m.id" :value="m">
                            {{ m.user_name }}
                          </option>
                        </select>
                        <span class="helper-text danger-text">{{ errorMemberAddLists }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm" @click="saveNewMemberInLists()">
                    {{ t('button.add', {}, { locale: lang }) }}
                  </button>
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalAddMembersOfLists()">
                    {{ t('button.close', {}, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para agregar un miembro en una lista -->


    <!-- Modal para eliminar un miembro de una lista -->
    <div v-if="modalDeleteMembersOnLists">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    {{ t('modalsMembersData.deleteMember', {}, { locale: lang }) }}
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalDeleteMembersOfLists()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <h6>{{ t('modalsMembersData.deleteMemberMsj', {}, { locale: lang }) }}</h6>
                  <b v-if="memberToList.type == 'user'">{{ memberToList.user_name }} - {{ memberToList.user_email }}</b>
                  <b v-else>{{ t('headerData.userGroups', {}, { locale: lang }) }} - {{ memberToList.user_name }}</b>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger btn-sm" @click="deleteMembersToLists()">
                    {{ t('button.delete', {}, { locale: lang }) }}
                  </button>
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalDeleteMembersOfLists()">
                    {{ t('button.close', {}, { locale: lang }) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para eliminar un miembro de una lista -->

  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { closeMenu, closeMenu2, showInfoData, checkError401, checkSuperAdminRol, checPermissions, filterUserAccessByTeam, validateCreateBoardByLimitPlam } from '@/utility/functions';
import { requiredFields, requiredFieldsNumber, emailFields } from '../utility/validations';
import Header from '@/components/template/Header.vue';
import Sidebar from '@/components/template/Sidebar.vue';
import ModalAddEditBoard from '@/components/boards/ModalAddEditBoard.vue';
import ModalAddEditLists from '@/components/lists/ModalAddEditLists.vue';
import ModalAddEditCards from '@/components/cards/ModalAddEditCards.vue';
import filesSection from '@/components/cards/files/filesSection.vue';
import { Container, Draggable } from "vue-dndrop";
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';


export default {
  name: 'Board',
  setup() {
    const { t, locale } = useI18n();
    return { 
      t,
      locale,
      closeMenu,
      showInfoData,
      checkSuperAdminRol,
      checPermissions,
      validateCreateBoardByLimitPlam,
    };
  },
  components: {
    Header,
    Sidebar,
    ModalAddEditBoard,
    ModalAddEditLists,
    ModalAddEditCards,
    Container,
    Draggable,
    FullCalendar,
    filesSection,
  },
  data() {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      modalDuplicateBoard: false,
      duplicate : {
        title: '',
        titleError: '',
        copyFile: '',
        errorCopyFile: '',
      },
      modalStoreBoard: false,
      modalAddMembers: false,
      addMember: {
        menuActive: 0,
        seletMember: '',
        seletMemberError: '',
        name: '',
        nameError: '',
        surname: '',
        surnameError: '',
        phone: '',
        phoneError: '',
        email: '',
        emailError: '',
        rol: -1,
        rolError: '',
        seletGroup: '',
        seletGroupError: '',
      },
      modalDeleteMembers: false,
      deleteMember: {
        type: '',
        board: 0,
        member: '',
        group: 0,
      },
      modalSaveBoardAsTemplate: false,
      formBoardTemplate: {
        board: 0,
        title: '',
        errTitle: '',
        description: '',
        copyFile: 0,
        errorCopyFile: '',
        tp: 1,
        file: '',
        errFile: '',
        categories: 0,
        errCategories: '',
        languages: '',
        errLanguages: '',
      },
      modalFilesOpenBoards: false,


      listView: true,
      storeListView: false,
      newItemList: false,
      listItem: {},
      modalStoredLists: false,
      modalCopyLists: false,
      myBoadsCopy: [],
      copyListForm: {
        title: '',
        errorTitle: '',
        board: -1,
        errorBoard: '',
        copyFile: '',
        errorCopyFile: '',        
      },
      newItemCard: false,
      listToAddCard: -1,
      calendarDate: '',
      cardItem: {},
      modalCopyOneCard: false,
      formCopyCard: {
        list: '',
        errorList: '',
        title: '',
        errorTitle: '',
        copyFile: '',
        errorCopyFile: '',
      },
      modalSaveCardAsTemplate: false,
      formTemplat: {
        title: '',
        errorTitle: '',
        copyFile: '',
        errorCopyFile: '',
      },

      upperDropPlaceholderOptions: {
        className: "cards-drop-preview",
        animationDuration: "120",
        showOnTop: true,
      },
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "120",
        showOnTop: true,
      },


      calendarView: false,
      calendarOptions: {
        locale: 'en',
        weekends: true,
        editable: false,
        droppable: true,
        eventDrop: this.handleDropEvent,
        selectable: false,
        selecHelper: false,
        selectMirror: false,
        dayMaxEvents: true,
        plugins: [dayGridPlugin, interactionPlugin],
        dateClick: this.handleDateClick,
        eventClick: this.onEventClick,
        eventMouseEnter: function (info) {
          info.el.title = info.el.text;
        },
        events: [],
        views: {
          dayGridWeek: {
            displayEventEnd: false,
            buttonText: 'Week',
          },
          dayGridMonth: {
            buttonText: 'Month',
            displayEventEnd: false,
            showNonCurrentDates: false,
          },
        },
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridWeek,dayGridMonth'
        },
        initialView: 'dayGridMonth',
        contentHeight: 700,
        aspectRatio: 1.5,
        eventColor: '#16aaff',
        eventClassNames: ['cardView'],
      },


      actualListMovil: 0,
      modalMoveListInMovil: false,
      listNewLevel: 0,
      modalMoveCardInMovil: false,
      formMoveCard: {
        indexList: 0,
        action: '',
        listToMove: 0,
        newlevel: 0,
      },

  
      listToMember: {},
      memberToList: {},
      memberAddLists: '',
      errorMemberAddLists: '',
      modalAddMembersOnLists: false,
      modalDeleteMembersOnLists: false,
    };
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang', 'modalAddEditBoards', 'modalAddEditLists', 'modalAddEditCards']),
    ...mapState('usersData', ['users', 'errorUsers']),
    ...mapState('boardsData', ['boards', 'boardItem', 'errorBoards', 'modalAddEditDeleteBoards']),
    ...mapState('listsAndCards', ['lists', 'calendarList', 'errorLists']),
    ...mapState('rolsData', ['rols', 'errorRols']),
    ...mapState('categoriesData', ['categories', 'errorCategories']),
    ...mapState('userGroupsData', ['filter', 'errorGroups']),


    /** Funcion para obtener los usuarios sin incluir los observadores */
    usersTeamNoViewers() {
      let data = [];
      this.users.forEach(item => {
        if (item.rol.name != 'viewersUser'){
          data.push(item);
        }
      })
      return data;
    },
    filterRols() {
      let data = [];

      this.rols.forEach(item => {
        if (this.userAccess.limits.users > this.usersTeamNoViewers.length) {
          if (item.name != 'superAdmin' && item.name != 'freeUser') {
            data.push(item);
          }
        } else {
          if (item.name == 'viewersUser') {
            data.push(item);
          }
        }
      });
      return data;
    },

    /**  */
    filterBoardByTeam() {
      let r = [];
      this.boards.forEach(item => {
        if (item.team_id == this.boardItem.team_id){
          r = item.boards;
        }
      })
      return r;
    },

    /** Funcion para obtener un arreglo de miembros de los tableros configurados para mostrarlos en las listas */
    filterBoardMembers() {
      let r = [];
      for (let i = 0; i < this.boardItem.members.length; i++) {
        const item = this.boardItem.members[i];
        if(item.type == 'user'){
          r.push({
            type: item.type,
            user_email: item.user_email,
            user_name: `${item.user_name} - ${item.user_email}`,
            group_id: item.group_id,
          });
        } else {
          r.push({
            type: item.type,
            user_email: item.user_email,
            user_name: `${this.t('headerData.userGroups', {}, { locale: this.lang })}: ${item.user_name}`,
            group_id: item.group_id,
          });

          item.group.forEach(it => {
            r.push({
              type: 'user',
              user_email: it.email,
              user_name: `${item.user_name}: ${it.name} - ${it.email}`,
              group_id: item.group_id,
            });
          })
        }
      }
      return r;
    }
  },
  methods: {    
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['changeModalAddEditLists', 'changeModalAddEditCards', 'loadSystemBackground']),
    ...mapActions('usersData', ['getUsersByTeamsData', 'registerUsersToBoardsMembers']),
    ...mapActions('boardsData', [
      'getSingleBoad', 'addLatestBoards', 
      'createDuplicateBoards', 'updateStoreStatus', 
      'getProfileMembersImg', 'addNewMembersBoards',
      'deleteMembersBoards', 'changeModalAddEditDeleteBoard', 'createTemplateBoards'
    ]),
    ...mapActions('listsAndCards', [
      'getListData', 'getCalendarListsData', 'storedList', 'copyList',
      'getStoredListsData', 'loadDataLists', 'updateListLevels',
      'createCopyCard', 'createTemplateByCard', 'updateCardsLevels',
      'updateCards', 'changeModalShowCardItem', 'resetDataLists', 'updateOneItemInChecklistClass',
      'addNewMemberInLists', 'deleteOneUserToLists'
    ]),
    ...mapActions('rolsData', ['getRolsData']),
    ...mapActions('categoriesData', ['getCategoriesData']),
    ...mapActions('userGroupsData', ['filterUserGroupsByTeam']),


    /** Funciones del Tablero */

    /** Funciones para mostrar el modal para Duplicar tablero */
    changeModalDuplicateBoard(){
      this.duplicate.title = '';
      this.duplicate.titleError = '';
      this.duplicate.copyFile = '';
      this.duplicate.errorCopyFile = '';
      this.modalDuplicateBoard = !this.modalDuplicateBoard;
    },

    /** Funcion para solicitar a la API que se cree la copia del tablero */
    async applyDuplicate(){

      this.duplicate.titleError = requiredFields(this.duplicate.title, this.lang);
      this.duplicate.errorCopyFile = requiredFields(this.duplicate.copyFile, this.lang);

      if (this.duplicate.titleError == '' && this.duplicate.errorCopyFile == '') {

        await this.createDuplicateBoards({
          board: this.boardItem.id,
          team: this.boardItem.team_id,
          title: this.duplicate.title,
          copyFile: this.duplicate.copyFile,
        });
        if (checkError401(this.errorBoards)) { this.logOut(); }
        if (this.errorBoards == null) { this.changeModalDuplicateBoard(); }
      }
    },

    /** Funcion para mostrar el modal para Archivar o desarchivar un tablero */
    changeModalStoreBoard(){
      this.modalStoreBoard = !this.modalStoreBoard;
    },

    /** Funcion para solicitar a la API que archive o desarchive un tablero */
    async storeBoard(){
      await this.updateStoreStatus({ id: this.boardItem.id, });
      if (checkError401(this.errorBoards)) { this.logOut(); }
      if (this.errorBoards == null) { 
        if (this.boardItem.stored){
          this.changeModalStoreBoard();
          this.$router.push('/dashboard');
        }else{
          this.changeModalStoreBoard();
        }
      }
    },

    /** Funcion para mostrar el modal para Add un miembro al tablero */
    changeModalAddMembers(){
      this.addMember = {
        menuActive: 0,
        seletMember: '',
        seletMemberError: '',
        name: '',
        nameError: '',
        surname: '',
        surnameError: '',
        phone: '',
        phoneError: '',
        email: '',
        emailError: '',
        rol: -1,
        rolError: '',
        seletGroup: '',
        seletGroupError: '',
      };
      this.modalAddMembers = !this.modalAddMembers;
    },

    /** Funcion para Add un miembro al tablero con la API */
    async addNewMember(){

      /** Agregar un miembro o un grupo de usuarios al tablero actual */
      if (this.addMember.menuActive == 0 || this.addMember.menuActive == 2) {

        /** Agregar un miembro individual */
        if(this.addMember.menuActive == 0) { 

          this.addMember.seletMemberError = requiredFields(this.addMember.seletMember, this.lang);
          if (this.addMember.seletMemberError == ''){

            await this.addNewMembersBoards({
              board_id: this.boardItem.id,
              user_email: this.addMember.seletMember,
              type: 'user'
            });
            if (checkError401(this.errorBoards)) { this.logOut(); }
            if (this.errorBoards == null) {
              this.addMember.seletMember = '';
              this.addMember.seletMemberError = '';
              this.getProfileMembersImg(this.addMember.seletMember);
            }
          }


        /** Agregar un gurpo de usuarios */
        } else {

          this.addMember.seletGroupError = requiredFields(this.addMember.seletGroup, this.lang);
          if (this.addMember.seletGroupError == ''){

            await this.addNewMembersBoards({
              board_id: this.boardItem.id,
              group_id: this.addMember.seletGroup,
              type: 'group'
            });
            if (checkError401(this.errorBoards)) { this.logOut(); }
            if (this.errorBoards == null) {
              this.addMember.seletGroup = '';
              this.addMember.seletGroupError = '';
            }
          }
        }

      /** Crear un usuario y añadirlo al tablero actual */
      } else {

        this.addMember.nameError = requiredFields(this.addMember.name, this.lang);
        this.addMember.surnameError = requiredFields(this.addMember.surname, this.lang);
        this.addMember.phoneError = requiredFields(this.addMember.phone, this.lang);
        this.addMember.emailError = requiredFields(this.addMember.email, this.lang);
        if (this.addMember.emailError != '') {
          this.addMember.emailError = emailFields(this.addMember.email, this.lang);
        }
        this.addMember.rolError = requiredFieldsNumber(this.addMember.rol, this.lang);

        if (this.addMember.nameError == '' && this.addMember.surnameError == '' && this.addMember.phoneError == '' && this.addMember.emailError == '' && this.addMember.rolError == ''){

          await this.registerUsersToBoardsMembers({
            ...this.addMember,
            team: this.boardItem.team_id,
          });
          await this.addNewMembersBoards({
            board_id: this.boardItem.id,
            user_email: this.addMember.email,
          });
          this.getProfileMembersImg(this.addMember.email);
          this.getUsersByTeamsData(this.boardItem.team_id);
          this.addMember = {
            menuActive: 1,
            seletMember: '',
            seletMemberError: '',
            name: '',
            nameError: '',
            surname: '',
            surnameError: '',
            phone: '',
            phoneError: '',
            email: '',
            emailError: '',
            rol: -1,
            rolError: '',
            seletGroup: '',
            seletGroupError: '',
          };
        }

      }
    },

    /** Funcion para mostrar el modal para Delete un miembro del tablero */
    changeModalDeleteMembers(item={}){

      if(Object.entries(item).length > 0) {

        this.deleteMember.type = item.type;
        this.deleteMember.member = item.type == 'user' ? item.user_email : '-';
        this.deleteMember.group = item.type == 'group' ? item.group_id : 0;
        
      } else {

        this.deleteMember.type = '';
        this.deleteMember.member = '';
        this.deleteMember.group = 0;

      }

      this.modalDeleteMembers = !this.modalDeleteMembers;
    },

    /** Funcion para Delete un miebro del tablero con la API */
    async actionDeleteMember(){
      if (Object.entries(this.deleteMember).length > 0) {

        await this.deleteMembersBoards({
          type: this.deleteMember.type,
          board: this.boardItem.id,
          email: this.deleteMember.member,
          group: this.deleteMember.group,
        });
        if (checkError401(this.errorBoards)) { this.logOut(); }
        if (this.errorBoards == null) { 
          this.changeModalDeleteMembers();
        }
      }
    },


    /** Funcion para mostrat el modal de los archivos adjuntos del tablero */
    changeModalFilesOpenBoards(){
      this.modalFilesOpenBoards = !this.modalFilesOpenBoards;
    },

    /** Funcion para cambiar el estatus del modal convertir tablero en template */
    changeModalTemplateBoard(){
      this.formBoardTemplate = {
        board: this.boardItem.id,
        title: `${showInfoData(this.boardItem.title, this.lang)} (Template)`,
        errTitle: '',
        description: '',
        copyFile: 0,
        errorCopyFile: '',
        tp: 1,
        file: '',
        errFile: '',
        categories: 0,
        errCategories: '',
        languages: '',
        errLanguages: '',
      };
      this.getCategoriesData();
      this.modalSaveBoardAsTemplate = !this.modalSaveBoardAsTemplate;
    },

    /** Funcion para detectar el Evento select files del modal agregar Archivo */
    handleFileUpload() {
      this.formBoardTemplate.file = this.$refs.file.files[0];
    },


    /** Funcion para crear un templete de un tablero */
    async saveBoardAsTemplate(){

      if (this.formBoardTemplate.tp == 0) { /** Publico */

        this.formBoardTemplate.errTitle = requiredFields(this.formBoardTemplate.title, this.lang);
        this.formBoardTemplate.errFile = requiredFields(this.formBoardTemplate.file, this.lang);
        this.formBoardTemplate.errCategories = requiredFieldsNumber(this.formBoardTemplate.categories, this.lang);
        this.formBoardTemplate.errLanguages = requiredFields(this.formBoardTemplate.languages, this.lang);

        if(this.formBoardTemplate.errTitle == '' && this.formBoardTemplate.errFile == '' && this.formBoardTemplate.errCategories == '' && this.formBoardTemplate.errLanguages == ''){
          let formData = new FormData();
          formData.append('board', this.formBoardTemplate.board);
          formData.append('title', this.formBoardTemplate.title);
          formData.append('creator', this.userInfo.e21);
          formData.append('copyFile', this.formBoardTemplate.copyFile);
          formData.append('tp', this.formBoardTemplate.tp);
          formData.append('description', this.formBoardTemplate.description);
          formData.append('file', this.formBoardTemplate.file);
          formData.append('categories', this.formBoardTemplate.categories);
          formData.append('languages', this.formBoardTemplate.languages);

          await this.createTemplateBoards(formData);
          if (checkError401(this.errorBoards)) { this.logOut(); }
          if (this.errorBoards == null) { this.changeModalTemplateBoard(); }
        }
      
      } else { /** Privado */

        this.formBoardTemplate.errTitle = requiredFields(this.formBoardTemplate.title, this.lang);

        if (this.formBoardTemplate.errTitle == '') {
          let formData = new FormData();
          formData.append('board', this.formBoardTemplate.board);
          formData.append('title', this.formBoardTemplate.title);
          formData.append('creator', this.userInfo.e21);
          formData.append('copyFile', this.formBoardTemplate.copyFile);
          formData.append('tp', this.formBoardTemplate.tp);
          formData.append('description', this.formBoardTemplate.description);
          formData.append('file', this.formBoardTemplate.file);
          formData.append('categories', this.formBoardTemplate.categories);
          formData.append('languages', this.formBoardTemplate.languages);

          await this.createTemplateBoards(formData);
          if (checkError401(this.errorBoards)) { this.logOut(); }
          if (this.errorBoards == null) { this.changeModalTemplateBoard(); }
        }
      }
    },


    /** Final funciones del Tablero */




    /** Funciones de las listas */


    /** Funcion para verificar si el usuario actual puede ver la lista */
    showListsToUser(members) {

      let verif = false;

      if (this.userAccess.rol.name == 'viewersUser') {
        members.forEach(item => {
          if(item.type == 'user') {
            if (this.userInfo.e21 == item.user_email) {
              verif = true;
            }
          }else{
            item.group.forEach(element => {
              if (this.userInfo.e21 == element) {
                verif = true;
              }
            });
          }
        });

      } else {

        if (members.length == 0) {

          return true;

        } else {

          if (checkSuperAdminRol(this.userInfo.access)) {

            verif = true;

          } else {

            if (this.userInfo.e21 == this.boardItem.owner) {

              verif = true;

            } else {

              members.forEach(item => {
                if(item.type == 'user') {
                  if (this.userInfo.e21 == item.user_email) {
                    verif = true;
                  }
                }else{
                  item.group.forEach(element => {
                    if (this.userInfo.e21 == element.email) {
                      verif = true;
                    }
                  });
                }
              });
            }
          }
        }
      }

      return verif;
    },


    /** Mostrar opciones de las listas y tarjetas */
    showOption(objet, id) {
      let icon = document.getElementById('icon' + objet + id);
      icon.style.display = "";
    },

    /** Ocultar opciones de las listas y tarjetas */
    hidenOption(objet, index) {

      if (objet === 'List') {

        this.lists.forEach(element => {

          let menu = document.getElementById('menu' + objet + element.id);
          if (menu != null) {
            menu.className = "dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu";
          }

          let icon = document.getElementById('icon' + objet + element.id);
          if (icon != null) {
            icon.style.display = "none";
          }
        });

      } else {

        let c = this.lists[index].cards;

        c.forEach(element => {
          let menu = document.getElementById('menu' + objet + element.id);
          if (menu != null) {
            menu.className = "dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu";
          }
          let icon = document.getElementById('icon' + objet + element.id);
          if (icon != null) {
            icon.style.display = "none";
          }
        });
      }
    },

    /** Funcion para abrir el modal para Add y EditList */
    changeModalLists(newItemList, list = {}){
      this.newItemList = newItemList;
      this.listItem = list;
      this.changeModalAddEditLists();
    },

    /** Funcion para mostrar el modal para archivar o desarchivar una lista */
    changeModalStoreList(item = {}){
      this.listItem = item;
      this.modalStoredLists = !this.modalStoredLists;
    },

    /** Funcion para archivar o desarchivar una lista */
    async saveStoreLists(){
      await this.storedList(this.listItem.id);
      if (checkError401(this.errorLists)) { this.logOut(); }
      if (this.errorLists == null) {
        this.changeModalStoreList();
      }
    },

    /** Funcion para mostrar el modal para copiar una lista */
    changeModalCopyLists(item = {}){
      this.listItem = item;
      if(Object.entries(item).length > 0){
        this.myBoadsCopy = [];
        this.boards.forEach(element => {
          element.boards.forEach(item => {
            if (item.owner == this.userInfo.e21) {
              this.myBoadsCopy.push(item);
            }
          })
        });
      }
      this.copyListForm = {
        title: '',
        errorTitle: '',
        board: -1,
        errorBoard: '',
        copyFile: '',
        errorCopyFile: '',
      };
      this.modalCopyLists = !this.modalCopyLists;
    },

    /** Funcion para copiar una Lista */
    async copyLists() {

      this.copyListForm.errorBoard = requiredFields(this.copyListForm.board, this.lang);
      this.copyListForm.errorTitle = requiredFields(this.copyListForm.title, this.lang);
      this.copyListForm.errorCopyFile = requiredFields(this.copyListForm.copyFile, this.lang);

      if (this.copyListForm.errorBoard == '' && this.copyListForm.errorTitle == '' && this.copyListForm.errorCopyFile == '') {
        this.copyList({
          id: this.listItem.id,
          idBoard: this.copyListForm.board,
          title: this.copyListForm.title,
          copyFile: this.copyListForm.copyFile,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.copyListForm = {
            title: '',
            errorTitle: '',
            board: -1,
            errorBoard: '',
            copyFile: '',
            errorCopyFile: '',   
          };
          this.changeModalCopyLists();
        }
      }
    },

    /** Funcion para mostrar o ocultar la vista de listas archivadas */
    async loadStoreListView(){

      if (this.storeListView) {

        await this.getListData(this.boardItem.id);
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.storeListView = false;
          this.calendarView = false;
          this.listView = true;
        }

      } else {

        await this.getStoredListsData(this.boardItem.id);
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.calendarView = false;
          this.storeListView = true;
          this.listView = true;
        }
      }
    },

    /** Funcion para mover una Lista de posicion  */
    onColumnDrop(dropResult) { 
      let lists = Object.assign([], this.lists);
      lists = this.applyDrag(lists, dropResult);
      this.loadDataLists(lists);
      this.apiDragLists();
    },

    /** Funcion para aplicar cambio en el orden de un elemento */
    applyDrag(arr, dragResult) {

      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },

    /** Funcion para consolidar con la API el nuevo orden de las Listas */
    async apiDragLists() { 
      let r = [];
      for (let i = 0; i < this.lists.length; i++) {
        this.lists[i].nivel = i+1;
        r.push({
          list: this.lists[i].id,
          nivel: i + 1,
        });
      }

      await this.updateListLevels({ data: JSON.stringify(r) });
      if (checkError401(this.errorBoards)) { this.logOut(); }
    },

    /** Funcion para cambiar el estado del modal para mover las listas en vista movil */
    changeModalMoveListsInMovil(item = {}) {
      this.listItem = item;
      this.listNewLevel = 0;
      this.modalMoveListInMovil = !this.modalMoveListInMovil;
    },

    /** Funcion para ordenar o mover una tarjeta en vista movil */
    applyDropToListInMovil() {

      if (this.listNewLevel != 0) {

        let newlists = Object.assign([], this.lists);
        newlists = this.lists.filter(item => item.id != this.listItem.id);
        let newIndex = 0;
        newlists.forEach((item, index) => {
          if (item.id == this.listNewLevel) {
            newIndex = index;
          }
        });

        newlists.splice(newIndex, 0, this.listItem);

        this.loadDataLists(newlists);
        this.actualListMovil = 0;
        this.apiDragLists();
        this.changeModalMoveListsInMovil();
      }
    },


    /** Funcion para Cambiar el estatus del modal para agregar un miembro a una lista */
    changeModalAddMembersOfLists(list = {}) {
      this.listToMember = list;
      this.modalAddMembersOnLists = !this.modalAddMembersOnLists;
    },


    /** Funcion para agregar un miembro a una lista */
    async saveNewMemberInLists() {

      this.errorMemberAddLists = requiredFields(this.memberAddLists, this.lang);
      if (this.errorMemberAddLists == '') {

        this.addNewMemberInLists({
          type: this.memberAddLists.type,
          list_id: this.listToMember.id,
          user_email: this.memberAddLists.user_email,
          group_id: this.memberAddLists.group_id,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.memberAddLists = '';
          this.errorMemberAddLists = '';
        }
      }
    },


    /** Funcion para cambiar el estatus del modal para eliminar miembros de las listas */
    changeModalDeleteMembersOfLists(list = {}, member = {}) {
      this.listToMember = list;
      this.memberToList = member;
      this.modalDeleteMembersOnLists = !this.modalDeleteMembersOnLists;
    },

    /** Funcion para eliminar un miembro de una lista */
    async deleteMembersToLists() {
      await this.deleteOneUserToLists({
        list: this.listToMember.id,
        email: this.memberToList.type == 'user' ? this.memberToList.user_email : '-',
        type: this.memberToList.type,
        group: this.memberToList.group_id,
      });
      if (checkError401(this.errorLists)) { this.logOut(); }
      if (this.errorLists == null) {
        this.changeModalDeleteMembersOfLists();
      }
    },

    /** Final funciones de las listas */






    /** Funciones de las tarjetas */

    /** Funcion para verificar si el usuario actual puede ver la tarjeta */
    showCardToUser(members) {

      let verif = false;

      if (this.userAccess.rol.name == 'viewersUser') {
      
        members.forEach(item => {
          if (this.userInfo.e21 == item.user_email) {
            verif = true;
          }
        }); 

      } else {

        if (members.length == 0) {

          return true;

        } else {

          if (checkSuperAdminRol(this.userInfo.access)) {
            
            verif = true;

          } else {

            if (this.userInfo.e21 == this.boardItem.owner) {

              verif = true;

            } else {
              members.forEach(item => {
                if(item.type == 'user') {
                  if (this.userInfo.e21 == item.user_email) {
                    verif = true;
                  }
                }else{
                  item.group.forEach(element => {
                    if (this.userInfo.e21 == element.email) {
                      verif = true;
                    }
                  });
                }
              });
            }
          }
        }
      }

      return verif;
    },

    /** Validar borde de las tarjetas */
    validateBorder(card) {

      let hoy = moment().format('YYYY-MM-DD' + ' 23:59:59');
      let f = card.expiration.substring(0, 10) + ' 23:59:59';

      if (card.status == true) { /** Completada */

        return "border-left:5px solid green; padding: 0px !important;";

      } else if (card.checklist_completed == card.checklist_cant && card.checklist_cant != 0) { /** Todos los checklist completados */

        return "border-left:5px solid green; padding: 0px !important;";

      } else if ('1600-01-01' == card.expiration.substring(0, 10)) { /** Sin fecha de expiracion */

        return "padding: 0px !important;";

      } else if (moment(hoy).unix() > moment(f).unix()) { /** Vencida */

        return "border-left:5px solid red; padding: 0px !important;";

      }

      return "padding: 0px !important;"; /** Defecto */
    },

    /** Funcion para abrir el modal de Add y Edit Tarjetas */
    openModalAddEditCards(newItem, listId, cardItem){

      this.newItemCard = newItem;
      this.listToAddCard = listId;
      this.cardItem = cardItem;

      this.changeModalAddEditCards();
    },

    /** Funcion para abrir el modal de copiar tarjetas */
    changeModalCopyCards(item = {}){
      this.cardItem = item;
      this.formCopyCard = {
        list: '',
        errorList: '',
        title: '',
        errorTitle: '',
        copyFile: '',
        errorCopyFile: '',
      };
      this.modalCopyOneCard = !this.modalCopyOneCard;
    },

    /** Funcion para copiar una tarjeta en una lista */
    async applyCopyToCard(){

      this.formCopyCard.errorList = requiredFields(this.formCopyCard.list, this.lang);
      this.formCopyCard.errorTitle = requiredFields(this.formCopyCard.title, this.lang);
      this.formCopyCard.errorCopyFile = requiredFields(this.formCopyCard.copyFile, this.lang);

      if (this.formCopyCard.errorList == '' && this.formCopyCard.errorTitle == '' && this.formCopyCard.errorCopyFile == ''){

        this.createCopyCard({
          card: this.cardItem.id,
          list: this.formCopyCard.list,
          title: this.formCopyCard.title,
          copyFile: this.formCopyCard.copyFile,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.changeModalCopyCards();
        }
      }
    },

    /** Funcion para mostrar el modal para guardar una tarjeta en template */
    changeModalSaveCardAsTemplate(item = {}){
      this.cardItem = item;
      this.formTemplat.title = Object.entries(item).length != 0 ? `${showInfoData(this.cardItem.title)} (Template)` : '';
      this.formTemplat.copyFile = '';
      this.formTemplat.errorCopyFile = '';
      this.modalSaveCardAsTemplate = !this.modalSaveCardAsTemplate;
    },

    /** Funcion para crear un Template en base a una tarjeta */
    async saveCardAsTemplate(){

      this.formTemplat.errorTitle = requiredFields(this.formTemplat.title, this.lang);
      this.formTemplat.errorCopyFile = requiredFields(this.formTemplat.copyFile, this.lang);

      if (this.formTemplat.errorTitle == '' && this.formTemplat.errorCopyFile == ''){

        this.createTemplateByCard({
          card: this.cardItem.id,
          title: this.formTemplat.title,
          copyFile: this.formTemplat.copyFile,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.formTemplat = {
            title: '',
            errorTitle: '',
            copyFile: '',
            errorCopyFile: '',
          };
          this.changeModalSaveCardAsTemplate();
        }
      }

    },

    /** Funcion para detectar cambios en el orden de las tarjetas */
    async onCardDrop(listId, dropResult) {

      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {

        let lists = Object.assign([], this.lists);
        let list = lists.filter((p) => p.id === listId)[0];
        let listIndex = lists.indexOf(list);

        let newList = Object.assign({}, list);
        newList.cards = this.applyDrag(newList.cards, dropResult);
        lists.splice(listIndex, 1, newList);
        await this.loadDataLists(lists);
        await this.saveNewLeveCards();
      }
    },

    /** Funcion para retornar el index de las listas */
    getCardPayload(listId) {
      return (index) => {
        return this.lists.filter((p) => p.id === listId)[0].cards[index];
      };
    },

    /** Funcion para tomar el nuevo orden de las tarjetas y enviarlo a la api */
    async saveNewLeveCards() { 

      /** Se recorren las listas y se toma el nuevo orden */
      let data = [];
      this.lists.forEach(item => {
        for (let i = 0; i < item.cards.length; i++) {
          item.cards[i].nivel = i+1;
          data.push({
            list: item.id,
            card: item.cards[i].id,
            nivel: i+1,
          });
        }
      });

      await this.updateCardsLevels({ data: JSON.stringify(data) });
      if (checkError401(this.errorLists)) { this.logOut(); }
    },

    /** Funcion para cambiar el estado del modal para mover las tarjetas en vista movil */
    changeModalMoveCardsInMovil(item = {}, i = 0) {
      this.cardItem = item;
      this.formMoveCard = {
        indexList: i,
        action: '',
        listToMove: 0,
        ordenLevel: 0,
      };
      this.modalMoveCardInMovil = !this.modalMoveCardInMovil;
    },

    /** Funcion para ordenar o mover una tarjeta en vista movil */
    applyDropToCardInMovil(){
      if(this.formMoveCard.action != ''){

        let newlists = Object.assign([], this.lists);
        let indexNew = 0; 

        if (this.formMoveCard.action == 'order') {

          newlists[this.formMoveCard.indexList].cards = this.lists[this.formMoveCard.indexList].cards.filter(item => item.id !== this.cardItem.id);
          newlists[this.formMoveCard.indexList].cards.forEach((item, index) => {
            if (item.id == this.formMoveCard.newlevel) {
              indexNew = index;
            }
          })

          newlists[this.formMoveCard.indexList].cards.splice(indexNew, 0, this.cardItem);
        
        } else {

          let indexR = 0;

          newlists[this.formMoveCard.indexList].cards = this.lists[this.formMoveCard.indexList].cards.filter(item => item.id !== this.cardItem.id);
          newlists.forEach((item, index) => {
            if (item.id == this.lists[this.formMoveCard.listToMove].id) {
              indexR = index;
              item.cards.forEach((item2, index2) => {
                if (item2.id == this.formMoveCard.newlevel) {
                  indexNew = index2;
                }
              })
            }
          }); 

          newlists[indexR].cards.splice(indexNew, 0, this.cardItem);
        }

        this.loadDataLists(newlists);
        this.saveNewLeveCards();
        this.changeModalMoveCardsInMovil();
      }
    },

    /** Final funciones de las tarjetas */








    

    /** Funciones para la vista calendario (Tarjetas) */

    /** Funcion para mostrar o ocultar la vista de calendario de un tablero */
    async showCalendarView(){

      if (this.calendarView) {

        await this.getListData(this.boardItem.id);
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.calendarView = false;
          this.storeListView = false;
          this.listView = true;
        }        
              
      }else{

        await this.getCalendarListsData(this.boardItem.id);
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.calendarOptions.locale = this.lang;
          this.calendarOptions.events = this.calendarList;
          this.listView = false;
          this.storeListView = false;
          this.calendarView = true;
        }
      }
    },

    /** Funcion para detectar clic en una fecha del calendario */
    handleDateClick: function (arg) {

      this.calendarDate = arg.dateStr;
      this.openModalAddEditCards(false, -1, {})
    },

    /** Funcion para actualizar la fecha de una tarea desde el calendario */
    handleDropEvent: async function (arg) {
      let date = moment(arg.event.start.toISOString()).format('YYYY-MM-DD');

      if(arg.event.extendedProps.tp == 1) {
        await this.updateCards({
          id: arg.event.id,
          expiration: date,
        });
      } else {
        await this.updateOneItemInChecklistClass({
          id: arg.event.extendedProps.idCk,
          expiration: date,
        })
      }

      if (checkError401(this.errorLists)) { this.logOut(); }
      await this.getCalendarListsData(this.boardItem.id);
    },

    /** Funcion para detectar el clic en un evento del calendario */
    onEventClick(arg) {
      this.changeModalShowCardItem(arg.event._def.publicId);
    },

    /** Funciones para la vista calendario (Tarjetas) */


    /** Funciones para la vista en dispositivos pequeños */

    /** Funcion para moverse entre las listas en vista movil */
    buttonMovilList(op) {
      /** Atras */
      if(op == '-') {

        this.actualListMovil = this.actualListMovil == 0 ? this.lists.length-1 : this.actualListMovil - 1;

      /** Adelante */
      } else {

        this.actualListMovil = this.actualListMovil == this.lists.length - 1 ? 0 : this.actualListMovil + 1;
      }

    },

    /** Funcion para dirigirse a una lista especifica */
    movilMenuListIr(i) {
      this.actualListMovil = i;
    },
  },
  watch: {
    async $route() {

      this.calendarView = false;

      /** Si cambio de tablero */
      if (this.$route.path != '/dashboard') {

        this.resetDataLists();

        const keyId = this.$route.params.code.split('.');

        await this.getSingleBoad(keyId[0]);
        if (checkError401(this.errorBoards)) { this.logOut(); }
        if (this.errorBoards == null) {

          this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.boardItem.team_id);
          this.loadSystemBackground(this.boardItem.link_img);
          this.boardItem.members.forEach(async item => {
            this.getProfileMembersImg(item.user_email);
          });
          this.getUsersByTeamsData(this.boardItem.team_id);
          this.getRolsData();
        }

        await this.getListData(keyId[0]);
        if (checkError401(this.errorLists)) { this.logOut(); }

        this.addLatestBoards({ board: keyId[0] });

        closeMenu2();
        closeMenu();
      }
    },
    /** Cambiar el idioma del calendario al cambiar el idioma del sistema */
    lang() {
      this.calendarOptions.locale = this.lang;
    },
    /** Actualizar la data del calendario se la misma presenta un cambio */
    calendarList() {
      this.calendarOptions.events = this.calendarList;
    }
  },
  mounted: async function () {

    this.resetDataLists();

    const keyId = this.$route.params.code.split('.');

    await this.getSingleBoad(keyId[0]);
    if (checkError401(this.errorBoards)) { this.logOut(); }
    if (this.errorBoards == null){
      this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.boardItem.team_id);
      this.loadSystemBackground(this.boardItem.link_img);
      this.boardItem.members.forEach(async item => {
        if(item.type == 'user') {
          this.getProfileMembersImg(item.user_email);
        }
      });
      this.getUsersByTeamsData(this.boardItem.team_id);
      this.getRolsData();
    }

    await this.getListData(keyId[0]);
    if (checkError401(this.errorLists)) { this.logOut(); }

    if(keyId[2] != undefined){
      this.changeModalShowCardItem(keyId[2]);
    }

    this.addLatestBoards({ board: keyId[0] });

    this.filterUserGroupsByTeam(this.boardItem.team_id);

  }
}
</script> 


<style scoped>
.app-page-title .page-title-actions {
  margin-left: 1px;
}

.page-title-actions {
  margin-right: auto;
}

.espac {
  margin-right: 4px;
}

.button-font {
  font-size: 15px;
  color: white;
}

.card-body {
  flex: 1 1 auto;
  padding: 12px;
}

.card.mb-3 {
  height: 100px !important;
  margin-bottom: 10px !important;
}

.card-header {
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: .625rem;
  height: 2rem;
  padding: .30rem 0rem;
  margin-bottom: 0;
  color: inherit;
  background-color: #fff;
  border-bottom: 0px solid rgba(247, 245, 245, 0.125);
}

@media (min-width: 300px) and (max-width: 374px) {

  .card-header,
  .card-title {
    text-transform: none;
    color: rgba(32, 33, 34, 0.7);
    font-weight: bold;
    font-size: 11px;
  }

  .hiden-big {
    display: none !important;
  }

  .card-container {
    padding: 10px;
    margin: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .24);
    border-radius: 4px;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgb(223, 221, 221);
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(173, 173, 173, 0.1), 0 0 1px rgba(185, 184, 184, 0.1);
    transition: border-color .2s linear;
  }

  .button-align {
    text-align: center;
  }

  .margin-movil {
    margin-top: 8px;
    text-align: center;
  }
}

@media (min-width: 375px) and (max-width: 575px) {

  .card-header,
  .card-title {
    text-transform: none;
    color: rgba(32, 33, 34, 0.7);
    font-weight: bold;
    font-size: 11px;
  }

  .hiden-big {
    display: none !important;
  }

  .card-container {
    padding: 10px;
    margin: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .24);
    border-radius: 4px;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgb(223, 221, 221);
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(173, 173, 173, 0.1), 0 0 1px rgba(185, 184, 184, 0.1);
    transition: border-color .2s linear;
  }

  .button-align {
    text-align: center;
  }

  .margin-movil {
    margin-top: 8px;
    text-align: center;
  }
}

@media (min-width: 576px) and (max-width: 767px) {

  .card-header,
  .card-title {
    text-transform: none;
    color: rgba(32, 33, 34, 0.7);
    font-weight: bold;
    font-size: 11px;
  }

  .hiden-small {
    display: none !important;
  }

  .card-container {
    padding: 10px;
    margin: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .24);
    border-radius: 4px;
    max-width: 280px;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgb(223, 221, 221);
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(173, 173, 173, 0.1), 0 0 1px rgba(185, 184, 184, 0.1);
    transition: border-color .2s linear;
    min-width: 230px;
    max-width: 250px;
  }

  .button-align {
    text-align: center;
  }
}

@media (min-width: 768px) {

  .card-header,
  .card-title {
    text-transform: none;
    color: rgba(32, 33, 34, 0.7);
    font-weight: bold;
    font-size: 11px;
  }

  .hiden-small {
    display: none !important;
  }

  .card-container {
    padding: 10px;
    margin: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .24);
    border-radius: 4px;
    max-width: 280px;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgb(223, 221, 221);
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(173, 173, 173, 0.1), 0 0 1px rgba(185, 184, 184, 0.1);
    transition: border-color .2s linear;
    min-width: 230px;
    max-width: 250px;
  }

  .button-align {
    text-align: right;
  }
}

@media (min-width: 992px) {

  .card-header,
  .card-title {
    text-transform: none;
    color: rgba(32, 33, 34, 0.7);
    font-weight: bold;
    font-size: 11px;
  }

  .hiden-small {
    display: none !important;
  }

  .card-container {
    padding: 10px;
    margin: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .24);
    border-radius: 4px;
    max-width: 280px;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgb(223, 221, 221);
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(173, 173, 173, 0.1), 0 0 1px rgba(185, 184, 184, 0.1);
    transition: border-color .2s linear;
    min-width: 230px;
    max-width: 250px;
  }

  .button-align {
    text-align: right;
  }
}

@media (min-width: 1200px) {

  .card-header,
  .card-title {
    text-transform: none;
    color: rgba(32, 33, 34, 0.7);
    font-weight: bold;
    font-size: 11px;
  }

  .hiden-small {
    display: none !important;
  }

  .card-container {
    padding: 10px;
    margin: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .24);
    border-radius: 4px;
    max-width: 280px;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgb(223, 221, 221);
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(173, 173, 173, 0.1), 0 0 1px rgba(185, 184, 184, 0.1);
    transition: border-color .2s linear;
    min-width: 230px;
    max-width: 250px;
  }

  .button-align {
    text-align: right;
  }
}

.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0rem 0 0;
  width: 100%;
}

.style-1icon {
  color: white;
  font-size: 18px;
}

.style-img-user {
  padding-right: 2px !important;
  padding-left: 5px !important;
  max-width: 22.333% !important;
}

.style-data-user {
  font-size: 12px;
  padding-right: 2px !important;
  padding-left: 5px !important;
}

.style-icon-delete-user {
  color: red;
  cursor: default;
  font-size: 14px;
  padding-left: 2px !important;
}

.movil-conten-style {
  max-height: 75vh;
  max-width: 94vw;
  margin-left: 6vw;
  margin-right: 2vw;
}
</style>