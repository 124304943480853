<template>
  <div class="col-md-12" :style="typeFile == 'board' ? {} : {'margin-top':'1.5rem'}">
    <div class="row">
      <div class="col-md-12" :style="typeFile == 'board' ? {'margin-bottom': '2%'} : {}">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <i class="fas fa-file-alt"> </i>
          {{ t('modalsFilesData.attachedFiles', { }, { locale: lang }) }}
          <div class="btn-group dropdown">
            <button 
              v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('add:FilesCard', userAccess.permissions))"
              type="button" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false"
              class="mb-2 mr-2 btn-icon-only btn-shadow btn-dashed btn btn-outline-info btn-sm"
              :style="{ 'margin-left': typeFile == 'board' ? '30px' : '20px', 'border-radius':'20px!important'}"
              :title="t('modalsFilesData.addFile', {}, { locale: lang })"
            >
              <i class="fas fa-plus btn-icon-wrapper"> </i>
            </button>
            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu">
              <button type="button" tabindex="0" class="dropdown-item" @click="changeModalAddNewFile()">
                <i class="dropdown-icon fas fa-download"> </i>
                <span>{{ t('modalsFilesData.addFile', { }, { locale: lang }) }}</span>
              </button>
              <button type="button" tabindex="0" class="dropdown-item" @click="loadPickerGoogleDrive()">
                <i class="dropdown-icon"><img src="@/assets/googleDriveIcon/gDrive.png" :style="{'width':'65%'}"></i>
                <span>{{ t('modalsFilesData.driveInfo', { }, { locale: lang }) }}</span>
              </button>
              <button v-if="urlRoot != 'https://app.flashteam360.com/'" type="button" tabindex="0" class="dropdown-item" @click="oneDrivePickerApiLoad()">
                <i class="dropdown-icon"><img src="@/assets/oneDriveIcon/oneDriveIcon.png" :style="{'width':'80%'}"></i>
                <span>{{ t('modalsFilesData.oneDriveFiles', { }, { locale: lang }) }}</span>
              </button>
            </div>
          </div>
        </h5>
      </div>
      <div class="mb-3" :style="{'width':'100% !important', 'margin-top':'-15px !important'}">
        <div v-if="filesShow.length > 0" class="card-header">
          <ul class="nav nav-justified">
            <li class="nav-item" v-show="countLocal > 0">
              <a data-toggle="tab" href="#tab-file-0" :class="countLocal > 0 ? 'nav-link active show' : 'nav-link'" :style="{'font-size':'15px', 'color':'#3f6ad8'}">
                {{ `${t('modalsFilesData.files', {}, { locale: lang })} (${countLocal})` }}
              </a>
            </li>
            <li class="nav-item" v-show="countDrive > 0">
              <a data-toggle="tab" href="#tab-file-1" :class="(countLocal == 0 && countDrive > 0) ? 'nav-link active show' : 'nav-link'" :style="{'font-size':'15px', 'color':'#3f6ad8'}">
                {{ `${t('modalsFilesData.driveFiles', {}, { locale: lang })} (${countDrive})` }}
              </a>
            </li>
            <li v-if="urlRoot != 'https://app.flashteam360.com/'" class="nav-item" v-show="countOneDrive > 0">
              <a data-toggle="tab" href="#tab-file-2" class="nav-link" :style="{'font-size':'15px', 'color':'#3f6ad8'}">
                {{ `${t('modalsFilesData.oneDriveFiles', {}, { locale: lang })} (${countOneDrive})` }}
              </a>
            </li>
          </ul>
        </div>
        <div v-if="filesShow.length != 0" class="card-body" :style="{'margin-bottom':'-30px !important'}">
          <div class="tab-content">

            <!-- Archivos locales -->
            <div :class="countLocal > 0 ? 'tab-pane active' : 'tab-pane'" id="tab-file-0" role="tabpanel">
              <div class="row">
                <div v-for="file in loadArrayData('local', filesShow)" :key="file.id"
                  class="col-md-6 col-lg-6" @mouseenter="file.visibility = !file.visibility"
                  @mouseleave="file.visibility = !file.visibility"
                >
                  <div class="card-shadow-primary widget-chart widget-chart2 text-left card style-icon-file">
                    <div class="widget-content p-0 w-100">
                      <div class="widget-content-outer">
                        <div class="row">
                          <div class="col-md-4 text-center" @click="openOneFile(file)">
                            <img :src="imgLocal[iconLocalFile(file.file.mimetype)]" class="img-size-flash">
                          </div>
                          <div class="col-md-6" @click="openOneFile(file)">
                            <b :style="{'font-size':'12px'}">{{ showNameFile(file.file) }}</b>
                          </div>
                          <div class="col-md-2">
                            <i v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('delete:FilesCard', userAccess.permissions))"
                              v-show="file.visibility" class="lnr-trash btn-icon-wrapper delete-icon"
                              :title="t('modalsFilesData.deleteFile', {}, { locale: lang })"
                              @click="changeModalDelete(file)"></i>
                            <i v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('delete:FilesCard', userAccess.permissions))"
                              class="movil-show lnr-trash btn-icon-wrapper delete-icon"
                              :title="t('modalsFilesData.deleteFile', {}, { locale: lang })"
                              @click="changeModalDelete(file)"> </i>
                          </div>
                          <div class="col-md-12 text-right" :style="{'font-size':'0.7rem'}">
                            {{ moment(file.createdAt).format('MM/DD/YYYY') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Archivos de Google Drive -->
            <div :class="(countLocal == 0 && countDrive > 0) ? 'tab-pane active' : 'tab-pane'" id="tab-file-1" role="tabpanel">
              <div class="row">
                <div v-for="file in loadArrayData('drive', filesShow)" :key="file.id"
                  class="col-md-6 col-lg-6" @mouseenter="file.visibility = !file.visibility"
                  @mouseleave="file.visibility = !file.visibility">
                  <div class="card-shadow-primary widget-chart widget-chart2 text-left card style-icon-file">
                    <div class="widget-content p-0 w-100">
                      <div class="widget-content-outer">
                        <div class="row">
                          <div class="col-md-4 text-center" @click="openOneFile(file)">
                            <img :src="imgDrive[iconDriveFile(file.file.mimetype)]" class="img-size-flash">
                          </div>
                          <div class="col-md-6" @click="openOneFile(file)">
                            <b :style="{'font-size':'12px'}">{{ showNameFile(file.file) }}</b>
                          </div>
                          <div class="col-md-2">
                            <i v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('delete:FilesCard', userAccess.permissions))"
                              v-show="file.visibility" class="lnr-trash btn-icon-wrapper delete-icon"
                              :title="t('modalsFilesData.deleteFile', {}, { locale: lang })"
                              @click="changeModalDelete(file)"> </i>
                            <i v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('delete:FilesCard', userAccess.permissions))" 
                              class="movil-show lnr-trash btn-icon-wrapper delete-icon"
                              :title="t('modalsFilesData.deleteFile', {}, { locale: lang })"
                              @click="changeModalDelete(file)"> </i>
                          </div>
                          <div class="col-md-12 text-right" :style="{'font-size':'0.7rem'}">
                            {{ moment(file.createdAt).format('MM/DD/YYYY') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Archivos de OneDrive -->
            <div class="tab-pane" id="tab-file-2" role="tabpanel">
              <div class="row">
                <div v-for="file in loadArrayData('oneDrive', filesShow)" :key="file.id"
                  class="col-md-6 col-lg-6" @mouseenter="file.visibility = !file.visibility"
                  @mouseleave="file.visibility = !file.visibility">
                  <div class="card-shadow-primary widget-chart widget-chart2 text-left card style-icon-file">
                    <div class="widget-content p-0 w-100">
                      <div class="widget-content-outer">
                        <div class="row">
                          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center"
                            @click="openOneFile(file)">
                            <img :src="imgOneDrive[iconDriveFile(file.file.mimetype)]" class="img-size-flash">
                          </div>
                          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center"
                            @click="openOneFile(file)">
                            <b class="text-center" style="font-size: 12px;">{{ showNameFile(file.file) }}</b>
                          </div>
                          <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 text-center">
                            <i v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('delete:FilesCard', userAccess.permissions))" 
                              v-show="file.visibility" class="lnr-trash btn-icon-wrapper text-center delete-icon"
                              :title="t('modalsFilesData.deleteFile', {}, { locale: lang })"
                              @click="changeModalDelete(file)"> </i>
                            <i v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('delete:FilesCard', userAccess.permissions))" 
                              class="movil-show lnr-trash btn-icon-wrapper text-center delete-icon"
                              :title="t('modalsFilesData.deleteFile', {}, { locale: lang })"
                              @click="changeModalDelete(file)"> </i>
                          </div>
                          <div class="col-md-12 text-right" :style="{'font-size':'0.7rem'}">
                            {{ moment(file.createdAt).format('MM/DD/YYYY') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Agregar Archivos locales -->
  <div v-if="modalAddNewFile">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalsFilesData.addFile', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalAddNewFile()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div v-if="!loadingFile" class="modal-body dropzone" :class="{'active-dropzone': dropActive }"
                @dragenter.prevent="handleDropZoneIn" @dragleave.prevent="handleDropZoneEnd" @dragover.prevent
                @drop="drop($event)">

                <label for="file">

                  <img src="@/assets/file-upload.svg" :style="{ 'width': '3.8rem' }">

                  <span :style="{'font-size':'12px'}">
                    <b>{{ t('modalsFilesData.dragDropFile', { }, { locale: lang }) }}</b>
                  </span>
                  
                  <input class="inputdropzone" type="file" id="file" ref="file" @change="handleFileUpload()">

                </label>
                
                <span v-if="newFile != ''">
                  <b>{{ t('modalsFilesData.file', { }, { locale: lang }) }}: </b> {{ newFile.name }}
                </span>
              </div>

              <div v-else class="modal-body loader-zone">
                <div class="loader-wrapper d-flex justify-content-center align-items-center">
                  <div class="loader">
                    <div class="ball-scale-ripple-multiple">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="addNewFileInCard()">
                  {{ t('button.add', { }, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalAddNewFile()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal Agregar Archivos locales -->

  <!-- Modal Eliminar archivos -->
  <div v-if="modalDeleteOneFile">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalsFilesData.deleteFile', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalDelete()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <h6>{{ t('modalsFilesData.fileDeleteMsj', { }, { locale: lang }) }}</h6>
                <b>{{ showNameFile(itemFile.file) }}</b>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deleteTheFile()">
                  {{ t('button.delete', { }, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalDelete()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal Eliminar archivos -->
</template>


<script>
import { checkError401, checkSuperAdminRol, checPermissions, filterUserAccessByTeam } from '@/utility/functions';
import { useI18n } from 'vue-i18n';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'FilesSection',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      checkSuperAdminRol,
      checPermissions,
      moment
    };
  },
  props: {
    typeFile: String,
  },
  data: function () {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      /** Datos para usar Google Drive */
      clientId: "583479376489-aehbh7qprtfbrr50jgsbaab3b481o8o8.apps.googleusercontent.com",
      appId: "583479376489",
      scope: ['https://www.googleapis.com/auth/drive.file'],
      oauthToken: '',
      pickerApiLoaded: false,
      developerKey: 'AIzaSyBtc-Pkz7uMTZThrgfEjFluL_z3_SYifDw',
      
      /** Datos para usar OneDrive */
      oneDriveClientId: '0d3c9e7b-7a81-46ea-9a4d-81162a3d3d4d',
      
      /** Arreglo con las imagenes para los diferentes archivos */
      imgLocal: [
        require("@/assets/localIcon/local-media.png"),
        require("@/assets/localIcon/local-doc.png"),
      ],
      imgDrive: [
        require("@/assets/googleDriveIcon/drive-folder.png"),
        require("@/assets/googleDriveIcon/drive-media.png"),
        require("@/assets/googleDriveIcon/drive-doc.png"),
      ],
      imgOneDrive: [
        require("@/assets/oneDriveIcon/oneDrive-files.png"),
        require("@/assets/oneDriveIcon/oneDrive-media.png"),
        require("@/assets/oneDriveIcon/oneDrive-doc.png"),
      ],
      urlRoot: '',

      itemFile: {},
      modalAddNewFile: false,
      loadingFile: false,
      newFile: '',
      dropActive: false,
      modalDeleteOneFile: false,
    };
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang']),
    ...mapState('listsAndCards', ['cardItemId', 'cardItem', 'errorLists']),
    ...mapState('boardsData', ['boardItem', 'errorBoards']),
    filesShow() {
      if (this.typeFile == 'board') {
        return this.boardItem.attachments;
      } else {
        return this.cardItem.attachments;
      }
    },
    /** Propiedad Computada para obtener el numero de archivos locales */
    countLocal() {
      let res = 0;
      this.filesShow.forEach(element => {
        if ((element.file.source != 'drive') && (element.file.source != 'oneDrive')) {
          res++;
        }
      });
      return res;
    },
    /** Propiedad Computada para obtener el numero de archivos Drive Google */
    countDrive() {
      let res = 0;
      this.filesShow.forEach(element => {
        if (element.file.source == 'drive') {
          res++;
        }
      });
      return res;
    },
    /** Propiedad Computada para obtener el numero de archivos OneDrive */
    countOneDrive() {
      let res = 0;
      this.filesShow.forEach(element => {
        if (element.file.source == 'oneDrive') {
          res++;
        }
      });
      return res;
    },
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('listsAndCards', ['getFileToOneCard', 'addNewFielInCard', 'addNewFielInCardLocal', 'deleteFileInCard']),  
    ...mapActions('boardsData', ['addNewFielInBoard', 'addNewFielInBoardLocal', 'deleteFileInBoard']),
    

    /** Funcion obtener el nombre del archivo dependiendo del TP */
    showNameFile(file) { 

      if (file.source != 'drive' && file.source != 'oneDrive') {

        return file.name.substring(14);

      } else {

        return file.name;
      }
    },

    /** Funcion para filtrar los archivos por tp */
    loadArrayData(tp, files) {

      let r = [];

      if (tp == 'local') {

        files.forEach(element => {
          if ((element.file.source != 'drive') && (element.file.source != 'oneDrive')) {
            r.push(element);
          }
        });

      } else {

        files.forEach(element => {
          if (element.file.source == tp) {
            r.push(element);
          }
        });
      }

      return r;
    },

    /** Funcion para retornar el index de los iconos de los archivos de Google Drive */
    iconDriveFile(mimetype) { 
      let r = mimetype.split("/");
      if (mimetype == 'application/vnd.google-apps.folder') {

        return 0;

      } else if (r[0] == 'image' || r[0] == 'audio' || r[0] == 'video') {

        return 1;

      } else {

        return 2;
      }
    },

    /** Funcion para retornar el index de los iconos de los archivos Locales */
    iconLocalFile(mimetype) { 
      let r = mimetype.split("/");
      if (r[0] == 'image' || r[0] == 'audio' || r[0] == 'video') {

        return 0;

      } else {

        return 1;
      }
    },


    /** Funciones Para Google Drive */

    /** Funcion para iniciar las funciones del selector de archivo de Google Drive */
    loadPickerGoogleDrive() { 
      window.gapi.load('auth', { 'callback': this.onAuthApiLoad });
      window.gapi.load('picker', { 'callback': this.onPickerApiLoad });
    },
    
    /** Funcion para iniciar el Inicio de session del usuario de Google Drive */
    onAuthApiLoad() { 
      window.gapi.auth.authorize({
        'client_id': this.clientId,
        'scope': this.scope,
        'immediate': false
      },
        this.handleAuthResult);
    },

    /** Funcion para manejar el resultado del inicio de sesion de Google Drive */
    handleAuthResult(authResult) { 
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      } else {
        console.log(authResult.error);
      }
    },

    /** Funcion para realizar el primer llamado al selector de Archivos de Google Drive */
    onPickerApiLoad() { 
      this.pickerApiLoaded = true;
      this.createPicker();
    },

    /** Funcion para crear el selector de archivos de Google Drive luego de que el usuario inicie sesion */
    createPicker() { 
      if (this.pickerApiLoaded && this.oauthToken) {
        var titlePicker = this.t('modalsFilesData.driveSmj', {}, { locale: this.lang });
        var DisplayView = new window.google.picker.DocsView().setIncludeFolders(true).setSelectFolderEnabled(true);
        var UploadView = new window.google.picker.DocsUploadView().setIncludeFolders(true);
        var picker = new window.google.picker.PickerBuilder()
          .addView(DisplayView)
          .addView(UploadView)
          .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
          .setAppId(this.appId)
          .setOAuthToken(this.oauthToken)
          .setDeveloperKey(this.developerKey)
          .setCallback(this.pickerCallback)
          .setTitle(titlePicker)
          .build();
        picker.setVisible(true);
      }
    },

    /** Funcion para procesar los archivos seleccionados de Google Drive */
    pickerCallback(data) { 
      if (data.action == window.google.picker.Action.PICKED) {
        if (data.docs.length != 0) {
          this.addDriveFile(data.docs);
        }
      }
    },

    /** Funcion para almacenar los datos de los archivos seleccionados de Google Drive */
    async addDriveFile(files) { 

      let r = [];
      for (let i = 0; i < files.length; i++) {
        var file = files[i];
        r.push({
          name: file.name,
          mimetype: file.mimeType,
          size: file.sizeBytes,
          url: file.url,
        });
      }

      if (this.typeFile == 'board') {

        this.addNewFielInBoard({
          source: 'drive',
          typeAction: 'board',
          board_id: this.boardItem.id,
          card_id: null,
          data: JSON.stringify(r),
        });
        if (checkError401(this.errorLists)) { this.logOut(); }

      } else {

        this.addNewFielInCard({
          source: 'drive',
          typeAction: 'card',
          board_id: null,
          card_id: this.cardItemId,
          data: JSON.stringify(r),
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
      }
    },

    /** Funciones Para Google Drive */



    /** Funciones para Onde Drive */

    /** Funciones para cargar el selector de archivos de One Drive de Microsoft */
    async oneDrivePickerApiLoad() { 
      var odOptions = {
        clientId: this.oneDriveClientId,
        action: "share",
        openInNewWindow: true,
        multiSelect: true,
        viewType: 'all',
        advanced: {
          redirectUri: this.urlRoot,
        },
        success: this.oneDriveSelectFiles,
        cancel: function () { console.log('Window closed') },
        error: function (error) { console.log(error) }
      }
      await window.OneDrive.open(odOptions);
    },

    /** Funcion para verificar si se seleccionaron archivos de la API de Oner Drive */
    oneDriveSelectFiles(files) { 
      if (files.value.length != 0) {
        this.addOneDroveFile(files.value);
      }
    },

    /** Funcion para almacenar los datos de los archivos seleccionados de One Drive */
    async addOneDroveFile(files) { 

      let r = [];
      for (let i = 0; i < files.length; i++) {
        var file = files[i];

        let mimeType = file.file != undefined ? file.file.mimeType : 'application/vnd.google-apps.folder';

        r.push({
          name: file.name,
          mimetype: mimeType,
          size: file.size,
          url: file.webUrl,
        });
      }

      if (this.typeFile == 'board') {

        this.addNewFielInBoard({
          source: 'oneDrive',
          typeAction: 'board',
          board_id: this.boardItem.id,
          card_id: null,
          data: JSON.stringify(r),
        });
        if (checkError401(this.errorLists)) { this.logOut(); }

      } else {

        this.addNewFielInCard({
          source: 'oneDrive',
          typeAction: 'card',
          board_id: null,
          card_id: this.cardItemId,
          data: JSON.stringify(r),
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
      }
    },

    /** Funciones para Onde Drive */


    /** Funcion para abrir un archivo dependiendo del TP  */
    async openOneFile(item) {

      if (item.file.source == 'local') {

        await this.getFileToOneCard({
          id: item.id,
          name: item.file.name.substring(14)
        });
        if (checkError401(this.errorLists)) { this.logOut(); }

      } else {

        var win = window.open(item.file.url, '_blank');
        win.focus();
      }
    },

    /** Funcion para mostrar el modal Add Files Locales */
    changeModalAddNewFile(){
      this.loadingFile = false;
      this.newFile = '';
      this.dropActive = false;
      this.modalAddNewFile = !this.modalAddNewFile;
    },

    /** Funcion para detectar el Evento DropStar del modal agregar Archivo */
    handleDropZoneIn(event) {
      event.dataTransfer.dropEffect = 'copy';
      event.dataTransfer.effectAllowed = 'copy';
      this.dropActive = true;
    },

    /** Funcion para detectar el Evento DropEnd del modal agregar Archivo */
    handleDropZoneEnd() {
      if (this.newFile == '') {
        this.dropActive = false;
      }
    },

    /** Funcion para detectar el Evento Drop del modal agregar Archivo */
    drop(event) {
      event.preventDefault();
      this.newFile = event.dataTransfer.files[0];
      this.dropActive = true;
    },

    /** Funcion para detectar el Evento select files del modal agregar Archivo */
    handleFileUpload() {
      this.newFile = this.$refs.file.files[0];
      this.dropActive = true;
    },

    /** Funcion para agregar un archivo local */
    async addNewFileInCard(){

      if (this.newFile != ''){

        let formData = new FormData();
        formData.append('file', this.newFile);
        formData.append('source', 'local');
        this.loadingFile = true;

        if (this.typeFile == 'board') {

          formData.append('typeAction', 'board');
          formData.append('board_id', this.boardItem.id);
          formData.append('card_id', null);

          this.addNewFielInBoardLocal(formData);
          if (checkError401(this.errorBoards)) { this.logOut(); }
          if (this.errorBoards == null) {
            this.newFile = '';
            this.dropActive = false;
            this.loadingFile = false;
          }

        } else {

          formData.append('typeAction', 'card');
          formData.append('board_id', null);
          formData.append('card_id', this.cardItem.id);

          this.addNewFielInCardLocal(formData);
          if (checkError401(this.errorLists)) { this.logOut(); }
          if (this.errorLists == null) {

            this.newFile = '';
            this.dropActive = false;
            this.loadingFile = false;
          }
        }
      }
    },

    /** Funcion para mostrar el modal Delete files */
    changeModalDelete(item = {}) { 
      this.itemFile = item;
      this.modalDeleteOneFile = !this.modalDeleteOneFile;
    },

    /** Funcion para eliminar un archivo */
    async deleteTheFile(){

      if (this.typeFile == 'board') {

        await this.deleteFileInBoard({
          objet: this.boardItem.id,
          file: this.itemFile.id,
        });
        if (checkError401(this.errorBoards)) { this.logOut(); }
        if (this.errorBoards == null) {
          this.changeModalDelete();
        }

      } else {

        await this.deleteFileInCard({
          objet: this.cardItem.id,
          file: this.itemFile.id,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.changeModalDelete();
        }
      }
    }
  },
  mounted: async function () {

    if (this.typeFile == 'board'){
      this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.boardItem.team_id);
    } else {
      this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.cardItem.team_id);
    }

    this.urlRoot = window.location.hostname == 'localhost' ? 'http://localhost:8080/' : 'https://app.flashteam360.com/';
  }
}
</script>

<style scoped>
.btn-sm,
.btn-group-sm>.btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

@media (min-width: 300px) {
  .img-size-flash {
    width: 30%;
  }
}

@media (min-width: 375px) {
  .img-size-flash {
    width: 30%;
  }
}

@media (min-width: 576px) {
  .img-size-flash {
    width: 30%;
  }
}

@media (min-width: 768px) {
  .img-size-flash {
    width: 30%;
  }
}

@media (min-width: 992px) {
  .img-size-flash {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .img-size-flash {
    width: 50%;
  }
}

.delete-icon {
  color: red;
  font-size: 18px;
  margin-left: 15px;
}

.style-icon-file {
  background-color: white;
  border: 1px solid gray;
  margin-bottom: 5px;
}

.modal-mask {
  position: fixed;
  z-index: 990;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.dropzone {
  min-height: auto;
  padding: 1.5rem 1.75rem;
  text-align: center;
  cursor: pointer;
  border: 1.5px dashed rgb(46, 134, 193);
  background-color: rgb(214, 234, 248);
  border-radius: 0.475rem !important;
}

.loader-zone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputdropzone {
  display: none;
}

.active-dropzone {
  color: rgb(0, 0, 0);
  background-color: rgba(169, 204, 227);
  border: 1.5px dashed rgb(31, 97, 141);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>