export default {

  /** Inicio y final de las funciones */
  boardsActionBegin(state) {
    state.loading = true;
  },

  boardsActionErr(state, err) {
    state.loading = false;
    state.errorBoards = err;
  },
  /** Inicio y final de las funciones */


  /** Funcion para reiniciar la data a sus estados iniciales */
  resetDataBoardsSuccess(state) {
    state.loading = false;
    state.boards = [];
    state.modalAddEditDeleteBoards = false;
    state.newBoard = true;
    state.teamToBoard = 0;
    state.boardItem = {};
    state.storesBoards = [];
    state.latestBoards = [];
    state.stadistBoards = [];
    state.calendarBoards = [];
    state.backgrounds = [];
    state.modalShowUserTemplateBoard = false;
    state.templatesBoards = [];
    state.errorBoards = null;
  },


  /** Funciones para Obtener los tableros de un usuario  */
  getBoadsSuccess(state, data) {
    state.loading = false;
    state.boards = data;
    state.errorBoards = null;
  },
  /** Funciones para Obtener los tableros de un usuario  */


  /** Funciones para cambiar el estado del modal para agregar editar o eliminar tablero */
  changeModalAddEditDeleteBoardSuccess(state, data) {
    state.loading = false;
    state.modalAddEditDeleteBoards = !state.modalAddEditDeleteBoards;
    state.newBoard = data.new;
    state.teamToBoard = data.team;
    state.errorBoards = null;
  },


  /** Funciones para agregar un nuevo tablero a la data */
  addBoardsSuccess(state, data) {
    state.loading = false;
    state.boards.forEach(item => {
      if (item.team_id == data.team_id) {
        item.boards.push(data);
      }
    })
    state.errorBoards = null;
  },
  /** Funciones para agregar un nuevo tablero a la data */


  /** Funciones para editar un tablero */
  updateBoardsSuccess(state, data) {
    state.loading = false;
    state.boards.forEach(item => {
      if (item.team_id == data.team_id) {
        item.boards.forEach(item2 => {
          if (item2.id == data.id) {
            item2.title = data.title;
            item2.description = data.description;
            item2.link_img = data.link_img;
          }
        })
      }
    })

    /** Se actualiza el item actual del tablero */
    state.boardItem.title = data.title;
    state.boardItem.description = data.description;
    state.boardItem.link_img = data.link_img;
    state.errorBoards = null;
  },
  /** Funciones para editar un tablero */


  /** Funciones para Obtener los datos de un tablero por el id  */
  getSingleBoadSuccess(state, data) {
    state.loading = false;
    state.boardItem = data;
    state.errorBoards = null;
  },
  /** Funciones para Obtener los datos de un tablero por el id  */


  /** Funciones para eliminar un tablero */
  deleteBoardsSuccess(state, data) {
    state.loading = false;
    state.boards.forEach(item => {
      item.boards = item.boards.filter(item2 => item2.id !== data);
    })
    state.errorBoards = null;
  },
  /** Funciones para eliminar un tablero */


  /** Funciones para agregar un tablero a favoritos */
  addBoardToFavoritesSuccess(state, data) {
    state.loading = false;

    state.boards.forEach(item => {
      item.boards.forEach(item2 => {
        if (item2.id == data) {
          item2.favorite = true;
        }
      })
    });

    state.latestBoards.forEach(item => {
      if (item.id == data) {
        item.favorite = true;
      }
    });

    state.errorBoards = null;
  },
  /** Funciones para agregar un tablero a favoritos */


  /** Funciones para eliminar un tablero de favoritos */
  deleteBoardToFavoritesSuccess(state, data) {
    state.loading = false;

    state.boards.forEach(item => {
      item.boards.forEach(item2 => {
        if (item2.id == data) {
          item2.favorite = false;
        }
      })
    });

    state.latestBoards.forEach(item => {
      if (item.id == data) {
        item.favorite = false;
      }
    });

    state.errorBoards = null;
  },
  /** Funciones para eliminar un tablero de favoritos */


  /** Funciones para obtener los tableros almacenados del usuario */
  getStoresBoadsSuccess(state, data) {
    state.loading = false;
    state.storesBoards = data.payload;
    state.errorBoards = null;
  },
  /** Funciones para obtener los tableros almacenados del usuario */


  /** Funciones para obtener las imagenes de fondo para los tableros */
  getBackgroundsForBoardsSuccess(state, data) {
    state.loading = false;
    state.backgrounds = data;
    state.errorBoards = null;
  },
  /** Funciones para obtener las imagenes de fondo para los tableros */


  /** Funciones para Obtener los ultimos tableros consultados por de un usuario  */
  getLatestBoardsSuccess(state, data) {
    state.loading = false;
    state.latestBoards = data;
    state.errorBoards = null;
  },
  /** Funciones para Obtener los ultimos tableros consultados por de un usuario  */


  /** Funciones para almacernar un tabler en la lista de visitados  */
  addLatestBoardsSuccess(state) {
    state.loading = false;
    state.errorBoards = null;
  },
  /** Funciones para almacernar un tabler en la lista de visitados  */


  /** Funciones para obtener las estadisticas de las tareas por tableros  */
  getStatisticsBoardsSuccess(state, data) {
    state.loading = false;
    state.stadistBoards = data;
    state.errorBoards = null;
  },
  /** Funciones para obtener las estadisticas de las tareas por tableros  */


  /** Funciones para obtener las data de la vista calendario de todos los tableros de un usuario  */
  getCalendarBoardsDataSuccess(state, data) {
    state.loading = false;
    state.calendarBoards = data;
    state.errorBoards = null;
  },
  /** Funciones para obtener las data de la vista calendario de todos los tableros de un usuario  */


  /** Funciones para crear una copia de un tablero */
  createDuplicateBoardsSuccess(state, data) {
    state.loading = false;
    state.boards.forEach(item => {
      if (item.team_id == data.team_id) {
        item.boards.push(data);
      }
    })
    state.errorBoards = null;
  },
  /** Funciones para crear una copia de un tablero */


  /** Funciones para archivar o desarchivar un tablero */
  updateStoreStatusSuccess(state, data) {
    state.loading = false;

    if (data.stored) {
      state.boards = state.boards.filter(item => item.id !== data.id);
    } else {
      state.boards.push(data);
    }
    state.boardItem.stored = data.stored;
    state.errorBoards = null;
  },
  /** Funciones para archivar o desarchivar un tablero */


  /** Funcion para asignar la imagen de usuario de un miembro */
  getProfileMembersImg(state, data) {
    state.loading = false;

    let blob = new Blob([data.profile.data], { type: data.profile.data.type });
    var reader = new window.FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let base64data = reader.result;
      state.boardItem.members.forEach(item => {
        if (item.user_email == data.email) {
          item.img_user = base64data;
        }
      });
    }
    state.errorAuth = null;
  },
  /** Funcion para asignar la imagen de usuario de un miembro */


  /** Funciones para añadir un miembro a un tablero */
  addNewMembersBoardsSuccess(state, data) {
    state.loading = false;
    state.boardItem.members.push(data);
    state.errorBoards = null;
  },
  /** Funciones para añadir un miembro a un tablero */


  /** Funciones para eliminar un miembro de un tablero */
  deleteMembersBoardsSuccess(state, data) {
    state.loading = false;
    if(data.type == 'user') {
      state.boardItem.members = state.boardItem.members.filter(item => item.user_email !== data.email);
    } else {
      state.boardItem.members = state.boardItem.members.filter(item => item.group_id !== data.group);
    }
    state.errorBoards = null;
  },
  /** Funciones para eliminar un miembro de un tablero */


  /** Funciones para agregar un archivo a un tablero */
  addNewFielInBoardSuccess(state, data) {
    state.loading = false;
    data.forEach(item => {
      state.boardItem.attachments.push(item.file);
    })
    state.errorBoards = null;
  },
  /** Funciones para agregar un archivo a un tablero */


  /** Funciones para eliminar un archivo de un tablero */
  deleteFileInBoardSuccess(state, data) {
    state.loading = false;
    state.boardItem.attachments = state.boardItem.attachments.filter(item => item.id !== data);
    state.errorBoards = null;
  },
  /** Funciones para eliminar un archivo de un tablero */


  /** Funciones para finalizar el proceso de crear un template de un tablero */
  createTemplateBoardsSuccess(state) {
    state.loading = false;
    state.errorBoards = null;
  },
  /** Funciones para finalizar el proceso de crear un template de un tablero */


  /** Funciones para cambiar el estatus del modal para template de tablero */
  showModalTemplateBoardSuccess(state) {
    state.loading = false;
    state.modalShowUserTemplateBoard = !state.modalShowUserTemplateBoard;
    state.errorBoards = null;
  },
  /** Funciones para cambiar el estatus del modal para template de tablero */


  /** Funciones para obtener los tableros templates de un usuario */
  getBoardsTemplatesDataSuccess(state, data) {
    state.loading = false;
    state.templatesBoards = data;
    state.errorBoards = null;
  },
  /** Funciones para obtener los tableros templates de un usuario */

  /** Funcion para agregar el archivo imagen de un template de tableros */
  getPublicTemplateImgSuccefus(state, data) {
    state.loading = false;

    state.templatesBoards.forEach(item => {
      if (item.info.id == data.id) {
        let blob = new Blob([data.query.data], { type: data.query.data.type });
        var reader = new window.FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          item.img = base64data;
        }
      }
    });
    state.errorBoards = null;
  },
  /** Funcion para agregar el archivo imagen de un template de tableros */
};