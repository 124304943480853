import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
//import { getItem } from '@/utility/localStorageControl';
import toastr from 'toastr';


/** Notificacion Exito */
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};


/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};


const state = () => ({
  loading: false,
  rols: [],
  permissions: [],
  errorRols: null,
});


const actions = {

  /** Reset data */
  resetDataRolsAndPermissions({ commit }) {
    try {

      commit('resetDataRolsAndPermissionsSuccess');

    } catch (err) {

      console.log(err);
    }
  },

  /** Action para procesar Get data Rols */
  async getRolsData({ commit }) {
    try {

      commit('actionRolsDataBegin');

      let response = await DataService.get('/rols');

      console.log(response.data);

      await commit('getRolsDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionRolsDataErr', err);
    }
  },


  /** Action para procesar Registro de rol en el sistema */
  async registerRols({ commit }, data) {
    try {

      commit('actionRolsDataBegin');

      let response = await DataService.post('rols', data);

      console.log(response.data.payload);

      await commit('registerRolsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('actionRolsDataErr', err);
    }
  },


  /** Action para procesar actualizar rols */
  async updateRolsData({ commit }, data) {
    try {

      await commit('actionRolsDataBegin');

      let response = await DataService.put(`/rols/${data.id}`, data);

      console.log(response.data.payload);

      commit('updateRolsDataSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionRolsDataErr', err);
    }
  },


  /** Action para procesar eliminar rol */
  async deleteRolsData({ commit }, data) {
    try {

      await commit('actionRolsDataBegin');

      let response = await DataService.delete(`rols/${data}`);

      console.log(response.data.payload);

      commit('deleteRolsDataSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionRolsDataErr', err);
    }
  },


  /** Action para procesar Get data Permisos */
  async getPermissionsData({ commit }) {
    try {

      commit('actionRolsDataBegin');

      let response = await DataService.get('/permissions');

      console.log(response.data);

      await commit('getPermissionsDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionRolsDataErr', err);
    }
  },


  /** Action para procesar Registro de permisos en el sistema */
  async registerPermissions({ commit }, data) {
    try {

      commit('actionRolsDataBegin');

      let response = await DataService.post('permissions', data);

      console.log(response.data.payload);

      await commit('registerPermissionsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('actionRolsDataErr', err);
    }
  },


  /** Action para procesar actualizar Permisos */
  async updatePermissionsData({ commit }, data) {
    try {

      await commit('actionRolsDataBegin');

      let response = await DataService.put(`/permissions/${data.id}`, data);

      console.log(response.data.payload);

      commit('updatePermissionsDataSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionRolsDataErr', err);
    }
  },


  /** Action para procesar eliminar Permisos */
  async deletePermissionsData({ commit }, data) {
    try {

      await commit('actionRolsDataBegin');

      let response = await DataService.delete(`permissions/${data}`);

      console.log(response.data.payload);

      commit('deletePermissionsDataSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionRolsDataErr', err);
    }
  },


  /** Action para asignar permisos a un rol */
  async assignPermissionsToRol({ commit }, data) {
    try {

      commit('actionRolsDataBegin');

      let response = await DataService.post('rols/assignPermissions', data);

      console.log(response.data.payload);

      await commit('assignPermissionsToRolSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err.response);

      addNotificationError(err.response.data.message);

      commit('actionRolsDataErr', err);
    }
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};