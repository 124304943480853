import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import toastr from 'toastr';

/** Notificaciones del resultado de los procesos  */
/** Notificacion Exito 
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};
*/

/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};

const state = () => ({
  loading: false,
  version: '3.1.0 (25/02/2023)',
  lang: 'en',
  flagLang: {
    'en': 'US',
    'es': 'ES'
  },
  showModalInfoFlash: false,
  errorSyst: null,
  modalAddEditLists: false,
  modalAddEditLabes: false,
  cardToLabel: 0,
  modalAddEditCards: false,
  modalRols: false,
  modalPermissions: false,
  modalTeams: false,
  systemBackground: '',
  modalUsersGroups: false,
  userMenu: false,
});


const actions = {

  /** Reset data */
  resetDataSystemSetup({ commit }) {
    try {

      commit('resetDataSystemSetupSuccess');

    } catch (err) {

      console.log(err);
    }
  },

  /** Funcion para cambiar el lenguaje */
  changeLanguage({ commit }, l) {
    try {

      commit('changeLanguageBegin');
      commit('changeLanguageSuccess', l);

      return true

    } catch (err) {

      commit('changeLanguageErr', err);
    }
  },


  /** Funcion para cambiar el estado del modal Informacion Flash Team360 */
  changeModalFlashInfo({ commit }, status) {
    try {

      commit('changeModalFlashInfoSuccess', status);

    } catch (err) {

      commit('changeModalFlashInfoErr', err);
    }
  },


  /** Obtener archivo para descargar Beta Terster Agreement */
  async getFileBetaTerster({ commit }) {
    try {

      await commit('getFileBetaTersterBegin');

      const query = await DataService.getFile(`attachments/open/files/terms`);

      console.log(query)

      await commit('getFileBetaTersterSuccess');

      const r = new Blob([query.data], { type: query.data.type });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(r);
      link.download = 'Agreement.pdf';
      link.click();

    } catch (err) {

      console.log(err);

      addNotificationError(err.message);

      await commit('getFileBetaTersterErr', err);
    }
  },


  /** Funcion para cambiar el estado del modal Add y Edit List */
  changeModalAddEditLists({ commit }) {
    try {

      commit('changeModalAddEditListsSuccess');

    } catch (err) {

      commit('changeModalAddEditListsErr', err);
    }
  },


  /** Funcion para cambiar el estado del modal Add y Edit Labels */
  changeModalAddEditLabes({ commit }, card = 0) {
    try {

      commit('changeModalAddEditLabesSuccess', card);

    } catch (err) {

      commit('changeModalAddEditLabesErr', err);
    }
  },


  /** Funcion para cambiar el estado del modal Add y Edit Cards */
  changeModalAddEditCards({ commit }) {
    try {

      commit('changeModalAddEditCardsSuccess');

    } catch (err) {

      commit('changeModalAddEditCardsErr', err);
    }
  },


  changeModalRols({ commit }) {
    try {

      commit('changeModalRolsSuccess');

    } catch (err) {

      commit('changeModalRolsErr', err);
    }
  },


  changeModalPermissions({ commit }) {
    try {

      commit('changeModalPermissionsSuccess');

    } catch (err) {

      commit('changeModalPermissionsErr', err);
    }
  },


  changeModalTeams({ commit }) {
    try {

      commit('changeModalTeamsSuccess');

    } catch (err) {

      commit('changeModalTeamsErr', err);
    }
  },


  loadSystemBackground({ commit }, data) {
    try {

      commit('loadSystemBackgroundSuccess', data);

    } catch (err) {

      commit('loadSystemBackgroundErr', err);
    }
  },


  changeModalUserGroups({ commit }) {
    try {

      commit('changeModalUserGroupsSuccess');

    } catch (err) {

      commit('changeModalUserGroupsErr', err);
    }
  },

  changeMenuValue({commit}, value) {
    try {

      if(value == 'close') {
        
        commit('changeMenuValueClose');

      } else {

        commit('changeMenuValueChange');

      }

    } catch (err) {

      commit('changeMenuValueErr', err);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};