import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';
import toastr from 'toastr';


/** Notificacion Exito */
const addNotificationSuccess = (msj) => {
  toastr.success(msj);
};


/** Notificacion Error */
const addNotificationError = (msj) => {
  toastr.error(msj);
};

const state = () => ({
  loading: false,
  lists: [],
  cardsTemplate: [],
  calendarList: [],
  modalShowInfoCard: false,
  cardItemId: 0,
  cardItem: {},
  errorLists: null,
  mentions: [],
});


const actions = {

  /** Reset data */
  resetDataListAndCards({ commit }) {
    try {

      commit('resetDataListAndCardsSuccess');

    } catch (err) {

      console.log(err);
    }
  },


  /** Action para procesar Get data Lists */
  async getListData({ commit }, board) {
    try {

      commit('actionBegin');

      let response = await DataService.get(`/lists/${board}`);

      console.log(response.data);

      await commit('getListsSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para obtener la data para la vista de calendario de las listas de un tablero */
  async getCalendarListsData({ commit }, data) {
    try {

      commit('actionBegin');

      let response = await DataService.get(`/lists/dataDate/${data}`);

      console.log(response.data);

      await commit('getCalendarListsDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para agregar una nueva lista */
  async addNewList({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      commit('actionBegin');

      let response = await DataService.post('lists', d);

      console.log(response.data.payload);

      await commit('addNewListSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para actualizar una lista */
  async updateList({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.put(`/lists/${data.id}`, data);

      console.log(response.data.payload);

      commit('updateListSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para eliminar una lista  */
  async deleteList({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.delete(`lists/${data}`);

      console.log(response.data);

      await commit('deleteListSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para Archivar una lista  */
  async storedList({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.put(`lists/store/${data}`);

      console.log(response.data);

      await commit('storedListSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para Copiar una lista en un tablero  */
  async copyList({ commit }, data) {
    try {

      await commit('actionBegin');

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      let response = await DataService.post(`/lists/copy/${data.id}`, d);

      console.log(response.data);

      await commit('copyListSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para procesar Get data Store Lists */
  async getStoredListsData({ commit }, board) {
    try {

      commit('actionBegin');

      let response = await DataService.get(`/lists/storedLists/${board}/${getItem('e21')}`);

      console.log(response.data);

      await commit('getListsSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para cargar una lista */
  async loadDataLists({ commit }, data) {
    try {

      commit('actionBegin');
      await commit('getListsSuccess', data);

    } catch (err) {
      console.log(err);
      addNotificationError(err);
      commit('actionErr', err);
    }
  },

  /** Action para actualizar los niveles de las listas de un tablero */
  async updateListLevels({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.put(`/lists/drag/l`, data);
      console.log(response.data.payload);
      commit('updateListLevelsSuccess');

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },



  /** Action para agregar un miembro a una lista*/
  async addNewMemberInLists({ commit }, data) {
    try {

      commit('actionBegin');

      let response = await DataService.post('lists/members', data);

      console.log(response.data.payload);

      await commit('addNewMemberInListsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para eliminar un miembro de una lista */
  async deleteOneUserToLists({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.delete(`/lists/members/${data.list}/${data.email}/${data.type}/${data.group}`);

      console.log(response.data.payload);

      await commit('deleteOneUserToListsSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }

  },


  /** Action para actualizar una etiqueta en las tarjetas de las listas */
  async updateLabelInLists({ commit }, data) {
    try {

      commit('updateLabelInListsSuccess', data);

    } catch (err) {

      console.log(err);
      addNotificationError(err);
      commit('actionErr', err);
    }
  },

  /** Action para eliminar una etiqueta en las tarjetas de las listas */
  async deleteLabelInLists({ commit }, data) {
    try {

      commit('deleteLabelInListsSuccess', data);

    } catch (err) {

      console.log(err);
      addNotificationError(err);
      commit('actionErr', err);
    }
  },

  /** Action para agregar una etiqueta en una tarjeta de las listas */
  async addLabelInLists({ commit }, data) {
    try {

      commit('addLabelInListsSuccess', data);

    } catch (err) {

      console.log(err);
      addNotificationError(err);
      commit('actionErr', err);
    }
  },

  /** Action para procesar Get data Cards Templates */
  async getCardsTemplatesData({ commit }) {
    try {

      commit('actionBegin');

      let response = await DataService.get(`/cards/getTemplate/${getItem('e21')}`);

      console.log(response.data);

      await commit('getCardsTemplatesDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para agregar una nueva tarjeta */
  async addNewCards({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      commit('actionBegin');

      let response = await DataService.post('cards', d);

      console.log(response.data.payload);

      await commit('addNewCardsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para agregar una nueva tarjeta a partir de un Template */
  async addNewCardsByTemplate({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      commit('actionBegin');

      let response = await DataService.post('cards/addByTemplate', d);

      console.log(response.data.payload);

      await commit('addNewCardsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para copiar una carpeta en una lista */
  async createCopyCard({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      commit('actionBegin');

      let response = await DataService.post('cards/copy', d);

      console.log(response.data.payload);

      await commit('addNewCardsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para crear un Template de una tarjeta */
  async createTemplateByCard({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      commit('actionBegin');

      let response = await DataService.post('cards/StoreTemplate', d);

      console.log(response.data.payload);

      await commit('createTemplateByCardSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para actualizar una tarjeta */
  async updateCards({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      }

      await commit('actionBegin');

      let response = await DataService.put(`/cards/${data.id}`, d);

      console.log(response.data.payload);

      commit('updateCardsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para actualizar el estatus de una tarjeta */
  async updateStatusCards({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.put(`/cards/status/${data}/${getItem('e21')}`);

      console.log(response.data.payload);

      commit('updateStatusCardsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para actualizar el estatus de template especial de un template */
  async updateSpecialTemplateCards({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.put(`/cards/specialTemplate/${data}/${getItem('e21')}`);

      console.log(response.data.payload);

      commit('updateSpecialTemplateCardsSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para actualizar los niveles de las las tarjetas */
  async updateCardsLevels({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.put(`cards/drag/cards/l`, data);

      console.log(response.data.payload);

      commit('updateCardsLevelsSuccess');

      //addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Funcion para cambiar el estado del modal para mostrar el contenido de una tarjeta */
  async changeModalShowCardItem({ commit }, id = 0) {
    try {

      await commit('actionBegin');

      await commit('changeModalShowCardItemSuccess', id);

    } catch (err) {

      commit('actionErr', err);
    }
  },


  /** Action para procesar Get data de la tarjeta a mostrar contenido */
  async getCardItemData({ commit }, id) {
    try {

      await commit('actionBegin');

      let response = await DataService.get(`cards/show/${id}`);

      console.log(response.data);

      await commit('getCardItemDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para eliminar una tarjeta */
  async deleteCardItem({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.delete(`cards/${data}`);

      console.log(response.data);

      await commit('deleteCardItemSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para agregar un miembro a una tarjeta */
  async addNewMemberInCard({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      commit('actionBegin');

      let response = await DataService.post('cards/members', d);

      console.log(response.data.payload);

      await commit('addNewMemberInCardSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para eliminar un miembro de una tarjeta */
  async deleteOneUserFromCard({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.delete(`/cards/members/${data.card}/${data.email}/${getItem('e21')}/${data.type}/${data.group}`);

      console.log(response.data.payload);

      await commit('deleteOneUserFromCardSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }

  },




  /** Action para buscar las mensiones en las notas de un usuario*/
  async getMentionsInNotesData({ commit }) {
    try {

      await commit('actionBegin');

      let response = await DataService.get(`notes/mentions/${getItem('e21')}`);

      console.log(response.data);

      await commit('getMentionsInNotesDataSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para agregar una nota a una tarjeta */
  async addNewNoteInCard({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{
          user_email: getItem('e21'),
          user_name: getItem('n21'),
        },
      };

      commit('actionBegin');

      let response = await DataService.post('notes', d);

      console.log(response.data.payload);

      await commit('addNewNoteInCardSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para actulizar una nota de una tarjeta */
  async updateNoteInCard({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{
          user_email: getItem('e21'),
          user_name: getItem('n21'),
        },
      };

      await commit('actionBegin');

      let response = await DataService.put(`/notes/${data.id}`, d);

      console.log(response.data.payload);

      commit('updateNoteInCardSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para eliminar una nota de una tarjeta */
  async deleteNoteInCard({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.delete(`notes/${data}`);

      console.log(response.data.payload);

      await commit('deleteNoteInCardSuccess', data);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para cambiar el estatus de un notificacion de una mension */
  async changeNotificationStatusMention({commit}, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.put(`/notes/changeMentions/${data}`);

      console.log(response.data.payload);

      commit('changeNotificationStatusMentionSuccess', data);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para agregar un archivo a una tarjeta (Drive y OneDrive) */
  async addNewFielInCard({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21'), },
      };

      commit('actionBegin');

      let response = await DataService.post('attachments', d);

      console.log(response.data.payload);

      await commit('addNewFielInCardSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para agregar un archivo a una tarjeta (Local) */
  async addNewFielInCardLocal({ commit }, data) {
    try {

      data.append('creator', getItem('e21'));

      commit('actionBegin');

      let response = await DataService.post('attachments', data);

      console.log(response.data.payload);

      await commit('addNewFielInCardSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para descargar un archivo de una tarjeta */
  async getFileToOneCard({ commit }, data) {
    try {

      await commit('actionBegin');

      const query = await DataService.getFile(`/attachments/open/files/${data.id}`);

      console.log(query)

      await commit('getFileToOneCardSuccess');

      const r = new Blob([query.data], { type: query.data.type });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(r);
      link.download = data.name;
      link.click();

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para eliminar un archivo de una tarjeta */
  async deleteFileInCard({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.delete(`attachments/card/${data.objet}/${data.file}/${getItem('e21')}`);

      console.log(response.data.payload);

      await commit('deleteFileInCardSuccess', { id: data.file, hist: response.data.payload });

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }

  },


  /** Action para añadir una nueva clase de checklist a una tarjeta */
  async addNewChecklistClassInCard({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21'), },
      };

      commit('actionBegin');

      let response = await DataService.post('checklist/class', d);

      console.log(response.data.payload);

      await commit('addNewChecklistClassInCardSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para actualizar una clase de checklist */
  async updateOneChecklistClass({ commit }, data) {
    try {

      const d = {
        ...data,
        ...{ creator: getItem('e21') },
      };

      await commit('actionBegin');

      let response = await DataService.put(`checklist/class/${data.id}`, d);

      console.log(response.data.payload);

      commit('updateOneChecklistClassSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para eliminar una clase de checklist de una tarjeta */
  async deleteOneChecklistClass({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.delete(`checklist/class/${data}/${getItem('e21')}`);

      console.log(response.data.payload);

      await commit('deleteOneChecklistClassSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para actualizar los niveles de las clases de checklist en una tarjeta */
  async updateChecklistClassLevelInCard({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.put(`checklist/drag/class/l`, data);

      console.log(response.data.payload);

      commit('updateChecklistClassLevelInCardSuccess');

      //addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para filtrar los Items de una clase por el filtro recibido*/
  async getItemsClasssByFilter({ commit }, data) {
    try {

      commit('actionBegin');

      let response = await DataService.get(`/checklist/filter/${data.class}/${data.filter}`);

      console.log(response.data);

      await commit('getItemsClasssByFilterSuccess', response.data.payload);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },









  /** Action para añadir un nuevo item a una clase de checklist */
  async addNewItemInChecklistClass({ commit }, data) {
    try {

      let d = {
        ...data,
        ...{ creator: getItem('e21'), }
      }

      commit('actionBegin');

      let response = await DataService.post('checklist', d);

      console.log(response.data.payload);

      await commit('addNewItemInChecklistClassSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para actualizar un item (checklist) */
  async updateOneItemInChecklistClass({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.put(`checklist/${data.id}`, data);

      console.log(response.data.payload);

      await commit('updateOneItemInChecklistClassSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para eliminar un item (checklist) */
  async deleteOneItemInChecklistClass({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.delete(`checklist/${data}/${getItem('e21')}`);

      console.log(response.data.payload);

      await commit('deleteOneItemInChecklistClassSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para cambiar el estatus de un item (checklist) */
  async updateStatusOneItemChecklistClass({ commit }, data) {
    try {

      await commit('actionBegin');

      let d = {
        email: getItem('e21'),
        name: getItem('n21'),
      };

      let response = await DataService.put(`checklist/check/${data.id}`, d);

      console.log(response.data.payload);

      await commit('updateStatusOneItemChecklistClassSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para asignar un responsable a un item (checklist) */
  async assignItemChecklistClassToUser({ commit }, data) {
    try {

      commit('actionBegin');

      let response = await DataService.post('checklist/members', data);

      console.log(response.data.payload);

      await commit('assignItemChecklistClassToUserSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Action para eliminar el responsable de un item (checklist) */
  async unassignItemChecklistClassToUser({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.delete(`checklist/members/${data.id}/${data.user}/${data.type}/${data.group}`);

      console.log(response.data.payload);

      await commit('assignItemChecklistClassToUserSuccess', response.data.payload);

      addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },


  /** Action para actualizar los niveles de los items de una clase de checklists  */
  async updateItemsLevelInChecklistClass({ commit }, data) {
    try {

      await commit('actionBegin');

      let response = await DataService.put(`checklist/drag/check/l`, data);

      console.log(response.data.payload);

      await commit('updateItemsLevelInChecklistClassSuccess', response.data.payload);

      //addNotificationSuccess(response.data.message);

    } catch (err) {

      console.log(err);
      if (err.response.status != '401') {
        const msj = err.response.data.message != null ? err.response.data.message : err.response.message;
        addNotificationError(msj);
      }

      commit('actionErr', err);
    }
  },

  /** Reiniciar las variables de las listas */
  resetDataLists({ commit }) {
    try {

      commit('resetDataListsSuccess');

    } catch (err) {

      console.log(err);
    }
  },

  /** Reiniciar las variables de las tarjetas */
  resetDataCards({ commit }) {
    try {

      commit('resetDataCardsSuccess');

    } catch (err) {

      console.log(err);
    }
  }

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};