<template>
  <div class="app-sidebar" @mouseenter="closeMenu()" :style="{ 'border':'1px solid silver', }">
    <div class="app-header__logo">
      <div class="logo-src"></div>
      <div class="header__pane ml-auto">
        <div>
          <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button id="b-movil" type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <span>
        <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
          <span class="btn-icon-wrapper">
            <i class="fa fa-ellipsis-v fa-w-6"></i>
          </span>
        </button>
      </span>
    </div>
    <div id="sidebar-app2" class="scrollbar-sidebar" :style="{ 'position':'relative' }">
      <div class="app-sidebar__inner" :style="{ 'max-height':'72vh', 'overflow-x':'hidden', 'overfloy-y':'auto' }">
        <ul class="vertical-nav-menu">
          <li class="app-sidebar__heading">
            <router-link to="/dashboard">
            <i class="metismenu-icon iconSidebar" :style="{ 'opacity':'1 !important', }"></i>
              {{ t('sidebarData.myBoards', {}, { locale: lang }) }}
            </router-link>
          </li>

          <li v-for="b in boards" :key="b.team_id" class="" v-show="b.boards.length > 0">
            <a @click="b.showOpt = !b.showOpt" style="cursor: default;">
              <i class="metismenu-icon pe-7s-diamond"></i>
              {{ b.team_name }}
              <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
            </a>
            <transition name="fade">
              <ul>
                <li v-for="m in b.boards" :key="m.id" v-show="b.showOpt">
                  <a>
                    <i class="metismenu-icon"></i>
                    <b @click="$router.push(`/board/${m.id}`)"
                      :style="{'display':'inline-block!important','font-size':'11px','min-width':'172px', 'cursor':'default'}"
                      :title="showInfoData(m.title, lang, 1)">
                      {{ showInfoData(m.title, lang, 2) }}
                    </b>
                    <i :class="m.favorite ? 'fa fa-star btn-icon-wrapper' : 'lnr-star btn-icon-wrapper'"
                      :style="{'margin-left':'9px','color':'gold', 'font-size':'1rem', 'cursor': 'default' }"
                      :title="t(m.favorite ? 'sidebarData.removeBookmark' : 'sidebarData.addBookmark', {}, { locale: lang })"
                      @click="bookmark(!m.favorite, m.id)">
                    </i>
                  </a>
                </li>
              </ul>
            </transition>
          </li>
        </ul>
      </div>

      <div class="app-sidebar__inner" style="position:absolute; bottom:5px;">
        <ul class="vertical-nav-menu" style="cursor: default;">
          <li @click="changeModalFlashInfo(true)">
            <a><b style="font-size: 11px;">{{ t('sidebarData.aboutFlash', {}, { locale: lang }) }}</b></a>
          </li>
          <li @click="openAgreement()">
            <a><b style="font-size: 11px;">{{ t('sidebarData.downloadBetaT', {}, { locale: lang }) }}</b></a>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="modalTerms">
      <ModalTerms />
    </div>

  </div>
</template>


<script>
import ModalTerms from '@/components/users/ModalTerms.vue';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { closeMenu, showInfoData, checkError401 } from '../../utility/functions';
export default {
  name: 'Sidebar',
  setup() {
    const { t, locale } = useI18n();
    return { 
      t,
      locale,
      closeMenu,
      showInfoData
    };
  },
  component: {
    ModalTerms,
  },
  computed: {
    ...mapState('system', ['lang', 'errorSyst']),
    ...mapState('boardsData', ['boards', 'errorBoards']),
  },
  methods: {
    ...mapActions('system', ['changeModalFlashInfo', 'getFileBetaTerster']),
    ...mapActions('auth', ['logOut']),
    ...mapActions('boardsData', ['getBoadsData', 'addBoardToFavorites', 'deleteBoardToFavorites', '']),

    /** Funcion para actualizar los tableros favoritos */
    async bookmark(tp, board) { 

      /** Agreagar tablero a favorito */
      if (tp) {

        await this.addBoardToFavorites(board);
        if (checkError401(this.errorBoards)) { this.logOut(); }

      /** Eliminar tablero de favoritos */
      } else { 

        await this.deleteBoardToFavorites(board);
        if (checkError401(this.errorBoards)) { this.logOut(); }
      }
    },

    /** Funcion para abrir el pdf de Beta Participation Agreement  */
    async openAgreement() {
      await this.getFileBetaTerster();
      if (checkError401(this.errorSyst)) { this.logOut(); }
    },

  },
  mounted: async function () {
    if(this.boards.length == 0) {
      await this.getBoadsData();
      if (checkError401(this.errorBoards)) { this.logOut(); }
    }
  }
};
</script>


<style scoped>

a.router-link-exact-active {
  color: #3f6ad8;
  background: #e0f3ff;
  font-weight: bold;
}

.iconSidebar {
  background-image: url(../../assets/Icon-2.png);
  opacity: 1 !important;
}

</style>