<template>
  <div class="row text-center" :style="{ 'margin-bottom':'15px' }">
    <div class="col-md-12">
      <h5 class="text-left">
        {{ boardsFilter.team_name }}
      </h5>
    </div>

    <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3" 
      v-if="validateCreateBoardByLimitPlam(boardsFilter.boards, userInfo.e21, userAccess.limits.boards) && (checkSuperAdminRol(userInfo.access) || checPermissions('add:Boards', userAccess.permissions))">
        <div class="mb-3 card text-white card-body board-card bloque-b" @click="changeModalAddEditDeleteBoard({ new: true, team: boardsFilter.team_id })">
          <div class="row" :style="{ 'z-index':'5' }">
            <div class="col-md-12">
              <h5 class="text-white card-title" :style="{ 'margin-top':'40px' }">
                {{ t('dashboardData.addNewBoard', {}, {locale: lang}) }}
              </h5>
            </div>
          </div>
        </div>
    </div>

    <BoardCard v-for="board in boardsFilter.boards" :key="board.id" :board="board" />
  </div>
</template>

<script>
import { filterUserAccessByTeam, checkSuperAdminRol, checPermissions, validateCreateBoardByLimitPlam } from '@/utility/functions';
import { useI18n } from 'vue-i18n';
import { mapState, mapActions } from 'vuex';
import BoardCard from '@/components/boards/BoardCard.vue';
export default {
  name: 'BoardBlock',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      checkSuperAdminRol,
      checPermissions,
      validateCreateBoardByLimitPlam
    };
  },
  props: {
    boardsFilter: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    BoardCard,
  },
  data() {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
    };
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang']),
    ...mapState('boardsData', ['boards']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('boardsData', ['getBoadsData', 'getLatestBoards', 'getCalendarBoardsData', 'changeModalAddEditDeleteBoard']),
    ...mapActions('listsAndCards', ['updateCards',]),
  },
  mounted: async function () {

    this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.boardsFilter.team_id);
  }
}
</script> 


<style scoped>

.board-card {
  min-height: 150px;
  max-height: 150px;
}

.bloque-b {
  max-width: 320px;
  background: #3692ee;
  text-align: center;
  cursor: default;
}

</style>