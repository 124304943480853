<template>
  <div class="col-md-12" :style="{'margin-bottom':'10px'}">
    <div class="row">
      <div class="col-md-12">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <i class="fas fa-bookmark"></i>
          {{ t('labelCardData.label', { }, { locale: lang }) }}
          <button v-if="(checkSuperAdminRol(userInfo.access) || checPermissions('add:Labels', userAccess.permissions))"
            class="mb-2 mr-2 btn-icon-only btn-shadow btn-dashed btn btn-outline-info btn-sm"
            :style="{'margin-left':'10px', 'border-radius':'8.2rem'}" :title="t('labelCardData.addLabel', {}, { locale: lang })"
            @click="changeModalAddEditLabes(cardItem.id)">
            <i class="fas fa-plus btn-icon-wrapper"> </i>
          </button>
        </h5>
      </div>
      <div class="col-md-12 avatar-wrapper mt-2 avatar-wrapper-overlap">
        <div class="row">
          <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2 badge" v-for="label in cardItem.labels" :key="label.id"
            :style="{ 'background-color': label.color, 'color': 'cornsilk', 'cursor': 'default', 'margin-left': '5px'}" 
            @click="checkDeleteLabelPermission(label)">
            {{ label.title }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="modalDeleteLabelinCard">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('labelCardData.deleteLabel', {}, {locale: lang}) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalDeleteLabel()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <h6>{{ t('labelCardData.labelDeleteMsj', {}, {locale: lang}) }}</h6>
                <b>{{ labelDelet.title }}</b>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deleteLabelInCard()">
                  {{ t('button.delete', {}, {locale: lang}) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalDeleteLabel()">
                  {{ t('button.close', {}, {locale: lang}) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import { checkError401, checkSuperAdminRol, checPermissions, filterUserAccessByTeam } from '@/utility/functions';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
export default {
  name: 'LabelsSection',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      checkSuperAdminRol,
      checPermissions 
    };
  },
  data: function () {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      modalDeleteLabelinCard: false,
      labelDelet: {},
    }
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang']),
    ...mapState('labelsData', ['resultAction', 'errorLabels']),
    ...mapState('listsAndCards', ['cardItemId', 'cardItem']),
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('system', ['changeModalAddEditLabes']),
    ...mapActions('labelsData', ['deleteLabel']),
    ...mapActions('listsAndCards', ['deleteLabelInLists']),

    /** Funcion para ver si tiene permiso de eliminar label */
    checkDeleteLabelPermission(item){
      if (checkSuperAdminRol(this.userInfo.access) || checPermissions('view:LabelsConfig', this.userAccess.permissions)) {
        this.changeModalDeleteLabel(item);
      }
    },

    /** Funcion para mostrar el modal de elimiar la relacion de la etiqueta con la tarjeta */
    changeModalDeleteLabel(item = {}){
      this.labelDelet = item;
      this.modalDeleteLabelinCard = !this.modalDeleteLabelinCard;
    },

    /** Funcion para eliminar la relacion de una etiqueta con la tarjeta */
    async deleteLabelInCard(){

      await this.deleteLabel({
        label: this.labelDelet.id,
        card: this.cardItemId,
      });
      if (checkError401(this.errorLabels)) { this.logOut(); }
      if (this.errorLabels == null) {

        this.cardItem.labels = this.cardItem.labels.filter(item => item.id !== this.resultAction.label);
        await this.deleteLabelInLists(this.resultAction);
        this.changeModalDeleteLabel();
      }

    }
  },
  mounted: function () {

    this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.cardItem.team_id);
  }
}
</script>


<style scoped>
.btn-sm,
.btn-group-sm>.btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.label-flash {
  height: 20px;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 5px;
}
</style>