<template>

  <!-- Boton agregar Checklist (Clase) -->
  <div div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right" :style="{'margin-top':'1rem'}">
    <button v-if="cardItem.template == true || (checkSuperAdminRol(userInfo.access) || checPermissions('add:Checklist', userAccess.permissions))"
      class="mb-2 mr-2 btn-icon-only btn-shadow btn-dashed btn btn-outline-info btn-sm cardCheck"
      :title="t('modalCClass.addClassCheck', {}, { locale: lang })" @click="changeModalAddNewCClass()" :style="{'border-radius':'8.2rem'}" >
      <i class="fas fa-plus btn-icon-wrapper" > </i>
    </button>
  </div>
  <!-- Boton agregar Checklist (Clase) -->


  <!-- Bloque principal de la seccion de Clases y Tareas -->
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" @mouseleave="closeAll()">

    <!-- Contenedor principal para las clases de checklists (Clases) -->
    <Container orientation="vertical" @drop="onColumnDrop($event)" drag-handle-selector=".column-drag-handle"
      :drop-placeholder="upperDropPlaceholderOptions" 
    >

      <!-- Elementos Checklist (Clases)  -->
      <Draggable v-for="cclass in cardItem.checklistsClass" :key="cclass.id">

        <!-- Contenedor completo Checklist (Clases) -->
        <div class="row">
          
          <!-- Encabezado-->
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h5 class="modal-title" id="exampleModalLongTitle">
              <div class="row">

                <!-- Icono para mover -->
                <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1" :style="{'max-width':'6.333% !important', 'cursor':'move'}">
                  <span class="column-drag-handle">&#x2630;</span>
                </div>
                <!-- Icono para mover -->

                <!-- Titulo y Opciones -->
                <div v-if="!cclass.showEdit" class="col-sm-12 col-md-10 col-lg-10 col-xl-10">
                  <div class="row">

                    <!-- Titulo de la lista -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left" @click="checkEditTitleChecklists(cclass)">
                      <i class="fas fa-check-square" :style="{'margin-right':'8px'}"></i>
                      <b>{{ showInfoData(cclass.class_name, lang) }}</b>
                    </div>

                    <!-- Opciones Crear Item y Eliminar Clase  -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
                      <!-- Agregar un Item -->
                      <i v-if="cardItem.template == true || (checkSuperAdminRol(userInfo.access) || checPermissions('add:ItemChecklist', userAccess.permissions))"
                        :style="{'color':'#16aaff','margin-left':'10px','font-size':'18px'}" class="fas fa-plus btn-icon-wrapper"
                        :title="t('modalChecklistData.addCheckList', {}, { locale: lang })"
                        @click="changeModalAddEditCheck({}, cclass)"> </i>
                      <!-- Eliminar una clases -->
                      <i v-if="cardItem.template == true || (checkSuperAdminRol(userInfo.access) || checPermissions('delete:Checklist', userAccess.permissions))"
                        :style="{'color':'red','margin-left':'10px','font-size':'18px'}" class="lnr-trash btn-icon-wrapper"
                        :title="t('modalCClass.deletClassCheck', {}, { locale: lang })"
                        @click="changeModalDeleteOneClass(cclass)"> </i>
                    </div>

                  </div>
                </div>
                <!-- Titulo y Opciones -->

                <!-- Seccion de Editar el titulo -->
                <div v-else class="col-sm-10 col-md-10 col-lg-10 col-xl-10" :style="{'margin-bottom':'10px'}">
                  <div class="row">
                    <div class="col-sm-12 col-md-10 col-lg-10 col-xl-10">
                      <div class="input-group">
                        <input type="text" class="form-control" v-model="titleCClas">
                        <div class="input-group-append">
                          <button type="button" class="btn btn-primary clipboard-trigger"
                            :title="t('button.save', {}, { locale: lang })" @click="updateTitleToCClass()">
                            <i class="fas fa-save"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1" style="">
                      <button type="button" class="btn" :title="t('button.close', {}, { locale: lang })" @click="showOptionEditTitleOneCClass()">
                        <i class="fas fa-times" :style="{'font-size':'25px'}"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Seccion de Editar el titulo -->

              </div>
            </h5>
          </div>
          <!-- Encabezado-->

          <!-- Barra de progreso-->
          <div v-if="cclass.totalChecklists != 0" class="col-sm-12 col-md-12 col-lg-12 col-xl-12" :style="{'margin-bottom':'-10px'}">
            <div class="mb-3 progress">
              <div class="progress-bar" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"
                :style="`width:${classPercentage(cclass)}%;`"><b>{{ classPercentage(cclass) }}%</b></div>
            </div>
          </div>
          <!-- Barra de progreso-->

          <!-- Filtros -->
          <div v-if="cclass.totalChecklists != 0" class="col-sm-12 col-md-12 col-lg-12 col-xl-12 card-header"
            :style="{'margin-bottom':'-5px','border-bottom':'1px solid rgba(242, 243, 244, 0.13) !important'}">
            <ul class="nav nav-justified" :style="{'margin-bottom':'14px'}">
              
              <!-- Tareas Pendientes -->
              <li v-show="cclass.relChecklist.pending > 0" class="nav-item" :style="{'height':'38px'}">
                <a id="men-check0" data-toggle="tab" :style="{'color':'#3f6ad8'}" @click="filterByClass(cclass, 0)"
                  :class="cclass.showCheck == 0 ? 'ms-font-size nav-link active show' : 'ms-font-size nav-link'"
                >
                  {{t('modalChecklistData.outstandingChecklists', { }, { locale: lang }) }} ({{ cclass.relChecklist.pending }}) 
                </a>
              </li>
              <!-- Tareas Pendientes -->

              <!-- Tareas Vencidas -->
              <li v-show="cclass.relChecklist.overdue > 0" class="nav-item" :style="{'height':'38px'}">
                <a id="men-check1" data-toggle="tab" :style="{'color':'red'}" @click="filterByClass(cclass, 1)"
                  :class="cclass.showCheck == 1 ? 'ms-font-size nav-link active show' : 'ms-font-size nav-link'"
                >
                  {{ t('modalChecklistData.expiredChecklists', { }, { locale: lang }) }} ({{ cclass.relChecklist.overdue }})</a>
              </li>
              <!-- Tareas Vencidas -->
              
              <!-- Tareas Completadas -->
              <li v-show="cclass.relChecklist.completed > 0" class="nav-item" :style="{'height':'38px'}">
                <a id="men-check2" data-toggle="tab" :style="{'color':'green'}" @click="filterByClass(cclass, 2)"
                  :class="cclass.showCheck == 2 ? 'ms-font-size nav-link active show' : 'ms-font-size nav-link'"
                > 
                  {{ t('modalChecklistData.completedChecklists', { },{ locale: lang }) }} ({{ cclass.relChecklist.completed }})
                </a>
              </li>
              <!-- Tareas Completadas -->

            </ul>
          </div>
          <!-- Filtros -->


          <!-- Contenedor interno de los Checklist (Clases) para las tareas (Item) de cada clase -->
          <Container class="container-drag-interno" group-name="col"
            @drop="(e) => onChecklistsDropFather(cclass.id, e)" :get-child-payload="getChecklistPayloadFather(cclass.id)"
            drag-class="card-ghost" drop-class="card-ghost-drop" :drop-placeholder="dropPlaceholderOptions"
          >
          
            <!-- Elemento Tarea (Item) Padre (Principal) -->
            <Draggable v-for="check in cclass.checklists" :key="check.id"
              class="col-sm-12 col-md-12 col-lg-12 col-xl-12 parent-item"
            >

              <!-- Elemento Tarea (Item) Padres Interno -->
              <div class="row">

                <!-- Item padre -->
                <div :class="`${check.itemClass} col-sm-12 col-md-12 col-lg-12 col-xl-12`">
                  <div class="draggable-item" :style="{'min-height':'38px'}" @mouseleave="check.showInfo = false">
                  <div class="row">
                    <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 text-center">
                      <span class="column-drag-handle" :style="{'cursor':'move','font-size':'20px'}">&#x2630;</span>
                    </div>
                    <div class="col-sm-12 col-md-11 col-lg-11 col-xl-11">
                      <div class="row" :style="{'margin-top':'2px'}" @mouseenter="check.visibility=true" @mouseleave="check.visibility=false">
                        <!-- Icono de Check -->
                        <div v-if="cardItem.template == true || (checkSuperAdminRol(userInfo.access) || checPermissions('mark:ItemChecklist', userAccess.permissions))" 
                          class="col-sm-12 col-md-1 col-lg-1 col-xl-1 text-center custom-checkbox custom-control">
                          <input v-if="check.status" type="checkbox" checked="checked" :id="check.id" class="custom-control-input"
                            @click="changeStatusOneCheck(cclass, check)">
                          <input v-else type="checkbox" :id="check.id" class="custom-control-input"
                            @click="changeStatusOneCheck(cclass, check)">
                          <label class="custom-control-label text-center" :for="check.id">&nbsp;</label>
                        </div>
                        <!-- Icono de Check -->
                        <!-- Titulo del Item -->
                        <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 text-flash">
                          <span :class="{'tachado':check.status}">
                            <b class="font-z" :style="{'color': chooseColorItemTitle(check)}">
                              <div v-html="convertText(showInfoData(check.title, lang))"></div>
                            </b>
                          </span>
                        </div>
                        <!-- Titulo del Item -->
                        <!-- Opciones del Item -->
                        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 text-center">
                          <div v-show="check.visibility" class="mb-2 mr-2 btn-group">
                            <!-- Mostrar informacion del Item-->
                            <button v-if="!cardItem.template" @click="check.showInfo = !check.showInfo" type="button" class="btn-icon btn-icon-only btn btn-link"
                              :style="{'padding':'.10rem .10rem !important'}">
                              <i :style="{'font-size':'14px'}" class="lnr-eye btn-icon-wrapper"></i>
                            </button>
                            <!-- Editar Item -->
                            <button v-if="cardItem.template == true || (checkSuperAdminRol(userInfo.access) || checPermissions('edit:ItemChecklist', userAccess.permissions))"
                              type="button" title="Edit" class="btn-icon btn-icon-only btn btn-link"
                              :style="{'padding':'.10rem .10rem !important'}"
                              @click="changeModalAddEditCheck(check, cclass)">
                              <i :style="{'font-size':'14px','color':'blue'}" class="lnr-pencil btn-icon-wrapper"></i>
                            </button>
                            <!-- Agregar un Sub Item -->
                            <button v-if="cardItem.template == true || (checkSuperAdminRol(userInfo.access) || checPermissions('add:ItemChecklist', userAccess.permissions))"
                              type="button" :title="t('modalChecklistData.addCheckList', {}, { locale: lang })" class="btn-icon btn-icon-only btn btn-link"
                              :style="{'padding':'.10rem .10rem !important'}"
                              @click="changeModalAddEditCheck({}, cclass, check.id)">
                              <i :style="{'font-size':'16px','color':'blue'}" class="pe-7s-plus btn-icon-wrapper"></i>
                            </button>
                            <!-- Asignar fecha de inicio -->
                            <button v-if="validateUserStartDate(check.user_responsable)"
                              type="button" :title="t('modalChecklistData.assignStartDate', { }, { locale: lang })" class="btn-icon btn-icon-only btn btn-link"
                              :style="{'padding':'.10rem .10rem !important'}"
                              @click="changeModalStartDateOneItem(check)">
                              <i :style="{'font-size':'16px','color':'blue'}" class="pe-7s-date btn-icon-wrapper"></i>
                            </button>
                            <!-- Eliminar Item -->
                            <button v-if="cardItem.template == true || (checkSuperAdminRol(userInfo.access) || checPermissions('delete:ItemChecklist', userAccess.permissions))"
                              type="button" title="Delete" class="btn-icon btn-icon-only btn btn-link"
                              :style="{'padding':'.10rem .10rem !important'}" @click="changeModalDeleteItem(check, cclass)">
                              <i :style="{'font-size':'14px','color':'red'}" class="lnr-trash btn-icon-wrapper"></i>
                            </button>
                          </div>
                        </div>

                        <!-- Informacion del Item-->
                        <transition name="fade">
                          <div v-show="check.showInfo" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="row" :style="{'transition-delay':'1.5s'}">

                              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                <span>
                                  <b class="font-s">{{ t('modalChecklistData.assignedTo', { }, { locale: lang }) }}: </b>
                                  <br>
                                  <i v-if="!check.is_responsable" class="lnr-user btn-icon-wrapper" title="Add User"
                                    :style="{'color':'blue','font-size':'14px','margin-left':'25px'}" @click="changeModalAddUserToItem(check)"> 
                                  </i>
                                  <span v-else class="font-s">
                                    {{ check.user_responsable.name }}
                                    <i class="lnr-trash btn-icon-wrapper" :style="{'color':'red','font-size':'14px'}" title="Delete User" 
                                      @click="changeModalDeleteUserToItem(check)"> 
                                    </i>
                                  </span>
                                </span>
                              </div>

                              <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                <span>
                                  <b class="font-s">{{ t('modalChecklistData.expiration', { }, { locale: lang }) }}: </b>
                                  <br>
                                  <span class="font-s" :style="{ 'color': check.itemColor }">
                                    {{ check.expiration.substring(0, 10) == '1600-01-01' ? '' : moment(check.expiration).format('MM-DD-YYYY') }}
                                  </span>
                                </span>
                              </div>

                              <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                <span>
                                  <b class="font-s">{{ t('modalChecklistData.started', { }, { locale: lang }) }}: </b>
                                  <br>
                                  <span class="font-s" :style="{ 'color': check.itemColor }">
                                    {{ check.start_date.substring(0, 10) == '1600-01-01' ? '' : moment(check.start_date).format('MM-DD-YYYY') }}
                                  </span>
                                </span>
                              </div>

                              <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                <span v-if="check.status">
                                  <b class="font-s">{{ t('modalChecklistData.completed', { }, { locale: lang }) }}: </b>
                                  <br>
                                  <span class="font-s">{{ moment(check.completed).format('MM-DD-YYYY') }}</span>
                                </span>
                              </div>

                              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                <span v-if="check.status">
                                  <b class="font-s">{{ t('modalChecklistData.userCompleted', { }, { locale: lang }) }}:
                                  </b>
                                  <br>
                                  <span class="font-s">{{ check.user_completed.name }}</span>
                                </span>
                              </div>

                            </div>
                          </div>
                        </transition>
                        <!-- Informacion del Item-->
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
                <!-- Item padre -->

                <!-- Bloque interno para los Sub-Item (Hijos) -->
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="background-color: rgba(219, 220, 220, 0.686);">

                  <!-- Contenedor interno para los Sub-Item (Hijos)  -->
                  <Container  class="container-drag-interno-child" group-name="col" 
                    @drop="(e) => onChecklistsDropSon(cclass.id, check.id, e)" :get-child-payload="getChecklistPayloadSon(cclass.id, check.id)"
                    drag-class="card-ghost" drop-class="card-ghost-drop" :drop-placeholder="dropPlaceholderOptions"
                  >
                    
                    <!-- Item Hijo (Sub-Item)-->
                    <Draggable v-for="subCheck in check.subItems" :key="subCheck.id"
                      class="col-sm-12 col-md-12 col-lg-12 col-xl-12 child-item"
                    >

                      <div :class="`${subCheck.itemClass} col-sm-12 col-md-12 col-lg-12 col-xl-12`">

                        <div class="draggable-item" :style="{'min-height':'38px'}" @mouseleave="subCheck.showInfo = false">
                          <div class="row">

                            <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 text-center">
                              <span class="column-drag-handle" :style="{'cursor':'move','font-size':'20px'}">&#x2630;</span>
                            </div>
                            
                            <div class="col-sm-12 col-md-11 col-lg-11 col-xl-11">
                              <div class="row" :style="{'margin-top':'2px'}" @mouseenter="subCheck.visibility=true" @mouseleave="subCheck.visibility=false">
                                
                                <!-- Icono de Check -->
                                <div v-if="cardItem.template == true || (checkSuperAdminRol(userInfo.access) || checPermissions('mark:ItemChecklist', userAccess.permissions))" 
                                  class="col-sm-12 col-md-1 col-lg-1 col-xl-1 text-center custom-checkbox custom-control"
                                >
                                  <input v-if="subCheck.status" type="checkbox" checked="checked" :id="subCheck.id" class="custom-control-input"
                                    @click="changeStatusOneCheck(cclass, subCheck)">
                                  <input v-else type="checkbox" :id="subCheck.id" class="custom-control-input"
                                    @click="changeStatusOneCheck(cclass, subCheck)">
                                  <label class="custom-control-label text-center" :for="subCheck.id">&nbsp;</label>
                                </div>
                                <!-- Icono de Check -->

                                <!-- Titulo del Item -->
                                <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 text-flash">
                                  <span :class="{'tachado':subCheck.status}">
                                    <b class="font-z" :style="{'color': chooseColorItemTitle(subCheck)}">
                                      <div v-html="convertText(showInfoData(subCheck.title, lang))"></div>
                                    </b>
                                  </span>
                                </div>
                                <!-- Titulo del Item -->

                                <!-- Opciones del Item -->
                                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 text-center">
                                  <div v-show="subCheck.visibility" class="mb-2 mr-2 btn-group">

                                    <!-- Mostrar informacion del Item-->
                                    <button v-if="!cardItem.template" @click="subCheck.showInfo = !subCheck.showInfo" type="button" class="btn-icon btn-icon-only btn btn-link"
                                      :style="{'padding':'.10rem .10rem !important'}">
                                      <i :style="{'font-size':'14px'}" class="lnr-eye btn-icon-wrapper"></i>
                                    </button>

                                    <!-- Editar Item -->
                                    <button v-if="cardItem.template == true || (checkSuperAdminRol(userInfo.access) || checPermissions('edit:ItemChecklist', userAccess.permissions))"
                                      type="button" title="Edit" class="btn-icon btn-icon-only btn btn-link"
                                      :style="{'padding':'.10rem .10rem !important'}"
                                      @click="changeModalAddEditCheck(subCheck, cclass, check.id)">
                                      <i :style="{'font-size':'14px','color':'blue'}" class="lnr-pencil btn-icon-wrapper"></i>
                                    </button>

                                    <!-- Asignar fecha de inicio -->
                                    <button v-if="validateUserStartDate(subCheck.user_responsable)"
                                      type="button" :title="t('modalChecklistData.assignStartDate', { }, { locale: lang })" class="btn-icon btn-icon-only btn btn-link"
                                      :style="{'padding':'.10rem .10rem !important'}"
                                      @click="changeModalStartDateOneItem(subCheck)">
                                      <i :style="{'font-size':'16px','color':'blue'}" class="pe-7s-date btn-icon-wrapper"></i>
                                    </button>

                                    <!-- Eliminar Item -->
                                    <button v-if="cardItem.template == true || (checkSuperAdminRol(userInfo.access) || checPermissions('delete:ItemChecklist', userAccess.permissions))"
                                      type="button" title="Delete" class="btn-icon btn-icon-only btn btn-link"
                                      :style="{'padding':'.10rem .10rem !important'}" @click="changeModalDeleteItem(subCheck, cclass)">
                                      <i :style="{'font-size':'14px','color':'red'}" class="lnr-trash btn-icon-wrapper"></i>
                                    </button>

                                  </div>
                                </div>
                                <!-- Opciones del Item -->

                                <!-- Informacion del Item-->
                                <transition name="fade">
                                  <div v-show="subCheck.showInfo" class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="row" :style="{'transition-delay':'1.5s'}">

                                      <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <span>
                                          <b class="font-s">{{ t('modalChecklistData.assignedTo', { }, { locale: lang }) }}: </b>
                                          <br>
                                          <i v-if="!subCheck.is_responsable" class="lnr-user btn-icon-wrapper" title="Add User"
                                            :style="{'color':'blue','font-size':'14px','margin-left':'25px'}" @click="changeModalAddUserToItem(subCheck)"> 
                                          </i>
                                          <span v-else class="font-s">
                                            {{ subCheck.user_responsable.name }}
                                            <i class="lnr-trash btn-icon-wrapper" :style="{'color':'red','font-size':'14px'}" title="Delete User" 
                                              @click="changeModalDeleteUserToItem(subCheck)"> 
                                            </i>
                                          </span>
                                        </span>
                                      </div>

                                      <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                        <span>
                                          <b class="font-s">{{ t('modalChecklistData.expiration', { }, { locale: lang }) }}: </b>
                                          <br>
                                          <span class="font-s" :style="{ 'color': subCheck.itemColor }">
                                            {{ subCheck.expiration.substring(0, 10) == '1600-01-01' ? '' : moment(subCheck.expiration).format('MM-DD-YYYY') }}
                                          </span>
                                        </span>
                                      </div>

                                      <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                        <span>
                                          <b class="font-s">{{ t('modalChecklistData.started', { }, { locale: lang }) }}: </b>
                                          <br>
                                          <span class="font-s" :style="{ 'color': subCheck.itemColor }">
                                            {{ subCheck.start_date.substring(0, 10) == '1600-01-01' ? '' : moment(subCheck.start_date).format('MM-DD-YYYY') }}
                                          </span>
                                        </span>
                                      </div>

                                      <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                        <span v-if="subCheck.status">
                                          <b class="font-s">{{ t('modalChecklistData.completed', { }, { locale: lang }) }}: </b>
                                          <br>
                                          <span class="font-s">{{ moment(subCheck.completed).format('MM-DD-YYYY') }}</span>
                                        </span>
                                      </div>

                                      <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                        <span v-if="subCheck.status">
                                          <b class="font-s">{{ t('modalChecklistData.userCompleted', { }, { locale: lang }) }}:
                                          </b>
                                          <br>
                                          <span class="font-s">{{ subCheck.user_completed.name }}</span>
                                        </span>
                                      </div>

                                    </div>
                                  </div>
                                </transition>
                                <!-- Informacion del Item-->

                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                      
                    </Draggable>
                    <!-- Item Hijo (Sub-Item)-->
                  
                  </Container>
                  <!-- Contenedor interno para los Sub-Item (Hijos)  -->
                  
                </div>
                <!-- Contenedor interno para los Sub-Item -->

              </div>
              <!-- Elemento Tarea (Item) Padres Interno -->


            </Draggable>
            <!-- Elemento Tarea (Item) Padre (Principal) -->
          

          </Container>
          <!-- Contenedor interno de los Checklist (Clases) para las tareas (Item) de cada clase -->


        </div>
        <!-- Contenedor completo Checklist (Clases) -->

      </Draggable>
      <!-- Elemento Checklist (Clases)  -->


    </Container>
    <!-- Contenedor principal para las clases de checklists (Clases) -->

  </div>
  <!-- Bloque principal de la seccion de Clases y Tareas -->





  <!-- Modal para Añadir una clase -->
  <div v-if="modalAddNewCclass">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalCClass.addClassCheck', {}, { locale: lang }) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalAddNewCClass()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="position-relative form-group" :style="{'text-align':'center'}">
                      <label for="" class="">{{ t('modalChecklistData.title', { }, { locale: lang }) }}</label>
                      <input name="title" id="title" type="text" class="form-control form-control-sm" v-model="formCclas.title" />
                      <span class="helper-text danger-text">{{ formCclas.errorTitle }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="addNewCclassInCard()">
                  {{ t('button.add', {}, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalAddNewCClass()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para Añadir una clase -->



  <!-- Modal para eliminar una clase -->
  <div v-if="modalDeleteOneClass">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalCClass.deletClassCheck', { }, { locale:lang }) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  @click="changeModalDeleteOneClass()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <h6>{{ t('modalCClass.deleteMsjClass', { }, { locale: lang }) }}</h6>
                <b>{{ showInfoData(selectClass.class_name, lang) }}</b>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deleteOneClassToCard()">
                  {{ t('button.delete', { }, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"
                  @click="changeModalDeleteOneClass()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para eliminar una clase -->



  <!-- Modal para Añadir o editar un Item -->
  <div v-show="modaAddEditItemClass" @click="closeEmojisPicket">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t(formItem.new ? 'modalChecklistData.addCheckList' : 'modalChecklistData.editCheckList', {}, { locale: lang }) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalAddEditCheck()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <div class="row">

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div class="row">
                            <div class="col-xs-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                              <label for="" class="">{{ t('modalChecklistData.title', { }, { locale: lang }) }}</label>
                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4" style="text-align: right;">
                              <div id="alfaArea2" class="btn-group dropdown" :title="t('button.emojis', { }, { locale: lang })">
                                <button type="button" aria-haspopup="true" aria-expanded="false" class="btn-icon btn-icon-only btn btn-link" @click="showAlfa = !showAlfa;">
                                  <i :style="{'font-size':'20px'}" class="lnr-mustache btn-icon-wrapper"></i>
                                </button>
                                <div tabindex="-1" role="menu" aria-hidden="true" :style="{'height':'16rem'}" :class="`dropdown-menu-shadow dropdown-menu-right dropdown-menu-hover-link dropdown-menu ${showAlfa ? 'show':'hidden'}`">
                                  <emoji-picker class="light" id="emojisItems"></emoji-picker>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <textarea rows="1" class="form-control autosize-input" :style="{'height':'60px'}" v-model="formItem.title"></textarea>
                          <span class="helper-text danger-text">{{ formItem.errorTitle }}</span>
                        </div>
                      </div>
                      
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('modalChecklistData.expirationDate', { }, { locale: lang }) }}</label>
                      <div class="date-picker input-group mb-3">
                        <input type="date" id="expiration" name="expiration" class="form-control form-control-sm"
                          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" v-model="formItem.expiration">
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('modalChecklistData.assignUser', { }, { locale: lang }) }}</label>
                      <select class="multiselect-dropdown form-control form-control-sm" v-model="formItem.member">
                        <option value="">{{ t('modalsMembersData.chooseMember', { }, { locale: lang }) }}</option>
                        <option v-for="(m, index) in filterBoardMembers" :key="m.id" :value="index">
                          {{ m.user_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                    <div class="position-relative form-group">
                      <div class="position-relative form-check">
                        <input v-if="formItem.priority" name="check" type="checkbox" class="form-check-input" checked @click="changesPriorityState()">
                        <input v-else name="check" type="checkbox" class="form-check-input" @click="changesPriorityState()">
                        <label for="itemPriority" class="form-check-label">
                          {{ t('modalChecklistData.itemPriority', { }, { locale: lang }) }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-show="formItem.priority" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('modalChecklistData.priorityLevel', {}, { locale: lang }) }}</label>
                      <select class="multiselect-dropdown form-control form-control-sm" v-model="formItem.priorityLevel" 
                        :style="{ 'color': formItem.priorityLevel == 1 ? '#ff7213' : formItem.priorityLevel == 2 ? '#f7b14f' : '#edd57e', 'font-weight': 'bold' }">
                        <option :value="3" :style="{ 'color': '#edd57e', 'font-weight':'bold' }"> {{ t('modalChecklistData.low', {}, { locale: lang }) }}</option>
                        <option :value="2" :style="{'color':'#f7b14f', 'font-weight':'bold'}"> {{ t('modalChecklistData.medium', {}, { locale: lang }) }}</option>
                        <option :value="1" :style="{ 'color': '#ff7213', 'font-weight':'bold'}"> {{ t('modalChecklistData.high', {}, { locale: lang }) }}</option>
                      </select>
                    </div>  
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="addOrEditOneItem()">
                  {{ t(formItem.new ? 'button.add' : 'button.save', {}, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalAddEditCheck()">
                    {{ t('button.close',{ }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para Añadir o editar un Item -->



  <!-- Modal para agregar un responsable a un item -->
  <div v-if="modalAddUserToItem">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalChecklistData.assignUser', { }, { locale:lang }) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalAddUserToItem()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('modalChecklistData.members', { }, { locale: lang }) }}</label>
                      <select class="multiselect-dropdown form-control form-control-sm" v-model="userToItem">
                        <option value="">{{ t('modalsMembersData.chooseMember', { }, { locale: lang }) }}</option>
                        <option v-for="m in filterBoardMembers" :key="m.id" :value="m">
                          {{ m.user_name }}
                        </option>
                      </select>
                      <span class="helper-text danger-text">{{ errorUserToItem }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="addOneUserToChecklist()">
                  {{ t('button.add', { },{ locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalAddUserToItem()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para agregar un responsable a un item -->


  <!-- Modal para eliminar el responsable de un item -->
  <div v-if="modalDeleteUserToItem">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalChecklistData.deleteUser', { }, { locale:lang}) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalDeleteUserToItem()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <h6>{{ t('modalChecklistData.deleteUserMsj', { }, { locale: lang }) }}</h6>
                <b v-if="selectCheck.user_responsable.type=='user'">{{ selectCheck.user_responsable.name }} - {{ selectCheck.user_responsable.email }}</b>
                <b v-else>{{ t('headerData.userGroups', {}, { locale: lang }) }} - {{ selectCheck.user_responsable.name }}</b>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deletUserToItem()">
                  {{ t('button.delete', { }, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalDeleteUserToItem()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para eliminar el responsable de un item -->


  <!-- Modal para eliminar un item -->
  <div v-if="modalDeleteOneItem">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalChecklistData.deleteChecklist', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalDeleteItem()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <h6>{{ t('modalChecklistData.deleteMsj', { }, { locale: lang }) }}</h6>
                <b>{{ showInfoData(selectCheck.title, lang) }}</b>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" @click="deleteOneItemCheck()">
                  {{ t('button.delete', { }, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalDeleteItem()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para eliminar un item -->


  <!-- Modal para asignar la fecha de inicio a un Item -->
  <div v-if="modalStartDateOneItem">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  {{ t('modalChecklistData.assignStartDate', { }, { locale: lang }) }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="changeModalStartDateOneItem()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-2 col-lg-12 col-xl-12">
                    <div class="position-relative form-group">
                      <label for="" class="">{{ t('modalChecklistData.selectTheDate', { }, { locale: lang }) }}</label>
                      <div class="date-picker input-group mb-3">
                        <input type="date" id="startDate" name="startDate" class="form-control form-control-sm"
                          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" v-model="formStartDate.start_date">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm" @click="editStartDateOneItem()">
                  {{ t('button.save', { }, { locale: lang }) }}
                </button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="changeModalStartDateOneItem()">
                  {{ t('button.close', { }, { locale: lang }) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <!-- Modal para asignar la fecha de inicio a un Item -->


</template>


<script>
import moment from 'moment';
import { showInfoData, checkError401, checkSuperAdminRol, checPermissions, filterUserAccessByTeam, convertText } from '@/utility/functions';
import { requiredFields } from '@/utility/validations';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Container, Draggable } from "vue-dndrop";
export default {
  name: 'ChecklistSection',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      showInfoData,
      moment,
      checkSuperAdminRol,
      checPermissions,
      convertText
    };
  },
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      upperDropPlaceholderOptions: {
        className: "cards-drop-preview",
        animationDuration: "120",
        showOnTop: true,
      },
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "120",
        showOnTop: true,
      },

      selectClass: {},
      titleCClas: '',
      modalAddNewCclass: false,
      formCclas: {
        title: '',
        errorTitle: ''
      },
      modalDeleteOneClass: false,
      modalAddUserToItem: false,
      userToItem: '',
      errorUserToItem: '',
      modalDeleteUserToItem: false,
      modaAddEditItemClass: false,
      selectCheck: {},
      formItem: {
        id: 0,
        cclass: 0,
        new: true,
        title: '',
        errorTitle: '',
        expiration: '',
        member: '',
        priority: false,
        priorityLevel: 3,
        type: 1,
        parent: 0,
      },
      modalDeleteOneItem: false,
      modalStartDateOneItem: false,
      formStartDate: {
        id: 0,
        cclass: 0,
        new: false,
        start_date: '',
      },
      showAlfa: false,
    };
  },
  computed: {
    ...mapState('auth', ['userInfo']),
    ...mapState('system', ['lang']),
    ...mapState('boardsData', ['boardItem']),
    ...mapState('listsAndCards', ['cardItemId', 'cardItem', 'errorLists']),
    /** Funcion para obtener un arreglo de miembros de los tableros configurados para mostrarlos en las listas */
    filterBoardMembers() {
      let r = [];
      for (let i = 0; i < this.boardItem.members.length; i++) {
        const item = this.boardItem.members[i];
        if(item.type == 'user'){
          r.push({
            type: item.type,
            user_email: item.user_email,
            user_name: `${item.user_name} - ${item.user_email}`,
            group_id: item.group_id,
          });
        } else {
          r.push({
            type: item.type,
            user_email: item.user_email,
            user_name: `${this.t('headerData.userGroups', {}, { locale: this.lang })}: ${item.user_name}`,
            group_id: item.group_id,
          });

          item.group.forEach(it => {
            r.push({
              type: 'user',
              user_email: it.email,
              user_name: `${item.user_name}: ${it.name} - ${it.email}`,
              group_id: item.group_id,
            });
          })
        }
      }
      return r;
    }
  },
  methods: {
    ...mapActions('auth', ['logOut']),
    ...mapActions('listsAndCards', [
      'addNewChecklistClassInCard', 'updateOneChecklistClass', 'updateChecklistClassLevelInCard',
      'updateItemsLevelInChecklistClass', 'deleteOneChecklistClass', 'updateStatusOneItemChecklistClass',
      'assignItemChecklistClassToUser', 'unassignItemChecklistClassToUser', 'addNewItemInChecklistClass',
      'updateOneItemInChecklistClass', 'deleteOneItemInChecklistClass', 'getItemsClasssByFilter', 'getCalendarListsData'
    ]),
    /** Funcion para elegir el color de la tarea dependiendo de la prioridad */
    chooseColorItemTitle(item){
      let r = 'black';
      if(item.priority){
        if(item.priority_level == 1) {
          r = '#ff7213';
        } else if( item.priority_level == 2) {
          r = '#f7b14f';
        } else {
          r = '#edd57e';
        }
      } else {
        r = 'black';
      }
      return r;
    },

    /** Funcion para cambiar el filtro de una clase */
    async filterByClass(cclass, filter) {
      if(cclass.showCheck != filter) {
        await this.getItemsClasssByFilter({
          class: cclass.id,
          filter: filter
        });
      }
    },

    /** Funcion para verificar si tiene permiso de editar el titulo de una clase */
    checkEditTitleChecklists(item) {
      if (this.cardItem.template == true || checkSuperAdminRol(this.userInfo.access) || checPermissions('edit:Checklist', this.userAccess.permissions)) {
        this.showOptionEditTitleOneCClass(item);
      }
    },

    /** Funcion para cerrar todas las opciones */
    closeAll(){
      this.cardItem.checklistsClass.forEach(item => {
        item.showEdit = false;
        item.checklists.forEach(item2 => {
          
          item2.visibility = false;
          item2.showInfo = false;

          item2.subItems.forEach(item3 => {
            item3.visibility = false;
            item3.showInfo = false;
          })
        });
      });
    },


    /** Funcion para calcular el porcentaje de los checklists completados de una clase */
    classPercentage(checkClass) { 
      if (checkClass.totalChecklists == 0) {
        
        return 0;

      } else {

        const r = (checkClass.relChecklist.completed / checkClass.totalChecklists) * 100;
        return r.toFixed(0);
      }
    },



    /** Funciones para las clases de checklists */


    /** Funcion para abrir el modal para agregar una clase  */
    changeModalAddNewCClass(){
      this.formCclas =  {
        title: '',
        errorTitle: ''
      };
      this.modalAddNewCclass = !this.modalAddNewCclass;
    },

    /** Funcion para agregar una nueva clase */
    async addNewCclassInCard(){
      this.formCclas.errorTitle = requiredFields(this.formCclas.title, this.lang);

      if(this.formCclas.errorTitle == ''){

        this.addNewChecklistClassInCard({
          name: this.formCclas.title,
          card_id: this.cardItemId,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.changeModalAddNewCClass();
        }
      }
    },

    /** Funcion para abrir opcion de editar titulo de una sola clase */
    showOptionEditTitleOneCClass(item = {}){
      if (Object.entries(this.selectClass).length > 0){
        this.selectClass.showEdit = false;
      }
      if (Object.entries(item).length > 0) {
        this.selectClass = item;
        this.titleCClas = this.showInfoData(item.class_name, this.lang);
        this.selectClass.showEdit = true;
      }else{
        this.selectClass = item;
        this.titleCClas = '';
      }
    },

    /** Funcion para actualizar el titulo de una clase */
    async updateTitleToCClass() {

      if (this.titleCClas != '' && Object.entries(this.selectClass).length > 0){
        await this.updateOneChecklistClass({ 
          id: this.selectClass.id,
          name: this.titleCClas, 
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.selectClass.showEdit = false;
          this.titleCClas = '';
          this.selectClass = {};
        }
      }
    },



    /**** Funciones para el Drag an Drop de los elementos clases  
     * 
     * 
     * *****/


    /** Funcion para detectar el movimiento de las clases y aplicar el Drag */
    onColumnDrop(dropResult) {
      /** Se crea una copia de las clases */
      let cclass = Object.assign([], this.cardItem.checklistsClass);
      cclass = this.applyDrag(cclass, dropResult);
      this.cardItem.checklistsClass = cclass;
      this.apiDragClass();
    },


    /** Funcion para cambiar el orden de los elementos del arreglo */
    applyDrag(arr, dragResult) { 

      const { removedIndex, addedIndex, payload } = dragResult;

      /** No se realizo ningun cambio en las clases se retorna el mismo arreglo */
      if (removedIndex === null && addedIndex === null) return arr;


      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },


    /** Funcion para tomar el nuevo nivel de las clases de checklist */
    async apiDragClass() { 
      let r = [];
      for (let i = 0; i < this.cardItem.checklistsClass.length; i++) {
        r.push({
          cclass: this.cardItem.checklistsClass[i].id,
          nivel: i + 1,
        });
      }
      await this.updateChecklistClassLevelInCard({ data: JSON.stringify(r) });
      if (checkError401(this.errorLists)) { this.logOut(); }
    },


    /**** Funciones para el Drag an Drop de los elementos clases  
     * 
     * 
     * *****/



    /** Funcion para cambiar el estatus del modal eliminar clase de checklist */
    changeModalDeleteOneClass(item = {}) {
      this.selectClass = item;
      this.modalDeleteOneClass = !this.modalDeleteOneClass;
    },

    /** Funcion para eliminar la clase de checklist seleccionada */
    async deleteOneClassToCard() {
      if(Object.entries(this.selectClass).length > 0){
        await this.deleteOneChecklistClass(this.selectClass.id);
        if (checkError401(this.errorLists)) { this.logOut(); }
        if(this.errorLists == null){
          this.changeModalDeleteOneClass();
          await this.getCalendarListsData(this.boardItem.id);
        }
      }
    },


    /** Funciones para las clases de checklists */








    /** Funciones para los Items de las clases de checklists */


    /** Funcion para cambiar el estatus de un Item Checklist */
    async changeStatusOneCheck(cclass, check) {
      await this.updateStatusOneItemChecklistClass({ id: check.id});
      if (checkError401(this.errorLists)) { this.logOut(); }
      if (this.errorLists == null) {
        await this.getItemsClasssByFilter({
          class: cclass.id,
          filter: cclass.showCheck
        });
        await this.getCalendarListsData(this.boardItem.id);
      }
    },

    /** Funcion para cambiar el estatus de Agregar user a un Item */
    changeModalAddUserToItem(item = {}) {
      this.selectCheck = item;
      this.modalAddUserToItem = !this.modalAddUserToItem;
    },

    /** Funcion para añadir un responsable a un Item */
    async addOneUserToChecklist() {
      this.errorUserToItem = requiredFields(this.userToItem, this.lang);

      if(this.errorUserToItem == ''){

        await this.assignItemChecklistClassToUser({
          checkslist_id: this.selectCheck.id,
          userEmail: this.userToItem.user_email,
          userName: this.userToItem.user_name,
          type: this.userToItem.type,
          groupId: this.userToItem.group_id,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if(this.errorLists == null){
          this.userToItem = '';
          this.changeModalAddUserToItem();
        }
      }
    },

    /** Funcion para cambiar el estatus de eliminar un responsable de un Item */
    changeModalDeleteUserToItem(item = {}) {
      this.selectCheck = item;
      this.modalDeleteUserToItem = !this.modalDeleteUserToItem;
    },


    /** Funcion para eliminar el responsbale de un item  */
    async deletUserToItem() {
      if (Object.entries(this.selectCheck).length > 0){
        await this.unassignItemChecklistClassToUser({
          id: this.selectCheck.id,
          user: this.selectCheck.user_responsable.email == "" ? '-' : this.selectCheck.user_responsable.email,
          type: this.selectCheck.user_responsable.type,
          group: this.selectCheck.user_responsable.group_id,
        });
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.changeModalDeleteUserToItem();
        }
      }
    },


    /** Funcion para cambiar el modal para Añadir y editar un Item */
    changeModalAddEditCheck(item = {}, cclass = {}, father = null) {

      if (Object.entries(item).length == 0){

        this.formItem = {
          id: 0,
          cclass: Object.entries(cclass).length == 0 ? 0 : cclass.id,
          new: true,
          title: '',
          errorTitle: '',
          expiration: '',
          member: '',
          priority: false,
          priorityLevel: 3,
          type: father == null ? 1 : 2,
          parent: father == null ? 0 : father,
        };
        this.selectClass = cclass;

      }else{

        this.formItem.id = item.id;
        this.formItem.new = false;
        this.formItem.cclass = item.class_id;
        this.formItem.title = item.title;
        this.formItem.expiration = '1600-01-01' == item.expiration.substring(0, 10) ? '' : moment(item.expiration).format('YYYY-MM-DD');
        this.formItem.priority = item.priority;
        this.formItem.priorityLevel = item.priority_level;
        this.formItem.type = item.type;
        this.formItem.parent = item.parent;
        this.selectClass = cclass;
        this.formItem.member = '';
        if(item.is_responsable == true) {
          for (let i = 0; i < this.filterBoardMembers.length; i++) {
            const element = this.filterBoardMembers[i];
            if(item.user_responsable.type == 'user') {
              if((element.type == item.user_responsable.type) && (element.user_email == item.user_responsable.email)) {
                this.formItem.member = i;
              }
            } else {
              if((element.type == item.user_responsable.type) && (element.group_id == item.user_responsable.group_id)) {
                this.formItem.member = i;
              }
            }
          }
        }
      }
    
      this.modaAddEditItemClass = !this.modaAddEditItemClass;
    },

    /** Funcion para cambiar el estado del checklist de Prioridad de un item */
    changesPriorityState(){
      this.formItem.priority = !this.formItem.priority;
      this.formItem.priorityLevel = 3;
    },

    /** Funcion para agregar o editar un item */
    async addOrEditOneItem() {

      this.formItem.errorTitle = requiredFields(this.formItem.title, this.lang);

      if(this.formItem.errorTitle == '') {

        if(!this.formItem.priority) {
          this.formItem.priorityLevel = 0;
        }

        if(this.formItem.new) {

          if(!this.formItem.priority) {
            this.formItem.priorityLevel = 0;
          }

          await this.addNewItemInChecklistClass({
            title: this.formItem.title,
            expiration: this.formItem.expiration == '' ? '1600-01-01' : this.formItem.expiration,
            class_id: this.formItem.cclass,
            card_id: this.cardItemId,
            priority: this.formItem.priority,
            priorityLevel: this.formItem.priorityLevel,
            resp: typeof this.formItem.member == 'string' ? false : true,
            userName: typeof this.formItem.member == 'string' ? '' : this.filterBoardMembers[this.formItem.member].user_name,
            userEmail: typeof this.formItem.member == 'string' ? '' : this.filterBoardMembers[this.formItem.member].user_email,
            type: typeof this.formItem.member == 'string' ? '' : this.filterBoardMembers[this.formItem.member].type,
            groupId: typeof this.formItem.member == 'string' ? '' : this.filterBoardMembers[this.formItem.member].group_id,
            type2: this.formItem.type,
            parent: this.formItem.parent,
          });
          if (checkError401(this.errorLists)) { this.logOut(); }
          if (this.errorLists == null) {
            this.getItemsClasssByFilter({
              class: this.selectClass.id,
              filter: this.selectClass.showCheck
            });
            this.changeModalAddEditCheck();
            await this.getCalendarListsData(this.boardItem.id);
          }

        }else {

          if (!this.formItem.priority) {
            this.formItem.priorityLevel = 0;
          }

          await this.updateOneItemInChecklistClass({
            id: this.formItem.id,
            title: this.formItem.title,
            expiration: this.formItem.expiration == '' ? '1600-01-01' : this.formItem.expiration,
            priority: this.formItem.priority,
            priorityLevel: this.formItem.priorityLevel,
            resp: typeof this.formItem.member == 'string' ? false : true,
            userName: typeof this.formItem.member == 'string' ? '' : this.filterBoardMembers[this.formItem.member].user_name,
            userEmail: typeof this.formItem.member == 'string' ? '' : this.filterBoardMembers[this.formItem.member].user_email,
            type: typeof this.formItem.member == 'string' ? '' : this.filterBoardMembers[this.formItem.member].type,
            groupId: typeof this.formItem.member == 'string' ? '' : this.filterBoardMembers[this.formItem.member].group_id,
            type2: this.formItem.type,
            parent: this.formItem.parent,
          });
          if (checkError401(this.errorLists)) { this.logOut(); }
          if (this.errorLists == null) {
            this.getItemsClasssByFilter({
              class: this.selectClass.id,
              filter: this.selectClass.showCheck
            });
            this.changeModalAddEditCheck();
            await this.getCalendarListsData(this.boardItem.id);
          }
        }
      }
    },

    /** Funcion cambiar el estado de el modal para eliminar un item */
    changeModalDeleteItem(item = {}, cclass = {}) {
      this.selectCheck = item;
      this.selectClass = cclass;
      this.modalDeleteOneItem = !this.modalDeleteOneItem;
    },
    
    /** Funcion para eliminar un Item */
    async deleteOneItemCheck(){
      if(Object.entries(this.selectCheck).length > 0){

        await this.deleteOneItemInChecklistClass(this.selectCheck.id);
        if (checkError401(this.errorLists)) { this.logOut(); }
        if (this.errorLists == null) {
          this.getItemsClasssByFilter({
            class: this.selectClass.id,
            filter: this.selectClass.showCheck
          });
          this.changeModalDeleteItem();
          await this.getCalendarListsData(this.boardItem.id);
        }
      }
    },



    /*** 
     * 
     * Funciones para el Drag an Drop de los item de las clases (Tareas)
     * 
    */

    /** Funcion para retornar el index de la clases del contenedor para los items padres */
    getChecklistPayloadFather(cId) {
      return (index) => {
        return this.cardItem.checklistsClass.filter((p) => p.id === cId)[0].checklists[index];
      };
    },



    /** Funcion para retornar el index de la tarea padre para el contenedor de los item hijos */
    getChecklistPayloadSon(idClass, idFather) {
      
      let r = [];
      this.cardItem.checklistsClass.forEach(item => {
        if(item.id == idClass) {
          r = item.checklists;
        }
      })

      return (index) => {
        return r.filter((p) => p.id === idFather)[0].subItems[index];
      };
    },


    /** Funcion para aplicar el cambio de orden de las Tareas Padres */
    async onChecklistsDropFather(idClass, dropResult) {

      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {

        let cclasss = Object.assign([], this.cardItem.checklistsClass);
        let cclass = cclasss.filter((p) => p.id === idClass)[0];
        let classIndex = cclasss.indexOf(cclass);

        let newCClass = Object.assign({}, cclass);
        newCClass.checklists = this.applyDrag(newCClass.checklists, dropResult);
        cclasss.splice(classIndex, 1, newCClass);
        this.cardItem.checklistsClass = cclasss;
      
        await this.apiDragChecklist();
    
      }
    },


    /** Funcion para aplicar el cambio de orden de las Tareas Hijos */
    async onChecklistsDropSon(idClass, idFather, dropResult) {

      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {

        let cclass = this.cardItem.checklistsClass.filter((p) => p.id === idClass)[0];
        let classIndex = this.cardItem.checklistsClass.indexOf(cclass);
        let tareaPadre = cclass.checklists.filter((p) => p.id === idFather)[0];
        let tareaPadreIndex = cclass.checklists.indexOf(tareaPadre);

        tareaPadre.subItems = this.applyDrag(tareaPadre.subItems, dropResult);
        this.cardItem.checklistsClass[classIndex].checklists[tareaPadreIndex] = tareaPadre;

        await this.apiDragChecklist();
      }
    },


    /** Funcion para tomar el nuevo nivel de los item de las clases de checklist */
    async apiDragChecklist() {
      let data = [];
      for (let y = 0; y < this.cardItem.checklistsClass.length; y++) {
        data = [];
        let item = this.cardItem.checklistsClass[y];
        for (let i = 0; i < item.checklists.length; i++) {

          let item2 = item.checklists[i];
          data.push({
            cclass: item.id,
            check: item2.id,
            nivel: i + 1,
            type: 1,
            parent: 0,
          });

          for (let j = 0; j < item2.subItems.length; j++) {
            
            let item3 = item2.subItems[j];
            data.push({
              cclass: item.id,
              check: item3.id,
              nivel: j + 1,
              type: 2,
              parent: item2.id,
            });
          }
        }

        await this.updateItemsLevelInChecklistClass({ data: JSON.stringify(data), card: this.cardItemId });
        if (checkError401(this.errorLists)) { this.logOut(); }
      }


      for (let y = 0; y < this.cardItem.checklistsClass.length; y++) {
        let item = this.cardItem.checklistsClass[y];
        await this.getItemsClasssByFilter({
          class: item.id,
          filter: item.showCheck
        });
      }

    },

    /*** 
     * 
     * Funciones para el Drag an Drop de los item de las clases (Tareas)
     * 
    */






    /** Funcion para cambiar el estado del modal para asignar fecha de inicio al item */
    changeModalStartDateOneItem(item = {}) {
      if (Object.entries(item).length == 0){

        this.formStartDate = {
          id: 0,
          start_date: '',
        };

      }else{

        this.formStartDate.id = item.id;
        this.formStartDate.start_date = '1600-01-01' == item.start_date.substring(0, 10) ? '' : moment(item.start_date).format('YYYY-MM-DD');
      }

      this.modalStartDateOneItem = !this.modalStartDateOneItem;
    },


    /** Funcion para actualizar la fecha de inicion de un item */
    async editStartDateOneItem() {
      await this.updateOneItemInChecklistClass({
        id: this.formStartDate.id,
        start_date: this.formStartDate.start_date == '' ? '1600-01-01' : this.formStartDate.start_date,
      });
      if (checkError401(this.errorLists)) { this.logOut(); }
      if (this.errorLists == null) {
        this.changeModalStartDateOneItem();
        await this.getCalendarListsData(this.boardItem.id);
      }
    },


    /** Funcion para validar si debe mostrar la opcion de Asignar fecha de inicio */
    validateUserStartDate(users) {
      let r = false;

      if (Object.entries(users).length == 0) {
      
        r = true;
      
      }else {

        if(users.type == 'user') {

          if(users.email == this.userInfo.e21) {
            r = true;
          }

        } else {

          let group = JSON.parse(users.group);
          for (let i = 0; i < group.length; i++) {
            if(group[i] == this.userInfo.e21) {
              r = true;
            }
          }

        }
      }
      return r;
    },


    /** Agregar un emoji a la tarea */
    addTotext(emoji) {
      this.formItem.title = `${this.formItem.title}${emoji}`;
    },

    closeEmojisPicket(e) {
      if (!document.getElementById('alfaArea2').contains(e.target)) {
        this.showAlfa = false;
      }
    },
    /** Funciones para los Items de las clases de checklists */
  },

  mounted: async function () {
    if (!this.cardItem.template) {
      this.userAccess = filterUserAccessByTeam(this.userInfo.access, this.cardItem.team_id);
    }

    document.getElementById('emojisItems').addEventListener('emoji-click', event => this.addTotext(event.detail.unicode));
  },
}
</script>


<style scoped>
.cardCheck {
  margin-left: 10px;
  font-size: 12px !important;
  margin-bottom: -20px !important;
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #b4bdc5;
  border-radius: .25rem;
}

.font-s {
  font-size: 11.5px;
}

.tachado {
  text-decoration: line-through;
}

.art-obj1 {
  margin-left: 0px;
  padding-left: 5px;
  padding-top: 5px;
  margin-bottom: 2px;
  background-color: #f5f6f8;
  border-left: 5px solid green;
}

.art-obj2 {
  margin-left: 0px;
  padding-left: 5px;
  padding-top: 5px;
  margin-bottom: 2px;
  background-color: #f5f6f8;
  border-left: 5px solid red;
}

.art-obj {
  margin-left: 0px;
  padding-left: 5px;
  padding-top: 5px;
  margin-bottom: 2px;
  background-color: #f5f6f8;
  border-left: 5px solid #007bff;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .6s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (min-width: 300px) {
  .ms-font-size {
    font-size: 10px;
  }

  .text-flash {
    text-align: center;
  }

  .font-z {
    font-size: 11px;
  }
}

@media (min-width: 375px) {
  .ms-font-size {
    font-size: 10px;
  }

  .text-flash {
    text-align: center;
  }

  .font-z {
    font-size: 11px;
  }
}

@media (min-width: 576px) {
  .ms-font-size {
    font-size: 12px;
  }

  .text-flash {
    text-align: center;
  }

  .font-z {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .ms-font-size {
    font-size: 12px;
  }

  .text-flash {
    text-align: left;
  }

  .font-z {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .ms-font-size {
    font-size: 12px;
  }

  .text-flash {
    text-align: left;
  }

  .font-z {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .ms-font-size {
    font-size: 12px;
  }

  .text-flash {
    text-align: left;
  }

  .font-z {
    font-size: 13px;
  }

  emoji-picker {
    height: 15rem;
    width: 15rem;
    --input-font-color: #010101;
    --num-columns: 5;
  }
}

.parent-item {
  width: 94%;
  margin-left: 3%;
  min-height: 38px;
}

.child-item {
  width: 100%;
  margin-left: 0%;
  min-height: 38px;
  margin-top: 2px;
}

.container-drag-interno {
  width: 95%; 
  min-height: 55px; 
  margin-top: -8px;
  padding: 5px;
}

.container-drag-interno-child {
  width: 105%; 
  min-height: 12px; 
  margin-top: -8px;
  padding: 5px;
}

</style>