<template>
  <div>
    <Header />
    <div class="app-main">
      <Sidebar />
      {{ closeMenu() }}
      <div class="app-main__outer" @mouseenter="closeMenu()" :style="{'background-color':'white'}">
        <div class="app-main__inner">
          <div class="app-page-title" :style="{ 'background': 'rgba(238, 233, 233, 0.45)' }">
            <!-- Header del Contenido  -->
            <div class="page-title-wrapper row">
              <!-- Detalles del Tablero -->
              <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="page-title-heading">
                  <div class="page-title-icon">
                    <i class="pe-7s-gleam icon-gradient bg-amy-crisp"></i>
                  </div>
                  <div>
                    <h4>{{ t('userSettings.changeMyPlan', {}, { locale: lang }) }}</h4>
                    <div class="page-title-subheading">
                      {{ t('userSettings.infoYsettings', {}, { locale: lang }) }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- Detalles del Tablero -->

            </div>
            <!-- Header del Contenido  -->
          </div>

          <!-- Container Normal -->
          <div class="container" :style="{'max-width':'85vw !important', 'min-height':'90vh !important', 'margin-top':'3vh'}">

            <div class="row">

              <div class="col-md-12 col-lg-6 col-xl-3" v-for="(p) in plans" :key="p.id" v-show="p.cost > 0">
                <div class="card-shadow-primary profile-responsive card-border mb-3 card" :style="{'border-color': 'silver'}">
                  <div class="dropdown-menu-header" :style="{'background-color': '#fffefb'}">
                    <div class="dropdown-menu-header-inner">
                      <div class="menu-header-image opacity-2"></div>
                      <div class="menu-header-content btn-pane-right">
                        <div class="avatar-icon-wrapper mr-2 avatar-icon-xl">
                          <img src="../assets/logo-m.png" alt="Avatar 5" :style="{ 'width': '100%', 'object-fit': 'contain'}">
                        </div>
                        <div :style="{'margin-top':'15px'}" class="text-center">
                          <h3 class="widget-heading" :style="{ 'color': '#495057' }">{{ p.name }}</h3>
                          <p class="widget-subheading" :style="{ 'color': '#495057' }">{{ p.description }}</p>
                          <h1 :style="{ 'color': '#495057' }">$ {{ p.cost }} </h1>
                        </div>

                        <div class="menu-header-btn-pane">
                          <div>
                            <div role="group" class="btn-group text-center">
                              <div class="nav">

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-content" :style="{ 'border-color': 'silver' }">
                    <div class="tab-pane active show" id="tab-example-161">
                      <ul class="list-group list-group-flush">
                        <li class="p-0 list-group-item">
                          <div class="grid-menu grid-menu-2col">
                            <div class="no-gutters row">
                              <div class="col-sm-6">
                                <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                  <i class="fas fa-users btn-icon-wrapper btn-icon-lg mb-3"> </i>{{ p.members_limit }}
                                </button>
                              </div>
                              <div class="col-sm-6">
                                <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                  <i class="fas fa-mail-bulk btn-icon-wrapper btn-icon-lg mb-3"> </i>{{ p.boards_limit }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                  </div>
                  <div class="text-center d-block card-footer">
                    <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-primary" @click="showModalchangePlan(p)">
                      <i class="fas fa-cart-arrow-down"></i> {{ t('button.addToCart', {}, { locale: lang }) }}
                    </button>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <!-- Container Normal -->

        </div>
      </div>
    </div>


    <!-- Modal para confirmar el cambio de plan -->
    <div v-if="modalChangePlan">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog  modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="modalLabel"><i class=""></i>&nbsp;  
                    {{ t('userSettings.changeSubscription', {}, { locale: lang }) }}
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModalchangePlan()">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>

                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-6">
                      <h4>{{ t('userSettings.details', {}, { locale: lang }) }}</h4>

                      <table class="table">
                        <thead>
                          <tr>
                            <th width="50%">{{ t('userSettings.product', {}, { locale: lang }) }}</th>
                            <th width="25%">{{ t('userSettings.price', {}, { locale: lang }) }}</th>
                            <th width="25%">{{ t('userSettings.subtotal', {}, { locale: lang }) }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>{{ t('userSettings.traninig', {}, { locale: lang }) }}</td>
                            <td>$ {{ planItem.cost }}</td>
                            <td>$ {{ planItem.cost }}</td>
                          </tr>     
                        </tbody>
                                        
                        <tfoot>
                          <tr style="font-size: 2em;">
                            <th colspan="2" style="text-align: right; letter-spacing: 2px;">
                              {{ t('userSettings.total', {}, { locale: lang }) }}
                            </th>
                            <th><b>${{ planItem.cost }}</b></th>
                          </tr>
                        </tfoot>

                      </table>

                    </div>


                    <div class="col-md-6" id="colRightCheckout">

                      <h4 v-if="billing == undefined || billing.length == 0" class="text-center text-muted" :style="{ 'margin-top': '10px' }" >
                        <div class="text-rigth" :style="{'text-align':'right','margin':'10px'}">
                          <a data-toggle="modal" data-backdrop="static" class="btn-pill btn-wide active btn btn-outline-alternate btn-sm" @click="openModalNewCard()">
                            {{ t('button.newcard', {}, { locale: lang }) }} 
                          </a>
                        </div>
                        {{ t('userSettings.paymentMethodsMsm', {}, { locale: lang }) }}
                      </h4>

                      <table v-else  id="paymentMethodsTable" class="table">
                        <tr v-for="b in billing" :key="b.uuid">
                          <td>
                            <input v-if="b.default" :id="`check_${b.uuid}`" checked="checked" title="default" class="chk_default" name="default" type="radio"/>
                            <input v-else :id="`check_${b.uuid}`" title="default" class="chk_default" name="default" type="radio" @click="seletCredicCardToPay(b)"/>
                            <label class="far setDefault" title="default" :for="`check_${b.uuid}`"></label> 
                          </td>
                          <td>
                            <img :src="cardsIconBilling(b.type)" :style="{ 'width': '60px' }">
                            * * * *{{ b.last4 }}
                          </td>
                          <td>
                            <b :style="{ 'padding': '.0rem .0rem' }">{{ b.holdername }}</b><br>
                          </td>
                        </tr>  
                      </table>
                    </div>

                  </div>
                </div>

                <div class="modal-footer" v-if="billing != undefined || billing.length > 0">
                  <button type="button" class="btn btn-success btn-sm" @click="processPlanChange()">
                    {{ t('button.placeOrder', {}, { locale: lang }) }}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Modal para confirmar el cambio de plan -->


    <!-- Modal para agregar Tarjetas de credito -->
    <div v-if="modalAddNewCard">
      <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      {{ t('userSettings.addPaymentMethod', {}, { locale: lang }) }}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="openModalNewCard()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
        
                    <div class="row">

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="first_name" type="text" name="first_name" 
                          placeholder="First Name" 
                          class="form-control" 
                          v-model="formCreditCards.first_name"/>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="last_name" type="text" name="last_name" placeholder="Last Name"
                          class="form-control" 
                          v-model="formCreditCards.last_name"/>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <input id="address1" name="address1" type="text" placeholder="Address"
                          class="form-control" 
                          v-model="formCreditCards.address1"/>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-group">
                          <input id="address2" name="address1" type="text" placeholder="Address"
                          class="form-control" 
                          v-model="formCreditCards.address2"/>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="city" name="city" type="text" placeholder="City"
                          class="form-control" 
                          v-model="formCreditCards.city"/>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <input id="state" name="State" type="text" placeholder="FL"
                          class="form-control" 
                          v-model="formCreditCards.state"/>
                        </div>
                      </div>

                    </div>
      
                    <br>
                    <!-- Contenedor para la informacion de la tarjeta de credito -->
                    <div id="card-container" :style="{ 'margin-top': '0' }"></div>
                    <div id="payment-status-container"></div> 
                    <!-- Contenedor para la informacion de la tarjeta de credito -->
                  
                    <div class="col-md-12">
                      <button id="card-button" class="btn btn-primary btn-block btn-pill" type="button" @click="handleStoreCardMethodSubmission()">
                        {{ t('button.storeCard', {}, { locale: lang }) }}
                      </button>
                    </div>

                  </div>

                </div>
              </div>
            </div>
        </div>
      </transition>
    </div>
    <!-- Modal para agregar Tarjetas de credito -->


  </div>
</template>


<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { useI18n } from 'vue-i18n';
import { closeMenu, showInfoData, checkSuperAdminRol, checPermissions, getTeamsByPermissions, checkError401 } from '@/utility/functions';
import Header from '@/components/template/Header.vue';
import Sidebar from '@/components/template/Sidebar.vue';
import toastr from 'toastr';

export default {
  name: 'Changing Plans',
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      closeMenu,
      showInfoData,
      checkSuperAdminRol,
      checPermissions,
      getTeamsByPermissions,
      moment
    };
  },
  components: {
    Header,
    Sidebar,
  },
  data() {
    return {
      userAccess: {
        rol: {},
        team: {},
        plan: {},
        permissions: [],
        limits: {},
      },
      appId: 'sq0idp-8Vb-gFkiOlaB0sI9asg-1g',// 'sandbox-sq0idb-lqHc3vg149Bk37aA1Y8BAg'
      locationId: 'LWNMZDMNJ1VQR',
      payments: null,
      iti: null,
      card: null,
      cardsIcons: {
        VISA: require("@/assets/cardsIcon/Visa.svg"),
        MASTERCARD: require("@/assets/cardsIcon/Mastercard.svg"),
        AMERICAN_EXPRESS: require("@/assets/cardsIcon/Amex.svg"),
        DISCOVER: require("@/assets/cardsIcon/Discover.svg"),
        JCB: require("@/assets/cardsIcon/JCB.svg"),
      },
      modalChangePlan: false,
      planItem: {},
      cardItem: {},
      modalAddNewCard: false,
      formCreditCards: {
        first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
      },
    };
  },
  computed: {
    ...mapState('auth', ['userInfo', 'errorAuth']),
    ...mapState('system', ['lang']),
    ...mapState('plansData', ['plans', 'errorPlans']), 
    ...mapState('paymentsData', ['billing', 'teamToChangePlan', 'errorPayments',]),  
  },
  methods: {
    ...mapActions('auth', ['logOut', 'processChangeFromCurrentPlan']),
    ...mapActions('system', ['loadSystemBackground']),
    ...mapActions('plansData', ['getPlansData']),
    ...mapActions('paymentsData', ['getBillingsData', 'registerNewBilling', 'assignTeamForPlanChange',]),


    /** Funcion para mostrar el modal para cambiar el plan */
    async showModalchangePlan(plan = {}) {

      this.planItem = plan;
      if (Object.entries(plan).length > 0) {
        await this.getBillingsData();
        this.billing.forEach(item => {
          if (item.default == 1) {
            this.cardItem = item;
          }
        })
      }
      this.modalChangePlan = !this.modalChangePlan;

    },


    /** Funciones para la opciones de las tarjetas */

    cardsIconBilling(tp) {
      if (tp == 'VISA') {

        return this.cardsIcons.VISA;

      } else if (tp == 'MASTERCARD') {

        return this.cardsIcons.MASTERCARD;

      } else if (tp == 'AMERICAN_EXPRESS') {

        return this.cardsIcons.AMERICAN_EXPRESS;

      } else if (tp == 'DISCOVER') {

        return this.cardsIcons.DISCOVER;

      } else if (tp == 'JCB' || tp == 'DISCOVER_DINERS') {

        return this.cardsIcons.JCB;

      }
    },


    /** Funcion para seleccionar una tarjeta de credito para procesar el pago */
    seletCredicCardToPay(item) {
      this.cardItem = item;
    },


    /** Funcion para mostrar el modal para agregar Tarjetas de credito */
    async openModalNewCard() {
      this.modalAddNewCard = !this.modalAddNewCard;
      this.formCreditCards = {
        first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
      };

      if (this.modalAddNewCard) {

        /** Se inicializa el contenedor de la tarjeta de Square */
        if (!window.Square) {
          throw new Error('Square.js failed to load properly');
        }

        try {
          this.payments = window.Square.payments(this.appId, this.locationId);
        } catch (e) {
          console.error('Initializing', e);
          const statusContainer = document.getElementById('payment-status-container');
          statusContainer.className = 'missing-credentials';
          statusContainer.style.visibility = 'visible';
          return;
        }

        try {
          this.card = await this.initializeCard(this.payments);
        } catch (e) {
          console.error('Initializing Card failed', e);
          return;
        }
        /** Se inicializa el contenedor de la tarjeta de Square */
      }
    },


    /** Funcion para inicializar el contenedor de la tarjeta de credito */
    async initializeCard(payments) {
      const card = await payments.card();
      await card.attach('#card-container');
      return card;
    },


    /** Funcion para procesar el registro de la tarjeta de credito */
    async handleStoreCardMethodSubmission() {
      try {

        /* disable the submit button as we await tokenization and make a payment request. */
        const token = await this.tokenize(this.card);
        let verificationToken = await this.verifyBuyer(this.payments, token);
        const storeCardResults = await this.storeCard(token, verificationToken);

        console.debug('Store Card Success', storeCardResults);
        this.openModalNewCard();

      } catch (e) {
        console.error('Store Card Failure', e);
      }
    },


    /** Funcion generar el token de la tarjeta ingresada por el usuario */
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        throw new Error(
          `Tokenization errors: ${JSON.stringify(tokenResult.errors)}`
        );
      }
    },



    /** Funcion para verificar los datos del comprador (Tarjeta) */
    async verifyBuyer(payments, token) {

      const verificationDetails = {
        billingContact: {
          addressLines: [this.formCreditCards.address1, this.formCreditCards.address2],
          familyName: this.formCreditCards.last_name,
          givenName: this.formCreditCards.first_name,
          country: 'US',
          region: this.formCreditCards.state,
          city: this.formCreditCards.city,
        },
        intent: 'STORE',
      };

      const verificationResults = await payments.verifyBuyer(token, verificationDetails);
      return verificationResults.token;
    },


    /** Funcion para procesar los pagos de registro mediante la api */
    async storeCard(token, verificationToken) {

      const bodyParameters = {
        customerPaymentID: this.userInfo.pId,
        locationid: this.locationId,
        token: token,
        verification_token: verificationToken,
        billing_address1: this.formCreditCards.address1,
        billing_address2: this.formCreditCards.address2,
        billing_city: this.formCreditCards.city,
        billing_state: this.formCreditCards.state,
        billing_first_name: this.formCreditCards.first_name,
        billing_last_name: this.formCreditCards.last_name,
      };

      await this.registerNewBilling(bodyParameters);
    },


    /** Funciones para la opciones de las tarjetas */




    /** Funcion para procesar el cambio de plan en base al plan seleccionado */
    async processPlanChange() {
      
      if (Object.entries(this.cardItem).length > 0) {
        let body = {
          plan: this.planItem.id,
          user: this.userInfo.u21,
          card: this.cardItem.uuid,
          team: this.teamToChangePlan.id
        }

        await this.processChangeFromCurrentPlan(body);
        if (checkError401(this.errorAuth)) { this.logOut(); }
        if (this.errorAuth == null) {
          this.assignTeamForPlanChange({});
          this.showModalchangePlan();
          this.$router.push('/dashboard');
        }
      } else {
        toastr.error('There is no credit card associated with the user');
      }
    },

  },
  mounted: async function () {

    this.loadSystemBackground('');
    await this.getPlansData({ 'f1': 'active', 'f2': 'plan' });
    if (checkError401(this.errorPlans)) { this.logOut(); }
  },
}
</script> 


<style scoped>

</style>