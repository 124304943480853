
import es from '../translations/es.json';
import en from '../translations/en.json';

/** Campo obligatorio  texto*/
const requiredFields = (field, l = 'en') => {
  if (field != '') {
    return '';
  } else {
    return l == 'en' ? en.msjErrorForms['required'] : es.msjErrorForms['required'];
  }
};

/** Campo obligatorio Numero */
const requiredFieldsNumber = (field, l = 'en') => {
  if (field >= 0) {
    return '';
  } else {
    return l == 'en' ? en.msjErrorForms['required'] : es.msjErrorForms['required'];
  }
};


/** Campo obligatorio Select Numero */
const requiredFieldsNumberSelect = (field, l = 'en') => {
  if (field > 0) {
    return '';
  } else {
    return l == 'en' ? en.msjErrorForms['required'] : es.msjErrorForms['required'];
  }
};


/** Campo Contraseñas iguales */
const samePasswords = (p1, p2, l = 'en') => {
  if (p1 === p2) {
    return '';
  } else {
    return l == 'en' ? en.msjErrorForms['samePasswords'] : es.msjErrorForms['samePasswords'];
  }
};


/** Campo Correo electronico  */
const emailFields = (field, l = 'en') => {
  const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  if (emailRegex.test(field)) {
    return '';
  } else {
    return l == 'en' ? en.msjErrorForms['emailFormat'] : es.msjErrorForms['emailFormat'];
  }
};

/** Campos archivos requeridos */
const requiredFiles = (field, l = 'en') => {
  if (field != '') {
    return '';
  } else {
    return l == 'en' ? en.msjErrorForms['requiredFile'] : es.msjErrorForms['requiredFile'];
  }
};

/** Verificar que sea un numero */
const numbersType = (field, l = 'en') => {
  if (!isNaN(field)) {
    return '';
  } else {
    return l == 'en' ? en.msjErrorForms['numbersType'] : es.msjErrorForms['numbersType'];
  }
};


export { requiredFields, requiredFieldsNumber, samePasswords, emailFields, requiredFiles, numbersType, requiredFieldsNumberSelect };
