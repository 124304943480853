import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Board from '../views/Board.vue'
//import Registers from '../views/Registers.vue'
import RegistersPlans from '../views/RegistersPlans.vue'
import SystemConfig from '../views/SystemConfig.vue'
import SettingsAccount from '../views/SettingsAccount.vue'
import InfoUserPlans from '../views/InfoUserPlans.vue'
import ChangingPlans from '../views/ChangingPlans.vue'
import StoreFlashTeam from '../views/StoreFlashTeam.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/board/:code',
    name: 'Board',
    component: Board
  },
  {
    path: '/registers/:plan',
    name: 'Registers',
    component: RegistersPlans
  },
  {
    path: '/systemConfig',
    name: 'System Config',
    component: SystemConfig
  },
  {
    path: '/settingsAccount',
    name: 'Settings Account',
    component: SettingsAccount
  },
  {
    path: '/userPlans',
    name: 'Users Plans',
    component: InfoUserPlans
  },
  {
    path: '/changingPlans',
    name: 'Changing Plans',
    component: ChangingPlans
  },
  {
    path: '/storeFlash',
    name: 'Store Flash Team',
    component: StoreFlashTeam
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
