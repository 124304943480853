export default {

  actionRolsDataBegin(state) {
    state.loading = true;
  },

  actionRolsDataErr(state, err) {
    state.loading = false;
    state.errorRols = err;
  },


  /** Funcion para reiniciar la data a sus estados iniciales */
  resetDataRolsAndPermissionsSuccess(state) {
    state.loading = false;
    state.rols = [];
    state.permissions = [];
    state.errorRols = null;
  },


  /** Funciones para Obtener los roles */
  getRolsDataSuccess(state, data) {
    state.loading = false;
    state.rols = data;
    state.errorRols = null;
  },


  /** Funcion para precesar el registro de un usuario */
  registerRolsSuccess(state, data) {
    state.loading = false;
    state.rols.push(data);
    state.errorRols = null;
  },


  /** Funcion para actualizar los datos de un usuario */
  updateRolsDataSuccess(state, data) {
    state.loading = false;
    if (state.rols.length > 0) {
      state.rols.forEach(item => {
        if (item.id == data.id) {
          item.name = data.name;
          item.display_name = data.display_name;
          item.description = data.description;
          item.createdAt = data.createdAt;
        }
      });
    }
    state.errorRols = null;
  },


  /** Funcion para eliminar un rol */
  deleteRolsDataSuccess(state, data) {
    state.loading = false;
    if (state.rols.length > 0) {
      state.rols = state.rols.filter(item => item.id !== data);
    }
    state.errorRols = null;
  },


  /** Funciones para Obtener los permiso */
  getPermissionsDataSuccess(state, data) {
    state.loading = false;
    state.permissions = data;
    state.errorRols = null;
  },


  /** Funcion para precesar el registro de un permiso */
  registerPermissionsSuccess(state, data) {
    state.loading = false;
    state.permissions.push(data);
    state.errorRols = null;
  },


  /** Funcion para actualizar los datos de un permiso */
  updatePermissionsDataSuccess(state, data) {
    state.loading = false;
    if (state.permissions.length > 0) {
      state.permissions.forEach(item => {
        if (item.id == data.id) {
          item.name = data.name;
          item.display_name = data.display_name;
          item.description = data.description;
          item.createdAt = data.createdAt;
        }
      });
    }
    state.errorRols = null;
  },


  /** Funcion para eliminar un permiso */
  deletePermissionsDataSuccess(state, data) {
    state.loading = false;
    if (state.permissions.length > 0) {
      state.permissions = state.permissions.filter(item => item.id !== data);
    }
    state.errorRols = null;
  },


  /** Funcion para actualizar los datos de un usuario */
  assignPermissionsToRolSuccess(state, data) {
    state.loading = false;
    if (state.rols.length > 0) {
      state.rols.forEach(item => {
        if (item.id == data.id) {
          item.permissions = data.permissions;
        }
      });
    }
    state.errorRols = null;
  },

};